import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';

import AddCustomAssessment from './addNewDrive/AddCustomAssessment';
import AddDefaultDrive from './addNewDrive/AddDefaultDrive';
import InterviewerGuide from '../common/InterviewerGuide';
import SelectAssessmentType from './addNewDrive/SelectAssessmentType';

import '../../styles/addNewDriveModal.scss';
import AddVideoQuestions from './addNewDrive/AddVideoQuestions';
import AddCustomFields from './addNewDrive/AddCustomFields';

const AddNewDrive = (props) => {
    const { open, onClose, change, setChange, handleSnackClick, setSnackErr, companyName, selectBotMenu } = props;

    const [showSelectType, setShowSelectType] = useState(true);
    const [showDefaultForm, setShowDefaultForm] = useState(false);
    const [showCustomForm, setShowCustomForm] = useState(false);
    const [showInterviewGuide, setShowInterviewGuide] = useState(false);
    const [driveRole, setDriveRole] = useState('');
    const [bot, setBot] = useState();
    const [videoQuestions, setVideoQuestions] = useState([]);
    const [showVideoQuestion, setShowVideoQuestion] = useState(false);
    const [addVideoCount, setAddVideoCount] = useState(0);
    const [showCreateCustomAssessment, setShowCreateCustomAssessment] = useState(false);
    const [customAssessmentProblemStatement, setCustomAssessmentProblemStatement] = useState('');
    const [customFieldsData, setCustomFieldsData] = useState({});

    const openDefaultForm = (val) => {
        setBot(val);
        setShowSelectType(false);
        setShowDefaultForm(true);
    }

    const openCustomForm = () => {
        setShowSelectType(false);
        setShowCustomForm(true);
        setDriveRole('');
    }

    const openInterviewGuide = () => {
        setShowCustomForm(false);
        setShowInterviewGuide(true);
    }

    const openCustomAssessmentForm = () => {
        setShowCreateCustomAssessment(true);
        setShowCustomForm(false);
    }

    const closeCustomAssessmentForm = () => {
        setShowCreateCustomAssessment(false);
        setShowCustomForm(true);
    }

    const goBackCustomForm = () => {
        setShowCustomForm(true);
        setShowInterviewGuide(false);
        setShowVideoQuestion(false);
    }

    const openVideoQuestionForm = () => {
        if (videoQuestions.length === 0 || (videoQuestions.length !== addVideoCount)) {
            const videoQuesArr = [];
            for (let i = 0; i < addVideoCount; i++) {
                const videoQuesObj = {
                    question: ''
                };
                videoQuesArr.push(videoQuesObj);
            }
            setVideoQuestions([...videoQuesArr]);
        }
        if (addVideoCount > 0) {
            setShowCustomForm(false);
            setShowVideoQuestion(true);
        }
    }

    const goBack = () => {
        setShowCustomForm(false);
        setShowDefaultForm(false);
        setShowSelectType(true);
    }

    useEffect(() => {
        if (open) {
            setShowSelectType(true);
            setShowDefaultForm(false);
            setShowCustomForm(false);
            setShowInterviewGuide(false);
            setDriveRole('');
            setShowVideoQuestion(false);
            setShowCreateCustomAssessment(false);
            setVideoQuestions([]);
            setAddVideoCount(0);
        }
    }, [open]);

    const onChangeCustomAssessment = (val) => {
        if (val) {
            openCustomAssessmentForm();
        } else {
            closeCustomAssessmentForm();
        }
    }

    return (
        <Modal open={open} onClose={onClose} center closeOnOverlayClick={false}>
            <div id="addNewDriveModal">
                {
                    showSelectType ? (
                        <SelectAssessmentType
                            selectBotMenu={selectBotMenu}
                            openDefaultForm={openDefaultForm}
                            openCustomForm={openCustomForm}
                        />
                    ) : ''
                }
                {
                    showDefaultForm ? (
                        <AddDefaultDrive
                            goBack={goBack}
                            bot={bot}
                            companyName={companyName}
                            change={change}
                            setChange={setChange}
                            onClose={onClose}
                            handleSnackClick={handleSnackClick}
                            setSnackErr={setSnackErr}
                        />
                    ) : ''
                }
                {
                    showCustomForm ? (
                        <AddCustomAssessment
                            goBack={goBack}
                            videoQuestions={videoQuestions}
                            openInterviewGuide={openInterviewGuide}
                            driveRole={driveRole}
                            setDriveRole={setDriveRole}
                            setCustomAssessmentProblemStatement={setCustomAssessmentProblemStatement}
                            customFieldsData={customFieldsData}
                            setAddVideoCount={setAddVideoCount}
                            openVideoQuestionForm={openVideoQuestionForm}
                            onChangeCustomAssessment={onChangeCustomAssessment}
                            handleSnackClick={handleSnackClick}
                            setSnackErr={setSnackErr}
                            addVideoCount={addVideoCount}
                            onClose={onClose}
                            companyName={companyName}
                        />
                    ) : ''
                }
                {
                    showInterviewGuide ? (
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                                <div className='back-btn' onClick={goBackCustomForm}>
                                    <div></div>
                                </div>
                            </div>
                            <InterviewerGuide productId={driveRole} />
                        </div>
                    ) : ''
                }
                {
                    showVideoQuestion ? (
                        <AddVideoQuestions
                            goBackCustomForm={goBackCustomForm}
                            videoQuestions={videoQuestions}
                            setVideoQuestions={setVideoQuestions}
                        />
                    ) : ''
                }
                {
                    showCreateCustomAssessment ? (
                        <AddCustomFields
                            closeCustomAssessmentForm={closeCustomAssessmentForm}
                            driveRole={driveRole}
                            customFieldsData={customFieldsData}
                            setCustomFieldsData={setCustomFieldsData}
                            setCustomAssessmentProblemStatement={setCustomAssessmentProblemStatement}
                            customAssessmentProblemStatement={customAssessmentProblemStatement}
                        />
                    ) : ''
                }
            </div>
        </Modal>
    )
}

export default AddNewDrive;