import React, { useState, useEffect, useRef } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from "react-router-dom";
import { TourProvider } from '@reactour/tour';
import { useLocation, useHistory } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Analytics from "./pages/analytics/Analytics";
import Applicants from "./pages/applicants/Applicants";
import AssessmentHistory from './pages/freeApp/AssessmentHistory';
import Bots from './pages/bots/Bots';
import ChatboxPage from "./pages/ChatboxPage";
import DriveDetails from './pages/DriveDetails';
import DemoboxPage from "./pages/DemoboxPage";
import Company from "./pages/companies/Company";
import CreateAssessment from './pages/freeApp/CreateAssessment';
import DashboardPage from "./pages/DashboardPage";
import DemoSignInPage from './pages/DemoSignInPage';
import EmailDashboard from "./pages/emailDashboard/EmailDashboard";
import Feedback from "./pages/feedback/Feedback";
import Finalized from "./pages/finalized/Finalized";
import ForgotPassword from './pages/freeApp/ForgotPassword';
import FreeSignIn from './pages/freeApp/SignIn';
import FreeSignUp from './pages/freeApp/SignUp';
import FreeSuccess from './pages/freeApp/Success';
import InProgress from "./pages/InProgress/InProgress";
import Invalid from "./pages/invalid/Invalid";
import LocationCheck from "./LocationCheck";
import MyAccount from "./containers/MyAccount";
import NewApplicants from "./pages/newApplicants/NewApplicants";
import QuickMenu from "./pages/quickMenu/QuickMenu";
import ResetPassword from './pages/freeApp/ResetPassword';
import ReviewApplicants from "./pages/reviewApplicants/ReviewApplicants";
import Settings from "./pages/settings/Settings";
import Sidebar from "./components/sidebar/Sidebar";
import Sidebar1 from "./components/sidebar/Sidebar1";
import SidebarSuperAdmin from "./components/sidebar/SidebarSuperAdmin";
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import Success from "./pages/success/Success";
import SuperUser from './pages/superUser/SuperUser';
import Team from "./pages/team/Team";
import Upload from "./pages/emailEditor/Upload";
import Workflow from "./pages/workflows/Workflow";
import Verification from "./pages/freeApp/Verification";
import VideoSubmission from "./pages/videoSubmission/videoSubmission";
import { getSuccessMsg } from './selectors/app';
import { resetSuccessMsg as resetSuccessMsgRequest } from './actions/app';

import "./App.css";

function Dashboard(props) {
	const { resetSuccessMsg, successMsg } = props;

	const history = useHistory();
	const location = useLocation();

	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [shouldShowTour, setShouldShowTour] = useState(localStorage.getItem('userName') ? false : true);
	const [notification, setNotification] = useState({});
	const [listening, setListening] = useState(false);

	const refObj = useRef();

	useEffect(() => {
		if (!listening) {
			const events = new EventSource(`${process.env.REACT_APP_BASE_SERVER_URL_FULL}/events/${localStorage.getItem('email')}`);

			events.onmessage = (event) => {
				const parsedData = JSON.parse(event.data);

				if (!Array.isArray(parsedData)) {
					setNotification(parsedData);
				}
			};

			setListening(true);
		}
	}, [listening]);

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setGreenSnackOpen(false);
		resetSuccessMsg();
	};

	useEffect(() => {
		if (!successMsg) return;

		handleGreenSnackClick();
	}, [successMsg]);

	const styles = {
		popover: (base, state) => {
			return {
				...base,
				background: "linear-gradient(180deg, #215E80 0%, #077991 100%)",
				borderRadius: 40,
				paddingLeft: 35,
				paddingRight: 35,
				paddingTop: 20,
				paddingBottom: 20,
				maxWidth: 600,
				margin: 30,
				...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
			}
		},
	}

	const opositeSide = {
		top: 'bottom',
		bottom: 'top',
		right: 'left',
		left: 'right',
	}

	function doArrow(position, verticalAlign, horizontalAlign) {
		if (!position || position === 'custom') {
			return {}
		}
		const side = 30
		const isVertical = position === 'top' || position === 'bottom'
		const width = isVertical ? side : side * 0.866
		const height = isVertical ? side * 0.866 : side

		const color = 'rgba(33, 95, 129, 1)'

		const spaceFromSide = 10

		const obj = isVertical ? {
			'borderLeft': `${width / 2}px solid transparent`, // CSS Triangle width
			'borderRight': `${width / 2}px solid transparent`, // CSS Triangle width
			[`border${position[0].toUpperCase()}${position.substring(1)}`]:
				`${height}px solid ${color}`, // CSS Triangle height
			[isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
				width + spaceFromSide, // space from side
			[opositeSide[position]]: -height,
		} : {
			'borderTop': `${height / 2}px solid transparent`, // CSS Triangle width
			'borderBottom': `${height / 2}px solid transparent`, // CSS Triangle width
			[`border${position[0].toUpperCase()}${position.substring(1)}`]:
				`${width}px solid ${color}`, // CSS Triangle height
			[isVertical ? opositeSide[horizontalAlign] : verticalAlign]:
				height + spaceFromSide, // space from side
			[opositeSide[position]]: -width,
		};

		return {
			'&::after': {
				content: "''",
				width: 0,
				height: 0,
				position: 'absolute',
				...obj,
			},
		}
	}

	return (
		<>
			<TourProvider
				steps={[]}
				showNavigation={false}
				showCloseButton={false}
				showButtons={false}
				showNumber={false}
				disableInteraction={true}
				showBadge={false}
				onClickMask={() => { }}
				styles={styles}
				position="bottom"
				startAt={0}
				beforeClose={() => {
					if (location.pathname !== "workflows") history.push("/workflows");
				}}
			>
				<Switch>
					<Route exact path='/signup'>
						<SignUpPage />
					</Route>
					<Route exact path='/signin'>
						<SignInPage />
					</Route>

					<Route exact path="/companies">
						<SidebarSuperAdmin hidden={true} workflowId="" />
						<Company />
					</Route>
					<Route exact path="/bots">
						<SidebarSuperAdmin hidden={true} workflowId="" />
						<Bots />
					</Route>
					<Route exact path="/superUser">
						<SidebarSuperAdmin hidden={true} workflowId="" />
						<SuperUser />
					</Route>
					<Route exact path="/feedback">
						<SidebarSuperAdmin hidden={true} workflowId="" />
						<Feedback />
					</Route>
					<Route exact path="/workflows">
						<Sidebar1 hidden={true} refObj={refObj} />
						<Workflow shouldShowTour={shouldShowTour} setShouldShowTour={setShouldShowTour} notification={notification} refObj={refObj} />
					</Route>
					<Route exact path="/quickMenu">
						<LocationCheck>
							<Sidebar />
							<QuickMenu notification={notification} />
						</LocationCheck>
					</Route>
					<Route exact path="/analytics">
						<LocationCheck>
							<Sidebar />
							<Analytics notification={notification} />
						</LocationCheck>
					</Route>
					<Route exact path="/workflows/:workflowId">
						<LocationCheck>
							<Sidebar />
							<QuickMenu notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/applicants">
						<LocationCheck>
							<Sidebar />
							<Applicants notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/new-applicants">
						<LocationCheck>
							<Sidebar />
							<NewApplicants notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/email-editor">
						<LocationCheck>
							<Sidebar />
							<Upload />
						</LocationCheck>
					</Route>
					<Route path="/in-progress">
						<LocationCheck>
							<Sidebar />
							<InProgress />
						</LocationCheck>
					</Route>
					<Route path="/review-applicants">
						<LocationCheck>
							<Sidebar />
							<ReviewApplicants notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/settings">
						<LocationCheck>
							<Sidebar />
							<Settings notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/drive-details">
						<LocationCheck>
							<Sidebar />
							<DriveDetails notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/finalized">
						<LocationCheck>
							<Sidebar />
							<Finalized notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/video-submission">
						<LocationCheck>
							<Sidebar />
							<VideoSubmission notification={notification} />
						</LocationCheck>
					</Route>
					<Route path="/email-dashboard">
						<LocationCheck>
							<Sidebar />
							<EmailDashboard />
						</LocationCheck>
					</Route>
					<Route path="/demorush/:workflowId">
						<DemoSignInPage />
					</Route>
					<Route path="/dashboard/:comp/:workflowId/:user_id">
						<DashboardPage />
					</Route>
					<Route path="/chatbox">
						<ChatboxPage />
					</Route>
					<Route path="/demobox">
						<DemoboxPage />
					</Route>

					<Route path="/team">
						<LocationCheck>
							<Sidebar1 hidden={true} refObj={refObj} />
							<Team notification={notification} refObj={refObj} />
						</LocationCheck>
					</Route>

					<Route path="/my-account">
						<Sidebar1 hidden={true} />
						<MyAccount notification={notification} />
					</Route>

					<Redirect exact from='/' to='/signin' />
					<Route path="/invalid">
						<Invalid />
					</Route>
					<Route path="/success">
						<Success />
					</Route>
					<Route path="/free/signin">
						<FreeSignIn />
					</Route>
					<Route path="/free/signup">
						<FreeSignUp />
					</Route>
					<Route path="/free/verification/:token">
						<Verification />
					</Route>
					<Route path="/free/forgot-password">
						<ForgotPassword />
					</Route>
					<Route path="/free/reset-password/:token">
						<ResetPassword />
					</Route>
					<Route path="/free/home">
						<AssessmentHistory />
					</Route>
					<Route path="/free/create-assessment">
						<CreateAssessment />
					</Route>
					<Route path="/free/success">
						<FreeSuccess />
					</Route>
				</Switch>
			</TourProvider>

			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose}>
				<Alert onClose={handleGreenSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{successMsg}
				</Alert>
			</Snackbar>
		</>
	);
}

const mapStateToProps = createStructuredSelector({
	successMsg: getSuccessMsg()
});

const mapDispatchToProps = (dispatch) => ({
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest())
});

Dashboard.propTypes = {
	successMsg: PropTypes.string,
	resetSuccessMsg: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);


