import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Typography, Button } from '@material-ui/core';
import { useLocation } from 'react-router';
import { withStyles } from '@material-ui/core';

import axios from '../../../axios';

const text = {
	color: "#3AB9D6",
	fontSize: "2vh",
	margin: "auto"
};

const CustomTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: "#333",
		color: '#fff',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}))(Tooltip);

const ChatInput2 = (props) => {
	const { addToMessageBox, messages, setNextQues, setProgress } = props;

	let location = useLocation();

	const [newMessage1, setNewMessage1] = useState(0);
	const [newMessage2, setNewMessage2] = useState(0);

	const makeConvoMssg = (no1, no2) => {
		var dt = new Date();
		var h = dt.getHours(), m = dt.getMinutes();
		var _time = (h > 12) ? (h - 12 + ':' + m + ' PM') : (h + ':' + m + ' AM');
		let convoMssg = {
			q: (messages.messages[0].options[0] ? messages.messages[0].options[0] : "") + " " + no1 + " " + (messages.messages[0].options[1] ? messages.messages[0].options[1] : "") + " " + no2 + " " + (messages.messages[0].options[2] ? messages.messages[0].options[2] : ""),
			isMyMessage: true,
			time: _time
		};

		return convoMssg;
	}

	const onSendMessage = () => {
		let convoMssg = makeConvoMssg(newMessage1, newMessage2);
		const url = '/userconvo';
		let getData = {
			user_id: location.state.code,
			comp: location.state.comp,
			message1: messages.messages[0].qid,
			message2: [newMessage1, newMessage2].toString(),
			mode: 0
		};

		axios
			.post(url, getData, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				let parsedMssg = res.data.msg1[0].map((ele, index) => {
					return ({
						q: ele,
						ref: "na"
					});
				});
				parsedMssg[parsedMssg.length - 1]["qtype"] = res.data.msg1[1];
				parsedMssg[parsedMssg.length - 1]["options"] = res.data.msg1[2];
				parsedMssg[parsedMssg.length - 1]["qid"] = res.data.msg1[3];
				parsedMssg[parsedMssg.length - 1]["exhibit"] = res.data.msg1[4];
				parsedMssg[parsedMssg.length - 1]["progress"] = res.data.msg1[5];
				let respMssg = {
					q: res.data.msg2
				};
				let parsedRespMssg = respMssg.q.split(";;").map((ele) => {
					return ({
						q: ele,
						ref: "na"
					});
				});
				let finalQues = [...parsedRespMssg, convoMssg];
				sendToChatBox(finalQues, finalQues.length - 1);
				setTimeout(() => { setNextQues(parsedMssg.reverse()) }, (finalQues.length) * 1500);
				setProgress(parseInt(res.data.msg1[5], 10));
			})
			.catch((err) => {
				console.log(err);
			});
		setNewMessage1("");
		setNewMessage2("");
	}

	const sendToChatBox = (msglist, x) => {
		addToMessageBox([msglist[x]]);

		if (x > 0)
			setTimeout(() => { sendToChatBox(msglist, x - 1) }, 1500);
	};

	const onPressEnter = (event) => {
		const { key } = event;

		if (key === "Enter") {
			event.preventDefault();
			onSendMessage(newMessage1, newMessage2);
		}
	};

	const onSendButtonClick = () => {
		onSendMessage(newMessage1, newMessage2);
	};

	return (
		<div id="chat-form" style={{
			height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display: "flex", alignItems: "center", justifyItems:
				"center", padding: "0 10vw"
		}}>
			<Typography style={text}>
				{messages.messages[0].options[0]}
			</Typography>
			<input type="number" placeholder="Type..." style={{ height: "2vh", width: "12vw", borderRadius: "10px", fontSize: "2.5vh", padding: "5px 1vw", margin: "10px" }} value={newMessage1} onKeyUp={onPressEnter} onChange={(e) => setNewMessage1(e.target.value)} />
			<Typography style={text}>
				{messages.messages[0].options[1]}
			</Typography>
			<input type="number" placeholder="Type..." style={{ height: "2vh", width: "12vw", borderRadius: "10px", fontSize: "2.5vh", padding: "5px 1vw", margin: "10px" }} value={newMessage2} onKeyUp={onPressEnter} onChange={(e) => setNewMessage2(e.target.value)} />
			<Typography style={text}>
				{messages.messages[0].options[2]}
			</Typography>
			<Button onClick={onSendButtonClick} style={{ padding: 0, margin: "1vw" }}>
				<CustomTooltip title="Send">
					<img src="send.png" style={{ height: "3.5vh", margin: "10px auto 10px" }} alt='' />
				</CustomTooltip>
			</Button>
			<Button onClick={onPressEnter} style={{ padding: 0, margin: "1vw" }}>
				<img src="mic (1).png" style={{ height: "3.5vh", margin: "10px auto 10px" }} alt='' />
			</Button>
		</div>
	)
}

export default ChatInput2;
