import React, { useEffect, useRef, useState } from "react";

import "../../styles/funnel.scss";

function Funnel(props) {
	const funnelRef = useRef();
	const [funnelWidth, setFunnelWidth] = useState(0);
	const { invited, submitted, shortlisted, hired, width } = props;

	useEffect(() => {
		if(width) {
			setFunnelWidth(width);
		} else {
			setFunnelWidth(funnelRef.current.offsetWidth);
		}
	}, [width]);

	return (
		<div className="funnel" ref={funnelRef} width={funnelWidth}>
			<div className="ellipsis" style={{ height: (funnelWidth * 0.0875) }}></div>
			<div
				id="trapezium1"
				className="trapezium"
				style={{
					borderLeftWidth: (funnelWidth * 0.1),
					borderRightWidth: (funnelWidth * 0.1),
					width: (funnelWidth * 0.8),
					marginTop: (funnelWidth * (-1) * 0.04),
					borderTopWidth: (funnelWidth * 0.1375) 
				}}
			>
				<div
					className="text"
					style={{ marginTop: (funnelWidth * (-1) * 0.06), fontSize: (funnelWidth >= 400 ? 12 : 10) }}
				>
					invited{invited ? `: ${invited}` : (funnelWidth >= 400 ? ': 0' : '')}
				</div>
				{
					invited && funnelWidth < 400 ? (
						<div className="tooltip">{invited}</div>
					) : null
				}
			</div>
			<div
				className="parrallelogram parrallelogram1"
				style={{ height: (funnelWidth * 0.05), width: (funnelWidth * 0.725), marginLeft: (funnelWidth * 0.125)}}
			></div>
			<div
				id="trapezium2"
				className="trapezium"
				style={{
					borderLeftWidth: (funnelWidth * 0.1),
					borderRightWidth: (funnelWidth * 0.1),
					width: (funnelWidth * 0.55),
					marginLeft: (funnelWidth * 0.125),
					borderTopWidth: (funnelWidth * 0.1375) 
				}}
			>
				<div
					className="text"
					style={{ marginTop: (funnelWidth * (-1) * 0.085), fontSize: (funnelWidth >= 400 ? 12 : 10) }}
				>
					submitted{submitted ? `: ${submitted}` : (funnelWidth >= 400 ? ': 0' : '')}
				</div>
				{
					submitted && funnelWidth < 400 ? (
						<div className="tooltip">{submitted}</div>
					) : null
				}
			</div>
			<div
				className="parrallelogram parrallelogram2"
				style={{ height: (funnelWidth * 0.05), width: (funnelWidth * 0.475), marginLeft: (funnelWidth * 0.25)}}
			></div>
			<div
				id="trapezium3"
				className="trapezium"
				style={{
					borderLeftWidth: (funnelWidth * 0.1),
					borderRightWidth: (funnelWidth * 0.1),
					width: (funnelWidth * 0.3),
					marginLeft: (funnelWidth * 0.25),
					borderTopWidth: (funnelWidth * 0.1375) 
				}}
			>
				<div
					className="text"
					style={{ marginTop: (funnelWidth * (-1) * 0.085), fontSize: (funnelWidth >= 400 ? 12 : 10) }}
				>
					shortlisted{shortlisted ? `: ${shortlisted}` : (funnelWidth >= 400 ? ': 0' : '')}
				</div>
				{
					shortlisted && funnelWidth < 400 ? (
						<div className="tooltip">{shortlisted}</div>
					) : null
				}
			</div>
			<div
				className="parrallelogram parrallelogram3"
				style={{ height: (funnelWidth * 0.05), marginLeft: (funnelWidth * 0.375), width: (funnelWidth * 0.225)}}
			></div>
			<div
				id="trapezium4"
				className="trapezium"
				style={{
					borderLeftWidth: (funnelWidth * 0.125),
					borderRightWidth: (funnelWidth * 0.125),
					marginLeft: (funnelWidth * 0.375),
					borderTopWidth: (funnelWidth * 0.175) 
				}}
			>
				<div
					className="text"
					style={{ marginTop: (funnelWidth * (-1) * 0.1375), fontSize: (funnelWidth >= 400 ? 12 : 10) }}
				>
					hired{hired ? `: ${hired}` : (funnelWidth >= 400 ? ': 0' : '')}
				</div>
				{
					hired && funnelWidth < 400 ? (
						<div className="tooltip">{hired}</div>
					) : null
				}
			</div>
		</div>
	)
}

export default Funnel;
