import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Logo from '../../assets/logo-white.png';
import successImg from '../../assets/successImg.png';

function Success() {
	const history = useHistory();

	let redirectTimeout = setTimeout(function() {
		history.push('/free/create-assessment');
	}, 3000);;

	useEffect(() => {
		history.listen(() => {
			if(history.action === 'POP') {
				clearTimeout(redirectTimeout);
				history.push('/free/home');
			}
		});
	}, [])

	return (
		<div>

			<div
				style={{
					height: "100vh",
					width: "100vw",
					color: "#fff",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontSize: "18px",
					fontWeight: "700",
					backgroundColor: "#298d59",
					backgroundImage: "linear-gradient(to right, #23597C, #008095)"
				}}
			>
				<div style={{ width: "40%", marginRight: "15px" }}>
					<img
						src={successImg}
						alt=""
						style={{
							maxWidth: "100%"
						}}
					/>
				</div>
				<div style={{ maxWidth: "40%", marginLeft: "15px", marginTop: "50px" }}>
					<div style={{ minWidth: "350px", padding: "20px 8px", borderTop: "1px solid #fff", borderBottom: "1px solid #fff" }}>
						<p>Thanks for trying our mini sample interviewer. Hope you liked it! Start creating your customized assessment now</p>
					</div>
					<img src={Logo} style={{ maxHeight: "10vh", marginTop: "20px" }} alt="" />
				</div>

			</div>
		</div>
	)
}

export default Success
