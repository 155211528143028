import React, { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { useHistory } from "react-router";
import { Modal } from 'react-responsive-modal';
import emptyRowImg from '../../assets/emptyrow1.png';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFeedbackList } from '../../selectors/app';
import { getFeedback as getFeedbackRequest } from '../../actions/app';

function Feedback(props) {
	const {
		feedbackList,
		getFeedback
	} = props;

	const history = useHistory();
	const location = {
		state: {
			role: "super-admin"
		}
	}

	const [viewModal, setViewModal] = useState(false);
	const onOpenViewModal = () => setViewModal(true);
	const onCloseViewModal = () => setViewModal(false);

	const [pageSize, setPageSize] = useState(15);
	const [currentFeed, setCurrentFeed] = useState([]);

	useEffect(() => {
		if (location.state.role != "super-admin") {
			history.push("/invalid");
		}
	}, []);


	useEffect(() => {
		getFeedback();
	}, []);

	useEffect(() => {
		if (currentFeed.length == 0) return;

		onOpenViewModal();
	}, [currentFeed]);


	const columns = [
		{
			field: "rating",
			headerName: "Rating",
			headerAlign: "left",
			width: 130,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row datagrid__row-center">
							{params.row.rating}
						</p>
					</>
				);
			}
		},
		{
			field: "feedback",
			headerName: "Feedback",
			headerAlign: "left",
			flex: 1,
			minWidth: 250
		},
		{
			field: "createdDate",
			headerName: "Date",
			headerAlign: "left",
			width: 200,
			renderCell: (params) => {
				return (
					<>
						<p className="datagrid__row datagrid__row-center">
							{renderDate(params.row.createdDate)}
						</p>
					</>
				);
			}
		},
		{
			field: "action",
			headerName: "Actions",
			minWidth: 140,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<div className="datagrid__row datagrid__row-center">
						<Button
							className="btn-datagrid"
							size="small"
							variant="contained"
							onClick={() => setCurrentFeed([params.row])}
						>
							View
						</Button>
					</div>
				)
			}
		},
	]

	function renderDate(date) {
		let createdDate = new Date(date);
		return (
			<>{createdDate.toLocaleString()}</>
		)
	}

	function NoRowsOverlay() {
		return (
			<div className="norowsoverlay__container">
				<img
					src={emptyRowImg}
					alt="no rows"
					className="norowsoverlay__img"
				/>
				<p className="norowsoverlay__text">Nothing to show</p>
			</div>
		)
	}

	return (
		<div className="mainbar" style={{ display: "flex", flexDirection: "column", marginLeft: "20vw", width: "80vw", minHeight: "100vh" }}>
			<Modal open={viewModal} onClose={onCloseViewModal} center classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
				<div className="modal__heading">
					Info
				</div>
				{
					currentFeed.length != 0 ?
						Object.keys(currentFeed[0]).map(key => (
							<>
								<div>
									<span><b>{key} : </b></span>
									<span>{key == "createdDate" ? renderDate(currentFeed[0][key]) : currentFeed[0][key]}</span>
								</div><br />
							</>
						))
						:
						""
				}
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseViewModal}
					>
						Ok
					</Button>
				</div>
			</Modal>

			<div className="notificationbar">
			</div>
			<div className="actionbar">
			</div>

			<div className="datagrid__container">
				<DataGrid
					sortingOrder={['desc', 'asc', null]}
					components={{ NoRowsOverlay }}
					getRowId={(row) => row._id}
					rows={feedbackList}
					disableSelectionOnClick
					columns={columns}
					autoHeight
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					className="datagrid__scroll scroll-blue"
				/>
			</div>

		</div>
	);
}


const mapStateToProps = createStructuredSelector({
	feedbackList: getFeedbackList()
});

const mapDispatchToProps = (dispatch) => {
	return {
		getFeedback: () => dispatch(getFeedbackRequest()),
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);