import React, {useState, useEffect} from 'react'
import axios from '../../../axios'
// import { Button } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useSpeechRecognition } from 'react-speech-kit';
import { useLocation } from 'react-router'
import Modal from 'react-responsive-modal';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core'

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#333",
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function ChatInput({messages, addToMessageBox, setNextQues, setProgress}) {
  
  
  const [modal, setModal] = useState(false);
  const onOpenModal = () => setModal(true);
  const onCloseModal = () => setModal(false);
  const [newMessage, setNewMessage] = useState("");
  let [micOn, setMicOn] = useState(false)
  let location = useLocation()
  //const [val,setVal]=useState('')
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setNewMessage(result);
    },
  });
  const onSendMessage = (message) => {
    var dt = new Date();
    var h =  dt.getHours(), m = dt.getMinutes();
    var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
    let convoMssg = {
      q: message,
      isMyMessage: true
    }
    // addToMessageBox(convoMssg);
    setNewMessage("");
    
    const url = 'userconvo'
    let getData = {
      user_id : location.state.code,
      comp: location.state.comp,
      message1 : messages.messages[0].qid,
      message2 : message,
      mode: 0
    }
    
    console.log(getData)
    
      axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
      let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
        return({
          q: ele,
          ref: "na"
        })
      })
      parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
      parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
      parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
      parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
      parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]
      
        let respMssg = {
          q: res.data.msg2
        }
        let parsedRespMssg = respMssg.q.split(";;").map((ele)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        let finalQues = [...parsedRespMssg, convoMssg]
        // let combinedMssg = [...mssg, ...parsedRespMssg, convoMssg]
        // addToMessageBox(finalQues)
        sendToChatBox(finalQues,finalQues.length-1)
        //setTimeout(function(){addToMessageBox(finalQues)},1200)
        setTimeout(()=>{setNextQues(parsedMssg.reverse())},(finalQues.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
        console.log(messages)
      })
      .catch((err) => {
        console.log("this is err : ",err)
      })
  }

  function sendToChatBox(msglist,x){
    addToMessageBox([msglist[x]])
    if (x>0)
      setTimeout(() =>{sendToChatBox(msglist,x-1)},1500);
  }

  const onPressEnter =
    (event) => {
      const { key } = event;
      if (key === "Enter") {
        event.preventDefault(); // prevent Enter behaviour
        let trimmedNewMessage = newMessage.trim();
        console.log(trimmedNewMessage)
        if (trimmedNewMessage.length >= 1) {
          onSendMessage(trimmedNewMessage, );
        }
        else {
          return null
        }
      }

    }
    const letslisten=()=>{
      if(listening){
        stop()
        setMicOn(false)
        console.log('stopping')
      }
      else{
        listen()
        setMicOn(true)
        console.log('listening')
      }
    }
    const sendButtonClick = () => {
      let trimmedNewMessage = newMessage.trim();
        console.log(trimmedNewMessage)
        if (trimmedNewMessage.length >= 1) {
          onSendMessage(trimmedNewMessage, );
        }
        else {
          return null
        }
    }

    useEffect(() => {
      onOpenModal()
    }, [])
  return (
    <div id="chat-form" style={{position: "fixed", bottom: "0px", background: "#fff", height:"8vh", borderTopRightRadius: "16px", borderTopLeftRadius: "16px", display:"block",width:"68vw", marginTop:"5vh"}}>

      <Modal open={modal} onClose={onCloseModal} center styles={{modal:{borderRadius:"50px", padding:"40px"}}}>
         <p className="modal-text">
         In this Question Click on Mic Icon to answer by speech
         </p>
         <div className="button-div">
         <Button 
         className="modal-button" 
         onClick={()=>{
            onCloseModal()
         }} 
         style={{
            background: '#479BD2', 
            fontWeight: 'bold', 
            fontFamily:"Poppins", 
            letterSpacing:"0.01em", 
            borderRadius: '25px',
            padding: '5px 12px',
            fontSize: '16px',
            marginLeft:"10px"
         }}
         variant="contained"
         size="small"
         >
            <span style={{fontWeight: 'bold', fontSize: '16px'}}>Ok</span>
         </Button>
         </div>
      </Modal>
      {/* <Modal open={modal} onClose={onCloseModal} center classNames={{ closeIcon: 'closeIcon'}}>
        <div style={{marginTop:"3rem", padding:"15px"}}>
        </div>
        <div style={{display: "flex", flexDirection:"row-reverse"}}>
          <Button style={{back}}>
            Ok
          </Button>
        </div>
      </Modal> */}
      <input 
      type="text" 
      placeholder="Type..." 
      style={{
         float: 'left',
         boxSizing: 'border-box', 
         color: "",
         width: "100%", 
         height: "6vh", 
         borderRadius:"25px", 
         fontSize: "1.2rem", 
         padding: "5px 3vw", 
         border:"none", 
         background:micOn!="Enter Your Text"?"aliceblue":"#F8F8F8", 
         boxShadow:"0 5px 10px rgba(0, 0, 0, 0.2)"
      }}  
      value={newMessage} 
      onKeyUp={onPressEnter} 
      onChange={(e) => setNewMessage(e.target.value)}  
      disabled={micOn}
      />

      <Button 
      onClick={sendButtonClick} 
      style={{
         float: 'right',
         width: '70px',
         transform: 'translate(0,-4vh)',
         height: '0',
         overflow: 'visible'
      }}
      >
        <CustomTooltip title="Send">
          <img src="send.png" style={{height: "4vh", display:micOn?"none":""}} />
        </CustomTooltip>
      </Button>

      <Button 
      onClick={letslisten} 
      style={{
         float: 'left',
         width: '70px',
         transform: 'translate(-100%,-4vh)',
         height: '0',
         overflow: 'visible'
      }}
      >
        <CustomTooltip title={micOn?"Stop Recording":"Start Recording"}>
          <img src={micOn?"mic-off.png":"mic.png"} style={{height: "5vh"}} />
        </CustomTooltip>
      </Button>
    </div> 
  )
}