import React, { useState } from 'react';

import axios from '../../../axios';

const AddDefaultDrive = (props) => {
	const { goBack, bot, companyName, change, setChange, onClose, setSnackErr, handleSnackClick } = props;

	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');

	const onChangeName = (e) => {
        setName(e.target.value);
    }

    const onChangeDesc = (e) => {
        setDesc(e.target.value);
    }

	const sendWorkflowDefault = () => {
        if (name !== '') {
            const uri = "/company/workflow/";
            const getData = {
                workflowName: name,
                workflowDescription: desc,
                productId: {
                    isMegacase: false,
                    isCustom: true,
                    customId: bot.productId
                },
                problem: bot.description,
                title: bot.name,
                companyName: companyName,
            }

            axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
                .then(() => {
                    setChange(change + 1);
                })
                .catch((err) => {
                    console.log(err);
                });

            onClose();
        } else {
			setSnackErr('Name field is required');
            handleSnackClick();
        }
    }

	return (
		<div className='default-form'>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
				<div className='back-btn' onClick={goBack}>
					<div></div>
				</div>
				<div className='title'>Create new drive</div>
			</div>
			<div>
				<input type="text" onChange={onChangeName} value={name} placeholder='Name of drive' id="testNewDriveFlow-selector-3" />
			</div>
			<div>
				<textarea onChange={onChangeDesc} value={desc} placeholder='Add description' id="testNewDriveFlow-selector-4" />
			</div>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<div className='action' onClick={sendWorkflowDefault} style={{ padding: '10px 40px', borderRadius: 40 }} id="testNewDriveFlow-selector-5">Create</div>
			</div>
		</div>
	)
}

export default AddDefaultDrive;
