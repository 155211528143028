export const newData = [
   [{value: 'Number of current Assura customers'}, {value: '60000'}],
   [{value: 'Target customers'}, {value: '180000'}],
   [{value: 'Customer overlap'}, {value: '25000'}],
   [{value: 'Assura agents’ current annual volume of existing clients serviced'}, {value: '20000'}],
   [{value: 'Current annual new customer acquisition (Assura)'}, {value: '3%'}],
   [{value: 'Annual new customer acquisition (Insurance industry leader)'}, {value: '11%'}],
   [{value: 'Industry leading upsell / cross-sell rate in insurance'}, {value: '9%'}],
   [{value: 'Incremental upsell / cross-sell revenue growth per customer (Insurance industry leader)'}, {value: '7%'}],
   [{value: 'Incremental upsell / cross-sell revenue growth per customer (Assura)'}, {value: '3%'}],
   [{value: 'Average cash flow generated annually by Assura customer'}, {value: '650'}],
   [{value: 'Customer conversion rate from upsell / cross-sell activities (Assura)'}, {value: '6%'}],
   [{value: 'Customer conversion rate from upsell / cross-sell activities (Insurance industry leader)'}, {value: '14%'}],
   [{value: 'Maximal annual new customer outreach for Assura’s agents'}, {value: '4500'}],
   [{value: 'Assura WACC'}, {value: '7%'}],
   [{value: 'Terminal growth rate'}, {value: '1.50%'}],
   [{value: ''}, {value: ''}],
   [{value: 'Answer:'}, {value: ''}],
]

export const spreadSheetData = [
   {title: 'Number of current Assura customers', value: '60000'},
   {title: 'Target customers', value: '180000'},
   {title: 'Customer overlap', value: '25000'},
   {title: 'Assura agents’ current annual volume of existing clients serviced', value: '20000'},
   {title: 'Current annual new customer acquisition (Assura)', value: '3%'},
   {title: 'Annual new customer acquisition (Insurance industry leader)', value: '11%'},
   {title: 'Industry leading upsell / cross-sell rate in insurance', value: '9%'},
   {title: 'Incremental upsell / cross-sell revenue growth per customer (Insurance industry leader)', value: '7%'},
   {title: 'Incremental upsell / cross-sell revenue growth per customer (Assura)', value: '3%'},
   {title: 'Average cash flow generated annually by Assura customer', value: '650'},
   {title: 'Customer conversion rate from upsell / cross-sell activities (Assura)', value: '6%'},
   {title: 'Customer conversion rate from upsell / cross-sell activities (Insurance industry leader)', value: '14%'},
   {title: 'Maximal annual new customer outreach for Assura’s agents', value: '4500'},
   {title: 'Assura WACC', value: '7%'},
   {title: 'Terminal growth rate', value: '1.50%'},
   {value: 'Answer:', value: ''}
]