import React, {useState} from 'react'
import { Typography, Container } from '@material-ui/core'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { useWindowDimensions } from '../../utils/windowUtils';
import axios from "../../axios"
import { useHistory,useLocation} from 'react-router-dom'
import { Button } from '@material-ui/core';
import Modal from 'react-responsive-modal';
import '../../styles/modal.css'
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core'
import Feedback from './Feedback';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#333",
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const ProgressBar = ({progress, timerState,startT, setCaseState, messages, addToMessageBox, setIntervalId, setStopChat, toSpeak, setToSpeak}) => {

  console.log(timerState);

  const [showFeedback, setShowFeedBack] = useState(false)
  
  const [modal, setModal] = useState(false);
  const [leaveModal, setLeaveModal] = useState(false);
  const onOpenModal = () => setModal(true);
  const onCloseModal = () => setModal(false);
  const onCloseLeaveModal = () => {
    setLeaveModal(false);
    history.push('/free/success');
  }
  let location = useLocation()
  const [completedChatModal, setCompletedChatModal] = useState(false);

  const onOpenCompletedChatModal = () => {
    setCompletedChatModal(false);
    onSubmit();
  };

  const onCloseCompletedChatModal = () => {
    setCompletedChatModal(false)
    const postData = {
      user_id : location.state.code,
      comp: location.state.comp,
      mode: 3,
      message1: '',
      message2: ''
    }

    axios
      .post(url, postData)
      .then((res) => {
        console.log(res)
        // redirect to dashboard page
        setCaseState(false)
        setShowFeedBack(true);
      })
      .catch((err) => {
        console.log(err)
      })
  };

  let history = useHistory();
  const url = '/userconvo'
    const Parentdiv = {
        height: "2vh",
        backgroundColor: 'whitesmoke',
        borderRadius: 40,
        dispaly: "flex",
        margin: "auto 2vw auto 4vw",
        width: "25vw"
      }
      
      const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: "#3AB9D6",
       borderRadius:40,
        textAlign: 'right'
      }
      
      const progresstext = {
        position:"relative",
        top:"3vh",
        display: "flex",
        width:"15vw",
        // flexDirection: "column-reverse",
        padding: "0 10px",
        color: '#131B23',
        fontSize: "1.7vh",
        fontWeight: "600"
      }

      const renderTime = ({ remainingTime }) => {
        var timerhours = Math.floor(remainingTime / 3600);
        var timerminutes = -timerhours * 60 + Math.floor(remainingTime / 60);
        var timerseconds = -timerminutes * 60 - timerhours * 3600 + remainingTime;
        if (remainingTime === 0) {
          timerminutes = 0;
          timerseconds = 0;
        }
        return (
          <div className="timer bot-fifth-step" style={{position:"absolute",left:"-7vw",fontSize: "1.5vw", display:"flex", color: timerminutes>=5?"#868686":"red", fontWeight: "600", margin: "auto"}}>
            <span style={{marginRight:"0.4vh"}}>Time</span><span style={{marginRight:"1vh"}}> Left</span>
            <div className="minutesvalue">{timerminutes<10?`0${timerminutes}`:timerminutes}:</div>
            <div className="secondsvalue">{timerseconds<10?`0${timerseconds}`:timerseconds}</div>
          </div>
        );
      };

      const onClickHint = (e) => {
        const getData = {
          mode: 1,
          user_id : location.state.code,
          comp: location.state.comp,
          message1: messages.messages[0].qid,
          message2: '',
          user_id : "a"
        }
        
    
        axios
          .post(url, getData)
          .then((res) => {
            let mssg = [{
              q: res.data.message[0],
              qtype: res.data.message[1],
              options: res.data.message[2],
              qid: res.data.message[3]
            }]
            addToMessageBox(mssg)
            console.log(mssg)
            // add message to message context
          })
          .catch((err) => {
            console.log(err)
          })
      }

    const onSubmit = () => {
      setStopChat(true);
      onCloseModal();
      setLeaveModal(true);
    axios
      .post('/test/endchat', {})
      .then((res) => {
        console.log(res)
        // redirect to dashboard page
        setCaseState(false)
        setShowFeedBack(true)
      })
      .catch((err) => {
        console.log(err)
      })
    }


    return (
        <div style={{height: "12vh", background: "#E5E5E5", display: "flex"}}>
          
            <div className="bot-forth-step" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Tooltip placement="bottom" title="Progress is based on time allocation per question, and not number of questions.">
                <div style={Parentdiv}>
                    <div style={Childdiv}>
                        <span style={progresstext}>{`Progress Bar ${progress}%`}</span>
                    </div>
                </div>
              </Tooltip>
            </div>

            <div style={{display:"flex", alignItems:"flex-end", width:"100%"}}>
            <div style={{ display: "flex", padding: "0px 10px", margin: "auto 2vw auto auto", width: "10vw", flexDirection:"start"}}>
                <div style={{position: "relative", left: "7vw", display: "flex", margin:"auto" }}>

                <Modal open={completedChatModal} onClose={onCloseCompletedChatModal} center styles={{modal:{borderRadius:"50px", padding:"40px"}}}>
                <p className="modal-text">
                  You're done! Click OK to leave the chat window
                </p>
                <div className="button-div">
                  <Button 
                  className="modal-button" 
                  style={{
                     background: '#479BD2',
                     color: '#fff', 
                     fontWeight: 'bold', 
                     fontFamily:"Poppins", 
                     letterSpacing:"0.01em", 
                     borderRadius: '25px',
                     padding: '5px 12px',
                     fontSize: '16px',
                  }} 
                  onClick={()=>{
                    onCloseCompletedChatModal()
                  }} >
                  <span style={{fontSize: '16px', fontWeight: 'bold'}}>Ok</span>
                  </Button>
                </div>
              </Modal>

                <CountdownCircleTimer
                    // key = {key}
                    isPlaying={timerState}
                    duration = {10*60}
                    colors={[
                    ['#42B6D6', 1]
                    ]}
                    strokeWidth = {useWindowDimensions().height/200}
                    size = {0}
                    initialRemainingTime={10*60-startT}
                    trailColor = "#C4C4C4"
                    onComplete = {onOpenCompletedChatModal}
                    style={{margin: "auto"}}
                >
                    {renderTime}
                </CountdownCircleTimer>
                </div>
            </div>
            
            <div style={{display: "flex", margin: "auto 6.5vw", flexDirection:"column"}}>
              <Modal open={modal} onClose={onCloseModal} styles={{modal:{padding:"40px", borderRadius:"50px"}}} center>
                <p className="modal-text">
                  Are you sure you want to end the case?
                </p>
                <div className="button-div">
                  <Button 
                  className="modal-button" 
                  style={{
                     background: '#479BD2',
                     color: '#fff', 
                     fontWeight: 'bold', 
                     fontFamily:"Poppins", 
                     letterSpacing:"0.01em", 
                     borderRadius:"25px", 
                     marginLeft:"10px",
                     padding: '5px 12px',
                     fontSize: '16px',
                  }} 
                  onClick={onSubmit} >
                  <span style={{color: '#fff', fontSize: '16px', fontWeight: 'bold',textTransform: 'none'}}>Yes, submit!</span> 
                  </Button>
                  <Button 
                  className="cancel-modal-button" 
                  style={{
                     background: '#479BD2',
                     color: '#fff', 
                     fontWeight: 'bold', 
                     fontFamily:"Poppins", 
                     letterSpacing:"0.01em", 
                     borderRadius: '25px',
                     padding: '5px 12px',
                     fontSize: '16px',
                  }} 
                  onClick={() =>{
                    onCloseModal()
                  }} >
                  <span style={{color: '#fff', fontSize: '16px', fontWeight: 'bold',textTransform: 'none'}}>No, back to test</span>
                  </Button>
                </div>
              </Modal>
              <Modal open={leaveModal} onClose={onCloseLeaveModal} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
        <p className="modal-text">
        Your assessment has been submitted successfully. Click OK to end chat
        </p>
        <div className="button-div">
          <Button className="modal-button" onClick={onCloseLeaveModal} style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px',
								margin: '0 10px'
							}}
							size="small"
							variant="contained" >
            Ok
          </Button>
        </div>
      </Modal>
              <Button 
              className='bot-eighth-step'
              style={{
                  background:"#27BC36", 
                  color: '#fff', 
                  fontWeight: 'bold', 
                  fontFamily:"Poppins", 
                  letterSpacing:"0.01em", 
                  borderRadius: '25px',
                  padding: '5px 12px',
               }} 
               onClick={onOpenModal}
               >
               <span style={{color: '#fff', fontSize: '16px', fontWeight: 'bold'}}>Submit</span>
              </Button>
            </  div>
            </div>
            <Feedback showFeedback={showFeedback} setShowFeedBack={setShowFeedBack}/>
        </div>
    )
}

export default ProgressBar
