import React, { useState, useEffect, useRef } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import D3Funnel from 'd3-funnel';
import RightArrow from '../../assets/Icons/right.png';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Modal } from 'react-responsive-modal';
import { useLocation, useHistory } from 'react-router';

import AccountMenu from "../accountMenu/AccountMenu";
import axios from "../../axios";
import helperFunction from '../../helper/helperFunction';
import NotificationBell from '../notificationBell/notificationBell';
import { addNotification as addNotificationRequest } from '../../actions/app';
import { getCandidatesByType as getCandidatesByTypeRequest, updateUserSettings as updateUserSettingsRequest } from '../../actions/app';
import { getCandidatesList } from '../../selectors/app';

import emptyRowImg from '../../assets/emptyrow2.png';

import '../../styles/QuickMenu.scss';
import './QuickMenu.css';

function QuickMenu(props) {
    const { addNotification, updateUserSettings } = props
    const fin = useRef()
    const [workflow, setWorkflow] = useState()
    const [isLoading, setLoading] = useState(true)
    const [finalData, setFinalData] = useState()
    const [funnelData, setFunnelData] = useState()
    const [rows1, setRows1] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [CSVData, setCSVData] = useState([]);
    const location = useLocation()
    const history = useHistory();
    //  console.log(location.state)

    useEffect(() => {
        if (!workflow) return;

        let temp = []
        workflow.candidates.map((val) => {
            let x = { ID: val.id, name: val.name, email: val.email[0], status: val.status }
            temp.push(x);
        })
        setCSVData(temp);
    }, [workflow])

    const getScore = function (params) {
        var score = 0.0;
        var index = 0
        try {
            if (params.test && params.test.result && params.test.result.hasOwnProperty('total'))
                score = params.test.result.total
            else {
                if (params.productId.toLowerCase().includes('sale')) {
                    if (params.test.result.dab)
                        score += 0.2 * params.test.result.dab
                    if (params.test.result.lpg)
                        score += 0.2 * params.test.result.lpg
                    if (params.test.result.stk)
                        score += 0.1 * params.test.result.stk
                    if (params.test.result.ema)
                        score += 0.5 * params.test.result.ema
                }
                else {
                    for (let keys in params.row.test.result) {
                        score += params.test.result[keys]
                        index += 1
                    }
                    if (index > 0) score = score / index
                }
            }
            if (score > 0) score *= 100
        }
        catch {
            // console.log("Error in scores");
        }

        return score.toFixed();
    }

    useEffect(() => {
        if (finalData)
            setLoading(false)
    }, [finalData])


    useEffect(() => {
        axios.get(("/workflow/" + location.state.workflowId))
            .then((res) => {
                // console.log(res.data)
                localStorage.setItem('driveInvitedCount', res.data.invite.length)
                localStorage.setItem('driveSubmittedCount', res.data.review.length)
                addNotification({ driveInvitedCount: res.data.invite.length, driveSubmittedCount: res.data.review.length });
                setWorkflow(res.data)
                localStorage.setItem('settings', JSON.stringify(res.data.settings));
                updateUserSettings(res.data.settings);
                setFinalData([
                    <tr className="quickmenu__card-tr">
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-invited"></div>
                        </td>
                        <td className="quickmenu__card-td">Invited</td>
                        <td className="quickmenu__card-td">{res.data.candidates.length}</td>
                        <td className="quickmenu__card-td"><Link to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "applicants" } }} id="quickViewFlow-selector-2">View full list <ArrowForwardIcon style={{ color: "#4FCA64" }} /></Link></td>
                    </tr>,
                    <tr className="quickmenu__card-tr">
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-submitted"></div>
                        </td>
                        <td className="quickmenu__card-td">Submitted</td>
                        <td className="quickmenu__card-td">{res.data.hired.length + res.data.rejected.length + res.data.shortlisted.length + res.data.review.length + res.data.kiv.length}</td>
                        <td className="quickmenu__card-td"><Link to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "reviewApplicants" } }} id="quickViewFlow-selector-3">View full list <ArrowForwardIcon style={{ color: "#54C5EB" }} /></Link></td>
                    </tr>,
                    <tr className="quickmenu__card-tr">
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-shortlisted"></div>
                        </td>
                        <td className="quickmenu__card-td">Shortlisted</td>
                        <td className="quickmenu__card-td">{res.data.shortlisted.length}</td>
                        <td className="quickmenu__card-td"><Link to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "finalised" } }} id="quickViewFlow-selector-4">View full list <ArrowForwardIcon style={{ color: "#FFB74A" }} /></Link></td>
                    </tr>,
                    <tr className="quickmenu__card-tr">
                        <td>
                            <div className="quickmenu__card-legend quickmenu__card-legend-hired"></div>
                        </td>
                        <td className="quickmenu__card-td">Hired</td>
                        <td className="quickmenu__card-td">{res.data.hired.length}</td>
                        {/* <td><Link style={{marginLeft:'10px',textDecoration:"none", width:"100%", color:"black", fontSize: '16px', fontWeight: '600'}} to={{pathname:"/finalized", state:{workflowId: location.state.workflowId, workflowName:location.state.caseName, sidebar:"quickLook"}}}>View full list <ArrowForwardIcon style={{height: "18px", width: "20px", transform: 'scale(1.5) translate(0,2.5px)', color: `#22242C`}}/></Link></td> */}
                    </tr>])
                const dataf = [
                    { label: 'Invited', value: 100 },
                    { label: 'Submitted', value: ((res.data.hired.length + res.data.rejected.length + res.data.shortlisted.length + res.data.review.length + res.data.kiv.length) * 100.0 / res.data.candidates.length).toFixed() },
                    { label: 'Shortlisted', value: (res.data.shortlisted.length * 100.0 / res.data.candidates.length).toFixed() },
                    { label: 'Hired', value: (res.data.hired.length * 100.0 / res.data.candidates.length).toFixed() },
                ];
                const optionf = {
                    block: {
                        dynamicHeight: true,
                        dynamicSlope: false,
                        minHeight: 10,
                        fill: {
                            type: 'gradient',
                            scale: ['#4FCA64', '#54C5EB', '#FFB74A', '#22242C']
                        }
                    },
                    tooltip: {
                        enabled: true,
                        format: '{l} : {f}%'
                    },
                    label: { enabled: false },
                    chart: {
                        curve: {
                            height: 15,
                            enabled: true
                        },
                        totalCount: 200,
                        inverted: false
                    }
                };
                setFunnelData(<div className="chart" ref={fin} id='chart1' style={{ height: "150px" }}></div>)
                const chart = new D3Funnel(fin.current)
                chart.draw(dataf, optionf)
            })
            .catch((err) => {
                console.log(err)
                // helperFunction.redirectUnauthorisedUser(err, history);
            })

        let datax = {
            status: "shortlisted",
            id: location.state.workflowId
        }
        let datay = {
            status: "candidates",
            id: location.state.workflowId
        }
        axios.post("/gettype", datax)
            .then((res) => {
                setRows2(res.data)
                // setProgress(res.)
            })
            .catch(err => {
                helperFunction.redirectUnauthorisedUser(err, history);
            })
        axios.post("/gettype", datay)
            .then((res) => {
                if (res.data.length > 3)
                    setRows1(res.data)
                else
                    setRows1(res.data)
                // setProgress(res.)
            })
            .catch(err => {
                helperFunction.redirectUnauthorisedUser(err, history);
            })
    }, [])


    const [open, setOpen] = useState(false)
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    /*const lables = [
        'Invited',
        'Submited',
        'Shortlisted',
        'Hired',
      ];

    const data = [300, 50, 100, 200, 10];

      const colour = ["#4FCA64", "#54C5EB", "#FFB74A", "#22242C"]*/

    /*function renderData(){
        let finalData = []
        for(let i=0; i<lables.length;i++){
            finalData.push(<tr>
                <td>
                    <div style={{width: "15px", height: "15px", backgroundColor: `${colour[i]}`, borderRadius: "50%"}}></div>
                </td>
                <td style={{"fontFamily":"Mulish","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"23px", "color":"#404040"}}>{lables[i]}</td>
                <td style={{"fontFamily":"Mulish","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"23px", "color":"#404040"}}>{data[i]}</td>
                <td style={{"fontFamily":"Mulish","fontStyle":"normal","fontWeight":"bold","fontSize":"18px","lineHeight":"23px", "color":"#273B4A"}}><a>View Full List <ArrowForwardIcon style={{height: "18px", width: "20px", color: `${colour[i]}`}}/></Link></td>
            </tr>)
        }
        return finalData;
    }
*/
    function renderData() {
        return finalData;
    }

    function renderFunnelData() {
        return funnelData;
    }

    const getCSV = () => {
        /*axios.get('/workflow/csv/'+location.state.workflowId).then((res)=>{
          console.log(res)
        }).catch((err)=>{
          console.log(err)
        })*/
        var newwindow = window.open(process.env.REACT_APP_BASE_SERVER_URL_FULL + '/workflow/csv/' + location.state.workflowId)
        // var newwindow= window.open('http://localhost:5000/Linkpi/workflow/csv/'+location.state.workflowId)
        newwindow.focus();
        newwindow.onblur = function () { newwindow.close(); };
    }

    function renderFunnelData() {
        return funnelData;
    }


    return (
        <div className='mainbar'>
            <div className="notificationbar">
                <div className='notificationbar__icon-box'>
                    <NotificationBell />
                    <AccountMenu />
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
                <div className="modal__heading">
                    Chatbot Link
                </div>
                <p className="modal__heading-secondary">
                    {isLoading ? '' : process.env.REACT_APP_BASE_URL + "demorush/" + workflow._id}
                </p>
                <div className="modal__btnbox">
                    <Button
                        id="quickViewFlow-selector-9"
                        className="btn-primary"
                        onClick={() => {
                            onCloseModal()
                        }}
                        size="small"
                        variant="contained"
                    >
                        Okay
                    </Button>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={() => {
                            navigator.clipboard.writeText(isLoading ? '' : process.env.REACT_APP_BASE_URL + "demorush/" + workflow._id)
                            onCloseModal()
                        }}>
                        Copy
                    </Button>
                </div>
            </Modal>
            <div className="actionbar">
                <div className="actionbar__details">
                    <h2>Quick Overview</h2>
                    <p>{!isLoading ? workflow.name : null}</p>
                </div>
                <div className="actionbar__btn-box">
                    <Link
                        to={{
                            pathname: "/analytics",
                            state: {
                                workflowId: location.state.workflowId,
                                workflowName: location.state.caseName,
                                sidebar: "analytics"
                            }
                        }}>
                        <Button
                            className="btn-primary"
                            size="small"
                            variant="contained"
                            id="quickViewFlow-selector-7"
                        // onClick={() => onOpenModal()}
                        >
                            Analytics
                        </Button>
                    </Link>
                    <Button
                        className="btn-primary"
                        size="small"
                        variant="contained"
                        onClick={() => onOpenModal()}
                        id="quickViewFlow-selector-8"
                    >
                        Chatbot Link
                    </Button>

                    <Button
                        id="quickViewFlow-selector-10"
                        className="btn-primary"
                        size="small"
                        variant="contained"
                    >
                        {CSVData ? <CSVLink filename="Data.csv" data={CSVData} target="_blank">Download Report</CSVLink> : ''}
                    </Button>
                </div>
            </div>
            <div className="quickmenu__container">
                <div className="quickmenu__card-container quickmenu__card-container-flex">
                    <div className="quickmenu__card-leftbox">
                        <div className="quickmenu__card-headingbox">
                            <h3 className="quickmenu__card-heading">Overview</h3>
                        </div>
                        {/* {
                            workflow && (
                                <div className="quickmenu__card-chartbox">
                                    <Funnel
                                        width={400}
                                        invited={workflow.candidates.length}
                                        submitted={workflow.review.length}
                                        shortlisted={workflow.shortlisted.length}
                                        hired={workflow.hired.length}
                                    />
                                </div>
                            )
                        } */}
                        {renderFunnelData()}
                    </div>
                    <div className="quickmenu__card-rightbox">
                        <div>
                            <table>
                                <tbody>
                                    {renderData()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="quickmenu__card-container">
                    <div className="quickmenu__card-headingrow">
                        <div>
                            <h3 className="quickmenu__card-heading">All Applicants</h3>
                        </div>
                        <div>
                            <p className="quickmenu__card-count">Total applicants : {rows1.length}</p>
                        </div>
                        <div>
                            <Link className="quickmenu__card-link" to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "applicants" } }} id="quickViewFlow-selector-5">
                                <h3 className="quickmenu__card-fulllist">View full list <img src={RightArrow} alt="" /></h3>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='quickmenu__table-head' align="left"><span>ID</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Name</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Email</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Status</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Score</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows1.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <div
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        <img
                                                            src={emptyRowImg}
                                                            alt="no rows"
                                                            style={{
                                                                height: "350px",
                                                                width: "auto"
                                                            }}
                                                        />
                                                        <p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows1.length < 3 ? (
                                                rows1.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className='quickmenu__table-cell' component="th" scope="row" align="left"><span>{row.id}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.name}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.email}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.status == 'Complete' ? 'Submitted' : row.status}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{getScore(row)}%</span></TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                rows1.slice(0, 3).map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className='quickmenu__table-cell' component="th" scope="row" align="left"><span>{row.id}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.name}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.email}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.status == 'Complete' ? 'Submitted' : row.status}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{getScore(row)}%</span></TableCell>
                                                    </TableRow>
                                                ))
                                            )
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>

                <div className="quickmenu__card-container">
                    <div className="quickmenu__card-headingrow">
                        <div>
                            <h3 className="quickmenu__card-heading">Shortlisted</h3>
                        </div>
                        <div>
                            <p className="quickmenu__card-count">Shortlisted candidates : {rows2.length}</p>
                        </div>
                        <div>
                            <Link className="quickmenu__card-link" to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.caseName, sidebar: "finalised" } }} id="quickViewFlow-selector-6">
                                <h3 className="quickmenu__card-fulllist">View full list <img src={RightArrow} alt="" /></h3>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='quickmenu__table-head' align="left"><span>ID</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Name</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Email</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Status</span></TableCell>
                                        <TableCell className='quickmenu__table-head' align="left"><span>Score</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        rows2.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={5}>
                                                    <div
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        <img
                                                            src={emptyRowImg}
                                                            alt="no rows"
                                                            style={{
                                                                height: "350px",
                                                                width: "auto"
                                                            }}
                                                        />
                                                        <p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            rows2.length < 3 ? (
                                                rows2.map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className='quickmenu__table-cell' component="th" scope="row" align="left"><span>{row.id}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.name}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.email}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.status == 'Complete' ? 'Submitted' : row.status}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{getScore(row)}%</span></TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                rows2.slice(0, 3).map((row) => (
                                                    <TableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell className='quickmenu__table-cell' component="th" scope="row" align="left"><span>{row.id}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.name}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.email}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{row.status == 'Complete' ? 'Submitted' : row.status}</span></TableCell>
                                                        <TableCell className='quickmenu__table-cell' align="left"><span>{getScore(row)}%</span></TableCell>
                                                    </TableRow>
                                                ))
                                            )
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    candidates: getCandidatesList()
});

const mapDispatchToProps = (dispatch) => ({
    addNotification: (data) => dispatch(addNotificationRequest(data)),
    getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
    updateUserSettings: (data) => dispatch(updateUserSettingsRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickMenu);