import React, { useContext, useState } from "react";
import DragItem from "./DragItem";
import { Grid, GridItem } from "./Grid";
import GridContext from "./GridContext";
import "../../../../styles/dnd.css"

function App1() {
  const { items, moveItem, updateOptions, noOfRight } = useContext(GridContext);

  let itemsList = []
  let [hoverIndex, setHoverIndex] = useState(null) 
  const ShowList = () => {
    for(let x = 0; x < items.length; x++){
      itemsList.push(<DragItem key={items[x].index} id={items[x].index} onMoveItem={moveItem}>
        <GridItem>
          <li onMouseEnter={()=>setHoverIndex(x)} onMouseLeave={()=>setHoverIndex(null)}
          className={"option"}
            style={typoStyles}>
              <div className=".dot-grab"></div>
              <p style={{ display:"flex", fontSize: "2.4vh", color:x==hoverIndex?"rgb(8, 105, 184)":"#888", margin:"0",marginRight:"5px"}} >
                {x+1}
              </p>
              <p style={{margin: "auto"}}>
              {items[x].option}
              </p>
          </li>
          <img src="selected.png" height="24vh" style={{ display:x==hoverIndex?"flex":"none", position:"relative", margin:"0 0.5vw 0"}} />
        </GridItem>
      </DragItem>)
    }
    return itemsList
  }
  ShowList()
  return (
    <div>
      <Grid xs={12} style={{display: "flex", justifyContent: "center"}}>
        {updateOptions(items)}
        {itemsList}
      </Grid>
    </div>
  );
}

const typoStyles = {
  color: "#000",
  fontSize: "16px",
  boxShadow: "0 5px 10px rgba(0, 0, 0, 0.4)",
  padding: "1.5vh",
  margin: "2vh 0",
  borderRadius: "35px",
  lineHeight: "1.2vw",
  userSelect: "none",
  MSUserSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  cursor: "move",
  // cursor: "-webkit-grabbing",
  width: "90%",
  justifyContent: "center",
  textAlign: "center",
  display:"flex",
  alignItems: "center"
}

export default App1;
