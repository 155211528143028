import React, { useState, useEffect, useRef } from "react";
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Switch from '@mui/material/Switch';
import TextField from '@material-ui/core/TextField';
import XLSX from 'xlsx';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { IconButton } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import { Modal } from 'react-responsive-modal';
import { styled } from '@mui/material/styles';
import { useLocation, useHistory } from 'react-router';
import { useTour } from '@reactour/tour';

import axios from "../../axios";
import AccountMenu from "../accountMenu/AccountMenu";
import AddInviteEmail from "../emailEditor/AddInviteEmail";
import AddRemindEmail from "../emailEditor/AddRemindEmail";
import helperFunction from '../../helper/helperFunction';
import NotificationBell from "../notificationBell/notificationBell";
import UploadInvite from "../emailEditor/UploadInvite";
import UploadRemind from "../emailEditor/UploadRemind";
import {
	getCandidatesByType as getCandidatesByTypeRequest,
	editCandidateName as editCandidateNameRequest,
	resetSuccessMsg as resetSuccessMsgRequest,
	resetRerender as resetRerenderRequest,
	deleteCandidate as deleteCandidateRequest
} from '../../actions/app';
import { getCandidatesList, getRerender, getTotalAssessments, getUserPlan, getUserRole } from '../../selectors/app';
import { useWindowDimensions } from '../../utils/windowUtils';

import clockIcon from '../../assets/Icons/Time.png';
import Edit from '../../assets/Icons/Edit.png';
import emptyRowImg from '../../assets/emptyrow2.png';
import Trash from '../../assets/Icons/Trash.png';
import UploadIcon from '../../assets/Icons/Upload.png';

import sampleCSV from '../../assets/sampleCSV.csv';

import "./NewApplicants.css";
import 'react-responsive-modal/styles.css';
import { planAssessmentCount } from "../../constants/userPlanData";

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22,
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));


const QuickSearchToolbar = (props) => {
	const { value, onChange, clearSearch } = props;

	return (
		<div className="root">
			<div>
				<GridToolbarFilterButton />
			</div>
			<TextField
				variant="standard"
				value={value}
				onChange={onChange}
				placeholder="Search…"
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: value ? 'visible' : 'hidden' }}
							onClick={clearSearch}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</div>
	);
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

function NewApplicants(props) {
	const {
		rerender,
		editCandidateName,
		resetRerender,
		deleteCandidate,
		userPlan,
		totalAssessments,
		userRole
	} = props;

	const location = useLocation();
	const history = useHistory();
	const { isOpen, notification } = useTour();
	const windowDimensions = useWindowDimensions();

	const editNameRef = useRef(null);

	const [deleteModal, setDeleteModal] = useState(false);
	const [tempChange, setTempChange] = useState(0);
	const [convo, setConvo] = useState([]);
	const [change, setChange] = useState(0);
	const [bot, setBot] = useState();
	const [mailList, setMailList] = useState([]);
	const [data, setData] = useState([]);
	const [rows, setRows] = useState(data);
	const [loading, setLoading] = useState(false);
	const [workflowProductId, setWorkFlowProductId] = useState();
	const [inviteDisabled] = useState(false);
	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [open3, setOpen3] = useState(false);
	const [open4, setOpen4] = useState(false);
	const [csvColumns, setCsvColumns] = useState([]);
	const [tempInv, setTempInv] = useState();
	const [tempRem, setTempRem] = useState();
	const [personName, setPersonName] = useState('');
	const [personEmail, setPersonEmail] = useState('');
	const [tempp, setTemplate] = useState();
	const [tempr, setTemplateR] = useState();
	const [selectedInvTemp, setSelectedInvTemp] = useState(null);
	const [selectedRemTemp, setSelectedRemTemp] = useState(null)
	const [selectedAutoRemTemp, setSelectedAutoRemTemp] = useState(null);
	const [autoRemindHours, setAutoRemindHours] = useState(24);
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [remindCheckBox, setRemindCheckBox] = useState(true);
	const [processedData, setProcessedData] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [successMsg] = useState('Candidate Updated');
	const [errMsg, setErrMsg] = useState(null);
	const [snackOpen, setSnackOpen] = useState(false);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [selectionModel, setSelectionModel] = useState([]);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [pageSize, setPageSize] = useState(15);
	const [convo2, setConvo2] = useState([]);

	const onOpenDeleteModal = () => {
		if (selectionModel.length > 0) setDeleteModal(true);
		else {
			setErrMsg('Please select atleast one candidate')
			handleSnackClick();
		}
	};

	const onCloseDeleteModal = () => setDeleteModal(false);

	const nameEdit = (row) => {
		editCandidateName(row, editNameRef.current.value);
	}

	const handleDelete = (rowSelected) => {
		const IDs = [];
		rowSelected.map((val) => {
			IDs.push(val._id);
		});

		deleteCandidate(IDs);
	};

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (editModal) onCloseEditModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender])

	useEffect(() => {
		axios.get('/getBots').then((response) => {
			if (response.data.length > 0) {
				setConvo(response.data);
			}
		}).catch((err) => {
			console.log(err);
			helperFunction.redirectUnauthorisedUser(err, history);
		});
	}, []);

	useEffect(() => {
		axios.get(("/workflow/" + location.state.workflowId))
			.then((res) => {
				setWorkFlowProductId(res.data.productId);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [location.state.workflowId]);

	useEffect(async () => {
		setLoading(true);
		try {
			let added = await axios.post("/gettype", { status: "added", id: location.state.workflowId });
			let invite = await axios.post("/gettype", { status: "invite", id: location.state.workflowId });

			let totalData = [...added.data, ...invite.data];

			setData(totalData);
			setRows(totalData);
			setLoading(false);
		}
		catch (err) {
			console.log(err);
			setLoading(false);

			helperFunction.redirectUnauthorisedUser(err, history);
		}
	}, [change]);

	const onOpenModal = () => {
		if (userPlan.name && totalAssessments >= planAssessmentCount[userPlan.name].assessments) {
			setErrMsg('Your quota is full')
			handleSnackClick();
		} else if (userRole !== 'Admin') {
			setErrMsg('You are not authorised to do this action')
			handleSnackClick();
		} else {
			setOpen(true)
		}
	};

	const onCloseModal = () => {
		setPersonEmail('');
		setPersonName('');
		setOpen(false);
	};

	const onOpenModal2 = () => {
		if (userRole !== 'Admin') {
			setErrMsg('You are not authorised to do this action')
			handleSnackClick();
		} else {
			setOpen2(true)
		}
	};

	const onCloseModal2 = () => setOpen2(false);

	const onOpenModal3 = () => {
		setOpen3(true);

		convo.forEach((convoItem) => {
			if (workflowProductId.isCustom) {
				if (convoItem.productId === workflowProductId.customId) {
					setBot(convoItem);
				}
			} else if (workflowProductId.isMegacase) {
				if (convoItem.productId === workflowProductId.role) {
					setBot(convoItem);
				}
			} else {
				if (convoItem.productId === workflowProductId) {
					setBot(convoItem);
				}
			}
		});
	};

	const onCloseModal3 = () => {
		setSelectedInvTemp(null);
		setSelectedRemTemp(null);
		setRemindCheckBox(true);
		setAutoRemindHours(24);
		setOpen3(false)
	};

	const onOpenModal4 = () => setOpen4(true);

	const onCloseModal4 = () => setOpen4(false);

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const handleEmailCheck = (e) => {
		setPersonEmail(e.target.value);

		if (validateEmail(e.target.value)) {
			setInvalidEmail(false);
		} else {
			setInvalidEmail(true);
		}
	}

	const sendData = () => {
		const uri = "/company/workflow/candidate";
		const getData = {
			workflowId: location.state.workflowId,
			candlist: csvColumns
		};

		axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
			.then(() => {
				setChange(change + 1);
			})
			.catch((err) => {
				console.log(err);
				helperFunction.redirectUnauthorisedUser(err, history);
			});

		onCloseModal();
		setMailList(csvColumns);
		setCsvColumns([]);
		onCloseModal2();
	}
	const sendInvitetoAdded = (params) => {
		const data1 = [];

		if (params)
			data1.push({ name: params.name, email: params.email });
		else if (selectionModel.length > 0) {
			for (let index in rows) {
				if (selectionModel.includes(index))
					data1.push({ name: rows[index].name, email: rows[index].email });
			}
		}

		setMailList(data1);
		onOpenModal3();
	}

	const sendRemindertoAdded = (params) => {
		const data1 = [];

		if (params)
			data1.push({ name: params.name, email: params.email });
		else if (selectionModel.length > 0) {
			for (let index in rows) {
				if (selectionModel.includes(index))
					data1.push({ name: rows[index].name, email: rows[index].email });
			}
		}

		setMailList(data1);
		onOpenModal4();
	}

	useEffect(() => {
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'inviteEmail' }).then((res) => {
			setTempInv(res.data.mails);
			setTemplate(res.data.template);
		}).catch((err) => {
			console.log(err);
			helperFunction.redirectUnauthorisedUser(err, history);
		})
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'remindEmail' }).then((res) => {
			setTempRem(res.data.mails);
			setTemplateR(res.data.template);
		}).catch((err) => {
			console.log(err);
			helperFunction.redirectUnauthorisedUser(err, history);
		})
	}, [change, tempChange]);

	const handleRemindCheckBox = (event) => {
		setRemindCheckBox(event.target.checked);
	};

	const sendTest = () => {
		const uri = "/company/workflow/candidate/invite";
		const getData = {
			workflowId: location.state.workflowId,
			bot: bot,
			emailIndex: selectedInvTemp,
			candlist: mailList,
			remind: remindCheckBox ?
				{ shouldRemind: true, autoRemTemplate: selectedAutoRemTemp, autoRemHours: autoRemindHours }
				:
				{ shouldRemind: false, autoRemTemplate: null, autoRemHours: null }
		};

		axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				setChange(change + 1);
			})
			.catch((err) => {
				console.log(err);
				helperFunction.redirectUnauthorisedUser(err, history);
			});

		setMailList([]);
		onCloseModal3();
	}

	const sendReminder = () => {
		const uri = "/company/workflow/candidate/remind";
		const getData = {
			workflowId: location.state.workflowId,
			emailIndex: selectedRemTemp,
			candlist: mailList
		};

		axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
			.then(() => {
				setChange(change + 1);
			})
			.catch((err) => {
				console.log(err);
				helperFunction.redirectUnauthorisedUser(err, history);
			});

		setMailList([]);
		onCloseModal4();
	}

	const processData = (sheetData) => {
		if (userPlan.name && (totalAssessments + sheetData.length) > planAssessmentCount[userPlan.name].assessments) {
			setErrMsg('Your have exceeded your quota')
			handleSnackClick();
		} else {
			const list = [];
			sheetData.forEach((item) => {
				if (item.name && item.email && item.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
					list.push(item);
				} else {
					return;
				}
			});

			setProcessedData(list);
		}
	}

	const sendProcessedData = () => {
		setCsvColumns(processedData);
		setProcessedData([])
		onOpenModal3()
	}

	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = (evt) => {
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			const data = XLSX.utils.sheet_to_json(ws);

			processData(data);
		};
		reader.readAsBinaryString(file);
	}

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);

		if (searchValue == '') {
			setRows(data);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = data.filter((row) => {
				return Object.keys(row).some((field) => {
					return searchRegex.test(row[field].toString());
				});
			});

			setRows(filteredRows);
		}
	};

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackOpen(false);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setGreenSnackOpen(false);
	};

	const onOpenEditModal = () => {
		if (selectionModel.length == 1) setEditModal(true);
		else {
			setErrMsg('Please select atleast one candidate')
			handleSnackClick();
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	useEffect(() => {
		if (csvColumns.length != 0)
			sendData()
	}, [csvColumns]);

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);

		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
		else {
			let temp = [];
			for (let i = 0; i < selectionModel.length; i++) {
				temp.push(...rows.filter((row) => selectionModel[i] == row.id))
			}
			setSelectedRow(temp);
		}
	}, [selectionModel]);

	const customCol = [
		{
			field: "id",
			headerName: "ID",
			minWidth: windowDimensions.width / 15,
			headerAlign: 'center',
			align: 'center',
			flex: 1,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "5ch", width: "100%" }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: 'name',
			headerName: "Name",
			minWidth: windowDimensions.width / 8,
			flex: 1.5,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: "16px", fontWeight: "400" }}>
						{params.row.name}
					</div>
				);
			},
		},
		{
			field: 'email',
			headerName: "Email",
			minWidth: windowDimensions.width / 8,
			flex: 1.5,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: "16px", fontWeight: "400" }}>
						{params.row.email}
					</div>
				);
			},
		},
		{
			field: 'status',
			headerName: "Status",
			minWidth: windowDimensions.width / 12,
			flex: 1,
			headerAlign: 'center',
			align: 'center',
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "5ch", width: "100%" }}>
						{params.row.status}
					</div>
				)
			}
		}
	];

	if (userRole === 'Admin') {
		customCol.push(
			{
				field: 'action',
				headerName: "Action",
				minWidth: windowDimensions.width / 8,
				flex: 1,
				headerAlign: 'center',
				align: 'center',
				renderCell: (params) => {
					return (
						<div style={{ width: "150px", marginRight: '3ch' }}>
							{params.row.status == "Added" ?
								<Button
									id="manageInvitation-selector-13"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 28px',
									}}
									size="small"
									variant="contained"
									onClick={(e) => { e.stopPropagation(); sendInvitetoAdded(params.row) }}
								>
									<span style={{ fontSize: '14px', color: '#fff' }}>Invite</span>
								</Button>
								:
								<Button
									id="manageInvitation-selector-14"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 21px',
									}}
									size="small"
									variant="contained"
									onClick={(e) => { e.stopPropagation(); sendRemindertoAdded(params.row) }}
								>
									<span style={{ fontSize: '14px', color: '#fff' }}>Remind</span>
								</Button>
							}
						</div>
					)
				}
			}
		)
	}

	useEffect(() => {
		if (convo.length == 0) return;
		helperFunction.companyCheck(convo, setConvo2);
	}, [convo])

	const hanldeInviteModalValidation = () => {
		if (selectedInvTemp == null) return true;
		else {
			if (!remindCheckBox) {
				return false;
			} else if (remindCheckBox) {
				if (selectedAutoRemTemp == null) return true;
				else return false;
			}
		}
	}

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					style={{
						height: "350px",
						width: "auto"
					}}
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
			</div>
		)
	}

	return (
		<div className="container-div" style={{ overflowX: 'scroll' }}>
			<div className="inner-div" style={{ marginBottom: '10px' }}>
				<div className='top-icon-box'>
					{
						userRole === 'Admin' ? (
							<IconButton aria-label="edit" onClick={onOpenEditModal} id="manageInvitation-selector-18">
								<img src={Edit} alt="" width={30} height={30} />
							</IconButton>
						) : ''
					}
					{
						userRole === 'Admin' ? (
							<IconButton aria-label="delete" onClick={() => onOpenDeleteModal()} id="manageInvitation-selector-21">
								<img src={Trash} alt="" width={30} height={30} />
							</IconButton>
						) : ''
					}
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div >
			</div >
			<div className="userList"
				style={{
					overflowX: 'hidden'
				}}
			>
				<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
					<p className="modal-text">
						Do you want to delete this candidate?
					</p>
					<div className="button-div">
						<Button
							id="manageInvitation-selector-22"
							className="modal-button"
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px'
							}}
							size="small"
							variant="contained"
							onClick={() => {
								handleDelete(selectedRow);
								onCloseDeleteModal();
							}}>
							Yes
						</Button>
						<Button
							className="cancel-modal-button"
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px'
							}}
							size="small"
							variant="contained"
							onClick={() => {
								onCloseDeleteModal()
							}}>
							No
						</Button>
					</div>
				</Modal>
				<div className="userListTitleContainer" style={{ marginBottom: '20px', paddingTop: '10px' }}>
					<div className="inner-div-details">
						<h2>Manage Invitations</h2>
						<p>{location.state.workflowName}</p>
					</div>
					<div className="search-box" style={{ width: "35vw" }}>
						<input type="search" placeholder="Search" onChange={(event) => requestSearch(event.target.value)} />
					</div>
					<div style={{ fontSize: "15px", display: "flex", alignItems: "center" }}>
						<span style={{ display: "flex", alignItems: "center", marginRight: "10px", cursor: "pointer" }} onClick={() => {
							onOpenModal2()
						}}>
							<Button
								className="inner-div-btn"
								id="btn1"
								style={{
									background: '#479BD2',
									fontWeight: 'bold',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: "8px 20px",
									color: '#fff',
									whiteSpace: 'nowrap'
								}}
								size="small"
								variant="contained"
							>
								<span style={{ fontSize: '14px' }}>Upload List</span> <img style={{ height: "18px", width: "18px", transform: `translate(3px, 0)` }} src={UploadIcon} alt="" />
							</Button>
						</span>
						<Modal open={open3} onClose={onCloseModal3} classNames={{ modal: 'invitationModal', closeIcon: 'closeIcon' }}>
							<div className="modal-heading-workflow">
								<h3>Customise Invitation</h3>
							</div>
							<div style={{ marginTop: "20px" }}>
								{/* <FormControl fullWidth style={{ margin: "15px 0", borderRadius: "25px", background: "#eee" }}>
									<InputLabel id="select-assessment">Select assessment</InputLabel>
									<Select
										className="remove-bg"
										labelId="select-assessment"
										label="Select assessment"
										value={bot}
										onChange={(e) => { setBot(e.target.value) }}
									>
										{convo2.length == 0 ? <MenuItem ></MenuItem> :
											convo2.map((con, index) => {
												return (
													<MenuItem key={index} value={con}>{con.name}</MenuItem>
												)
											})
										}
									</Select>
								</FormControl> */}
								<FormControl fullWidth style={{ margin: "15px 0", borderRadius: 25, background: "#eee" }}>
									<InputLabel id="select-inv-template">Select invitation template</InputLabel>
									<Select
										id="manageInvitation-selector-7"
										className="remove-bg"
										labelId="select-inv-template"
										label="Select invitation template"
										value={selectedInvTemp}
										onChange={(e) => { setSelectedInvTemp(e.target.value) }}
										style={{ borderRadius: 25 }}
									>
										{tempInv?.length > 0 ?
											tempInv.map((con, index) => {
												return (
													<MenuItem key={index} value={index} id="manageInvitation-selector-8">{con.name}</MenuItem>
												)
											}) : <MenuItem ></MenuItem>}
									</Select>
								</FormControl>
								<Divider variant="middle" style={{ margin: "20px 0" }} />
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '30px' }}>
									<div>
										<h2>Auto-reminder</h2>
										<h4 style={{ color: "rgb(150,150,150)", fontWeight: "400" }}>Schedule reminder mails</h4>
									</div>
									<div style={{ alignSelf: 'flex-start' }}>
										<IOSSwitch
											id="manageInvitation-selector-9"
											sx={{ m: 1 }}
											checked={remindCheckBox}
											onChange={handleRemindCheckBox}
										/>
									</div>
								</div>
								{remindCheckBox ? (
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
										<FormControl style={{ minWidth: "230px", }}>
											<InputLabel id="auto-remind">Select reminder template</InputLabel>
											<Select
												id="manageInvitation-selector-10"
												className="remove-bg"
												labelId="auto-remind"
												label="Reminder template"
												value={selectedAutoRemTemp}
												onChange={(e) => { setSelectedAutoRemTemp(e.target.value) }}
												style={{ borderRadius: 24 }}
											>
												{tempRem?.length > 0 ?
													tempRem.map((con, index) => {
														return (
															<MenuItem key={index} value={index} id="manageInvitation-selector-11">{con.name}</MenuItem>
														)
													}) : <MenuItem ></MenuItem>}
											</Select>
										</FormControl>
										<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
											<p style={{ marginRight: "20px" }}>Remind After</p>
											<TextField
												style={{ width: "80px" }}
												label="Hours"
												variant="standard"
												value={autoRemindHours}
												onChange={(e) => { setAutoRemindHours(e.target.value) }}
												InputProps={{
													startAdornment: (
														<InputAdornment position='start'>
															<img
																src={clockIcon}
																style={{ height: '2rem', color: '#555' }}
																alt=''
															></img>
														</InputAdornment>
													),
												}}
											/>
										</div>
									</div>
								) : ''}
							</div>
							<div className="button-div">
								<Button
									id="manageInvitation-selector-12"
									className="text-transform-none"
									style={{
										background: '#479BD2',
										margin: '0 10px',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: "6px 20px",
										fontSize: '16px',
									}}
									size="small"
									variant="contained"
									disabled={hanldeInviteModalValidation()}
									onClick={() => sendTest()}
								>
									Invite now
								</Button>
								<Button
									className="text-transform-none"
									id="manageInvitation-selector-6"
									style={{
										background: '#479BD2',
										margin: '0 10px',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: "6px 20px",
										fontSize: '16px',
									}}
									size="small"
									variant="contained"
									onClick={onCloseModal3}
								>
									Invite later
								</Button>
							</div>
						</Modal>
						<Modal open={open4} onClose={onCloseModal4} classNames={{ modal: 'customModal-workflow', closeIcon: 'closeIcon' }}>
							<div className="modal-heading-workflow">
								Customise your reminder
							</div>
							<div className="input-div-workflow">
								<label><br />Select Template<br /></label>
								<Select
									id="manageInvitation-selector-15"
									className="remove-bg"
									value={setSelectedRemTemp}
									onChange={(e) => { setSelectedRemTemp(e.target.value) }}
									style={{ width: "380px", height: "45px", margin: "10px", "background": "#F2F2F2", "borderRadius": "30px" }}
								>
									<MenuItem selected>Select Template</MenuItem>
									{tempRem?.length > 0 ?
										tempRem.map((con, index) => {
											return (
												<MenuItem key={index} value={index} id="manageInvitation-selector-16">{con.name}</MenuItem>
											)
										}) : <MenuItem ></MenuItem>}
								</Select>
							</div>
							<div className="button-div">
								<Button
									id="manageInvitation-selector-17"
									className="text-transform-none"
									style={{
										background: '#479BD2',
										margin: '0 10px',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: "6px 20px",
										fontSize: '16px',
									}}
									size="small"
									variant="contained"
									onClick={() => sendReminder()}
								>
									Remind now
								</Button>
								<Button
									className="text-transform-none"
									style={{
										background: '#479BD2',
										margin: '0 10px',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: "6px 20px",
										fontSize: '16px',
									}}
									size="small"
									variant="contained"
									onClick={onCloseModal4}
								>
									Remind later
								</Button>
							</div>
						</Modal>
						<Modal open={open2} onClose={onCloseModal2} classNames={{ modal: 'customModal-workflow', closeIcon: 'closeIcon' }}>
							<div className="modal-heading-workflow">
								Upload CSV of candidates to invite
							</div>
							<div className="choose-file-div">
								<input
									type="file"
									accept=".csv"
									onChange={handleFileUpload}
								/>
							</div>
							<div>
								<a href={sampleCSV} download="sample_input_hirequotient.csv">Download sample CSV file</a>
							</div>
							<div className="button-div">
								<Button
									className="inner-div-btn"
									style={{
										background: '#479BD2',
										margin: '0 10px',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: "8px 20px",
										color: '#fff',
										fontSize: '16px'
									}}
									size="small"
									variant="contained"
									onClick={() => {
										sendProcessedData();
										onCloseModal2();
									}}
									disabled={inviteDisabled}
								>Invite
								</Button>
								<Button
									className="inner-div-btn"
									style={{
										background: '#479BD2',
										margin: '0 10px',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: "8px 20px",
										color: '#fff',
										fontSize: '16px'
									}}
									size="small"
									variant="contained"
									onClick={onCloseModal2}
								>
									Cancel
								</Button>
							</div>
						</Modal>
						<span style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => { onOpenModal() }}>
							<Button
								className="inner-div-btn"
								id="manageInvitation-selector-2"
								style={{
									background: '#479BD2',
									fontWeight: 'bold',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: "8px 20px",
									color: '#fff',
									whiteSpace: 'nowrap'
								}}
								size="small"
								variant="contained"
							>
								<span style={{ fontSize: '14px' }}>Add Applicant</span>
							</Button>
						</span>
						<Modal open={open} onClose={onCloseModal} classNames={{ modal: 'customModal-workflow', closeIcon: 'closeIcon' }}>
							<div style={{ margin: "0 auto", paddingLeft: "70px" }}>
								<div className="modal-heading-workflow">
									Invite Individual
								</div>
								<div className="input-div-applicant">
									<input id="manageInvitation-selector-3" type="text" placeholder="Name" style={{ "background": "#F2F2F2", "borderRadius": "30px" }} value={personName} onChange={(e) => { setPersonName(e.target.value) }} /><br />
									<input id="manageInvitation-selector-4" type="email" placeholder="Email" style={{ "background": "#F2F2F2", "borderRadius": "30px" }} value={personEmail} onChange={handleEmailCheck} />
									{invalidEmail ? (<p>Invalid Email</p>) : ''}
								</div>
								<div className="button-div" style={{ paddingRight: "200px" }}>
									<Button
										id="manageInvitation-selector-5"
										style={{
											background: invalidEmail || personName == '' || personEmail == '' ? 'rgba(71, 155, 210, 0.5)' : '#479BD2',
											margin: '0 10px',
											fontWeight: 'bold',
											fontFamily: "Poppins",
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: "8px 20px",
											color: '#fff',
											fontSize: '16px'
										}}
										disabled={invalidEmail || personName == '' || personEmail == ''}
										onClick={() => {
											setCsvColumns([{ name: personName, email: personEmail }]);
											onOpenModal3();
											setPersonEmail('');
											setPersonName('');
										}}
									>
										Invite
									</Button>
									<Button
										style={{
											background: '#479BD2',
											margin: '0 10px',
											fontWeight: 'bold',
											fontFamily: "Poppins",
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: "8px 20px",
											color: '#fff',
											fontSize: '16px'
										}}
										onClick={onCloseModal}
									>
										Cancel
									</Button>
								</div>
							</div>
						</Modal>
					</div>
				</div>
				<div className="cards-container">
					<div className="cards">
						<div className='add-card-container'>
							<div className='add-card-wrapper'>
								{isOpen ? <AddInviteEmail type="inviteEmail" index={1} field={""} /> : null}
								{isOpen ? <AddInviteEmail type="remindEmail" index={1} field={""} /> : null}
								{tempp ? <AddInviteEmail type="inviteEmail" index={tempInv.length} field={tempp} tempChange={tempChange} setTempChange={setTempChange} /> : null}
								{tempr ? <AddRemindEmail type="remindEmail" index={tempRem.length} field={tempr} tempChange={tempChange} setTempChange={setTempChange} /> : null}
							</div>
							<div className='upload-title'>
								<h3>Add templates</h3>
							</div>
						</div>
						{isOpen ?
							<UploadInvite
								type="inviteEmail"
								isDefault={false}
								index={1}
								field={{
									"name": "Invitation Template",
									"image": "default.png",
									"subject": "Invitation to HireQuotient Online Assessment",
									"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
									"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
									"button": "Let's Begin",
									"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
								}} /> : null}
						{isOpen ?
							<UploadRemind
								type="remindEmail"
								isDefault={false}
								index={1}
								field={{
									"name": "Invitation Template",
									"image": "default.png",
									"subject": "Invitation to HireQuotient Online Assessment",
									"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
									"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
									"button": "Let's Begin",
									"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
								}} /> : null}
						{tempInv?.length > 0 ?
							tempInv.map((temp, index) => {
								return (<UploadInvite key={index} type="inviteEmail" isDefault={false} tempChange={tempChange} setTempChange={setTempChange} index={index} field={temp} />)
							}) : null}
						{tempRem?.length > 0 ?
							tempRem.map((temp, index) => {
								return (<UploadRemind key={index} type="remindEmail" isDefault={false} tempChange={tempChange} setTempChange={setTempChange} index={index} field={temp} />)
							}) : null}
					</div>
				</div>
				{selectionModel.length > 0 ?
					<div style={{ background: "lightgrey", position: "relative", top: "0vh", minHeight: "6vh", padding: "1vh 20px 1vh", display: "flex", justifyContent: "space-between", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
						<div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}>
							<Button
								id="manageInvitation-selector-23"
								style={{
									background: '#479BD2',
									fontWeight: 'bold',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: "6px 20px",
									fontSize: '14px'
								}}
								size="small"
								variant="contained"
								onClick={() => { onOpenDeleteModal() }}
							>
								Delete
							</Button>
						</div>
					</div> : ""}
				<DataGrid
					rows={!isOpen ? rows : [{
						"_id": "61dc0299971cc34bfc531917",
						"name": "A",
						"email": [
							"ashu97j@gmail.com"
						],
						"id": "101",
						"code": "2a10jHHBkOsPKbUMB3QF5Cw4YuwaZ5uAV1Uuepy3ZbHvzFMsnYBnIZRq",
						"status": "Added",
						"lastActive": "2022-01-10T09:55:37.602Z",
						"createdDate": "2022-01-10T09:55:37.602Z",
						"company": "61730080f94921c9089ea99f",
						"workflow": "6178704387c9e1c238ae4f58",
						"productId": "ChatId4",
						"problem": "You are talking to the Head of Talent Acquisition (TA) at a well-funded Series C startup in the e-commerce space. They have had great success raising funds, and now have aggressive targets to grow the team and expand the business. Over the last 12 months, the team hired 28 people. Their target for the next 12 months is 80. ::The Head of TA is concerned that without significantly revamping the way they approach recruiting, they will not come anywhere close to meeting the hiring targets, which would greatly hinder the business’ ability to grow and scale.::She is seeking your help to ramp up their ability to hire without affecting hiring quality, while keeping the size of the HR team small. In order to meet these targets, she has received a much more generous budget than before to spend on recruiting.",
						"title": "HR Evaluative",
						"__v": 0
					}]}
					components={{ NoRowsOverlay }}
					getRowClassName={() =>
						`rowOnHover`
					}
					columns={customCol}
					checkboxSelection={userRole === 'Admin'}
					componentsProps={{
						toolbar: {
							value: searchText,
							onChange: (event) => requestSearch(event.target.value),
							clearSearch: () => requestSearch(''),
						},
					}}
					onSelectionModelChange={(newSelectionModel) => {
						setSelectionModel(newSelectionModel);
					}}
					selectionModel={selectionModel}
					autoHeight
					pageSize={pageSize}
					loading={loading}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[15, 50, 100]}
					style={{ marginBottom: "2vh", border: "none", minHeight: "500px", background: "#fff", borderRadius: "30px" }}
				/>
			</div>
			<Modal open={editModal} onClose={onCloseEditModal} center>
				<p className="modal-text">
					Edit candidate's name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								id="manageInvitation-selector-19"
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						id="manageInvitation-selector-20"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						Ok
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						Cancel
					</Button>
				</div>
			</Modal>
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose}>
				<Alert onClose={handleSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{errMsg ? errMsg : ''}
				</Alert>
			</Snackbar>

			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose}>
				<Alert onClose={handleGreenSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{successMsg ? successMsg : ''}
				</Alert>
			</Snackbar>
		</div >
	)
}

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList(),
	rerender: getRerender(),
	totalAssessments: getTotalAssessments(),
	userPlan: getUserPlan(),
	userRole: getUserRole()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
	editCandidateName: (row, newName) => dispatch(editCandidateNameRequest(row, newName)),
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	resetRerender: () => dispatch(resetRerenderRequest()),
	deleteCandidate: (data) => dispatch(deleteCandidateRequest(data))
});

NewApplicants.propTypes = {
	rerender: PropTypes.number,
	editCandidateName: PropTypes.func,
	resetRerender: PropTypes.func,
	deleteCandidate: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(NewApplicants);
