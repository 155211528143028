import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { resetPassword as resetPasswordRequest } from '../../actions/app';

import ForgotPasswordImg from '../../assets/forgot-password.png';
import Logo from '../../assets/Logo.png';

import '../../styles/freeApp/resetPassword.scss';

const ResetPassword = (props) => {
    const { resetPassword } = props;

    const { token } = useParams();
    const history = useHistory();

    const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }

    const onChangeRePassword = (e) => {
        setRePassword(e.target.value);
    }

    const onRequestLink = () => {
        if(password === rePassword) {
            const userData = {
                password,
                token
            };
    
            resetPassword({ userData, history });
        }
    }

    return (
        <div id='resetPasswordPage'>
            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fcfcfc', height: 100, alignItems: 'center' }}>
                <img src={Logo} alt='' style={{ width: 300 }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <div>
                    <img src={ForgotPasswordImg} alt='' style={{ width: 400, marginTop: -50 }} />
                </div>
                <div style={{ marginTop: -50 }}>
                    <div style={{ fontSize: 22, fontWeight: 600, color: '#646464' }}>Reset password</div>
                    <div>
                        <input style={{ padding: '16px 0', color: '#bababa', border: 'none', borderBottom: '1px solid #bababa', marginTop: 12, fontFamily: 'inherit', outline: 'none', fontSize: 18, fontWeight: '400', width: 300 }} type='text' placeholder='Enter password' onChange={onChangePassword} value={password} />
                    </div>
                    <div>
                        <input style={{ padding: '16px 0', color: '#bababa', border: 'none', borderBottom: '1px solid #bababa', marginTop: 12, fontFamily: 'inherit', outline: 'none', fontSize: 18, fontWeight: '400', width: 300 }} type='password' placeholder='Re-Enter password' onChange={onChangeRePassword} value={rePassword} autoComplete='new-password' />
                    </div>
                    <div style={{ marginTop: 45, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ marginRight: 25, backgroundColor: '#008095', borderRadius: 50, padding: '12px 24px', fontSize: 16, fontWeight: '400', color: '#fff', cursor: 'pointer' }} onClick={onRequestLink} autoComplete='new-password'>Reset</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
	resetPassword: (data) => dispatch(resetPasswordRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
