import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router';

import App1 from "./dnd/App";
import axios from '../../../axios';
import { GridProvider } from "./dnd/GridContext";

import "../../../styles/btn.css"
import styles from '../../../styles/MultiSelect.module.css';
import '../../../styles/dragAndDropQuestion.scss';

let typoStyles = {
	color: "#000",
	fontSize: "18px",
	background: "#dbdbdb",
	borderRadius: "12px",
	padding: "12px",
	margin: "10px",
	lineHeight: "20px",
	userSelect: "none",
	cursor: "grabbing",
	width: "65%",
	MSUserSelect: "none",
	MozUserSelect: "none",
	WebkitUserSelect: "none",
	textAlign: "center"
}

const Dnd = (props) => {
	const { messages, addToMessageBox, seeQues, setSeeQues, setNextQues, setProgress, addToExhibitObjs } = props;

	let currMssg = messages.messages[0].q;
	let map = messages.messages[0].options.map((option, index) => {
		return { "option": option, "index": index }
	});
	let location = useLocation();
	const no = parseInt(messages.messages[0].qtype[3]);

	const [options, updateOptions] = useState({ values: map });
	const [confirmation, setConfirmation] = useState(false);

	const onCloseConfirmation = () => setConfirmation(false);

	const onClickConfirm = () => {
		setSeeQues(true);

		let optionsSelected = [], selectedOptions = [];

		for (let x = no - 1; x >= 0; x--) {
			selectedOptions.push({ q: options[x].option, isMyMessage: true })
			optionsSelected.push(options[no - 1 - x].index)
		}

		const url = '/userconvo';
		let getData = {
			user_id: location.state.code,
			comp: location.state.comp,
			message1: messages.messages[0].qid,
			message2: optionsSelected.toString(),
			mode: 0
		};

		addToMessageBox(selectedOptions);
		axios
			.post(url, getData, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				let parsedMssg = res.data.msg1[0].map((ele, index) => {
					return ({
						q: ele,
						ref: "na"
					})
				});
				parsedMssg[parsedMssg.length - 1]["qtype"] = res.data.msg1[1];
				parsedMssg[parsedMssg.length - 1]["options"] = res.data.msg1[2];
				parsedMssg[parsedMssg.length - 1]["qid"] = res.data.msg1[3];
				parsedMssg[parsedMssg.length - 1]["progress"] = res.data.msg1[5];
				parsedMssg[parsedMssg.length - 1]["exhibit"] = res.data.msg1[4];
				let parsedRespMssg = res.data.msg2.split(";;").map((ele) => {
					return ({
						q: ele,
						ref: "na"
					})
				});

				setTimeout(() => { sendToChatBox(parsedRespMssg.reverse(), parsedRespMssg.length - 1, parsedMssg) }, optionsSelected.length * 1500);
				setProgress(parseInt(res.data.msg1[5], 10));
				addToExhibitObjs(res.data.msg1);
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const sendToChatBox = (msglist, x, ques) => {
		addToMessageBox([msglist[x]]);

		if (x > 0)
			setTimeout(() => { sendToChatBox(msglist, x - 1, ques) }, 1500);
		else
			if (ques != 'na') setNextQues(ques.reverse());
	}

	return (
		<div id="dragAndDropQuestion" style={{ background: "#fff", borderRadius: "2vh", boxShadow: "0.1rem 0.5rem 1rem grey" }}>
			<p style={{ color: "rgb(93, 172, 236)", padding: "2vh 4vw 0", fontSize: "16px", fontWeight: "600" }}> {currMssg.replaceAll('*', '').split('::').map(function (item, key) {
				return (
					<span >
						{item}
						{key === 0 ? <><br /></> : null}
						<br />
					</span>
				)
			})}</p>
			<div style={{ display: seeQues ? "none" : "flex", flexDirection: "column" }}>
				<Grid lg={12} style={{ display: confirmation ? "flex" : "none", margin: "auto " }}>
					<div style={{ padding: "10px", cursor: "pointer", width: "100%", display: "flex", justifyContent: "center" }}>
						<p style={{ width: "100%", textAlign: "center", fontSize: "1.5vw", color: "white", fontWeight: "bold" }}>Do you want to confirm the options in the following order? </p>
					</div>
				</Grid>
				{
					confirmation ?
						<Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "5vh 10vw", display: "flex", justifyContent: "center" }}>
							{
								options.slice(0, no).map((element, index) => {
									return (
										<Grid
											key={index}
											xs={12}
											style={{
												margin: "auto 0", display: "flex",
												alignItems: "stretch",
												width: "25vw",

												boxSizing: "border-box"
											}}>
											<p style={{ display: "flex", fontSize: "2.4vh", color: "#ffffff", margin: "auto" }} >
												{index + 1}.
											</p>
											<li
												className={index < no ? "selected" : ""}
												style={typoStyles}>
												{options[index].option}
											</li>
										</Grid>
									)
								})
							}
						</Grid>
						: <GridProvider options={map} updateOptions={updateOptions} noOfRight={parseInt(messages.messages[0].qtype[3], 10)}>
							<App1 />
						</GridProvider>
				}
			</div>
			<div id="chat-form" style={{ height: "70px", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display: seeQues ? "none" : "flex", justifyContent: "center", justifyContent: "flex-end" }}>
				{confirmation ? <div style={{ display: "flex", width: "40%" }}>
					<Button className={styles.SubmitButton} onClick={onCloseConfirmation} style={{}}>
						Back
					</Button>
					<Button className={styles.SubmitButton} onClick={onClickConfirm} style={{}} >
						Confirm
					</Button> </div> :
					<div>
						<Button className={styles.SubmitButton} onClick={onClickConfirm}>
							<p style={{ fontSize: '16px', fontWeight: 'bold', textTransform: "none" }}>Confirm</p>
						</Button>
					</div>
				}
			</div>
			<div id="chat-form" style={{
				display: seeQues ? "flex" : "none", height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
					"center"
			}}>
				<button className='btn btn__secondary' onClick={() => { setSeeQues(false) }}>
					See Options
				</button>
			</div>
		</div>
	)
}

Dnd.propTypes = {
	messages: PropTypes.object,
	addToMessageBox: PropTypes.func,
	seeQues: PropTypes.bool,
	setSeeQues: PropTypes.func,
	setNextQues: PropTypes.func,
	setProgress: PropTypes.func
}

export default Dnd;
