export const fixedAssessmentData = {
    'Project Manager': [
        [
            "You have just been hired as a project manager at Fire Product, a company that provides fire safety solutions to homes and offices. Fire Product has assessed that it is well positioned to enter the market for modular fire safety solutions for lithium-ion batteries in electric vehicles (EVs). Your first project is to design and develop such a solution.;;What would be your immediate priorities? You may pick either one or two out of the options below.",
            "max2",
            [
                "Ensuring the budget and manpower resources required for the project are ringfenced and available",
                "Effective two-way communication between team members and key stakeholders with defined roles and goals",
                "Define the problem by working out a full set of functional requirements for the system",
                "Diagram features for the first sprint and how it will fit into the whole system ",
                "Read the collection of user stories to better understand the project’s goals",
                "Review the highest scoring items in the project risk matrix to be aware of potential pitfalls"
            ],
            "1",
            "na",
            "0"
        ],
        [
            "An intern at Fire Product has been trying to write some draft user stories. The product owner has been unwell and has asked for your help to review the intern’s preliminary work, and to give some guidance on designing acceptance criteria. Select acceptable draft user stories from the list below to begin with. You may pick either one or two out of the options below.",
            "max2",
            [
                "As an EV driver, I need to make sure the battery does not explode to ensure my safety",
                "As an EV driver, I need to know when the chemical composition in my battery changes outside of standard parameters",
                "As an EV driver, I need to know when the temperature in my battery is unsafe so that I can stop the car",
                "As an EV owner, I need to know when my battery capacity starts to fall so that I can plan my driving trips better",
                "As an EV owner, I need chemically resistant shielding around the battery so that, if it leaks, it will not start a fire or damage the rest of the car",
                "As an EV owner, I need to know when my battery leaks so that I can get it replaced"
            ],
            "2",
            "na",
            "25"
        ],
        [
            "You pick two user stories to start with.::::User story 1: As an EV driver, I need to know when the temperature in my battery is unsafe so that I can stop the car::User story 2: As an EV owner, I need to know when my battery leaks so that I can get it replaced::The intern has suggested several possible acceptance criteria.::Select the most appropriate acceptable criteria (for either user story) from the list below.::You may pick either one or two out of the options below.",
            "max2",
            [
                "Given that the EV is not in PARK and the engine is running, when temperature of my battery exceeds a specified temperature, then there should be an alert light on my dashboard",
                "Given that the EV is not in PARK and the engine is running, when the risk of my battery catching fire exceeds a specified threshold, then there should be a continuous audible beep in the car",
                "Given that the EV is in PARK and the engine is running, when my battery catches fire, the headlights should flash and the doors should unlock",
                "Given that the EV is in PARK and the engine is running, when the battery casing integrity is compromised by more than 0.5%, then the car alarm should go off",
                "Given that the EV engine is not running, when my battery leaks, then I should receive an SMS alert",
                "Given that the EV engine is not running, when the organic gas compounds from inside my battery are detected on the casing, then the car should not start and should alert me"
            ],
            "3",
            "na",
            "50"
        ],
        [
            "Halfway through the project, you have been asked to prepare a few presentation slides to update the CEO on the current project status. What would be the least critical item(s) to prepare for your slide? You may pick either one or two out of the five options below.",
            "max2",
            [
                "A burndown chart for the current sprint",
                "A burndown chart for the whole product backlog",
                "A burndown chart for all the project’s costs",
                "A detailed risk matrix",
                "A forecasted end date based on the velocity"
            ],
            "4",
            "na",
            "75"
        ],
        ['We can end it here.', '', '', "-1", '', '100']
    ],
    "Consulting": [
        [
            "You are working with the CEO and the Head of Strategy of CorpBank, a global retail bank with a footprint in over 80 countries. Having grown rapidly through acquisition over the last 10 years, the bank is starting to re-evaluate this approach as profitability has consistently fallen short of projection. The CEO is considering whether CorpBank should start to divest under-performing units within its portfolio.::::8 years ago, they acquired Happy Bank, a local bank in Happyland, a fast-growing medium-income country with over 200m people. Performance has been disappointing, but the management team recently engaged a top strategy consultancy, McBoston, to develop a turnaround plan. Your team has been tasked with developing a recommendation on what CorpBank should do with Happy Bank.;;You gather data to help you determine which action to recommend. Rank the following pieces of data in terms of most helpful to least ?",
            "seq6",
            [
                "Projected P&L including the McBoston recommendations",
                "Comparison against CorpBank global benchmarks",
                "Original acquisition price of Happy Bank",
                "Recent bank M&A transaction multiples in Happyland",
                "Economic growth outlook for Happyland",
                "Happy Bank’s historical financials"
            ],
            "1",
            "na",
            "0"
        ],
        [
            "Your manager wants you to look at recent M&A transactions in Happyland. You do some research and pull together market data on recent transactions, and compile all the information into the following table. Based on this information, what would be the potential valuation range for Happy Bank? Please enter the maximum valuation, rounded to the nearest million.",
            "xls0",
            {
                "Workbook": {
                    "allowSave": true,
                    "allowOpen": true,
                    "enableRtl": false,
                    "locale": "en-US",
                    "enablePersistence": false,
                    "cellStyle": {
                        "fontFamily": "Calibri",
                        "verticalAlign": "bottom",
                        "textIndent": "0pt",
                        "backgroundColor": "#ffffff",
                        "color": "#000000",
                        "textAlign": "left",
                        "fontSize": "11pt",
                        "fontWeight": "normal",
                        "fontStyle": "normal",
                        "textDecoration": "none",
                        "border": "",
                        "borderLeft": "",
                        "borderTop": "",
                        "borderRight": "",
                        "borderBottom": ""
                    },
                    "showRibbon": true,
                    "showFormulaBar": true,
                    "showSheetTabs": true,
                    "allowEditing": true,
                    "enableContextMenu": true,
                    "allowAutoFill": true,
                    "selectionSettings": {
                        "mode": "Multiple"
                    },
                    "enableKeyboardNavigation": true,
                    "allowNumberFormatting": true,
                    "enableKeyboardShortcut": true,
                    "enableClipboard": true,
                    "allowCellFormatting": true,
                    "allowSorting": true,
                    "allowResizing": true,
                    "allowHyperlink": true,
                    "allowUndoRedo": true,
                    "allowFiltering": true,
                    "allowWrap": true,
                    "allowInsert": true,
                    "allowDelete": true,
                    "allowDataValidation": true,
                    "allowFindAndReplace": true,
                    "allowMerge": true,
                    "allowConditionalFormat": true,
                    "allowImage": true,
                    "allowChart": true,
                    "activeSheetIndex": 0,
                    "cssClass": "",
                    "height": "100%",
                    "width": "100%",
                    "allowScrolling": true,
                    "scrollSettings": {
                        "enableVirtualization": true,
                        "isFinite": false
                    },
                    "definedNames": [],
                    "isProtected": false,
                    "password": "",
                    "autoFillSettings": {
                        "showFillOptions": true
                    },
                    "showAggregate": true,
                    "saveUrl": "",
                    "sheets": [
                        {
                            "ranges": [
                                {}
                            ],
                            "columns": [
                                {
                                    "width": 221,
                                    "customWidth": true
                                },
                                {
                                    "width": 202,
                                    "customWidth": true
                                },
                                {
                                    "width": 223,
                                    "customWidth": true
                                },
                                {
                                    "width": 198,
                                    "customWidth": true
                                },
                                {
                                    "width": 204,
                                    "customWidth": true
                                },
                                {
                                    "width": 155,
                                    "customWidth": true
                                },
                                {
                                    "width": 143,
                                    "customWidth": true
                                },
                                {
                                    "width": 170,
                                    "customWidth": true
                                }
                            ],
                            "name": "Sheet1",
                            "rowCount": 100,
                            "colCount": 100,
                            "topLeftCell": "A1",
                            "activeCell": "D9",
                            "selectedRange": "D9:D9",
                            "usedRange": {
                                "rowIndex": 11,
                                "colIndex": 7
                            },
                            "rows": [
                                {
                                    "cells": [
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderTop": "1px solid #000000",
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt"
                                            },
                                            "value": "Bank"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderTop": "1px solid #000000",
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "fontWeight": "bold",
                                                "textAlign": "center"
                                            },
                                            "value": "Happy Bank"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderTop": "1px solid #000000",
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "fontWeight": "bold",
                                                "textAlign": "center"
                                            },
                                            "value": "Peer Bank 1"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderTop": "1px solid #000000",
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "fontWeight": "bold",
                                                "textAlign": "center"
                                            },
                                            "value": "Peer Bank 2"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderTop": "1px solid #000000",
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "fontWeight": "bold",
                                                "textAlign": "center"
                                            },
                                            "value": "Peer Bank 3"
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        }
                                    ],
                                    "height": 21
                                },
                                {
                                    "cells": [
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt"
                                            },
                                            "value": "Revenues (M)"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "color": "#222222",
                                                "textAlign": "center"
                                            },
                                            "value": 100
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 95
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 89
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 110
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt"
                                            },
                                            "value": "Net profit (M)"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "color": "#222222",
                                                "textAlign": "center"
                                            },
                                            "value": 8
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 9.8
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 10.6
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 16.5
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt"
                                            },
                                            "value": "Y-on-y profit growth (%)"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 3
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 4
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 5.5
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 3.5
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt"
                                            },
                                            "value": "Dividend yield"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": "0.012",
                                            "format": "0.00%"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": "0.021",
                                            "format": "0.00%"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": "0.018000000000000002",
                                            "format": "0.00%"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": "0.026000000000000002",
                                            "format": "0.00%"
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt"
                                            },
                                            "value": "Tangible Book Value (M)"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 98
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 105
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 114
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 179
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt"
                                            },
                                            "value": "Acquisition price"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": "?"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 147
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 148.4
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontSize": "11pt",
                                                "textAlign": "center"
                                            },
                                            "value": 214.5
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            },
                                            "value": "Maximum valuation"
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            },
                                            "value": "Fill this"
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "style": {
                                                "borderRight": "1px solid transparent",
                                                "verticalAlign": "bottom",
                                                "whiteSpace": "nowrap"
                                            },
                                            "value": "Note: UNCHANGED FROM STANDARD",
                                            "colSpan": 3
                                        },
                                        {
                                            "colSpan": -1,
                                            "style": {
                                                "borderRight": "1px solid transparent",
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "colSpan": -2,
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom",
                                                "fontStyle": "normal"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        },
                                        {
                                            "style": {
                                                "verticalAlign": "bottom"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "borderTop": "1px solid #000000"
                                            }
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": "Year 0"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": "Year 1"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": "Year 2"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": "Year 3"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": "Year 4"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": "Year 5"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": "Beyond Year 5"
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "borderTop": "1px solid #000000"
                                            },
                                            "value": "Revenues"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 100
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 110
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 125
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 135
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 146
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 157
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": "0.02",
                                            "format": "0.00%"
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "borderTop": "1px solid #000000"
                                            },
                                            "value": "Net profits"
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 8
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 3.5
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 5.5
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 13.5
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 14.6
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": 15.7
                                        },
                                        {
                                            "wrap": true,
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "verticalAlign": "bottom",
                                                "fontFamily": "Calibri",
                                                "fontSize": "11pt",
                                                "fontWeight": "normal",
                                                "textAlign": "right",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            },
                                            "value": "0.02",
                                            "format": "0.00%"
                                        }
                                    ]
                                }
                            ],
                            "showHeaders": true,
                            "showGridLines": true,
                            "state": "Visible",
                            "protectSettings": {
                                "selectCells": false
                            },
                            "isProtected": false,
                            "paneTopLeftCell": "A1",
                            "frozenRows": 0,
                            "frozenColumns": 0,
                            "index": 0,
                            "id": 1
                        }
                    ]
                }
            },
            "2",
            "na",
            "20"
        ],
        [
            "You take a look at the final presentation from the McBoston report. They have done a comprehensive strategy review and have made a number of high-impact recommendations to turn the business around. Assuming their projections are accurate, and that Happy Bank has a Weighted Average Cost of Capital of 10%, what would be the NPV of Happy Bank if all their recommendations were successfully implemented, rounded to the nearest million?",
            "num0",
            ["-"],
            "3",
            "na",
            "40"
        ],
        [
            "Your team arranges an internal discussion to determine which approach to take - should they recommend to fix the Happy Bank business and hold for the long-term, or should they recommend divesting it now. Which of the following are relevant considerations when deciding whether to fix or divest? (pick as many as apply) ",
            "mcq0",
            [
                "Recent capital injection from CorpBank into Happy Bank",
                "Opportunity cost of capital",
                "Operational separation (carve-out) costs in the event of divestment",
                "Uncertainty of forecasted revenues",
                "Economic outlook for Happyland"
            ],
            "4",
            "na",
            "60"
        ],
        [
            "Based on the information you have gathered so far, what would be your interim recommendation to the CEO - should he divest or turn around Happy Bank?",
            "txt0",
            ["-"],
            "5",
            "na",
            "80"
        ],
        ['We can end it here.', '', '', "-1", '', '100']
    ],
    "Sales": [
        [
            "You are an Account Executive at CoolTools, a B2B SaaS company that provides productivity tools to improve efficiency when teams have to work from home/remotely.;;You are working with your manager to plan your pipeline and try to to ensure that you can hit your sales targets in the coming months.;;Assuming that the average sale is $10,000, everything else remaining constant, how many more demos would you need to have each month if the monthly target goes up to $150,000?",
            "xls0",
            {
                "Workbook": {
                    "enableRtl": false,
                    "locale": "en-US",
                    "enablePersistence": false,
                    "cellStyle": {
                        "fontFamily": "Calibri",
                        "verticalAlign": "bottom",
                        "textIndent": "0pt",
                        "backgroundColor": "#ffffff",
                        "color": "#000000",
                        "textAlign": "left",
                        "fontSize": "11pt",
                        "fontWeight": "normal",
                        "fontStyle": "normal",
                        "textDecoration": "none",
                        "border": "",
                        "borderLeft": "",
                        "borderTop": "",
                        "borderRight": "",
                        "borderBottom": ""
                    },
                    "showRibbon": true,
                    "showFormulaBar": true,
                    "showSheetTabs": true,
                    "allowEditing": true,
                    "allowOpen": true,
                    "allowSave": true,
                    "enableContextMenu": true,
                    "allowAutoFill": true,
                    "selectionSettings": {
                        "mode": "Multiple"
                    },
                    "enableKeyboardNavigation": true,
                    "allowNumberFormatting": true,
                    "enableKeyboardShortcut": true,
                    "enableClipboard": true,
                    "allowCellFormatting": true,
                    "allowSorting": true,
                    "allowResizing": true,
                    "allowHyperlink": true,
                    "allowUndoRedo": true,
                    "allowFiltering": true,
                    "allowWrap": true,
                    "allowInsert": true,
                    "allowDelete": true,
                    "allowDataValidation": true,
                    "allowFindAndReplace": true,
                    "allowMerge": true,
                    "allowConditionalFormat": true,
                    "allowImage": true,
                    "allowChart": true,
                    "activeSheetIndex": 0,
                    "cssClass": "",
                    "height": "100%",
                    "width": "100%",
                    "allowScrolling": true,
                    "scrollSettings": {
                        "enableVirtualization": true,
                        "isFinite": false
                    },
                    "definedNames": [],
                    "isProtected": false,
                    "password": "",
                    "filterCollection": [],
                    "sortCollection": [],
                    "autoFillSettings": {
                        "showFillOptions": true
                    },
                    "showAggregate": true,
                    "saveUrl": "",
                    "sheets": [
                        {
                            "ranges": [
                                {}
                            ],
                            "columns": [
                                {
                                    "width": 100
                                },
                                {
                                    "width": 100,
                                    "customWidth": true
                                },
                                {
                                    "width": 203,
                                    "customWidth": true
                                },
                                {
                                    "width": 99,
                                    "customWidth": true
                                },
                                {
                                    "width": 111,
                                    "customWidth": true
                                },
                                {
                                    "width": 111,
                                    "customWidth": true
                                },
                                {
                                    "width": 111,
                                    "customWidth": true
                                },
                                {}
                            ],
                            "name": "Sheet1",
                            "rowCount": 80,
                            "colCount": 100,
                            "topLeftCell": "A1",
                            "activeCell": "A1",
                            "selectedRange": "A1:A1",
                            "usedRange": {
                                "rowIndex": 5,
                                "colIndex": 6
                            },
                            "rows": [
                                {
                                    "height": 21,
                                    "cells": [
                                        null,
                                        null,
                                        {
                                            "value": "Please enter the final value in cell D5",
                                            "colSpan": 5,
                                            "style": {
                                                "textAlign": "center",
                                                "verticalAlign": "middle",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#ffffcc"
                                            }
                                        },
                                        {
                                            "colSpan": -1,
                                            "style": {
                                                "textAlign": "center",
                                                "verticalAlign": "middle",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#ffffcc"
                                            }
                                        },
                                        {
                                            "colSpan": -2,
                                            "style": {
                                                "textAlign": "center",
                                                "verticalAlign": "middle",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#ffffcc"
                                            }
                                        },
                                        {
                                            "colSpan": -3,
                                            "style": {
                                                "textAlign": "center",
                                                "verticalAlign": "middle",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#ffffcc"
                                            }
                                        },
                                        {
                                            "colSpan": -4,
                                            "style": {
                                                "textAlign": "center",
                                                "verticalAlign": "middle",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#ffffcc"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "height": 21,
                                    "cells": [
                                        null,
                                        null,
                                        {
                                            "value": "Stage",
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#acebac",
                                                "fontSize": "12pt"
                                            }
                                        },
                                        {
                                            "value": "Demo",
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#acebac",
                                                "fontSize": "12pt"
                                            }
                                        },
                                        {
                                            "value": "Proposal",
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#acebac",
                                                "fontSize": "12pt"
                                            }
                                        },
                                        {
                                            "value": "Commitment",
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#acebac",
                                                "fontSize": "12pt"
                                            }
                                        },
                                        {
                                            "value": "Sale",
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#acebac",
                                                "fontSize": "12pt"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        null,
                                        null,
                                        {
                                            "value": "Last month",
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#acebac"
                                            }
                                        },
                                        {
                                            "value": 120,
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000"
                                            }
                                        },
                                        {
                                            "value": 36,
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000"
                                            }
                                        },
                                        {
                                            "value": 20,
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000"
                                            }
                                        },
                                        {
                                            "value": 10,
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        null,
                                        null,
                                        {
                                            "value": "2 months ago",
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "fontWeight": "bold",
                                                "backgroundColor": "#acebac"
                                            }
                                        },
                                        {
                                            "value": 110,
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000"
                                            }
                                        },
                                        {
                                            "value": 40,
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000"
                                            }
                                        },
                                        {
                                            "value": 18,
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000"
                                            }
                                        },
                                        {
                                            "value": 10,
                                            "style": {
                                                "textAlign": "center",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000"
                                            }
                                        }
                                    ]
                                },
                                {
                                    "cells": [
                                        null,
                                        null,
                                        {
                                            "value": "Demos every month (for monthly target of $150,000)",
                                            "style": {
                                                "textAlign": "center",
                                                "verticalAlign": "middle",
                                                "backgroundColor": "#acebac",
                                                "fontWeight": "bold",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000"
                                            },
                                            "wrap": true,
                                            "rowSpan": 2
                                        },
                                        {
                                            "value": "Fill this",
                                            "style": {
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000",
                                                "textAlign": "center",
                                                "backgroundColor": "#ffffcc"
                                            }
                                        }
                                    ],
                                    "height": 20
                                },
                                {
                                    "cells": [
                                        null,
                                        null,
                                        {
                                            "rowSpan": -1,
                                            "style": {
                                                "textAlign": "center",
                                                "verticalAlign": "middle",
                                                "backgroundColor": "#acebac",
                                                "fontWeight": "bold",
                                                "borderRight": "1px solid #000000",
                                                "borderTop": "1px solid #000000",
                                                "borderLeft": "1px solid #000000",
                                                "borderBottom": "1px solid #000000"
                                            },
                                            "wrap": true
                                        }
                                    ],
                                    "height": 20
                                }
                            ],
                            "showHeaders": true,
                            "showGridLines": true,
                            "state": "Visible",
                            "protectSettings": {
                                "selectCells": false
                            },
                            "isProtected": false,
                            "paneTopLeftCell": "A1",
                            "frozenRows": 0,
                            "frozenColumns": 0,
                            "index": 0
                        }
                    ]
                }
            },
            "1",
            "na",
            "0"
        ],
        [
            "To help grow the pipeline of leads, your manager has tasked you to build an outreach campaign for CoolTools.;;Rank the following steps in the correct sequence to create the campaign.",
            "seq5",
            [
                "Identify Target Audience",
                "Do a post-campaign analysis and optimise",
                "Create a budget and media strategy",
                "Optimise the channel of distribution and budget behind each channel",
                "Develop content / campaign mechanics"
            ],
            "2",
            "na",
            "20"
        ],
        [
            "One of the sales prospects that you reached out to on LinkedIn, Sharon, accepts your connection request with the message “Great to connect!”. How do you respond?",
            "txt0",
            ["-"],
            "3",
            "na",
            "40"
        ],
        [
            "Sharon, your prospect, replies positively, and you will soon be getting on your first sales call! You research her profile and company, and see that she is head of IT for a local fintech startup with around 200 people. What are the 3 most important questions you would like her to answer during your call?",
            "txt0",
            ["-"],
            "4",
            "na",
            "60"
        ],
        [
            "Your manager has asked you to prepare your pitch before your first client meeting with Sharon, and wants you to send him a short video where you introduce yourself. Record a video of up to 1 minute introducing yourself and why Sharon should listen to you.",
            "vid0",
            ["-"],
            "5",
            "na",
            "80"
        ],
        ['We can end it here.', '', '', "-1", '', '100']
    ]
}
