import React, {useState,useEffect} from 'react'
import axios from '../../../axios'
import { Button } from '@material-ui/core';
import { useSpeechRecognition } from 'react-speech-kit';
import { useLocation } from 'react-router'
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core'
import styles from '../../../styles/MultiSelect.module.css'

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#333",
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function ChatInput4({messages, addToMessageBox, setNextQues, setProgress, placeholder="Enter Your Text"}) {
  const [newMessage, setNewMessage] = useState(messages.messages[0].options[0]);
  
  const [rowH,setRowH] = useState(1)
  console.log(messages.messages[0].options[0])
  let [micOn, setMicOn] = useState(false)
  let location = useLocation()
  //const [val,setVal]=useState('')
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setNewMessage(result);
    },
  });

  useEffect(()=>{
    const rowlen = newMessage.split('\n').length
    setRowH(rowlen)
  },[newMessage])
  const onSendMessage = (message) => {
    var dt = new Date();
    var h =  dt.getHours(), m = dt.getMinutes();
    var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
    let convoMssg = {
      q: message.replaceAll('\n','::'),
      isMyMessage: true
    }
    addToMessageBox([convoMssg]);
    setNewMessage("");
    
    const url = 'userconvo'
    let getData = {
      user_id : location.state.code,
      comp: location.state.comp,
      message1 : messages.messages[0].qid,
      message2 : message,
      mode: 0
    }
    
    console.log(getData)
      axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
      let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
        return({
          q: ele,
          ref: "na"
        })
      })
      parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
      parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
      parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
      parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
      parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]
      
      
        let parsedRespMssg = res.data.msg2.split(";;").map((ele)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        let finalQues = [...parsedRespMssg.reverse()]
        // let combinedMssg = [...mssg, ...parsedRespMssg, convoMssg]
        // addToMessageBox(finalQues)
        sendToChatBox(finalQues,finalQues.length-1)
        //setTimeout(function(){addToMessageBox(finalQues)},1200)
        setTimeout(()=>{setNextQues(parsedMssg.reverse())},(finalQues.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
        console.log(messages)
      })
      .catch((err) => {
        console.log("this is err : ",err)
      })
  }

  function sendToChatBox(msglist,x){
    addToMessageBox([msglist[x]])
    if (x>0)
      setTimeout(() =>{sendToChatBox(msglist,x-1)},1500);
  }

    
    const sendButtonClick = () => {
      let trimmedNewMessage = newMessage.trim();
        console.log(trimmedNewMessage)
        if (trimmedNewMessage.length >= 1) {
          onSendMessage(trimmedNewMessage );
        }
        else {
          return null
        }
    }

    const handleKeyDown = function(e) {
      // Reset field height
      e.target.style.height = '100px';
  
      // Get the computed styles for the element
      const computed = window.getComputedStyle(e.target);
  
      // Calculate the height
      const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                   + parseInt(computed.getPropertyValue('padding-top'), 10)
                   + e.target.scrollHeight
                   + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                   + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
  
      e.target.style.height = `${height}px`;
  }

  return (
    <div style={{ background: "#fff", borderRadius:"2vh", boxShadow:"0.1rem 0.5rem 1rem grey", width:"50vw", marginTop:"1vh" }}>
      <div style={{width: "100%", padding: "10px", boxSizing:"border-box"}}>
         <textarea 
            // rows={rowH} 
            style={{ 
            display:"block",
            background: "#FAFAFA",
            width:"95%",
            fontSize: "16px",
            margin:"auto",
            border:"1px solid rgba(0,0,0,0.3)",
            borderRadius:"7px", 
            boxSizing:"border-box",
            padding:"8px 12px",
            boxShadow:"0 5px 10px rgb(0 0 0 / 10%)",
            height:"100px",
            minHeight:"100px"
            }} 
            value={newMessage} 
            onChange={(e) => setNewMessage(e.target.value)} 
            onKeyDown={handleKeyDown}
         /> 
      </div>


      <div id="chat-form" className="justify-content-end" style={{ height: "70px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

        
          <Button className={styles.SubmitButton} onClick={sendButtonClick}>
           <p style={{ fontSize:'16px',fontWeight:'bold',textTransform:"none"}}>Confirm</p>
          </Button>
      </div>
    </div>
   
  )
}

{/* <div id="chat-form" style={{height:"8vh", borderTopRightRadius: "16px", borderTopLeftRadius: "16px", display:"flex", alignItems:"center", justifyItems:
    "center", position:"relative", width:"68vw", marginTop:"5vh"}}>
      <textarea rows={rowH} style={{ color: "",width: "65vw", borderRadius:"25px", fontSize: "1.2rem", padding: "5px 3vw", margin:"auto 0", border:"none", background:"aliceblue", boxShadow:"0 5px 10px rgba(0, 0, 0, 0.2)"}}  value={newMessage} onChange={(e) => setNewMessage(e.target.value)} />
      
      <Button onClick={sendButtonClick} style={{padding: 0, margin: "1vw", position:"absolute", marginLeft:"63vw"}} disabled={placeholder!="Enter Your Text"} >
        <CustomTooltip title="Send">
          <img src="send.png" style={{height: "4vh",margin:"10px auto 10px"}} />
        </CustomTooltip>
      </Button>
      
      <Button style={{padding: 0, margin: "1vw", position:"absolute", marginLeft:"-5vw"}} disabled >
        <CustomTooltip title={micOn?"Stop Recording":"Start Recording"}>
          <img src="mic-off.png" style={{height: "5vh", margin: "10px auto 10px  "}} />
        </CustomTooltip>
      </Button>
    </div> */}