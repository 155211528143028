import Validator from 'validator';

const isEmpty = (value) => {
	return (
		value === undefined ||
		value === null ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		(typeof value === 'string' && value.trim().length === 0)
	);
};

export const validateSignUpInput = (data) => {
	let errors = {}

	data.firstName = !isEmpty(data.firstName) ? data.firstName : ''
	data.lastName = !isEmpty(data.lastName) ? data.lastName : ''
	data.institution = !isEmpty(data.institution) ? data.institution : ''
	data.email = !isEmpty(data.email) ? data.email : ''
	data.password = !isEmpty(data.password) ? data.password : ''

	if (Validator.isEmpty(data.firstName)) {
		errors.firstName = 'First name is required'
	}
	if (Validator.isEmpty(data.lastName)) {
		errors.lastName = 'Last name is required'
	}

	if (!Validator.isEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(data.email)) {
		errors.email = 'Email field is required'
	}

	if (Validator.isEmpty(data.password)) {
		errors.password = 'Password field is required'
	}

	if (Validator.isEmpty(data.institution)) {
		errors.institution = 'Institution is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateSignInInput = (data) => {
	let errors = {}

	data.email = !isEmpty(data.email) ? data.email : ''
	data.password = !isEmpty(data.password) ? data.password : ''

	if (!Validator.isEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(data.email)) {
		errors.email = 'Email field is required'
	}

	if (Validator.isEmpty(data.password)) {
		errors.password = 'Name field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateDemoInput = (data) => {
	let errors = {}

	data.name = !isEmpty(data.name) ? data.name : ''
	data.email = !isEmpty(data.email) ? data.email : ''

	if (!Validator.isEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(data.email)) {
		errors.email = 'Email field is required'
	}

	if (Validator.isEmpty(data.name)) {
		errors.name = 'Name field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateFreeSigninInput = (data) => {
	let errors = {}

	data.email = !isEmpty(data.email) ? data.email : ''
	data.password = !isEmpty(data.password) ? data.password : ''

	if (!Validator.isEmail(data.email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(data.email)) {
		errors.email = 'Email field is required'
	}

	if (Validator.isEmpty(data.password)) {
		errors.password = 'Password field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateEmail = (email) => {
	let errors = {}

	email = !isEmpty(email) ? email : ''

	if (!Validator.isEmail(email)) {
		errors.email = 'Email is invalid'
	}

	if (Validator.isEmpty(email)) {
		errors.email = 'Email field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateResetInput = (data) => {
	let errors = {}

	data.firstName = !isEmpty(data.firstName) ? data.firstName : ''
	data.lastName = !isEmpty(data.lastName) ? data.lastName : ''
	data.institution = !isEmpty(data.institution) ? data.institution : ''

	if (Validator.isEmpty(data.firstName)) {
		errors.firstName = 'First name is required'
	}

	if (Validator.isEmpty(data.lastName)) {
		errors.lastName = 'Last name is required'
	}

	if (Validator.isEmpty(data.institution)) {
		errors.institution = 'Institution is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validatePassword = (password) => {
	let errors = {}

	password = !isEmpty(password) ? password : ''

	if (Validator.isEmpty(password)) {
		errors.password = 'Password field is required'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateCreateNewDrive = (name, desc) => {
	let errors = {};

	name = !isEmpty(name) ? name : '';

	if (Validator.isEmpty(name)) {
		errors.name = 'Name field is required'
	}
	
	if (!validateSpoofText(name)) {
		errors.name = 'Enter Valid Name'
	}

	if (!validateSpoofText(desc)) {
		errors.desc = 'Enter Valid Description'
	}

	return {
		errors,
		isValid: isEmpty(errors),
	}
}

export const validateSpoofText = (val) => {
	return /^(?!\s)[\w\s-]*$/.test(val)
}
