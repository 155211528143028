import React, {useState, useEffect} from 'react'
import axios from '../../../axios'
import { useSpeechRecognition } from 'react-speech-kit';
import { useLocation } from 'react-router'
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core'
import { Modal } from 'react-responsive-modal';
import Button from '@mui/material/Button';
import {fixedAssessmentData} from "../../../constants/fixedAssessmentData"

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#333",
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function ChatInput({messages, addToMessageBox, setNextQues, setProgress, placeholder="Enter Your Text"}) {
  const [newMessage, setNewMessage] = useState("");
  let [micOn, setMicOn] = useState(false)
  let location = useLocation()
  //const [val,setVal]=useState('')
  const { listen, listening, stop } = useSpeechRecognition({
    onResult: (result) => {
      setNewMessage(result);
    },
  });
  const onSendMessage = (message) => {
    var dt = new Date();
    var h =  dt.getHours(), m = dt.getMinutes();
    var _time = (h > 12) ? (h-12 + ':' + m +' PM') : (h + ':' + m +' AM');
    let convoMssg = {
      q: message.replaceAll('\n','::'),
      isMyMessage: true
    }
    addToMessageBox([convoMssg]);
    setNewMessage("");
    
    const url = 'userconvo'
    let msg22='Thanks. Let\'s go to the next question.';
    const msg1=fixedAssessmentData[location.state.id][parseInt(messages.messages[0].qid)]

    let parsedMssg = msg1[0].split(";;").map((ele,index)=>{
        return({
          q: ele,
          ref: "na"
        })
      })
      parsedMssg[parsedMssg.length-1]["qtype"] = msg1[1]
      parsedMssg[parsedMssg.length-1]["options"] = msg1[2]
      parsedMssg[parsedMssg.length-1]["qid"] = msg1[3]
      parsedMssg[parsedMssg.length-1]["exhibit"] = msg1[4]
      parsedMssg[parsedMssg.length-1]["progress"] = msg1[5]

      if(msg1[5] == "100") {
        msg22 = '';
      }
      
      
        let parsedRespMssg = msg22.split(";;").map((ele)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        let finalQues = [...parsedRespMssg.reverse()]
        // let combinedMssg = [...mssg, ...parsedRespMssg, convoMssg]
        // addToMessageBox(finalQues)
        sendToChatBox(finalQues,finalQues.length-1)
        //setTimeout(function(){addToMessageBox(finalQues)},1200)
        setTimeout(()=>{setNextQues(parsedMssg.reverse())},(finalQues.length)*1500)
        setProgress(parseInt(msg1[5], 10))
        console.log(messages)
    /*let getData = {
      user_id : location.state.code,
      comp: location.state.comp,
      message1 : messages.messages[0].qid,
      message2 : message,
      mode: 0
    }
    
    console.log(getData)
      axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
      let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
        return({
          q: ele,
          ref: "na"
        })
      })
      parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
      parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
      parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
      parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
      parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]
      
      
        let parsedRespMssg = res.data.msg2.split(";;").map((ele)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        let finalQues = [...parsedRespMssg.reverse()]
        // let combinedMssg = [...mssg, ...parsedRespMssg, convoMssg]
        // addToMessageBox(finalQues)
        sendToChatBox(finalQues,finalQues.length-1)
        //setTimeout(function(){addToMessageBox(finalQues)},1200)
        setTimeout(()=>{setNextQues(parsedMssg.reverse())},(finalQues.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
        console.log(messages)
      })
      .catch((err) => {
        console.log("this is err : ",err)
      })*/
  }

  function sendToChatBox(msglist,x){
    addToMessageBox([msglist[x]])
    if (x>0)
      setTimeout(() =>{sendToChatBox(msglist,x-1)},1500);
  }

//   const onPressEnter =
//     (event) => {
//       const { key } = event;
//       if (key === "Enter") {
//         event.preventDefault(); // prevent Enter behaviour
//         let trimmedNewMessage = newMessage.trim();
//         console.log(trimmedNewMessage)
//         if (trimmedNewMessage.length >= 1) {
//           onSendMessage(trimmedNewMessage, );
//         }
//         else {
//           return null
//         }
//       }

//     }
   const onPressEnter = (event) => {
   const { key } = event;
   if (key === "Enter") {
      event.preventDefault(); // prevent Enter behaviour
      sendButtonClick();
      }
      else {
         return null
      }
   }

    const letslisten=()=>{
      if(listening){
        stop()
        setMicOn(false)
        console.log('stopping')
      }
      else{
        listen()
        setMicOn(true)
        console.log('listening')
      }
    }

   // modal
   const [warningModal, setWarningModal] = useState(false);
   const onOpenWarningModal = () => setWarningModal(true);
   const onCloseWarningModal = () => setWarningModal(false);


    const sendButtonClick = () => {
       //check for text0
       let trimmedNewMessage = newMessage.trim();

         console.log(trimmedNewMessage)
         
      
      //check for txt2
       if(messages.messages[0].qtype === "txt2")
      {
         

         if(trimmedNewMessage.includes('.')){
            let sentence = trimmedNewMessage.split('.');

            if(sentence.length > 1 && sentence[0].length >= 1 && sentence[1].length >= 1){
               onSendMessage(trimmedNewMessage);
            }
            else {
               onOpenWarningModal();

            }   
         }
         else {
            onOpenWarningModal()
         } 

      }
      //check for txt3
      else if(messages.messages[0].qtype === "txt3")
      {
         

         if(trimmedNewMessage.includes('.')){
            let sentence = trimmedNewMessage.split('.');

            if(sentence.length >= 3 && sentence[0].length >= 1 && sentence[1].length >= 1 && sentence[2].length >= 1){
               onSendMessage(trimmedNewMessage);
            }
            else {
               onOpenWarningModal();

            }   
         }
         else {
            onOpenWarningModal()
         } 
      }
      else{
         if (trimmedNewMessage.length >= 1) {
            onSendMessage(trimmedNewMessage);
         }
         else {
            return null
      }
    }
   }

  const [rowH,setRowH] = useState(1)

  // useEffect(()=>{
  //   const rowlen = newMessage.split('\n').length
  //   setRowH(rowlen)
  // },[newMessage])

  // useEffect(()=>{
  //   const rowlen = newMessage.split('\n').length
  //   setRowH(rowlen)
  // },[newMessage])

  const handleKeyDown = function(e) {
    // Reset field height
    e.target.style.height = '6vh';

    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target);

    // Calculate the height
    const height = e.target.scrollHeight
                //  +  parseInt(computed.getPropertyValue('border-top-width'), 10)
                //  + parseInt(computed.getPropertyValue('padding-top'), 10)
                //  + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                //  + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    e.target.style.height = `${height}px`;
}

  return (
    <div id="chat-form" style={{position: "fixed", bottom: "0px", background: "#fff", height:"max-content", borderTopRightRadius: "16px", borderTopLeftRadius: "16px", display:"block", width:"68vw", marginTop:"5vh"}}>

      {/* <div 
      contenteditable="true" 
      id="message" 
      class="remove-active-border" 
      data-placeholder={placeholder}
      style={{
        float: 'left',
        overflowY: 'auto',
        boxSizing: 'border-box', 
        color: "",
        width: "100%", 
        minHeight: "6vh",
        maxHeight: "100px", 
        borderRadius:"25px", 
        fontSize: "1.2rem", 
        padding: "7px 70px 5px 3vw", 
        border:"none", 
        background:placeholder!="Enter Your Text"?"#F8F8F8":"aliceblue", 
        boxShadow:"0 5px 10px rgba(0, 0, 0, 0.2)",
        color: "#000"
      }}
      disabled={placeholder!="Enter Your Text"}
      onInput={(e) => setNewMessage(e.target.innerText)}
      onMouseEnter={(e) => e.target.dataset.placeholder = ""}
      onMouseLeave={(e) => e.target.dataset.placeholder = placeholder}
      >
      </div> */}

    <textarea
      style={{
        float: 'left',
        boxSizing: 'border-box', 
        color: "",
        width: "100%", 
        minHeight: "6vh",
        height: "6vh",
        maxHeight: "100px", 
        borderRadius:"25px", 
        fontSize: "1.2rem", 
        padding: "7px 70px 5px 3vw", 
        border:"none", 
        background:placeholder!="Enter Your Text"?"#F8F8F8":"aliceblue", 
        boxShadow:"0 5px 10px rgba(0, 0, 0, 0.2)"
      }}
      placeholder={placeholder} 
      className="remove-active-border"
      value={newMessage} 
      // rows={rowH} 
      onChange={(e) => setNewMessage(e.target.value)} 
      disabled={placeholder!="Enter Your Text"}
      onKeyDown={handleKeyDown}
      />

      <Button   
      style={{
         float: 'right',
         width: '70px',
         transform: 'translate(0,-4vh)',
         height: '0',
         overflow: 'visible'
      }}
      onClick={sendButtonClick}
      disabled={placeholder!="Enter Your Text"} 
      >
        <CustomTooltip title="Submit">
          <img src={placeholder=="Enter Your Text"?"send.png":"send-off.png"} style={{height: "4vh"}} />
        </CustomTooltip>
      </Button>
      
      <Button 
      style={{
         float: 'left',
         width: '70px',
         transform: 'translate(-100%,-4vh)',
         height: '0',
         overflow: 'visible'
      }}
      onClick={letslisten} 
      disabled={placeholder!="Enter Your Text"}
      >
        <CustomTooltip title={micOn?"Stop Recording":"Start Recording"}>
          <img src={micOn||placeholder!="Enter Your Text"?"mic-off.png":"mic.png"} style={{height: "5vh"}} />
        </CustomTooltip>
      </Button>

      <Modal open={warningModal} onClose={onCloseWarningModal} center>
         <p className="modal-text">
         Are you sure you want to submit this question?
         </p>
         <div className="button-div">
         <Button 
         className="modal-button" 
         style={{
            background: '#479BD2', 
            fontWeight: 'bold', 
            fontFamily:"Poppins", 
            letterSpacing:"0.01em", 
            borderRadius: '25px',
            padding: '5px 12px',
            fontSize: '16px'
         }} 
         size="small" 
         variant="contained"
         onClick={()=>{
            onSendMessage(newMessage.trim());
            onCloseWarningModal()
         }}>
            Ok
         </Button>
         <Button 
         className="cancel-modal-button" 
         style={{
            background: '#479BD2', 
            fontWeight: 'bold', 
            fontFamily:"Poppins", 
            letterSpacing:"0.01em", 
            borderRadius: '25px',
            padding: '5px 12px',
            fontSize: '16px'
         }} 
         size="small" 
         variant="contained"
         onClick={() =>{
            onCloseWarningModal();
         }}>
            Cancel
         </Button>
         </div>
      </Modal>
    </div> 
  )
}