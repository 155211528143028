import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useParams } from 'react-router-dom';

import Loader from '../../components/common/Loader';
import { getShowVerificationSuccess, getUserVerificationLoading } from '../../selectors/app';
import { verifyUser as verifyUserRequest, setUserPassword as setUserPasswordRequest } from '../../actions/app';

import Logo from '../../assets/Logo.png';
import VerificationImg from '../../assets/verification.png';
import VerificationErrorImg from '../../assets/verification-error.png';
import VerificationSuccessImg from '../../assets/verification-success.png';

import '../../styles/freeApp/verification.scss';

const Verification = (props) => {
	const { isLoading, showVerificationSuccess, verifyUser, setUserPassword } = props;

	const { token } = useParams();
	const history = useHistory();

	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');

	useEffect(() => {
		if (token) {
			verifyUser({ id: token });
		}
	}, []);

	const onChangePassword = (e) => {
		setPassword(e.target.value);
	}

	const onChangeRePassword = (e) => {
		setRePassword(e.target.value);
	}

	const onSetPassword = () => {
		if (password === rePassword) {
			setUserPassword({ userData: { password }, history });
		}
	}

	return (
		<div id="verificationPage">
			{
				isLoading ? (
					<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
						<Loader />
					</div>
				) : (
					<>
						<div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fcfcfc', height: 100, alignItems: 'center' }}>
							<img src={Logo} alt='' style={{ width: 300 }} />
						</div>
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
							<div>
								<img src={VerificationImg} alt='' style={{ width: 400, marginTop: -50 }} />
							</div>
							<div>

								{
									showVerificationSuccess ? (
										<div style={{ display: 'flex', flexDirection: 'column', marginTop: -50 }}>
											<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
												<div style={{ color: '#646464', fontSize: 22, fontWeight: 600 }}>Authentication successful</div>
												<img src={VerificationSuccessImg} alt="" style={{ width: 36, marginLeft: 15 }} />
											</div>
											<div style={{ marginTop: 50 }}>
												<input style={{ padding: '16px 0', color: '#bababa', border: 'none', borderBottom: '1px solid #bababa', marginTop: 12, fontFamily: 'inherit', outline: 'none', fontSize: 18, fontWeight: '400', width: 300 }} type='text' placeholder='Enter password' onChange={onChangePassword} value={password} />
											</div>
											<div>
												<input style={{ padding: '16px 0', color: '#bababa', border: 'none', borderBottom: '1px solid #bababa', marginTop: 12, fontFamily: 'inherit', outline: 'none', fontSize: 18, fontWeight: '400', width: 300 }} type='password' placeholder='Re-Enter password' onChange={onChangeRePassword} value={rePassword} autoComplete='new-password' />
											</div>
											<div style={{ marginTop: 45, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
												<div style={{ marginRight: 25, backgroundColor: '#008095', borderRadius: 50, padding: '12px 24px', fontSize: 16, fontWeight: '400', color: '#fff', cursor: 'pointer' }} onClick={onSetPassword} autoComplete='new-password'>Set Password</div>
											</div>
										</div>
									) : (
										<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -50 }}>
											<div style={{ color: '#646464', fontSize: 22, fontWeight: 600 }}>Authentication failed</div>
											<img src={VerificationErrorImg} alt="" style={{ width: 36, marginLeft: 15 }} />
										</div>
									)
								}
							</div>
						</div>
					</>
				)
			}
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	isLoading: getUserVerificationLoading(),
	showVerificationSuccess: getShowVerificationSuccess()
});

const mapDispatchToProps = (dispatch) => ({
	verifyUser: (data) => dispatch(verifyUserRequest(data)),
	setUserPassword: (data) => dispatch(setUserPasswordRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
