import React, { useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Edit from '../../assets/Icons/Edit.png';
// import Trash from '../../assets/Icons/Trash.png';
import axios from "../../axios";
import NotificationBell from "../notificationBell/notificationBell";
import VideoCard from "./videoCard";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom'
import { useLocation } from "react-router";
import Tooltip from '@mui/material/Tooltip';
import './videoSubmission.css';

export default function VideoSubmission(props) {
	const { notification } = props;

	const location = useLocation()
	const history = useHistory();
	const { candidateData } = location.state;
	const [videoCardData, setVideoCardData] = useState([]);

	useEffect(async () => {
		if (!candidateData.test) return;

		let tempArr = [];
		for (let i = 0; i < candidateData.test.chat.length; i++) {
			let val = candidateData.test.chat[i];
			if (val.ans.includes('.mp4')) {
				let res = await axios.post('/cand-video', { key: val.ans })
				let tempData = { ques: val.ques, score: val.score, src: res.data.signedUrl, cid: val.cid, notes: val.notes };
				tempArr.push(tempData);
			}
		}

		setVideoCardData(tempArr);
	}, [candidateData])

	return (
		<div className="productList">
			<div className="inner-div" style={{ justifyContent: 'space-between' }}>
				<div>
					<Tooltip title="Go back">
						<IconButton style={{ color: 'rgb(180,180,180)' }} aria-label="back" onClick={history.goBack}><ArrowBackIcon /></IconButton>
					</Tooltip>
				</div>
				<div className='top-icon-box'>
					<IconButton aria-label="edit"><img src={Edit} alt="" width={30} height={30} /></IconButton>
					{/* <IconButton aria-label="delete"><img src={Trash} alt="" width={28} height={28}/></IconButton> */}
					<NotificationBell notification={notification} />
				</div>
			</div>

			<div className="container-div">
				<div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', marginBottom: "10px" }}>
					<div className="inner-div-details">
						<h2>Video Submission</h2>
					</div>
				</div>

				<div className="videobox-container">
					<div className="videobox-header">
						<p>Name: {candidateData.name}</p>
					</div>

					<div className="videobox-wrapper">
						{videoCardData.length == 0 ? 'No Video Submission' : videoCardData.map((val) => <VideoCard ques={val.ques} src={val.src} score={val.score} cid={val.cid} notes={val.notes} candidateData={candidateData} />)}
					</div>
				</div>

			</div>

		</div>
	)
}
