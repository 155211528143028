import React from 'react';
import PersonIcon from '@mui/icons-material/Person';

import '../../styles/freeApp/header.scss'
import { Link } from 'react-router-dom';

const Header = (props) => {
    const { showFreeTrial, onOpenFreeTrialModal, showCreateBtn } = props;

    const userEmail = localStorage.getItem('email');

    return (
        <div id="header">
            <Link to={"/free/home"} style={{ textDecoration: 'none' }}>
                <div style={{ fontSize: 32, fontWeight: '600', color: '#014954' }}>My Assessment</div>
                <div style={{ fontSize: 24, fontWeight: '600', color: '#014954' }}>(Free trial)</div>
            </Link>
            {/* <div style={{ fontSize: 16, fontWeight: '500', color: '#009FFF' }}>Upgrade to Premium now</div> */}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {
                        showFreeTrial ? (
                            <div style={{ color: '#fff', fontSize: 14, fontWeight: '500', backgroundColor: '#479BD2', borderRadius: 6, textDecoration: 'none', padding: '4px 16px', cursor: 'pointer' }} onClick={onOpenFreeTrialModal}>Try our sample</div>
                        ) : ''
                    }
                    {
                        showCreateBtn ? (
                            <Link to={'/free/create-assessment'} style={{ color: '#fff', fontSize: 14, fontWeight: '500', backgroundColor: '#479BD2', borderRadius: 6, textDecoration: 'none', padding: '4px 16px', cursor: 'pointer' }} onClick={onOpenFreeTrialModal}>+ Create</Link>
                        ) : ''
                    }
                    {/* <div style={{ backgroundColor: '#ddd', borderRadius: 32, display: 'inline-flex', padding: 4, marginLeft: 20 }}>
                        <PersonIcon style={{ width: 28, height: 28, color: "#479BD2" }} />
                    </div> */}
                </div>
                <div style={{ fontSize: 16, fontWeight: '500', color: '#7F8385', marginTop: 10 }}>{userEmail}</div>
            </div>
        </div>
    )
}

export default Header;
