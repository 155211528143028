import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Modal } from "react-responsive-modal";
import HelpSharpIcon from "@material-ui/icons/HelpSharp";
import "../../styles/scrollbar.css"
import VerticalCarousel from "./chat_section/VerticalExhibits";
import Zoom from 'react-medium-image-zoom';
import img1 from "../../assets/exhibits/case2/1.jpg"
import img2 from "../../assets/exhibits/case2/2.jpg"
import user from '../../assets/user.jpg';

function Sidebar({ toSpeak, setToSpeak, exhibits, photoRef, videoRef }) {
  const [modal1, setModal1] = useState(false);
  const onOpenModal1 = () => setModal1(true);
  const onCloseModal1 = () => setModal1(false);

//   const [modalExhibit, setModalExhibit] = useState("");
//   const onOpenModalExhibit = (exhibit) => {
//     console.log(exhibit);
//     setModalExhibit(exhibit);
//   };
//   const onCloseModalExhibit = () => setModalExhibit("");
  return (
    <div style={{ width: "15vw" }}>
      <div
        style={{
          height: "12vh",
          background: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="logo-blue.png"
          style={{ margin: "0", maxWidth: "12vw", maxHeight: "12vh" }}
        />
      </div>
      <div
        style={{
          height: "70vh",
          background: "#363740",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* sample-video displayed only durning tour */}
        <div 
        className="bot-second-step"
        id="sample-tour-video"
        style={{
          padding:"5px",
          marginBottom: "10px"
        }}
        >
          <img src={user} alt="sample tour video" style={{maxWidth: "100%"}} />
        </div>

        <div
        id="user-video-flex" 
        style={{
          width:"13vw",
          height:"20vh",
          padding:"0 0 15px",
          margin: "1vh 0 0",
          display:"none",
          alignItems:"center",
          justifyContent:"center",
          borderBottom: "1px solid white"
          }}
        >
          <video
            ref={videoRef}
            style={{
              maxHeight: "20vh",
              maxWidth: "13vw",
              borderRadius: "25px",
              background:"#000"
            }}
          />
        </div>
        <canvas
          ref={photoRef}
          id="canvas"
          width="13vw"
          height="20vh"
          style={{ display: "none" }}
        ></canvas>

        {/* sample-exhibit displayed only durning tour */}
        <div className="bot-third-step" id="sample-tour-exhibits" style={{padding: "0 5px"}}>
          <img src={img1} alt="exhibit" style={{maxWidth: '100%', margin: '5px 0'}} />
          <img src={img2} alt="exhibit" style={{maxWidth: '100%', margin: '5px 0'}} />
        </div>
        {/* <h2 style={{ margin: "1vh 0", display:exhibits.length > 0 ?"flex":"none" }}>Data</h2> */}
        {exhibits.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflowY: "auto",
              overflowX: "hidden",
              margin:"0 0 2vh",
              padding:"10px 0px 10px 0px",
              borderBottom: "1px solid #fff",
              width:"13vw",
              maxHeight:"30vh"
            }}
            className="scrollbar" 
          >
            {/* {exhibits.map((exhibit) => {
              if (exhibit !== "na")
                return (
                  <div
                      style={{
                        backgroundImage:`url(${exhibit})`,
                        minHeight: "14vh",
                        minWidth: "10vw",
                        margin: "0 20px 20px",
                        cursor: "pointer",
                        borderRadius:"25px",
                        backgroundSize:"cover"
                      }}
                      onClick={() => onOpenModalExhibit(exhibit)}
                    >
                  </div>
                );
              else return null;
            })} */}
             {exhibits.map((exhibit) => {
              if (exhibit !== "na")
                return (
                  <Zoom>
                  <img src={exhibit} alt="exhibit" style={{maxWidth: '100%', margin: '10px 0'}} />
                  </Zoom>
                );
              else return null;
             })}   
          </div>
          // <VerticalCarousel exhibits={exhibits} />
        ) : (
          // <h2 style={{ margin: "auto" }}>Data unlocked will be shown here</h2>
          null
        )}

        {/* <Modal open={modalExhibit} onClose={onCloseModalExhibit} classNames={{modal:"customModal"}} center styles={{modal:{borderRadius:"50px", padding:"40px"}}}>
          <img
            src={modalExhibit}
            style={{ margin: "5vh", cursor: "pointer",maxWidth:"85vw",maxHeight:"85vh" }}
          />
        </Modal> */}
      </div>

      <div
        style={{
          height: "18vh",
          background: "#525252",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems:"center",
          fontSize:"14px",
        }}
      >
        <div
          className="bot-sixth-step"
          style={{
            display: "flex",
            margin: "0 0 2vh",
            flexDirection: "row",
            alignItems: "center",
            color: "#fff",
            // width:"7vw"
          }}
          onClick={() => {
            setToSpeak(!toSpeak);
          }}
        >
          <img
            style={{
              height: "4.5vh",
              maxWidth: "4.5vh",
              marginRight:"0.5vw"
            }}
            src={toSpeak ? "./sound.png" : "./sound-off.png"}
            alt=""
          />
          <p style={{marginRight:toSpeak?"1vw":"0"}}>{toSpeak ? " Mute" : " Unmute"}</p>
        </div>

        <div
          className="bot-seventh-step"
          onClick={onOpenModal1}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "#fff",
            cursor: "pointer",
            // width:"7vw"
          }}
        >
          <img
            style={{
              height: "4.5vh",
              maxWidth: "4.5vh",
              marginRight:"0.5vw"
            }}
            src="./support.png"
            alt=""
          />
          <span>Support</span>
        </div>
        <Modal open={modal1} onClose={onCloseModal1} center styles={{modal:{borderRadius:"50px", padding:"40px"}}}>
          <div style={{ margin: "2vh 2vw" }}>
            Facing any difficulties? Email us at {" "}
            <a href="mailto::support@hirequotient.com">support@hirequotient.com</a>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Sidebar;
