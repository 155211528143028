import React, {useState} from 'react';
import classNames from 'classnames';
import { useRef, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import '../../../styles/ChatBody1.css';
import TncInput from '../helper_section/TncInput';
import VideoInput from '../helper_section/VideoInput';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
const Message = ({ isMyMessage, message, globalRef, setGlobalRef, newMssg, addToMessageBox, addFirstMessage, setProgress, setNextQues, messages }) => {

   //  const [open, setOpen] = useState(false);

   //  const onOpenModal = () => setOpen(true);
   //  const onCloseModal = () => setOpen(false);

    const messageClass = classNames('message-row', {
        'you-message': isMyMessage,
        'other-message': !isMyMessage
    });

    const tempRef = useRef()

    if(message.isMyMessage){
        let x = globalRef;
        //console.log(x)
        //onsole.log(message.ref)
        x[message.ref] = tempRef
        //console.log(x)
        setGlobalRef(x)
    }
    
    const imageThumbnail = 
        isMyMessage ? null : <img className="message-row-img" src="bot.png" alt={message.imageAlt} />;

    const getCurrentTime = () => {
        var dt = new Date();
        var h =  dt.getHours(), m = dt.getMinutes() > 9 ? dt.getMinutes() : ("0"+dt.getMinutes());
        var ampm = (h>12) ? 'PM' : 'AM';
        h = dt.getHours() > 9 ? dt.getHours() : ("0"+dt.getHours())
        var _time = h+":"+m+ampm;
        return _time
    }

    const ele = () => {
        //console.log(globalRef)
       if(message.isMyMessage) { 
           console.log(message.ref)
            return <div className={`${messageClass} userMsg`} ref = {globalRef[message.ref]}>
                <div className="message-content">
                    {imageThumbnail}
                    <div className={newMssg?"new":"message-text"}>
                        {
                                message.q.split('::').map(function(item, key) {
                                return (
                                <span >
                                    {item.split('***').map(function(item2, key2){
                                        return (<span>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)
                                        
                                    })}
                                    <br/>
                                    {/* <p style={{marginTop:"8px"}}></p> */}
                                </span>
                                )
                            })
                            }
                    </div>
                    <div className="message-time">{message.time?message.time:getCurrentTime()}</div>
                </div>
            </div>}
        else{
            if(message.ref!='na'){
                return <div className={messageClass} onClick={()=>{
                    globalRef[message.ref].current.scrollIntoView({ behavior: "smooth" })}}>
                    <div className="message-content">
                        {imageThumbnail}
                        <div className={newMssg?"new":"message-text"}>
                        <div className="message-text-parent">
                        {/* <div className="reply-text-left"></div> */}
                            {message.ques}
                        </div>
                            {
                                message.q.split('::').map(function(item, key) {
                                return (
                                <span >
                                    {item.split('***').map(function(item2, key2){
                                        return (<span>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)
                                        
                                    })}
                                    <br/>
                                    {/* <p style={{marginTop:"8px"}}></p> */}
                                </span>
                                )
                            })
                            }
                        </div>
                        <div className="message-time">{message.time?message.time:getCurrentTime()}</div>
                    </div>
                </div>
            }
            else
            return <div>
            {
                (message.exhibit && message.exhibit != 'na')?
                <div>
                    <div className={messageClass}>
                        <div className="message-content">
                            {imageThumbnail}
                            <div className={newMssg?"new-img":"message-text"} style={{padding: "10px", cursor:"pointer"}} 
                           //  onClick={onOpenModal}
                            >
                               <Zoom>
                                <img src={message.exhibit} alt="" style={{minHeight: "100px",minWidth: "100px",maxHeight:"50vh", maxWidth: "62vw", borderRadius:0, border: "1px"}}/>
                               </Zoom>
                            </div>
                            {/* <Modal open={open} onClose={onCloseModal} center classNames={{modal: 'customModal', closeIcon: 'closeIcon'}} styles={{modal:{borderRadius:"50px", padding:"40px"}}}>
                                <img src={message.exhibit} alt="" style={{minHeight: "100px",minWidth: "100px", margin: "25px",maxHeight: "80vh", maxWidth: "80vw"}}/>
                            </Modal> */}
                            <div className="message-time">{message.time?message.time:getCurrentTime()}</div>
                        </div>
                    </div>
                </div>:null
            }
            {(message.q &&message.q!='na')?<div className={messageClass}>
                <div className="message-content">
                    {imageThumbnail}
                    <div className={newMssg?"new":"message-text"}>
                        {
                            message.q.split('::').map(function(item, key) {
                                return (
                                <span >
                                    {item.split('***').map(function(item2, key2){
                                        return (<span>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)
                                        
                                    })}
                                    <br/>
                                    {/* <p style={{marginTop:"8px"}}></p> */}
                                </span>
                                )
                            })
                        }
                        {message.qtype=="tac"&&newMssg?<TncInput addToMessageBox={addToMessageBox} addFirstMessage={addFirstMessage}/>:null}
                        {(message.qtype === "vid0" || message.qtype === "vid2" || message.qtype === "vid3")&&newMssg?<VideoInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress}/>:null}
                    </div>
                    <div className="message-time">{message.time?message.time:getCurrentTime()}</div>
                </div>
            </div>:null}
            </div>
        }
    }

    useEffect(() => {
        const mssg = ele()
    }, [])

    return (
        <div style={{margin: "1vh 0 0"}}>
            {
               ele()
            }
        </div>
    );
}

const style = {
    background: "#b2dbbf",
    maxHeight: "100vh",
    maxWidth: "100vw"
}

export default Message;