import React from 'react';
import OwlCarousel from 'react-owl-carousel';

import forwardarrow from '../../assets/forwardarrow.png';
import backwardarrow from '../../assets/backarrow.png';
import interviewer from '../../assets/interviewguide.jpeg';
import reportarrow from '../../assets/reportarrow.svg';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { reportWorkFunctions } from '../../constants/reports';
import { getJobRole } from '../../utils/reports';

const InterviewerGuide = (props) => {
	const { productId } = props;

	let jobRole = getJobRole(productId);

	return (
		<div className='carousel-container'>
			<OwlCarousel
				className='owl-theme'
				loop
				nav
				items={1}
				navText={[`<div class='nav-button owl-prev'><img src=${backwardarrow}></div>`, `<a class='owl-next nav-button'><img src=${forwardarrow}></a>`]}
			>
				<div className="item" style={{ padding: "30px 20px", boxSizing: "border-box", margin: "10px 25px 25px 20px", boxShadow: "rgb(0 0 0 / 20%) 5px 5px 10px 5px", borderRadius: "25px", minHeight: "320px" }}>
					<h3 style={{ padding: "0 20px" }}>INTERVIEWER'S GUIDE</h3>
					<div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
						<div style={{ flex: "1", margin: "20px 0", boxSizing: "border-box" }}>
							<div>
								<p style={{ color: "#8F8F92", fontWeight: "500", fontSize: "14px" }}>
									Our assessment is built on the principle of testing how well candidates are able to
									perform critical work functions associated with the role. This enables us to observe
									directly how they are likely to perform in the job, as well as to identify strengths
									and gaps in their functional skills (role-specific capabilities) and core skills
									(general capabilities). Swipe right to view more details on the critical work functions,
									their associated tasks, and the underlying skills.
								</p>
							</div>
						</div>
						<div style={{ padding: "5px" }}>
							<img src={interviewer} alt="" style={{ maxWidth: "180px" }} />
						</div>
					</div>
					<div>
						<p style={{ color: "#8F8F92", fontWeight: "500", fontSize: "12px" }}>
							Note:  These classifications are based on the Skills Framework created by Skillsfuture Singapore.
							For more information, please visit &nbsp;
							<a href="https://www.skillsfuture.gov.sg/skills-framework" target='_blank' rel='noreferrer'>https://www.skillsfuture.gov.sg/skills-framework</a>
						</p>
					</div>
				</div>

				{
					Object.keys(reportWorkFunctions[jobRole]).map((item) => {
						if (reportWorkFunctions[jobRole][item].hasOwnProperty("typicalTasks")) {
							return (
								<div className="item" style={{ padding: "30px 20px", boxSizing: "border-box", margin: "10px 25px 25px 20px", boxShadow: "rgb(0 0 0 / 20%) 5px 5px 10px 5px", borderRadius: "25px", minHeight: "320px" }}>
									<h3 style={{ padding: "0 20px" }}>{item.label}</h3>
									<div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
										<div style={{ width: "47%", margin: "20px 0", boxSizing: "border-box" }}>
											<div>
												<span style={{ background: "#E4E4E4", padding: "10px", borderRadius: "10px", fontWeight: "700", color: "#707070", marginLeft: "25px" }}>Typical task</span>
											</div>
											<div style={{ marginTop: "30px" }}>
												{
													reportWorkFunctions[jobRole][item].typicalTasks.map((tt) => (
														<div style={{ display: "flex", margin: "10px 0" }}>
															<div style={{ marginRight: "15px", color: "#479BD2", marginTop: "5px" }}>
																<img style={{ width: "12px", height: "12px" }} src={reportarrow} alt='' />
															</div>
															<p style={{ fontSize: "12px" }}>{tt}</p>
														</div>
													))
												}
											</div>
										</div>
										<div style={{ width: "2px", background: "#E4E4E4", margin: "20px 0" }}>&nbsp;</div>
										<div style={{ width: "47%", margin: "20px 0", boxSizing: "border-box" }}>
											<div>
												<span style={{ background: "#E4E4E4", padding: "10px", borderRadius: "10px", fontWeight: "700", color: "#707070", marginLeft: "25px" }}>Associated skills</span>
											</div>
											<div style={{ marginTop: "30px" }}>
												{
													reportWorkFunctions[jobRole][item].associatedSkills.map((as) => (
														<div style={{ display: "flex", margin: "10px 0" }}>
															<div style={{ marginRight: "15px", color: "#479BD2", marginTop: "5px" }}><img style={{ width: "12px", height: "12px" }} src={reportarrow} alt='' /></div>
															<p style={{ fontSize: "12px" }}><span style={{ fontWeight: "700" }}>{as.title}: </span>{as.detail}</p>
														</div>
													))
												}
											</div>
										</div>
									</div>
								</div>
							)
						} else if (reportWorkFunctions[jobRole][item].hasOwnProperty("relevantQuestions")){
							return (
								<div className="item" style={{ padding: "30px 20px", boxSizing: "border-box", margin: "10px 25px 25px 20px", boxShadow: "rgb(0 0 0 / 20%) 5px 5px 10px 5px", borderRadius: "25px", minHeight: "320px" }}>
									<h3 style={{ padding: "0 20px" }}>{item.label}</h3>
									<div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
										<div style={{ width: "47%", margin: "20px 0", boxSizing: "border-box" }}>
											<div>
												<span style={{ background: "#E4E4E4", padding: "10px", borderRadius: "10px", fontWeight: "700", color: "#707070", marginLeft: "25px" }}>Description</span>
											</div>
											<div style={{ marginTop: "30px" }}>
												<p style={{ fontSize: "12px" }}>{reportWorkFunctions[jobRole][item].description}</p>
											</div>
										</div>
										<div style={{ width: "2px", background: "#E4E4E4", margin: "20px 0" }}>&nbsp;</div>
										<div style={{ width: "47%", margin: "20px 0", boxSizing: "border-box" }}>
											<div>
												<span style={{ background: "#E4E4E4", padding: "10px", borderRadius: "10px", fontWeight: "700", color: "#707070", marginLeft: "25px" }}>Relevent Questions</span>
											</div>
											<div style={{ marginTop: "30px" }}>
												{
													reportWorkFunctions[jobRole][item].relevantQuestions.map((rq) => (
														<div style={{ display: "flex", margin: "10px 0" }}>
															<div style={{ marginRight: "15px", color: "#479BD2", marginTop: "5px" }}><img style={{ width: "12px", height: "12px" }} src={reportarrow} alt='' /></div>
															<p style={{ fontSize: "12px" }}>{rq}</p>
														</div>
													))
												}
											</div>
										</div>
									</div>
								</div>

							);
						} else{
							return 
						}
					})
				}
			</OwlCarousel>
		</div>
	)
}

export default InterviewerGuide;
