import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Icon, MenuItem, Select as MuiSelect } from '@mui/material';

import axios from '../../../axios';
import RangeSlider from '../../../components/common/RangeSlider';
import SwitchBtn from '../../../components/common/SwitchBtn';
import { customAssessmentData, industryData, salesStagesData, workFunctionData } from '../newDriveData';
import { validateCreateNewDrive } from '../../../utils/validations';

import Edit from '../../../assets/Icons/Edit.png';
import InfoIcon from '../../../assets/info.png';

const AddCustomAssessment = (props) => {
    const { goBack, openInterviewGuide, openVideoQuestionForm, addVideoCount, setAddVideoCount, videoQuestions, onChangeCustomAssessment, customFieldsData, openCustomAssessmentForm, companyName, change, setChange, onClose, setSnackErr, handleSnackClick, driveRole, setDriveRole, setCustomAssessmentProblemStatement } = props;

    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [industry, setIndustry] = useState('');
    const [keyTask, setKeyTask] = useState({});
    const [finalVal, setFinalVal] = useState({});
    const [functionalSkill, setFunctionalSkill] = useState([]);
    const [coreSkill, setCoreSkill] = useState([]);
    const [workfunctionSelected, setWorkfunctionSelected] = useState([]);
    const [workfunctionOption, setWorkfunctionOption] = useState([]);
    const [jobRole, setJobRole] = useState('');
    const [showKeyTask, setShowKeyTask] = useState(false);
    const [salesStages, setSalesStages] = useState([]);
    const [workflowFixed, setWorkflowFixed] = useState(false);

    const onChangeName = (e) => {
        setName(e.target.value);
    }

    const onChangeDesc = (e) => {
        setDesc(e.target.value);
    }

    const onSubmitWorkflow = () => {
        const errorResult = validateCreateNewDrive(name, desc);
        let weightageSum = 0;

        if (errorResult.isValid) {
            if (driveRole === 'Associate consultant' || driveRole === 'Consultant') {
                coreSkill.forEach((cs) => {
                    weightageSum += parseInt(cs.weightage, 10);
                });

                if (weightageSum === 100) {
                    sendWorkflow();
                } else {
                    setSnackErr('Sum of weightage is not equal to 100');
                    handleSnackClick();
                }
            } else {
                sendWorkflow();
            }
        } else {
            Object.keys(errorResult.errors).forEach((item) => {
                setSnackErr(errorResult.errors[item]);
            });
            handleSnackClick();
        }
    }

    const sendWorkflow = () => {
        const uri = "/company/workflow/";
        const videoQsArr = [];
        videoQuestions.forEach((vq) => {
            if (vq.question) {
                videoQsArr.push(vq);
            }
        });
        const getData = {
            workflowName: name,
            workflowDescription: desc,
            productId: {
                role: driveRole,
                workfunction: driveRole != "Associate consultant" && driveRole != "Consultant" ? workfunctionSelected : {},
                functionalSkill: driveRole == "Associate consultant" || driveRole == "Consultant" ? functionalSkill : {},
                coreSkill: driveRole == "Associate consultant" || driveRole == "Consultant" ? coreSkill : {},
                keyTask: finalVal,
                isMegacase: true,
                isCustom: false,
                videoQs: videoQsArr.length > 0 ? true : false,
                workflowFixed: !workflowFixed
            },
            companyName: companyName,
        };

        if (videoQsArr.length > 0) {
            getData.productId.videoQsArr = videoQsArr;
        }

        if (jobRole) {
            getData.productId.jobTitle = jobRole;
            getData.productId.stages = salesStages;
        }

        if (Object.keys(customFieldsData).length > 0) {
            getData.productId.customFields = customFieldsData;
        }

        if (driveRole === 'Project Manager') {
            getData.productId.industry = industry;
        }

        axios.post(uri, JSON.stringify(getData), { headers: { 'Content-Type': 'application/json' } })
            .then((res) => {
                setChange(change + 1);
            })
            .catch((err) => {
                console.log(err);
            });

        onClose();
    }

    useEffect(() => {
        if (!driveRole) return;

        if (driveRole != "Associate consultant" && driveRole != "Consultant" && driveRole != "Sales and Marketing") {
            let arr = [];

            Object.keys(workFunctionData[driveRole]).map((val) => {
                const wfObj = {
                    value: val,
                    label: val
                };

                if (driveRole == 'Associate consultant' || driveRole == 'Consultant') {
                    wfObj.weightage = 10;
                }

                arr.push(wfObj)
            });

            setWorkfunctionOption(arr);
            setWorkfunctionSelected(arr);
        }

        if (driveRole == "Product Manager" || driveRole == "Project Manager") {
            let customAssessmentProbStatement = customAssessmentData[driveRole].problemStatement;
            customAssessmentData[driveRole].customFields.forEach((cf) => {
                const regex = new RegExp(`{{${cf.key}}}`, "g");
                if (customFieldsData[cf.label]) {
                    customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>${customFieldsData[cf.label]}</b>`);
                } else {
                    customAssessmentProbStatement = customAssessmentProbStatement.replace(regex, `<b>[${cf.label}]</b>`);
                }
            });
            setCustomAssessmentProblemStatement(customAssessmentProbStatement);
        }
    }, [driveRole]);

    useEffect(() => {
        console.log('hey dude');
        const selectedRole = jobRole ? jobRole : driveRole;
        let tempKeyTask = {};
        let tempfinalVal = {};

        workfunctionSelected.map((val) => {
            tempKeyTask = { ...tempKeyTask, [val.value]: workFunctionData[selectedRole][val.value] };
            tempfinalVal[driveRole] = { ...tempfinalVal[driveRole], [val.value]: workFunctionData[selectedRole][val.value] };
        })

        setKeyTask(tempKeyTask);
        setFinalVal(tempfinalVal);
    }, [workfunctionSelected]);

    useEffect(() => {
        if (!jobRole) return;

        if (jobRole != "Custom") {
            let arr = [];

            Object.keys(workFunctionData[jobRole]).map((val) => {
                arr.push({ value: val, label: val })
            });

            setWorkfunctionOption(arr);
            setWorkfunctionSelected(arr);
        } else {
            setShowKeyTask(false);
            setWorkfunctionOption([]);
            setWorkfunctionSelected([]);
            setSalesStages([]);
        }
    }, [jobRole])

    useEffect(() => {
        if (!driveRole) return;

        if (driveRole == "Associate consultant" || driveRole == "Consultant") {
            let arr = {
                [driveRole]: [...functionalSkill]
            };

            setFinalVal(arr);

            const newCoreSkill = [
                { value: 'Structuring', label: 'Structuring', weightage: 10 },
                { value: 'Insights', label: 'Insights', weightage: 10 },
                { value: 'Synthesis', label: 'Synthesis', weightage: 10 },
                { value: 'Quantitative', label: 'Quantitative', weightage: 10 },
                { value: 'Framing', label: 'Framing', weightage: 10 },
            ];
            setCoreSkill([...newCoreSkill]);
        }
    }, [functionalSkill]);

    const onChangeWorkflowFixed = (val) => {
        setWorkflowFixed(val);
    }

    const addKeyTask = function (role, workFunction, keyTask) {
        if (!(finalVal[role][workFunction].find((val) => val == keyTask))) {
            setFinalVal(
                {
                    [role]: {
                        ...finalVal[role],
                        [workFunction]: [...finalVal[role][workFunction], keyTask]
                    }
                }
            )
        }
    }

    const removeKeyTask = (role, workFunction, keyTask) => {
        const index = finalVal[role][workFunction].indexOf(keyTask);
        if (index != -1) {
            let arr = [...finalVal[role][workFunction]];
            arr.splice(index, 1);
            setFinalVal(
                {
                    [role]: {
                        ...finalVal[role],
                        [workFunction]: arr
                    }
                }
            )
        }
    }

    const onChangeWeightage = (e, val) => {
        console.log(val);
        const newCoreSkill = [...coreSkill];
        let index = 0;
        newCoreSkill.forEach((ncs, i) => {
            if (ncs.value === val.value) {
                index = i;
            }
        });
        newCoreSkill[index].weightage = parseInt(e.target.value, 10);
        setCoreSkill([...newCoreSkill]);
    }

    const removeCoreSkill = (val) => {
        const newCoreSkill = [...coreSkill];
        let index = 0;
        newCoreSkill.forEach((ncs, i) => {
            if (ncs.value === val.value) {
                index = i;
            }
        });

        newCoreSkill.splice(index, 1);
        setCoreSkill([...newCoreSkill]);
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
                <div className='back-btn' onClick={goBack}>
                    <div></div>
                </div>
                <div className='title'>Create new drive</div>
            </div>
            <div>
                <input type="text" onChange={onChangeName} value={name} placeholder='Name of drive' />
            </div>
            <div>
                <input type="text" onChange={onChangeDesc} value={desc} placeholder='Please add the details of the drive here....' />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'space-between' }}>
                <div>Select Role</div>
                <MuiSelect
                    className="add-role-select remove-bg"
                    value={driveRole}
                    onChange={(e) => {
                        setDriveRole(e.target.value);
                        setFinalVal({ [e.target.value]: {} })
                        setKeyTask({});
                        setWorkfunctionSelected([]);
                        setFunctionalSkill([]);
                        setJobRole('');
                        setShowKeyTask(false);
                        setCoreSkill([]);
                    }}>
                    <MenuItem value={'Product Manager'}>Product Manager</MenuItem>
                    <MenuItem value={'Project Manager'}>Project Manager</MenuItem>
                    <MenuItem value={'Sales and Marketing'}>Sales and Marketing</MenuItem>
                    <MenuItem value={'Customer Success'}>Customer Success</MenuItem>
                    <MenuItem value={'Associate consultant'}>Associate consultant</MenuItem>
                    <MenuItem value={'Consultant'}>Consultant</MenuItem>
                </MuiSelect>
                {
                    driveRole !== 'Sales and Marketing' ? (
                        <div
                            style={{
                                backgroundColor: '#479BD2',
                                borderRadius: 16,
                                padding: '4px 16px',
                                color: '#fff',
                                cursor: 'pointer',
                                width: 160,
                                textAlign: 'center',
                                boxSizing: 'border-box'
                            }}
                            onClick={() => {
                                setDriveRole();
                                setKeyTask({});
                                setFinalVal({})
                                setWorkfunctionSelected([]);
                                setFunctionalSkill([]);
                                setCoreSkill([]);
                            }}
                        >Default Settings</div>
                    ) : ''
                }
                {
                    driveRole ? (
                        <div style={{ marginLeft: 40, cursor: 'pointer' }} onClick={openInterviewGuide}>
                            <img src={InfoIcon} alt='' style={{ width: 32 }} />
                        </div>
                    ) : ''
                }
            </div>
            {
                driveRole === 'Sales and Marketing' ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'space-between' }}>
                        <div>Job Role</div>
                        <MuiSelect
                            className="add-role-select remove-bg"
                            value={jobRole}
                            onChange={(e) => {
                                setJobRole(e.target.value);
                                setShowKeyTask(false);
                            }}
                        >
                            <MenuItem value={'SDR'}>SDR</MenuItem>
                            <MenuItem value={'Account Executive'}>Account Executive</MenuItem>
                            <MenuItem value={'Marketing Manager'}>Marketing Manager</MenuItem>
                            <MenuItem value={'Custom'}>Custom</MenuItem>
                        </MuiSelect>
                        {
                            jobRole !== 'Custom' ? (
                                <div
                                    style={{
                                        backgroundColor: '#479BD2',
                                        borderRadius: 16,
                                        padding: '4px 16px',
                                        color: '#fff',
                                        cursor: 'pointer',
                                        width: 160,
                                        textAlign: 'center',
                                        boxSizing: 'border-box'
                                    }}
                                    onClick={() => {
                                        setJobRole('');
                                        setKeyTask({});
                                        setFinalVal({})
                                        setWorkfunctionSelected([]);
                                        setFunctionalSkill([]);
                                        setCoreSkill([]);
                                    }}
                                >Default Settings</div>
                            ) : ''
                        }
                    </div>
                ) : ''
            }
            {
                driveRole === 'Project Manager' ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20, justifyContent: 'space-between' }}>
                        <div>Select Industry</div>
                        <MuiSelect
                            className="add-role-select remove-bg"
                            value={industry}
                            onChange={(e) => {
                                setIndustry(e.target.value);
                            }}
                        >
                            {
                                industryData.map((id) => (
                                    <MenuItem key={id.key} value={id.key}>{id.label}</MenuItem>
                                ))
                            }
                        </MuiSelect>
                    </div>
                ) : ''
            }
            {
                (driveRole && driveRole != "Associate consultant" && driveRole != "Consultant" && driveRole != "Sales and Marketing") || (jobRole && jobRole !== 'Custom') ? (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20 }}>
                        <div>Critical work function</div>
                        <Select
                            className="add-wf-select remove-bg"
                            isMulti
                            options={workfunctionOption.length == 0 ? [] : workfunctionOption}
                            value={workfunctionSelected}
                            onChange={setWorkfunctionSelected}
                            style={{
                                control: styles => ({ ...styles, width: '380px' })
                            }}
                        />
                        <div
                            style={{
                                backgroundColor: '#479BD2',
                                borderRadius: 16,
                                padding: '4px 16px',
                                color: '#fff',
                                cursor: 'pointer'
                            }}
                            onClick={() => setShowKeyTask(true)}
                        >Specify Key Task</div>
                    </div>
                ) : ''
            }
            {
                driveRole == "Associate consultant" || driveRole == "Consultant" ? (
                    <>
                        <div className="workflow__newmodal-main workflow__newmodal-main-alignstart">
                            <label className="workflow__newmodal-label">Functional skills</label>
                            <div className="workflow__newmodal-select workflow__newmodal-select-dropdown">
                                <Select
                                    isMulti
                                    options={[
                                        { value: 'Management consultant/generalist', label: 'Management consultant/generalist' },
                                        { value: 'Strategy', label: 'Strategy' },
                                        { value: 'Marketing /Sales /Pricing', label: 'Marketing /Sales /Pricing' },
                                        { value: 'Operations', label: 'Operations' },
                                        { value: 'Technology & Digital', label: 'Technology & Digital' },
                                        { value: 'Corporate Finance & Accounting', label: 'Corporate Finance & Accounting' },
                                        { value: 'People & Organization', label: 'People & Organization' },
                                    ]}
                                    value={functionalSkill}
                                    onChange={setFunctionalSkill}
                                    style={{
                                        control: styles => ({ ...styles, width: '380px' })
                                    }}
                                    maxMenuHeight={150}
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: 50 }}>
                            <label className="workflow__newmodal-label">Select core skills</label>
                            <div className="workflow__newmodal-select workflow__newmodal-select-dropdown">
                                <Select
                                    isMulti
                                    options={[
                                        { value: 'Structuring', label: 'Structuring' },
                                        { value: 'Insights', label: 'Insights' },
                                        { value: 'Synthesis', label: 'Synthesis' },
                                        { value: 'Quantitative', label: 'Quantitative' },
                                        { value: 'Framing', label: 'Framing' },
                                    ]}
                                    value={coreSkill}
                                    onChange={setCoreSkill}
                                    style={{
                                        control: styles => ({ ...styles, width: '380px' })
                                    }}
                                    maxMenuHeight={150}
                                />
                            </div>
                        </div>
                    </>
                ) : ""
            }
            {
                jobRole === 'Custom' ? (
                    <div className="workflow__newmodal-main workflow__newmodal-main-alignstart">
                        <label className="workflow__newmodal-label">Pick relevant stages of sales cycle</label>
                        <div className="workflow__newmodal-select workflow__newmodal-select-dropdown">
                            <Select
                                isMulti
                                options={salesStagesData}
                                value={salesStages}
                                onChange={setSalesStages}
                                style={{
                                    control: styles => ({ ...styles, width: '380px' })
                                }}
                                maxMenuHeight={150}
                            />
                        </div>
                    </div>
                ) : ''
            }
            {
                showKeyTask && Object.keys(keyTask).length > 0 ?
                    Object.keys(keyTask).map((val, index) => (
                        <div key={index} style={{ marginTop: 20 }}>
                            <div style={{ display: 'flex', paddingLeft: 16, justifyContent: 'space-between', marginBottom: 20 }}>
                                <p style={{ fontSize: 16, color: '#010101' }}>{val}</p>
                            </div>
                            <div style={{ backgroundColor: '#f8f8f8', padding: 16, borderRadius: 40, marginTop: 10 }}>
                                <div style={{ fontSize: 14, color: '#595959' }}>De-select the ones that are not relevant to the role</div>
                                {
                                    keyTask[val].map((value, i) =>
                                    (
                                        <div
                                            className='key-task key-task-activekey'
                                            key={i}
                                        >
                                            <div
                                                onClick={(e) => {
                                                    addKeyTask(driveRole, val, value);
                                                    e.target.parentNode.classList.add("key-task-activekey");
                                                }}
                                                style={{ cursor: finalVal[driveRole][val].indexOf(value) > -1 ? 'default' : 'pointer', display: 'flex', flex: 1, fontSize: 16 }}
                                            >
                                                {value}
                                            </div>
                                            {
                                                finalVal[driveRole][val].indexOf(value) > -1 ? (
                                                    <div
                                                        style={{ marginLeft: 10, fontSize: 16, cursor: 'pointer' }}
                                                        onClick={(e) => {
                                                            removeKeyTask(driveRole, val, value);
                                                            e.target.parentNode.classList.remove("key-task-activekey");
                                                        }}
                                                    >
                                                        x
                                                    </div>
                                                ) : ''
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    )
                    ) : ''
            }
            {
                coreSkill.length > 0 ? (
                    <div style={{ background: '#f8f8f8', padding: '40px 50px', marginTop: 30 }}>
                        <div>
                            {
                                coreSkill.map((cs) => (
                                    <div style={{ display: 'flex', padding: '25px 0', alignItems: 'center' }} key={cs.label}>
                                        <div style={{ flex: 1 }}>{cs.label}</div>
                                        <div style={{ flex: 2, justifyContent: 'center', display: 'flex' }}>
                                            <RangeSlider
                                                onChangeSlider={(e) => onChangeWeightage(e, cs)}
                                                helperText="Add Weightage"
                                                minValue={10}
                                                maxValue={50}
                                                rangeValue={cs.weightage}
                                            />
                                        </div>
                                        <div style={{ flex: 1, justifyContent: 'flex-end', display: 'flex', cursor: 'pointer', color: '#858585' }} onClick={() => removeCoreSkill(cs)}>Remove</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                ) : ''
            }
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <label className="workflow__newmodal-label">Add custom video question</label>
                <MuiSelect
                    className="add-role-select remove-bg"
                    value={addVideoCount}
                    onChange={(e) => {
                        setAddVideoCount(e.target.value);
                    }}>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                </MuiSelect>
                <div
                    style={{
                        backgroundColor: '#479BD2',
                        borderRadius: 16,
                        padding: '4px 16px',
                        color: '#fff',
                        cursor: 'pointer',
                        width: 160,
                        textAlign: 'center',
                        boxSizing: 'border-box'
                    }}
                    onClick={openVideoQuestionForm}
                >
                    {videoQuestions.length > 0 && videoQuestions.length === addVideoCount ? 'Edit' : 'Add'}
                </div>
            </div>
            <div style={{ display: 'flex', marginTop: 20, alignItems: 'center' }}>
                <div style={{ marginRight: 50 }}>Variability</div>
                <SwitchBtn onChangeSwitch={onChangeWorkflowFixed} activeBtn={workflowFixed} />
            </div>
            {
                driveRole === 'Project Manager' || driveRole === 'Product Manager' ? (
                    <div style={{ display: 'flex', marginTop: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ marginRight: 50 }}>Create custom assessment</div>
                        <div style={{ marginRight: 50, color: '#B6B6B6', fontSize: 14 }}>Add custom fields to customize your assessment</div>
                        <SwitchBtn onChangeSwitch={onChangeCustomAssessment} activeBtn={Object.keys(customFieldsData).length > 0} />
                        {
                            Object.keys(customFieldsData).length > 0 ? (
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 25, cursor: 'pointer' }} onClick={openCustomAssessmentForm}>
                                    <Icon aria-label="edit"><img src={Edit} alt="" width={30} height={30} /></Icon>
                                    <div style={{ marginLeft: 10 }}>Edit</div>
                                </div>
                            ) : ''
                        }
                    </div>
                ) : ''
            }
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
                <div className='action' onClick={onSubmitWorkflow}>Submit</div>
            </div>
        </div>
    )
}

export default AddCustomAssessment;
