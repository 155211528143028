import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

import HelperSection from '../HelperSection';
import Message from './Message';

import '../../../styles/ChatBody1.css';
import "../../../styles/chatboxpage.css";
import '../../../styles/chatBody.scss';

const ChatBody = (props) => {
	const { messages, addToMessageBox, setNextQues, nextQues, seeQues, setSeeQues, setCaseState, setProgress, setStopChat, addFirstMessage, setMessageBox, exhibitObjs, addToExhibitObjs, startTime, startT, totalChatTime } = props;
	let [globalRef, setGlobalRef] = useState({ 'na': useRef(null) });
	const [modal, setModal] = useState(false);

	const location = useLocation();

	const ChatbodyScrollbarRef = useRef(null);

	const onOpenModal = () => setModal(true);

	const onCloseModal = () => setModal(false);

	const messageItems = messages.old.messages.length ? messages.old.messages.map((message, index) => {
		if (message.q.length !== 0)
			return <Message
				key={index}
				newMssg={false}
				isMyMessage={message.isMyMessage ? message.isMyMessage : false}
				message={message}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
				exhibitObjs={exhibitObjs}
				messages={messages}
				startTime={startTime}
				startT={startT}
			/>;
		else
			return null
	}) : "";

	const messageItems1 = [];

	if (messages.new.messages.length > 0)
		if (messages.new.messages[0].qtype && (messages.new.messages[0].qtype[0] == "m" || messages.new.messages[0].qtype[0] == "s")) {
			const dumdum = JSON.parse(JSON.stringify(messages.new.messages[0]));
			dumdum.q = 'na';

			messageItems1.push(<Message
				newMssg={true}
				isMyMessage={false}
				message={dumdum}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
				addFirstMessage={addFirstMessage}
				addToMessageBox={addToMessageBox}
				exhibitObjs={exhibitObjs}
				messages={messages.new}
				startTime={startTime}
				startT={startT}
				totalChatTime={totalChatTime}
			/>)
		} else if (messages.new.messages[0].qtype && (messages.new.messages[0].qtype === "vid0" || messages.new.messages[0].qtype === "vid2" || messages.new.messages[0].qtype === "vid3")) {
			messageItems1.push(<Message
				newMssg={true}
				isMyMessage={messages.new.messages[0].isMyMessage ? messages.new.messages[0].isMyMessage : false}
				message={messages.new.messages[0]}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
				addFirstMessage={addFirstMessage}
				addToMessageBox={addToMessageBox}
				setProgress={setProgress}
				setNextQues={setNextQues}
				messages={messages.new}
				exhibitObjs={exhibitObjs}
				startTime={startTime}
				startT={startT}
				totalChatTime={totalChatTime}
			/>)
		} else
			messageItems1.push(<Message
				newMssg={true}
				isMyMessage={messages.new.messages[0].isMyMessage ? messages.new.messages[0].isMyMessage : false}
				message={messages.new.messages[0]}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
				addFirstMessage={addFirstMessage}
				addToMessageBox={addToMessageBox}
				exhibitObjs={exhibitObjs}
				messages={messages.new}
				startTime={startTime}
				startT={startT}
				totalChatTime={totalChatTime}
			/>)
	for (let i = 1; i < messages.new.messages.length; i++) {
		if (messages.new.messages[i].q.length !== 0)
			messageItems1.push(<Message
				newMssg={true}
				isMyMessage={messages.new.messages[i].isMyMessage ? messages.new.messages[i].isMyMessage : false}
				message={messages.new.messages[i]}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
				addFirstMessage={addFirstMessage}
				addToMessageBox={addToMessageBox}
				exhibitObjs={exhibitObjs}
				messages={messages.new}
				startTime={startTime}
				startT={startT}
				totalChatTime={totalChatTime}
			/>)
	}

	useEffect(() => {
		const userMessages = document.getElementsByClassName('userMsg');
		const otherMessage = document.getElementsByClassName('other-message');
		if (userMessages[0]) {
			setTimeout(() => { userMessages[0].scrollIntoView({ alignToTop: true }); }, 0);
		}
		else if (otherMessage.length > 2) {
			otherMessage[otherMessage.length - 2].scrollIntoView({ alignToTop: true });
		}
	});

	return (
		<div id="chatMessageList" >
			<div ref={ChatbodyScrollbarRef} className="ChatbodyScrollbar" style={{ postion: "relative", boxSizing: "border-box", margin: "0 1vw", display: "flex", flexDirection: 'column-reverse', overflowY: "scroll", height: "100%" }}>
				<HelperSection messages={messages.new} addToMessageBox={addToMessageBox} nextQues={nextQues} setNextQues={setNextQues} seeQues={seeQues} setSeeQues={setSeeQues} setCaseState={setCaseState} setProgress={setProgress} setStopChat={setStopChat} addFirstMessage={addFirstMessage} setMessageBox={setMessageBox} addToExhibitObjs={addToExhibitObjs} startTime={startTime} startT={startT} totalChatTime={totalChatTime} />
				{messages.new.messages[0].isMyMessage ?
					<div className={"message-row other-message"}>
						<div className="message-content">
							<img className="message-row-img" src="bot.png" />
							<div>
								<div class="stage">
									<div class="dot-pulse"></div>
								</div>
							</div>
						</div>
					</div>
					: null}
				{messageItems1}
				{messageItems}
			</div>
			<div onClick={onOpenModal} style={{ display: "flex", flexDirection: "row", cursor: "pointer", justifyContent: "end", alignItems: "center", marginRight: "1vw" }}>
				{(location.state.prob !== 'na') ? <img
					style={{
						height: '7vh',
						display: 'block',
						marginRight: '1.5rem',
						paddingLeft: '1rem',
					}}
					src='./probStat.png'
					alt=''
				></img> : null
				}
			</div>
			<Modal open={modal} onClose={onCloseModal} center styles={{ modal: { padding: "40px", borderRadius: "50px" } }}>
				<h3>Revisit the Problem Statement here:</h3>
				<p style={{ margin: "20px 20px 10px" }}>{location.state.prob.replaceAll('::', " ").split('***').map(function (item2, key2) {
					return (<span>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)
				})}</p>
			</Modal>
		</div>
	)
}

ChatBody.propTypes = {
	messages: PropTypes.object,
	addToMessageBox: PropTypes.func,
	setNextQues: PropTypes.func,
	nextQues: PropTypes.array,
	seeQues: PropTypes.bool,
	setSeeQues: PropTypes.func,
	setCaseState: PropTypes.func,
	setProgress: PropTypes.func,
	setStopChat: PropTypes.func,
	addFirstMessage: PropTypes.func,
	setMessageBox: PropTypes.func
}

export default ChatBody;
