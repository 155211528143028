const config1 = [
	{
		name: "See Report",
		description: "See candidate's report by downloading it from the dashboard",
		key: "report"
	},
	{
		name: "Proctoring",
		description: "Access proctor video recorded during test",
		key: "vidproct"
	},
	{
		name: "Video Submissions",
		description: "If your test has video submission, turn on the feature for access",
		key: "vidanswer"
	},
	{
		name: "Chats",
		description: "See transcript of user's interaction",
		key: "transcript"
	},
	{
		name: "Post-assessment mail",
		description: "Customise and send mails to candidates to acknowledge submission of assessment",
		key: "completeEmail"
	},
	{
		name: "Drive Details",
		description: "Enable overview of role, critical work functions and key tasks that you selected",
		key: "driveDetails"
	}
];
export default function config() {
	return config1
}