import React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { logoutUser as logoutUserRequest } from '../../actions/app';

import '../../styles/freeApp/sidebar.scss';

const Sidebar = (props) => {
    const { logoutUser } = props;

    const history = useHistory();

    const onLogout = () => {
        logoutUser({userType: 'free', history});
    };

    return (
        <div id="sidebar">
            <div style={{ display: 'flex', flex: 1, padding: '40px 0', flexDirection: 'column', boxSizing: 'border-box' }}>
                <Link to={"/free/home"} style={{ padding: '8px 40px', borderRadius: 8, backgroundColor: 'rgba(209, 209, 209, 0.45)', fontWeight: '700', fontSize: 16, textDecoration: 'none', color: '#000' }}>History</Link>
            </div>
            <div style={{ padding: '28px 40px 0', borderTop: '1px solid rgba(0, 0, 0, 0.36)', boxSizing: 'border-box' }}>
                {/* <div style={{ fontSize: 16, fontWeight: '600', color: 'rgba(102, 102, 102, 0.73)' }}>Help and setting</div> */}
                <div style={{ marginTop: 5, fontSize: 16, fontWeight: '600', color: 'rgba(102, 102, 102, 0.73)', cursor: 'pointer' }} onClick={onLogout}>Log out</div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    logoutUser: (data) => dispatch(logoutUserRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
