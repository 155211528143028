import React, { useState, useEffect, useRef, useMemo } from 'react'
import Message from './Message'
import '../../../styles/ChatBody1.css'
import "../../../styles/chatboxpage.css"
import HelperSection from '../HelperSection'
import Modal from 'react-responsive-modal'
import { useLocation } from 'react-router'

const ChatBody = ({ messages, addToMessageBox, setNextQues, nextQues, seeQues, setSeeQues, setCaseState, setProgress, setIntervalId, setStopChat, addFirstMessage, setMessageBox }) => {

	let [globalRef, setGlobalRef] = useState({ 'na': useRef(null) })
	const [open, setOpen] = React.useState(false);
	const location = useLocation();

	const [modal, setModal] = useState(false);
	const onOpenModal = () => setModal(true);
	const onCloseModal = () => setModal(false);
	const toggleDrawer = (newOpen) => () => {
		console.log("sdfs")
		setOpen(newOpen);
	};
	//console.log(globalRef)
	console.log(messages.new)
	//console.log(messages1)
	const messageItems = messages.old.messages.length ? messages.old.messages.map((message, index) => {
		if (message.q.length !== 0)
			return <Message
				key={index}
				newMssg={false}
				isMyMessage={message.isMyMessage ? message.isMyMessage : false}
				message={message}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
			/>;
		else
			return null
	}) : "";

	const messageItems1 = []
	if (messages.new.messages.length > 0)
		if (messages.new.messages[0].qtype && (messages.new.messages[0].qtype[0] == "m" || messages.new.messages[0].qtype[0] == "s")) {
			console.log(messages.new.messages[0])
			const dumdum = JSON.parse(JSON.stringify(messages.new.messages[0]));
			dumdum.q = 'na'
			console.log(messages.new.messages[0], dumdum)
			messageItems1.push(<Message
				// key={index}
				newMssg={true}
				isMyMessage={false}
				message={dumdum}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
				addFirstMessage={addFirstMessage}
				addToMessageBox={addToMessageBox}
			/>)
		}
		else if (messages.new.messages[0].qtype && (messages.new.messages[0].qtype === "vid0" || messages.new.messages[0].qtype === "vid2" || messages.new.messages[0].qtype === "vid3")) {
			messageItems1.push(<Message
				// key={index}
				newMssg={true}
				isMyMessage={messages.new.messages[0].isMyMessage ? messages.new.messages[0].isMyMessage : false}
				message={messages.new.messages[0]}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
				addFirstMessage={addFirstMessage}
				addToMessageBox={addToMessageBox}
				setProgress={setProgress}
				setNextQues={setNextQues}
				messages={messages.new}
			/>)
		}
		else
			messageItems1.push(<Message
				// key={index}
				newMssg={true}
				isMyMessage={messages.new.messages[0].isMyMessage ? messages.new.messages[0].isMyMessage : false}
				message={messages.new.messages[0]}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
				addFirstMessage={addFirstMessage}
				addToMessageBox={addToMessageBox}
			/>)
	for (let i = 1; i < messages.new.messages.length; i++) {
		if (messages.new.messages[i].q.length !== 0)
			messageItems1.push(<Message
				// key={index}
				newMssg={true}
				isMyMessage={messages.new.messages[i].isMyMessage ? messages.new.messages[i].isMyMessage : false}
				message={messages.new.messages[i]}
				globalRef={globalRef} setGlobalRef={setGlobalRef}
				addFirstMessage={addFirstMessage}
				addToMessageBox={addToMessageBox}
			/>)
	}
	//   messages.new.messages.length?messages.new.messages.map((message, index) => {
	//   console.log(message)
	//   if(message.q.length !== 0)
	//     return <Message 
	//       key={index}
	//       newMssg={true}
	//       isMyMessage={message.isMyMessage?message.isMyMessage:false}
	//       message={message} 
	//       globalRef={globalRef} setGlobalRef={setGlobalRef}
	//     />;
	//   else
	//     return null
	// }): "";


	const ChatbodyScrollbarRef = useRef(null);

	//  function usePrevious(value) {
	//    const ref = useRef();
	//    useEffect(() => {
	//      ref.current = value;
	//    }, [value]); 
	//    return ref.current;
	//  }

	//  function getHeightInVh(val) {
	//    return (window.innerHeight /100) * val;
	//  }

	//  const prevScrollHeight = usePrevious(ChatbodyScrollbarRef.current ? ChatbodyScrollbarRef.current.scrollHeight : 0)

	//  useEffect(() => {
	//    if(!ChatbodyScrollbarRef.current) return;

	//    const diffScrollHeight = (ChatbodyScrollbarRef.current.scrollHeight - prevScrollHeight);
	//    if(diffScrollHeight > ChatbodyScrollbarRef.current.offsetHeight) 
	//    ChatbodyScrollbarRef.current.scrollTo({
	//       top: ChatbodyScrollbarRef.current.offsetHeight-diffScrollHeight-getHeightInVh(8),
	//       left: 0,
	//    });
	//    // ChatbodyScrollbarRef.current.firstChild.scrollIntoView({alignToTop : true});
	//  },[prevScrollHeight])

	useEffect(() => {
		const userMessages = document.getElementsByClassName('userMsg');
		const otherMessage = document.getElementsByClassName('other-message');
		if (userMessages[0]) {
			userMessages[0].scrollIntoView({ alignToTop: true });
		} else if (otherMessage.length > 2) {
			otherMessage[otherMessage.length - 2].scrollIntoView({ alignToTop: true });
		}
	})

	return (
		<div id="chatMessageList" style={{ position: 'relative', height: "84vh", background: "#fff", padding: "4vh 1vw 0", margin: "0 2vw", fontSize: "13px", color: "whitesmoke", borderTopLeftRadius: "100px", borderTopRightRadius: "100px" }} >
			<div ref={ChatbodyScrollbarRef} className="ChatbodyScrollbar" style={{ postion: "relative", boxSizing: "border-box", margin: "0 1vw", padding: "0 1vw 100px", display: "flex", flexDirection: 'column-reverse', overflowY: "scroll", height: "100%" }}>
				{/* <div ref={ChatbodyScrollbarRef} className="ChatbodyScrollbar" style={{postion: "relative", boxSizing: "border-box", margin:"0 1vw", padding:"0 1vw 8vh", display:"flex", flexDirection:'column-reverse', overflowY:"scroll", height:"100%"}}> */}

				<HelperSection messages={messages.new} addToMessageBox={addToMessageBox} nextQues={nextQues} setNextQues={setNextQues} seeQues={seeQues} setSeeQues={setSeeQues} setCaseState={setCaseState} setProgress={setProgress} setStopChat={setStopChat} addFirstMessage={addFirstMessage} setMessageBox={setMessageBox} />
				{messages.new.messages[0].isMyMessage ?
					<div className={"message-row", " other-message"}>
						<div className="message-content">
							<img className="message-row-img" src="bot.png" />
							<div>
								<div class="stage">
									<div class="dot-pulse"></div>
								</div>
							</div>
						</div>
					</div>
					: null}
				{messageItems1}
				{messageItems}
			</div>
			<div onClick={onOpenModal} style={{ display: "flex", flexDirection: "row", cursor: "pointer", justifyContent: "end", alignItems: "center", marginRight: "1vw" }}>
				{(location.state.prob !== 'na') ? <img
					style={{
						height: '7vh',
						display: 'block',
						marginRight: '1.5rem',
						paddingLeft: '1rem',
					}}
					src='./probStat.png'
					alt=''
				></img> : null
				}
			</div>
			<Modal open={modal} onClose={onCloseModal} center styles={{ modal: { padding: "40px", borderRadius: "50px" } }}>
				<h3>Revisit the Problem Statement here:</h3>
				<p style={{ margin: "20px 20px 10px" }}>{location.state.prob.replaceAll('::', " ").split('***').map(function (item2, key2) {
					return (<span>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)
				})}</p>
			</Modal>
		</div>
	)
}

export default ChatBody
