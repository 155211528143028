import React, { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { useHistory } from "react-router";
import { Modal } from 'react-responsive-modal';
import emptyRowImg from '../../assets/emptyrow1.png';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getRerender, getConvoList } from '../../selectors/app';
import {
	resetRerender as resetRerenderRequest,
	addBots as addBotsRequest,
	deleteBots as deleteBotsRequest,
	getBots as getBotsRequest
} from '../../actions/app';

function Bots(props) {
	const {
		rerender,
		resetRerender,
		addBots,
		deleteBots,
		convo,
		getBots
	} = props;

	const history = useHistory();
	const location = {
		state: {
			role: "super-admin"
		}
	}
	const [newModal, setNewModal] = useState(false)
	const onOpenNewModal = () => setNewModal(true);
	const onCloseNewModal = () => setNewModal(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const onOpenDeleteModal = () => setDeleteModal(true);
	const onCloseDeleteModal = () => {
		setBotToBeDeleted([]);
		setDeleteModal(false);
	}

	const [name, setName] = useState("");
	const [productId, setProductId] = useState("");
	const [problem, setProblem] = useState("");
	const [pageSize, setPageSize] = useState(15);
	const [change, setChange] = useState(0);
	const [botToBeDeleted, setBotToBeDeleted] = useState([]);

	useEffect(() => {
		if (location.state.role != "super-admin") {
			history.push("/invalid");
		}
	}, [change]);

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (newModal) onCloseNewModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender]);

	useEffect(() => {
		getBots();
	}, [change])

	useEffect(() => {
		if (botToBeDeleted.length == 0) return;

		onOpenDeleteModal();
	}, [botToBeDeleted]);


	const columns = [
		{
			field: "name",
			headerName: "Name",
			headerAlign: "left",
			align: "center",
			flex: 1
		},
		{
			field: "productId",
			headerName: "Product ID",
			headerAlign: "left",
			minWidth: 200
		},
		{
			field: "action",
			headerName: "Actions",
			minWidth: 140,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<div className="datagrid__row datagrid__row-center">
						<Button
							className="btn-datagrid btn-danger"
							size="small"
							variant="contained"
							onClick={() => setBotToBeDeleted([params.row])}
						>
							Delete
						</Button>
					</div>
				)
			}
		}
	]

	function sendBots() {
		addBots({ name: name, productId: productId, problem: problem });
	}

	function handleDelete(id) {
		deleteBots({ _id: id });
	}

	function renderDate(date) {
		let createdDate = new Date(date);
		return (
			<>{createdDate.toLocaleString()}</>
		)
	}

	function NoRowsOverlay() {
		return (
			<div className="norowsoverlay__container">
				<img
					src={emptyRowImg}
					alt="no rows"
					className="norowsoverlay__img"
				/>
				<p className="norowsoverlay__text">Nothing to show</p>
			</div>
		)
	}

	return (
		<div className="mainbar" style={{ display: "flex", flexDirection: "column", marginLeft: "20vw", width: "80vw", minHeight: "100vh" }}>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
				<div className="modal__heading">
					Do you want to delete this bot?
				</div>
				{
					botToBeDeleted.length != 0 ?
						Object.keys(botToBeDeleted[0]).map(key => (
							<>
								<div>
									<span><b>{key} : </b></span>
									<span>{key == "createdDate" ? renderDate(botToBeDeleted[0][key]) : botToBeDeleted[0][key]}</span>
								</div><br />
							</>
						))
						:
						""
				}
				<div className="modal__btnbox">
					<Button
						className="btn-primary btn-danger"
						size="small"
						variant="contained"
						disabled={botToBeDeleted.length == 0}
						onClick={() => {
							handleDelete(botToBeDeleted[0]._id);
						}}>
						Ok
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseDeleteModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>

			<Modal open={newModal} onClose={onCloseNewModal} classNames={{ modal: 'modal__container', closeIcon: 'modal__closeicon' }}>
				<div className="modal__heading">
					Create new bot
				</div>
				<div className="modal__inputbox">
					<input type="text" placeholder="Bot Name" value={name} onChange={(e) => { setName(e.target.value) }} /><br />
					<input type="text" placeholder="Product ID" value={productId} onChange={(e) => { setProductId(e.target.value) }} /><br />
					<textarea type="text" placeholder="Problem" onChange={(e) => { setProblem(e.target.value) }} required /><br />
				</div>
				<div className="modal__btnbox">
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={() => sendBots()}
					>
						Create
					</Button>
					<Button
						className="btn-primary"
						size="small"
						variant="contained"
						onClick={onCloseNewModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>

			<div className="notificationbar">
				<button className="btn-primary" onClick={onOpenNewModal}>Create new Bot</button>
			</div>
			<div className="actionbar">

			</div>

			{/* {selectionModel.length>0?
      <div className="datagrid__bulkaction">
        <div className="datagrid__bulkaction-btnbox">
          <Button className="btn-primary" onClick={() => {onOpenDeleteModal()}}>Delete</Button>
          <Button className="btn-primary" onClick={() => {}}>Edit</Button>
        </div>
      </div>:""} */}

			<div className="datagrid__container">
				<DataGrid
					sortingOrder={['desc', 'asc']}
					components={{ NoRowsOverlay }}
					getRowId={(row) => row._id}
					rows={convo.length == 0 ? [] : convo}
					disableSelectionOnClick
					columns={columns}
					autoHeight
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					className="datagrid__scroll scroll-blue"
				/>
			</div>

		</div>
	);
}


const mapStateToProps = createStructuredSelector({
	rerender: getRerender(),
	convo: getConvoList()
});

const mapDispatchToProps = (dispatch) => {
	return {
		resetRerender: () => dispatch(resetRerenderRequest()),
		addBots: (data) => dispatch(addBotsRequest(data)),
		deleteBots: (data) => dispatch(deleteBotsRequest(data)),
		getBots: () => dispatch(getBotsRequest()),
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Bots);