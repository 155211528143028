import React, {useState, useEffect} from 'react'
// import { Button } from '@material-ui/core'
import Button from '@mui/material/Button';
import styles from '../../../styles/MultiSelect.module.css'
// import {options} from "../../../data/conversations"
import { Grid } from '@material-ui/core'
import axios from '../../../axios'
import "../../../styles/btn.css"
import { Modal } from 'react-responsive-modal';
import "../../../styles/modal.css"
import { useLocation } from 'react-router'
import {fixedAssessmentData} from "../../../constants/fixedAssessmentData"

const MultiSelect = ({messages, addToMessageBox, setNextQues, seeQues, setSeeQues, setProgress}) => {
  let location = useLocation()
  const [modal, setModal] = useState(false);
  const onOpenModal = () => setModal(true);
  const onCloseModal = () => setModal(false);

  const [openImage, setOpenImage] = useState(false);
  const onOpenImageModal = () => setOpenImage(true);
  const onCloseImageModal = () => setOpenImage(false);

  const [optionCountModal, setOptionCountModal] = useState(false)
  const onOpenOptionCountModal = () => setOptionCountModal(true);
  const onCloseOptionCountModal = () => setOptionCountModal(false);

  const [confirmation, setConfirmation] = useState(false);
  const onCloseConfirmation = () => setConfirmation(false);

  let currqtype=messages.messages[0].qtype
  let currMssg = messages.messages[0].q
  let maxNo = Number(messages.messages[0].qtype.replace(/\D/g, ''));
  if(maxNo === 0)
    maxNo = Number.MAX_VALUE
  let options = messages.messages[0].options

  const [appState, changeAppState] = useState({
    activeObjects: new Set(),
    objects: messages.messages[0].options
  })

  const onConfirmation = () => {
    let optionsSelected = [];
    let selectedOptions = options.map((option, index) => {  
      if (appState.activeObjects.has(index)) {
        let x = {  
          q: option,
          isMyMessage: true,
          ref: ""
        }
        return x
      }
      else
        return null
    })
    for (let x = selectedOptions.length - 1; x >= 0; x--) 
      if (selectedOptions[x] !== null)   
        optionsSelected.push(selectedOptions[x])

    if (currqtype.includes('max')) {
      if (optionsSelected.length > maxNo || optionsSelected.length == 0) { 
        onOpenModal()
        return null;
      }
    }

    else if ((optionsSelected.length !== maxNo && maxNo !== Number.MAX_VALUE) || optionsSelected.length == 0) { 
      onOpenModal()
      return null;
    }
    //setConfirmation(true);
    onClickConfirm();
  };

  const onClickConfirm = () => {
    setSeeQues(true)

    let optionsSelected = [];
    let selectedOptions = options.map((option, index) => {  
      if (appState.activeObjects.has(index)) {
        let x = {  
          q: option,
          isMyMessage: true,
          ref: ""
        }
        return x
      }
      else
        return null
    })

    for (let x = selectedOptions.length - 1; x >= 0; x--) 
      if (selectedOptions[x] !== null)   
        optionsSelected.push(selectedOptions[x])
    const url = '/userconvo'
    let getData = {
      user_id : location.state.code,
      comp: location.state.comp,
      message1 : messages.messages[0].qid,
      message2 : [...appState.activeObjects].sort().join(','),
      mode: 0
    }
    console.log(getData)
    addToMessageBox(optionsSelected)
    let msg22='Thanks. Let\'s go to the next question.';
    const msg1=fixedAssessmentData[location.state.id][parseInt(messages.messages[0].qid)]
    
    let parsedMssg = msg1[0].split(";;").map((ele,index)=>{
        return({
          q: ele,
          ref: "na"
        })
      })
      parsedMssg[parsedMssg.length-1]["qtype"] = msg1[1]
      parsedMssg[parsedMssg.length-1]["options"] = msg1[2]
      parsedMssg[parsedMssg.length-1]["qid"] = msg1[3]
      parsedMssg[parsedMssg.length-1]["exhibit"] = msg1[4]
      parsedMssg[parsedMssg.length-1]["progress"] = msg1[5];

      if(msg1[5] == "100") {
        msg22 = '';
      }
      
      
      let parsedRespMssg = msg22.split(";;").map((ele)=>{
        return({
          q: ele,
          ref: "na"
        })
      })

        
        // addToMessageBox(optionsSelected)
        setTimeout(()=>{sendToChatBox(parsedRespMssg.reverse(),parsedRespMssg.length-1,parsedMssg)},  optionsSelected.length*1500);
        ;
        //addToMessageBox(optionsSelected)
        //setTimeout(function(){addToMessageBox(combinedMssg)},1500)
        //setTimeout(()=>{setNextQues(parsedMssg.reverse())},(1+parsedRespMssg.length)*1500)
        setProgress(parseInt(msg1[5], 10))
   /*axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
      console.log(res.data)
      let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
        parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
        parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
        parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
        parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]

       let parsedRespMssg = res.data.msg2.split(";;").map((ele)=>{
          return({
            q: ele,
            ref: "na"
          })
        })

        
        // addToMessageBox(optionsSelected)
        setTimeout(()=>{sendToChatBox(parsedRespMssg.reverse(),parsedRespMssg.length-1,parsedMssg)},  optionsSelected.length*1500);
        ;
        //addToMessageBox(optionsSelected)
        //setTimeout(function(){addToMessageBox(combinedMssg)},1500)
        //setTimeout(()=>{setNextQues(parsedMssg.reverse())},(1+parsedRespMssg.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
        // by context show next question
      })
      .catch((err) => {
        console.log(err)
      })*/
  }
  function sendToChatBox(msglist, x, ques) {  
    addToMessageBox([msglist[x]])
    if (x > 0)
      setTimeout(() => { sendToChatBox(msglist, x - 1, ques) }, 1500);
    else
      setNextQues(ques.reverse())
  }

  function toggleActive(index){
    console.log([...appState.activeObjects].join(','));
    if(appState.activeObjects.has(index)){

      let set = appState.activeObjects
      set.delete(index)
      changeAppState({...appState, activeObjects: set});
      return "";
    }
    else {
      if(appState.activeObjects.size === maxNo && maxNo === 1){
        let set = new Set();
        set.add(index)
        changeAppState({objects: appState.objects, activeObjects: set})
        return "changed option"
      }
      else if(appState.activeObjects.size === maxNo){
        onOpenOptionCountModal()
        return null
      }
      let set = appState.activeObjects
      set.add(index)
      changeAppState({...appState, activeObjects: set})
      return "selected"
    }

    
  }

  useEffect(() => {
    changeAppState({
      activeObjects: new Set(),
    objects: messages.messages[0].options
    })
  }, [messages])
  
  return (
    <div style={{ background: "#fff", borderRadius:"2vh", boxShadow:"0.1rem 0.5rem 1rem grey", width:"50vw", marginTop:"1vh" }}>

      <div style={{ display: seeQues ? "none" : "flex", flexDirection: "column" }}>

        

          <p style={{color:"#0B76BC", padding:"2vh 4vw 0", fontSize:"16px", fontWeight:"600"}}> {currMssg.replaceAll('*','').split('::').map(function(item, key) {
            return (
            <span >
                {item}
                {key===0&&item[0]=="Q"?<><br/></>:null}
                <br/>
            </span>
            )
        })
        }</p>
          {
            confirmation ? 
            <Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "2vh 10vw 5vh", display: "flex", justifyContent:'center'}}>
            <div style={{padding: "10px", cursor: "pointer", width: "100%", display: "flex", flexWrap: "wrap",justifyContent:'center'}}>
            <p style={{ width: "100%", textAlign: "center", fontSize: "1.3vw", color:"#000", fontWeight: "bold"}}>Do you want to confirm these options? </p> 
            {appState.objects.map((element, index = { index }) => {
           if(appState.activeObjects.has(index))  { return (
                <Grid
                  key={index}
                  xs={6}
                  style={{ margin: "2vh 0 0" }}
                >
                  <div style={typoStyles}>
                    {element}
                  </div>
                </Grid>
              ) } 
              
              else{
                return null;
              }
            })
           }</div> 
           </Grid>
           :
           <Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "2vh 4vw 5vh", display: "flex"}}>
              {appState.objects.map((element, index = { index }) => {
                return (
                  <Grid
                    key={index}
                    xs={6}
                    style={{margin:"0"}}
                  >
                    <div style={{...typoStyles,borderBottom:index<appState.objects.length-2?"1.5px solid #dfdfdf":null}} onClick={() => { toggleActive(index) }}>
                    {<img src={appState.activeObjects.has(index)?"Eo_circle_light-blue_checkmark.svg.png":"unselected.png"} width="25px" style={{marginRight:"12.5px"}} />}
                      {element}
                    </div>
                  </Grid>
                )
              })
            }
            </Grid>
}
        
      </div>


      <div id="chat-form" className='justify-content-end' style={{ height: "70px", display: seeQues ? "none" : "flex", alignItems: "center", justifyContent: "flex-end" }}>

        
          <Button variant="contained" size="small" className={styles.SubmitButton} onClick={onConfirmation}>
           <p style={{ fontSize:'16px',fontWeight:'bold',textTransform:"none"}}>Confirm</p>
          </Button>
        <Modal open={modal} onClose={onCloseModal} center styles={{modal:{borderRadius:"50px", padding:"40px"}}}>
          <p className="modal-text">
            Kindly select appropriate number of options.
          </p>
          <div className="button-div">
            <Button 
            variant="contained" 
            size="small"  
            className="modal-button" 
            onClick={onCloseModal} 
            style={{
               background: '#479BD2', 
               fontWeight: 'bold', 
               fontFamily:"Poppins", 
               letterSpacing:"0.01em", 
               borderRadius: '25px',
               padding: '5px 12px',
               fontSize: '16px',
               textTransform: 'none',
               marginLeft:"10px"
            }}
            >
              OK
            </Button>
          </div>
        </Modal>
        <Modal open={optionCountModal} onClose={onCloseOptionCountModal} center styles={{modal:{borderRadius:"50px", padding:"40px"}}}>
          <p className="modal-text">
            Cannot select more than {maxNo}, first deselect an option.
          </p>
          <div className="button-div">
            <Button 
            variant="contained" 
            size="small"  
            className="modal-button" 
            onClick={onCloseOptionCountModal} 
            style={{
               background: '#479BD2', 
               fontWeight: 'bold', 
               fontFamily:"Poppins", 
               letterSpacing:"0.01em", 
               borderRadius: '25px',
               padding: '5px 12px',
               fontSize: '16px',
               textTransform: 'none',
               marginLeft:"10px"
            }} 
            >
              OK
            </Button>
          </div>
        </Modal>
      </div>

      <div id="chat-form" style={{
        display: seeQues ? "flex" : "none", height: "70px", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
          "center"
      }}>
        <button className='btn btn__secondary' onClick={() => { setSeeQues(false) }}>
          See Options
        </button>
      </div>
    </div>
  )
}

let typoStyles = {
  color: "#000",
  fontSize: "16px",
  height:"100%",
  borderBottom: "",
  padding: "0px",
  margin: "2vh",
  lineHeight: "20px",
  userSelect: "none",
  MSUserSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  // textAlign: "center",
  display:"flex",
  alignItems:'center'
}
export default MultiSelect
