import React, {useState, useEffect} from 'react';
import Spreadsheet from "react-spreadsheet";
import Modal from 'react-responsive-modal';
import axios from '../../../../axios'
import Button from '@mui/material/Button';
// import {data as importedData} from './dataSource';
//import {newData} from './dataSource2';
import { useLocation } from 'react-router'
import "./spreadsheet.css";

export default function ReactSpreadSheet({messages, addToMessageBox, setNextQues, seeQues, setSeeQues, setProgress}) {
   let location = useLocation()
   const newData=messages.messages[0].options[0]
   const [data, setData] = useState(newData);
   const [spredSheetModalOpen, setSpredSheetModalOpen] = React.useState(false);
   const handleSpreadSheetModalOpen = () => setSpredSheetModalOpen(true);
   const handleSpreadSheetModalClose = () => setSpredSheetModalOpen(false);
   function sendToChatBox(msglist, x, ques) {  
    addToMessageBox([msglist[x]])
    if (x > 0)
      setTimeout(() => { sendToChatBox(msglist, x - 1, ques) }, 1500);
    else
      setNextQues(ques.reverse())
  }
   const onSubmit = () => {
    let optionsSelected = [{  
          q: 'Excel submitted.',
          isMyMessage: true,
          ref: ""
        }];
    const url = '/userconvo'
    let getData = {
      user_id : location.state.code,
      comp: location.state.comp,
      message1 : messages.messages[0].qid,
      message2 : data,
      mode: 0
    }
    console.log(getData)
    addToMessageBox(optionsSelected)
   axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
      console.log(res.data)
      let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
        parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
        parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
        parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
        parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]

        let respMssg = [{
                  q: res.data.msg2,
                  ref:"na"
                }]

        
        // addToMessageBox(optionsSelected)
        setTimeout(()=>{sendToChatBox(respMssg,respMssg.length-1,parsedMssg)},  optionsSelected.length*1500);
        ;
        /*addToMessageBox(optionsSelected)
        setTimeout(function(){addToMessageBox(combinedMssg)},1500)*/
        //setTimeout(()=>{setNextQues(parsedMssg.reverse())},(1+parsedRespMssg.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
        // by context show next question
      })
      .catch((err) => {
        console.log(err)
      })
  }

   return (
      <div>
         <Button 
            style={{
               background: '#479BD2', 
               fontWeight: 'bold', 
               fontFamily:"Poppins", 
               letterSpacing:"0.01em", 
               borderRadius: '25px',
               padding: '5px 12px',
               fontSize: '16px',
               textTransform: 'none'
            }}
            className="spreadSheetBtn"
            size="small" 
            variant="contained"
            onClick={handleSpreadSheetModalOpen}
            >
               Click here for calculation
         </Button>

         <Modal
         open={spredSheetModalOpen}
         onClose={handleSpreadSheetModalClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         center={true}
         >
            <div className='spreadSheet' style={{width: '100%', height: 'max-content', maxHeight: '600px', overflow: 'scroll', margin: '25px 0 0 0'}}>
               <Spreadsheet data={newData} onChange={setData} />
            </div>
            <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              margin: '25px 0 0 0' 
            }}>
               <Button 
               size="small" 
               variant="contained"
               onClick={onSubmit}
               style={{
                  background: '#479BD2', 
                  fontWeight: 'bold', 
                  fontFamily:"Poppins", 
                  letterSpacing:"0.01em", 
                  borderRadius: '25px',
                  padding: '5px 12px',
                  fontSize: '16px',
                  textTransform: 'none'
               }}
               >
                  Submit
               </Button>
            </div>
         </Modal>
      </div>
   )
}
