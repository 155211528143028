import React from 'react';
import { Link, useLocation } from "react-router-dom";
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getSettings, getUserName } from '../../selectors/app';

import Logo from "../../assets/Logo.png";
import DashboardActive from '../../assets/Icons/dashboard-active.png';
import TasksIcon from '../../assets/Icons/tasks.png';
import ContactsIcon from '../../assets/Icons/contacts.png';
import WidgetAlt from '../../assets/Icons/Widget_alt.png';

import "./sidebar.css";

function Sidebar(props) {
	const { hidden = false, userName, settings } = props;

	const location = useLocation();

	return (
		<div style={{ width: "20vw", color: "black" }}>
			<div className="sidebar">
				<div className="sidebarWrapper">
					<div className="logo-div">
						<img style={{ height: "auto", width: "11vw" }} src={Logo} alt="" />
					</div>
					<div className="user-div">
						<div className="user-details">
							<h4 style={{ padding: '0 8px', fontSize: '14px' }}>Welcome,<br /> {userName}</h4>
						</div>
					</div>
					<div className="sidebarMenu">
						<Link to={{ pathname: "/workflows", state: { sidebar: "workflows" } }} className="link">
							<Button><img src={DashboardActive} alt="" />&nbsp;Recruitment Drives</Button>
						</Link>
					</div>

					<div className={location.state.sidebar === "quickLook" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"} style={{ display: hidden ? "none" : "" }}>
						<Link to={{ pathname: "/workflows/" + location.state.workflowName, state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "quickLook" } }} className="link">
							<Button className='dashboard-fifth-step'><img src={TasksIcon} alt="" />&nbsp;Quick Overview</Button>
						</Link>
						<ul className="sidebarList">
							<Link to={{ pathname: "/applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "applicants" } }} className="link" id="applicantsFlow-selector-1">
								<li className={location.state.sidebar === "applicants" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
									All Applicants
								</li>
							</Link>
							<Link to={{ pathname: "/finalized", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "finalised" } }} className="link dashboard-sixth-step" id="reviewedFlow-selector-1">
								<li className={location.state.sidebar === "finalised" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
									Reviewed
								</li>
							</Link>
							<Link to={{ pathname: "/analytics", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "analytics" } }} className="link dashboard-seventh-step">
								<li className={location.state.sidebar === "analytics" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
									Analytics
								</li>
							</Link>
							<Link to={{ pathname: "/settings", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "settings" } }} className="link dashboard-eighth-step" id="settingsFlow-selector-1">
								<li className={location.state.sidebar === "settings" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
									Settings
								</li>
							</Link>
							{
								settings.driveDetails ? (
									<Link className="link dashboard-eighth-step"
										to={{ pathname: "/drive-details", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "drive-details" } }}>
										<li className={location.state.sidebar === "drive-details" ? "activeSidebarListItem" : "sidebarListItem"} style={{ color: "#555", fontSize: "0.9rem" }}>
											Drive Details
										</li>
									</Link>
								) : ''
							}
						</ul>
					</div>
					<div className={window.location.pathname === "/new-applicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link className="link" to={{ pathname: "/new-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "invitations" } }} id="manageInvitation-selector-1">
							<Button className="dashboard-second-step"><img src={DashboardActive} alt="" />&nbsp;Manage Invitations</Button>
						</Link>
					</div>
					<div className={location.state.sidebar === "reviewApplicants" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link className="link" to={{ pathname: "/review-applicants", state: { workflowId: location.state.workflowId, workflowName: location.state.workflowName, sidebar: "reviewApplicants" } }} id="reviewApplicantsFlow-selector-1">
							<Button className='dashboard-third-step'><img src={WidgetAlt} alt="" style={{ marginLeft: "-2px" }} />&nbsp;Review Applicants</Button>
						</Link>
					</div>
					<div className={window.location.pathname === "/team" ? "sidebarMenu activesidebarMenu" : "sidebarMenu"}>
						<Link to={{ pathname: "/team", state: { sidebar: "team" } }} style={{ textDecoration: "none" }}>
							<Button><img src={ContactsIcon} alt="" />&nbsp;Manage Team</Button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	userName: getUserName(),
	settings: getSettings()
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
