import React, { useState, useEffect, useMemo, useCallback } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import Snackbar from '@mui/material/Snackbar';
import Switch from '@mui/material/Switch';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Divider } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useLocation, useHistory } from 'react-router';

import AccountMenu from "../accountMenu/AccountMenu";
import axios from "../../axios";
import ChangePassword from '../../components/common/ChangePassword';
import config from "../../utils/config";
import DriveDetails from '../../components/settings/DriveDetails';
import helperFunction from '../../helper/helperFunction';
import NotificationBell from "../notificationBell/notificationBell";
import {
	getCandidatesByType as getCandidatesByTypeRequest,
	updateUserSettings as updateUserSettingsRequest,
	fetchDriveDetails as fetchDriveDetailsRequest
} from '../../actions/app';
import { getCandidatesList, getSettingsLoading, getSettings, getDriveDetails } from '../../selectors/app';

import './Settings.css';
import '../../styles/settings.scss';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	cursor: 'pointer'
};

const activeStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};


const Settings = (props) => {
	const { candidates, getCandidatesByType, notification, settings, updateUserSettings, fetchDriveDetails, driveDetails } = props;

	const location = useLocation();
	const history = useHistory();

	const [name, setName] = useState("");
	const [header, setHeader] = useState("");
	const [img, setImg] = useState("");
	const [file, setFile] = useState("");
	const [subject, setsubject] = useState("");
	const [path, setPath] = useState("");
	const [preEditorState, setPreEditorState] = useState("");
	const [postAssessmentModal, setPostAssessmentModal] = useState(false);
	const [snackOpen, setSnackOpen] = useState(false);
	const [mailChange, setMailChange] = useState(0);
	const [showVideoSetting, setShowVideoSetting] = useState(false);
	const [showDriveDetails, setShowDriveDetails] = useState(false);
	const [userSettings, setUserSettings] = useState({ ...settings });
	const [changePwdOpen, setChangePwdOpen] = useState(false);
	const [showCustomizePostMail, setShowCustomizePostMail] = useState(false);
	const [videoProctoring, setVideoProctoring] = useState(false);

	const onDrop = useCallback(acceptedFiles => {
		const dname = location.state.workflowId + 'completeEmail0';
		const files2 = acceptedFiles.find((file) => file);
		const files = acceptedFiles.map((file) => {
			setPath(dname + file.name.slice(-4));
			return new File([file], dname + file.name.slice(-4), { type: file.type });
		});

		setFile(files);
		setImg(files[0]);
		getBase64(files2);
	}, []);

	const { getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject } = useDropzone({ accept: 'image/*', onDrop });

	const handlePreEditorChange = (content, delta, source, editor) => {
		setPreEditorState(editor.getHTML());
	}

	const onOpenPostAssessmentModal = () => {
		setPostAssessmentModal(true);
	}

	const onClosePostAssessmentModal = () => {
		setPostAssessmentModal(false);
	}

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	useEffect(() => {
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'completeEmail' }).then((res) => {
			let mail = res.data.mails[0];

			setName(mail.name ? mail.name : "");
			setImg(mail.image ? mail.image : "");
			setFile(mail.image ? "https://hqpics.s3.ap-southeast-1.amazonaws.com/" + mail.image : "");
			setsubject(mail.subject ? mail.subject : "");
			setPath(mail.image ? mail.image : "");
			setHeader(mail.header ? mail.header : "");
			setPreEditorState(mail.body);
		}).catch((err) => {
			console.log(err);
			helperFunction.redirectUnauthorisedUser(err, history);
		});
	}, [mailChange]);

	const updateEmail = () => {
		let render = `<div style="width:100%;background:#bbb">
        <div style="width:500px;margin:auto;background:#fff;padding:20px;">
          <div style="width: 500px;text-align: center; ">
            <img src="https://hqpics.s3.ap-southeast-1.amazonaws.com/${path}" style=" max-width: 40%; height: auto;"/>
          </div>
          <p><span style="font-size:24px; text-align: left;">${header}</span></p>
          <br/><br/>
        <p><span style="font-size:16px; text-align: left;">${preEditorState}</span></p>
        </div>`;
		var formData = new FormData();
		formData.append('workflowId', location.state.workflowId);
		formData.append('key', 'completeEmail');
		formData.append('index', 0);
		formData.append('name', name);
		formData.append('image', img);
		formData.append('path', path);
		formData.append('body', preEditorState);
		formData.append('subject', subject);
		formData.append('render', render);
		axios.post("/workflow/setemail", formData, { 'Content-Type': 'multipart/form-data' })
			.then(() => {
				setMailChange(mailChange + 1);
			})
			.catch((err) => {
				console.log(err)
				helperFunction.redirectUnauthorisedUser(err, history);
			})
	}

	useEffect(() => {
		const reqData = {
			status: "review",
			id: location.state.workflowId
		};

		getCandidatesByType({ reqData, pageType: 'settings' });
	}, []);

	useEffect(() => {
		fetchDriveDetails({ workflowId: location.state.workflowId });
	}, []);

	useEffect(() => {
		if (!candidates) return;

		candidates.forEach((val) => {
			if (!val.test) {
				setShowVideoSetting(false);
				return;
			}

			setShowVideoSetting(true);
		});
		candidates.forEach((val) => {
			if (!val.productId) {
				setShowDriveDetails(false);
				return;
			} else if (!val.productId.role) {
				setShowDriveDetails(false);
				return;
			}

			setShowDriveDetails(true);
		});
	}, [candidates]);

	const handleChange = (event, key) => {
		const newUserSettings = { ...userSettings };
		newUserSettings[key] = event.target.checked;

		setUserSettings(newUserSettings);

		if (key == 'completeEmail') {
			if (event.target.checked) {
				setShowCustomizePostMail(true);
			} else {
				setShowCustomizePostMail(false);
			}
		}
	}

	const submitChange = (e) => {
		e.preventDefault();

		localStorage.setItem('settings', JSON.stringify(userSettings));

		updateUserSettings(userSettings);

		axios.post("/workflow/setting", { workflowid: location.state.workflowId, settings: userSettings })
			.then((res) => { console.log(res); handleSnackClick(); })
			.catch((err) => console.log(err))
	}

	const updateTable = () => {
		let configData = config();
		configData = configData.filter(val => val.key != 'vidproct');

		if (!showVideoSetting) configData = configData.filter(val => val.key != "vidanswer");

		if (!showDriveDetails) configData = configData.filter(val => val.key != "driveDetails");

		return configData.map((row, i) => (
			<TableRow
				key={row.name}
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<TableCell align="left">{row.name}</TableCell>
				<TableCell align="left">{row.description}</TableCell>
				<TableCell align="left">
					<Switch defaultChecked={settings[row.key] ? settings[row.key] : false} onChange={(e) => handleChange(e, row.key)} />
				</TableCell>
			</TableRow>
		));
	}

	const getBase64 = (file1) => {
		var reader = new FileReader();
		var reader2 = new FileReader();
		reader.readAsArrayBuffer(file1);
		reader2.readAsDataURL(file1);
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
		reader2.onload = function () {
			setFile(reader2.result);
		};
		reader2.onerror = function (error) {
			console.log('Error: ', error);
		};
	}

	const changeSubject = (e) => {
		setsubject(e.target.value);
	}

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject,
		isDragAccept
	]);



	const modules = useMemo(() => (
		{
			toolbar: [
				[{ header: [1, 2, 3, 4, false] }],
				["bold", "italic", "underline", "strike"],
				[{ list: "ordered" }, { list: "bullet" }],
				["link"],
				[{ 'color': [] }, { 'background': [] }]
			]
		}
	), []);

	const formats = useMemo(() => (
		["header", "bold", "italic", "underline", "strike", "list", "bullet", "indent", "link", "image", "color", "background"]
	), []);

	const onCloseChangePwd = () => {
		setChangePwdOpen(false);
	}

	const onOpenChangePwd = () => {
		setChangePwdOpen(true);
	}

	const handleVideoProctoring = (e) => {
		setVideoProctoring(e.target.checked);
		axios.post("/workflow/update-video-proctoring", { workflowid: location.state.workflowId, videoProctoring: e.target.checked })
			.then((res) => {
				handleSnackClick();
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		setVideoProctoring(driveDetails.videoProctoring);
	}, [driveDetails]);

	return (
		<div className='quick-menu-handler' style={{ padding: 20, overflowY: 'auto' }}>
			<div className="inner-div" style={{ marginBottom: 15 }}>
				<div className='top-icon-box'>
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			<DriveDetails driveDetails={driveDetails} />
			<div className='user-settings'>
				<div className='wrapper'>
					<div className='settings-header'>
						<div>Settings</div>
						<div>Description</div>
						<div>Action</div>
					</div>
					<div className='settings-item'>
						<div className='settings-name'>
							<div>Post-assessment mails</div>
							<Switch defaultChecked={false} onChange={(e) => handleChange(e, 'completeEmail')} id="settingsFlow-selector-6" />
						</div>
						<div className='settings-description'>Customise and send mails to candidates to acknowledge submission of assessment</div>
						<div className='settings-action'>
							{
								showCustomizePostMail ? (
									<div onClick={onOpenPostAssessmentModal} id="settingsFlow-selector-7">Customise</div>
								) : ''
							}
						</div>
					</div>
					<div className='settings-item'>
						<div className='settings-name'>Change your password</div>
						<div className='settings-description'>Change your login password here</div>
						<div className='settings-action'>
							<div onClick={onOpenChangePwd} id="settingsFlow-selector-2">Reset</div>
						</div>
					</div>
					<div className='settings-item'>
						<div className='settings-name'>Video Proctoring</div>
						<div className='settings-description'>Change your preference of video proctoring</div>
						<div className='settings-action'>
							<Switch checked={videoProctoring} onChange={handleVideoProctoring} />
						</div>
					</div>
					{/* <div className='settings-item'>
						<div className='settings-name'>Dashboard whitelabelling</div>
						<div className='settings-description'>Re-brand the dashboard by adding your company’s logo</div>
						<div className='settings-action'>
							<div>Change logo</div>
						</div>
					</div>
					<div className='settings-item'>
						<div className='settings-name'>Virtual Interviewer whitelabelling</div>
						<div className='settings-description'>Add your company’s branding to the virtual interviewer bots shared with candidates</div>
						<div className='settings-action'>
							<div>Change logo</div>
						</div>
					</div> */}
				</div>
			</div>
			<ChangePassword open={changePwdOpen} onClose={onCloseChangePwd} />
			{/* <div className="container-div" style={{ padding: "20px 0" }}>
				<div className="all-applicant-div" style={{ height: "max-content" }}>
					<div className="all-applicants-top-div">
						<div className="all-applicant-title">
							<div className="title">
								<h3>Settings</h3>
							</div>
						</div>
						<div className="all-applicants-no">
							<Button
								style={{
									background: '#479BD2',
									fontWeight: 'bold',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									fontSize: '16px',
									borderRadius: '25px',
									padding: '5px 25px'
								}}
								size="small"
								variant="contained"
								onClick={(e) => submitChange(e)}
							>
								Save
							</Button>
						</div>
					</div>
					<div className="all-applicants-bottom-div">
						<TableContainer component={Paper} style={{ border: "none" }}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell align="left" style={{ fontWeight: "bold" }}>Settings</TableCell>
										<TableCell align="left" style={{ fontWeight: "bold" }}>Description</TableCell>
										<TableCell align="left" style={{ fontWeight: "bold" }}>Enable/Disable</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{loading ? '' : updateTable()}
								</TableBody>
							</Table>
						</TableContainer>
						{loading ? <Loader /> : ''}
					</div>
				</div>
			</div> */}
			<Modal open={postAssessmentModal} onClose={onClosePostAssessmentModal} classNames={{ modal: 'customModal' }} center>
				<div style={{ display: "flex" }}>
					<div style={{ background: "#fff", padding: "20px", borderRadius: "25px 0px 0px 25px", maxWidth: "50%", width: "500px" }}>
						<div>
							<h2 style={{ marginBottom: "20px", color: "rgb(150,150,150)" }}>Post-assessment mail</h2>
						</div>
						<div style={{ marginBottom: "10px" }}>
							<div {...getRootProps(style)} style={baseStyle}>
								<img src={file} style={{ maxWidth: "40%", width: '300px', maxHeight: '180px' }} alt='' />
								<input {...getInputProps()} />
								{
									isDragActive ?
										<p>Drop the files here ...</p> :
										<p>Change Logo <AddIcon style={{ width: "18px", height: "18px", top: "4px", position: "relative" }} /></p>
								}
							</div>
						</div>
						<div className='modal-input'>
							<div style={{ marginLeft: "2%" }}>
								<input type="text" onChange={changeSubject} value={subject} placeholder="Subject" style={{ width: "90%", padding: "8px", marginBottom: "10px", boxShadow: "0px 1px 1px #EDEFF1", border: "none", boxSizing: "border-box" }} id="settingsFlow-selector-8" />
							</div>
							<div style={{ marginLeft: "2%" }}>
								<input type="text" onChange={(e) => setHeader(e.target.value)} value={header} placeholder="Message header" style={{ width: "90%", padding: "8px", marginBottom: "10px", boxShadow: "0px 1px 1px #EDEFF1", border: "none", boxSizing: "border-box" }} id="settingsFlow-selector-9" />
							</div>
						</div>
						<div style={{ border: "1px solid #EDEFF1", padding: '2px', minHeight: '100px', width: '500px' }}>
							<p style={{ margin: '0 0 10px 10px', fontSize: '12px', fontWeight: '600' }}>Body</p>
							<ReactQuill
								theme="snow"
								modules={modules}
								formats={formats}
								value={preEditorState}
								onChange={handlePreEditorChange}
								style={{ height: "100%" }}
							/>
						</div>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Button
								id="settingsFlow-selector-10"
								style={{
									color: "#fff",
									background: "orange",
									margin: "10px 20px",
									borderRadius: "20px"
								}}
								onClick={() => {
									updateEmail();
									onClosePostAssessmentModal();
								}}>
								Save
							</Button>
						</div>
					</div>
					<div style={{ padding: "20px", backgroundColor: "#fff", maxWidth: '50%', width: "500px" }}>
						<h2 style={{ marginBottom: "20px", color: "rgb(150,150,150)" }}>Preview</h2>
						<div id='preview' style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "30px 0 0 0", background: "#fff", padding: "20px" }}>
							<img src={file} style={{ maxWidth: "40%", width: '300px', maxHeight: '180px' }} alt='' />
							<Divider variant="middle" style={{ width: "80%", margin: "20px 0" }} />
							<div>
								<p style={{ fontWeight: "600", fontSize: "20px", textAlign: "center" }}>{header}</p>
							</div>
							<Divider variant="middle" style={{ width: "80%", margin: "20px 0" }} />
							<div style={{ overflow: "wrap", textAlign: 'left', width: '100%', overflowWrap: 'break-word', margin: '10px 0' }} dangerouslySetInnerHTML={{ __html: preEditorState }}></div>

						</div>
					</div>
				</div>
			</Modal>
			<Snackbar open={snackOpen} autoHideDuration={2000} onClose={handleSnackClose}>
				<Alert onClose={handleSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					Your settings have been saved
				</Alert>
			</Snackbar>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList(),
	loading: getSettingsLoading(),
	settings: getSettings(),
	driveDetails: getDriveDetails()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
	updateUserSettings: (data) => dispatch(updateUserSettingsRequest(data)),
	fetchDriveDetails: (data) => dispatch(fetchDriveDetailsRequest(data))
});

Settings.propTypes = {
	candidates: PropTypes.array,
	getCandidatesByType: PropTypes.func,
	notification: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
