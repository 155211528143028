import axios from 'axios'
import React from "react"

axios.defaults.withCredentials = true

//axios.defaults.headers['Access-Control-Allow-Origin'] = 'https://db.hirequotient.org';
//axios.defaults.headers['Accept'] = '*/*';
//axios.defaults.headers['Accept-Language'] = 'en';
//axios.defaults.headers['Content-Language'] = 'en-US';
///////axios.defaults.headers['Content-Type'] = 'text/plain';

const instance = axios.create({
 baseURL: process.env.REACT_APP_BASE_SERVER_URL,
//   baseURL: 'https://dummyimpress.herokuapp.com/api',
  withCredentials: true,
})

export default instance
