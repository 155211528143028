import React, { useState } from "react";
import axios from "../../axios"
import { useHistory, useLocation } from 'react-router-dom'
import ChatInput from "./helper_section/ChatInput";
// import VideoInput from "./helper_section/VideoInput";
import ChatInput2 from "./helper_section/ChatInput2";
import ChatInput3 from "./helper_section/ChatInput3";
import ChatInput4 from "./helper_section/ChatInput4";
// import TncInput from "./helper_section/TncInput";
import MultiSelect from "./helper_section/MultiSelect";
// import Instruction from "./helper_section/Instruction";
import Dnd from "./helper_section/Dnd"
import "../../styles/btn.css"
import { Button } from '@material-ui/core';
// import Modal from 'react-responsive-modal';
import '../../styles/modal.css'
import ChatVoice from "./helper_section/ChatVoice";
import styles from '../../styles/MultiSelect.module.css'
import Feedback from "./Feedback";
import ReactSpreadSheet from "./helper_section/spreadsheet/ReactSpreadSheet.js";
import EsyncSpreadSheet from "./helper_section/spreadsheet/EsyncSpreadSheet";
import Modal from "react-responsive-modal";
import MultiSelectDnD from "./helper_section/MultiSelectDnD";

const HelperSection = ({ messages, addToMessageBox, setNextQues, nextQues, seeQues, setSeeQues, setCaseState, setProgress, setIntervalId, setStopChat, addFirstMessage, setMessageBox }) => {

	const [showFeedback, setShowFeedBack] = useState(false)
	const [modal, setModal] = useState(false);
	const onOpenModal = () => {
		setStopChat(true);
		setModal(true);

		axios
			.post('/test/endchat', {})
			.then((res) => {
				console.log(res)
				// redirect to dashboard page
				setCaseState(false)
				setShowFeedBack(true)
			})
			.catch((err) => {
				console.log(err)
			})
		// setModal(true)
	};
	const onCloseModal = () => {
		setModal(false);
		history.push('/free/success');
	};


	let history = useHistory();
	const url = '/userconvo'
	let location = useLocation()


	const ele = () => {
		console.log(messages)
		if (messages?.messages[0]?.qtype === "tac") {
			return null
		}
		if (messages.messages[0].qid === "-1")
			return <div id="chat-form" style={{ height: "8vh", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display: "flex", alignItems: "center", justifyItems: "center" }}>
				<Button className={styles.SubmitButton} onClick={onOpenModal}>
					<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Submit</span>
				</Button>
			</div>


		if (nextQues.length == 0 && !messages.messages[0].qtype)
			return null
		if (!messages.messages[0].qtype && !messages.messages[0].isMyMessage)
			return <ChatInput3 addToMessageBox={addToMessageBox} nextQues={nextQues} setNextQues={setNextQues} setSeeQues={setSeeQues} />

		if (messages.messages[0].qtype === "vid0" || messages.messages[0].qtype === "vid2" || messages.messages[0].qtype === "vid3") {
			return null
		}
		if (messages.messages[0].qtype === "txt0") {
			return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		}
		if (messages.messages[0].qtype === "txt1") {
			return <ChatInput4 addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		}
		if (messages.messages[0].qtype === "txt2") {
			return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		}
		if (messages.messages[0].qtype === "txt3") {
			return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		}
		if (messages.messages[0].qtype === "xls0") {
			//  return <ReactSpreadSheet addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
			return <EsyncSpreadSheet addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />

		}
		if (
			messages.messages.length === 0 ||
			!("qtype" in messages.messages[0]) ||
			messages.messages[0].qtype[0] === "n"
		) {
			if (messages.messages[0].qtype[3] === "0")
				return <ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
			else {
				let noOfInputs = parseInt(messages.messages[0].qtype[3])
				return <ChatInput2 addToMessageBox={addToMessageBox} noOfInputs={noOfInputs} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
			}
		}
		else if (messages.messages[0].qtype[0] === "v") {
			return <ChatVoice addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} />
		}
		else if (messages.messages[0].qtype[0] === "m") {
			let instruction
			if (messages.messages[0].qtype[3] === "0")
				instruction = "Choose any number of options from above"
			else if (messages.messages[0].qtype.includes('max'))
				instruction = `Choose up to ${messages.messages[0].qtype[3]} options from the list above `
			else if (messages.messages[0].qtype[3] === "1")
				instruction = "Choose one option from the list above"
			else
				instruction = `Choose ${messages.messages[0].qtype[3]} options from the list above `

			return (
				<>
					<MultiSelect
						messages={messages}
						addToMessageBox={addToMessageBox}
						setNextQues={setNextQues}
						seeQues={false}
						setSeeQues={setSeeQues}
						setProgress={setProgress}
					/>
					<ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} placeholder={instruction} />
				</>
			);
		}
		else if (messages.messages[0].qtype[0] === "s") {
			var instruction = `Arrange first ${messages.messages[0].qtype[3]} in order`
			return (
				<>
					<Dnd
						messages={messages}
						addToMessageBox={addToMessageBox}
						setNextQues={setNextQues}
						seeQues={false}
						setSeeQues={setSeeQues}
						setProgress={setProgress}
					/>
					<ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} placeholder={"Drag to Rearrange"} />
				</>
			);
		} else if (messages.messages[0].qtype[0] === "r") {
			let instruction;

			if (messages.messages[0].qtype[3] === "0")
				instruction = "Choose any number of options from above"
			else if (messages.messages[0].qtype.includes('max'))
				instruction = `Select up to ${messages.messages[0].qtype[3]} options and order them logically`
			else if (messages.messages[0].qtype[3] === "1")
				instruction = "Choose one option from the list above"
			else
				instruction = `Select up to ${messages.messages[0].qtype[3]} options and order them logically`

			return (
				<>
					<MultiSelectDnD
						messages={messages}
						addToMessageBox={addToMessageBox}
						setNextQues={setNextQues}
						seeQues={false}
						setSeeQues={setSeeQues}
						setProgress={setProgress}
					/>
					<ChatInput addToMessageBox={addToMessageBox} messages={messages} setNextQues={setNextQues} setProgress={setProgress} placeholder={instruction} />
				</>
			);
		}
	};

	let renderElement = ele();
	return (
		<div style={{ borderTopRightRadius: "16px", borderTopLeftRadius: "16px", width: "70vw", margin: "0 0 2vh 64px", zIndex: "2" }}>
			<Modal open={modal} onClose={onCloseModal} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
				<p className="modal-text">
					Your assessment has been submitted successfully. Click OK to end chat
				</p>
				<div className="button-div">
					<Button className="modal-button" onClick={onCloseModal} style={{
						background: '#479BD2',
						fontWeight: 'bold',
						fontFamily: "Poppins",
						letterSpacing: "0.01em",
						borderRadius: '25px',
						padding: '5px 12px',
						fontSize: '16px',
						margin: '0 10px'
					}}
						size="small"
						variant="contained" >
						Ok
					</Button>
				</div>
			</Modal>
			<Feedback showFeedback={showFeedback} setShowFeedBack={setShowFeedBack} />
			{renderElement}
		</div>
	);
};

export default HelperSection;
