import React, { Component, createContext } from "react";

// Helper functions

function move(array, oldIndex, newIndex) {
	if (newIndex >= array.length) {
		newIndex = array.length - 1;
	}
	array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
	return array;
}

function moveElement(array, index, offset) {
	const newIndex = index + offset;

	return move(array, index, newIndex);
}

// Context

export const GridContext = createContext({ items: [] });

export class GridProvider extends Component {
	constructor(props) {
		// console.log(props)
		super(props);
		this.state = {
			items: props.options,
			moveItem: this.moveItem,
			setItems: this.setItems,
			updateOptions: props.updateOptions,
			noOfRight: props.noOfRight
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.options.length !== this.state.items.length) {
			this.setState({ items: nextProps.options });
		}
	}

	render() {
		return (
			<GridContext.Provider value={this.state}>
				{this.props.children}
			</GridContext.Provider>
		);
	}

	setItems = items => this.setState({ items });

	moveItem = (sourceId, destinationId) => {
		if (sourceId === -1 || destinationId === -1) {
			return;
		}
		console.log(sourceId, destinationId)
		const sourceIndex = this.state.items.findIndex(
			item => item.index === sourceId
		);
		const destinationIndex = this.state.items.findIndex(
			item => item.index === destinationId
		);

		console.log(destinationIndex, sourceIndex)
		// If source/destination is unknown, do nothing.
		const offset = destinationIndex - sourceIndex;
		console.log(offset)

		this.setState(state => ({
			items: moveElement(state.items, sourceIndex, offset)
		}));

	};
}

export default GridContext;
