import { Button } from '@mui/material';
import React from 'react';

const AddVideoQuestions = (props) => {
	const { goBackCustomForm, videoQuestions, setVideoQuestions } = props;

	return (
		<div className='add-video-question'>
			<div className='add-video-question-header'>
				<div className='back-btn' onClick={goBackCustomForm}>
					<div></div>
				</div>
				<div className="title" style={{ marginBottom: 0 }}>Add custom video question</div>
			</div>
			<div style={{ color: '#A5A5A5' }}>
				Customise the assessments by adding your own video questions. We recommend you to ask candidates to record video of upto 1 minute.
			</div>
			<div className='add-video-question-form'>
				{
					videoQuestions.map((vq, i) => (
						<div className="add-video-question-item">
							<div className="add-video-question-item-title">Video question {i + 1}</div>
							<div>
								<textarea
									placeholder="Add your question here"
									onChange={(e) => {
										const newVideoQs = [...videoQuestions];
										newVideoQs[i].question = e.target.value;
										setVideoQuestions([...newVideoQs]);
									}}
									value={vq.question}
									rows={4}
								/>
							</div>
						</div>
					))
				}
			</div>
			<div className="modal__btnbox">
				<Button
					className="btn-primary"
					size="small"
					variant="contained"
					onClick={goBackCustomForm}
				>
					Add
				</Button>
			</div>
		</div>
	)
}

export default AddVideoQuestions;
