import React, { useEffect, useRef, useState } from "react";
import Alert from '@mui/material/Alert';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import Switch from '@mui/material/Switch';
import TextField from '@material-ui/core/TextField';
import { Collapse } from '@mui/material';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSVLink } from "react-csv";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { Select } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Tooltip } from "@material-ui/core";
import { useLocation, useHistory } from 'react-router';
import { useTour } from '@reactour/tour';

import AccountMenu from "../accountMenu/AccountMenu";
import AddRejectEmail from "../emailEditor/AddRejectEmail";
import AddShortlistEmail from "../emailEditor/AddShortlistEmail";
import AnalyseModal from "../../components/common/AnalyseModal";
import axios from "../../axios";
import helperFunction from '../../helper/helperFunction';
import NotificationBell from "../notificationBell/notificationBell";
import UploadShortlist from "../emailEditor/UploadShortlist";
import UploadReject from "../emailEditor/UploadReject";
import { barChartData, doughnutOptions } from "../../constants/reports";
import { Bar, Doughnut } from "react-chartjs-2";
import { doughnutData } from "../../utils/reports";
import {
	getCandidatesByType as getCandidatesByTypeRequest,
	editCandidateName as editCandidateNameRequest,
	resetSuccessMsg as resetSuccessMsgRequest,
	resetRerender as resetRerenderRequest,
	deleteCandidate as deleteCandidateRequest
} from '../../actions/app';
import { getCandidatesList, getRerender, getUserRole } from '../../selectors/app';
import { useWindowDimensions } from "../../utils/windowUtils";

import Edit from '../../assets/Icons/Edit.png';
import emptyRowImg from '../../assets/emptyrow2.png';
import Subtract from '../../assets/Icons/Subtract.png';
import Trash from '../../assets/Icons/Trash.png';

import "./Finalized.css";

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
	marginLeft: 'auto',
	transition: theme.transitions.create('transform', {
		duration: theme.transitions.duration.shortest,
	}),
}));

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const buttonStyle = {
	color: "#fff",
	background: "#479BD2",
	fontFamily: 'Poppins',
	fontWeight: 'bold',
	height: "40px",
	borderRadius: "25px",
	padding: "5px 20px",
	marginRight: "10px",
	fontSize: "14px"
}

const QuickSearchToolbar = (props) => {
	const { value, onChange, clearSearch } = props;

	return (
		<div className="root">
			<div>
				<GridToolbarFilterButton />
			</div>
			<TextField
				variant="standard"
				value={value}
				onChange={onChange}
				placeholder="Search…"
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: value ? 'visible' : 'hidden' }}
							onClick={clearSearch}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</div>
	);
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

function Finalized(props) {
	const { editCandidateName, resetRerender, deleteCandidate, rerender, notification, userRole } = props;

	const { isOpen } = useTour();
	let location = useLocation();
	const history = useHistory();
	const windowDimensions = useWindowDimensions();

	const [deleteModal, setDeleteModal] = useState(false);
	const [bulkMailModal, setBulkMailModal] = useState(false);
	const [bulkMailShortlistTemp, setBulkMailShortlistTemp] = useState(0);
	const [bulkMailRejectTemp, setBulkMailRejectTemp] = useState(0);
	const [switchShortlistTemp, setSwitchShortlistTemp] = useState(false);
	const [switchRejectTemp, setSwitchRejectTemp] = useState(false);
	const [bulkMailLoading, setBulkMailLoading] = useState(false);
	const [chat, setChat] = useState({})
	const [modal, setModal] = useState(false);
	const [change, setChange] = useState(0);
	const [shortlistMailModalOpen, setShortlistMailModalOpen] = useState(false);
	const [rejectMailModalOpen, setRejectMailModalOpen] = useState(false);
	const [data, setData] = useState([])
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false)
	const [CSVData, setCSVData] = useState([]);
	const [tempInv, setTempInv] = useState([]);
	const [tempRem, setTempRem] = useState([]);
	const [tempp, setTemplate] = useState();
	const [tempr, setTemplateR] = useState();
	const [selectedTemp, setSelectedTemp] = useState(0);
	const [tempChange, setTempChange] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [selectionModel, setSelectionModel] = useState([]);
	const [pageSize, setPageSize] = useState(15);
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErr, setSnackErr] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState(null);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [expanded, setExpanded] = useState("");
	const [bulkStatus, setBulkStatus] = useState(null);
	const [totalScore, setTotalScore] = useState(0);
	const [barData, setBarData] = useState({ ...barChartData });
	const [reportModal, setReportModal] = useState(false);
	const [currentReportTab, setCurrentReportTab] = useState(0);
	const [videoCardData, setVideoCardData] = useState([]);
	const [shortlistedRows, setShortlistedRows] = useState([]);
	const [rejectedRows, setRejectedRows] = useState([]);

	const editNameRef = useRef(null);
	const doughnutRef = useRef();
	const barChartRef = useRef();

	const settings = JSON.parse(localStorage.getItem('settings'));

	useEffect(() => {
		if (chat.productId) {
			setReportModal(true);
		}
	}, [barData]);

	const onOpenReportModal = (e, params) => {
		e.stopPropagation();
		setChat(params.row);
		setTotalScore(parseInt((params.row.test.result.total || 0) * 100));

		const newBarData = { ...barData };

		newBarData.labels = [];
		newBarData.datasets[0].data = [];
		newBarData.datasets[1].data = [];

		if (params.row.test.chat[0].hasOwnProperty('idealqtime')) {
			params.row.test.chat.forEach((item, index) => {
				newBarData.labels.push('Q' + (1 + index));
				newBarData.datasets[0].data.push(item.idealqtime);
				newBarData.datasets[1].data.push(item.userqtime);
			});
		}

		setBarData({ ...newBarData });
	};

	const onCloseReportModal = () => setReportModal(false);

	const onOpenModal = () => setModal(true);

	const onCloseModal = () => setModal(false);

	const onOpenDeleteModal = () => {
		if (selectionModel.length > 0) setDeleteModal(true);
		else {
			setSnackErr("Please select atleast one candidate");
			handleSnackClick();
		}
	};

	const onCloseDeleteModal = () => setDeleteModal(false);

	const onOpenBulkMailModal = () => setBulkMailModal(true);

	const onCloseBulkMailModal = () => {
		setBulkMailModal(false)
	};

	const nameEdit = (row) => {
		editCandidateName(row, editNameRef.current.value);
	}

	const handleDelete = (rowSelected) => {
		const IDs = [];
		rowSelected.map((val) => {
			IDs.push(val._id);
		});

		deleteCandidate(IDs);
	};

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (editModal) onCloseEditModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender]);

	const sendBulkMail = function () {
		let shortlistCandidates = [];
		let rejectedCandidates = [];

		setBulkMailLoading(true);

		rows.map((val) => {
			if (val.status === "Shortlisted") shortlistCandidates.push(val);
			else if (val.status === "Rejected") rejectedCandidates.push(val);
		})

		if (switchShortlistTemp && switchRejectTemp) {
			let shortlist = axios.post('/company/workflow/candidate/shortlist', { workflowId: shortlistCandidates[0].workflow, candlist: shortlistCandidates, emailIndex: bulkMailShortlistTemp });
			let reject = axios.post('/company/workflow/candidate/reject', { workflowId: rejectedCandidates[0].workflow, candlist: rejectedCandidates, emailIndex: bulkMailRejectTemp });

			Promise.all([shortlist, reject])
				.then(() => {
					setSuccessMsg("Shortlist & Rejection Mail Sent")
					handleGreenSnackClick();
					onCloseBulkMailModal();
					setBulkMailLoading(false);
				})
				.catch(err => {
					console.log(err)
					helperFunction.redirectUnauthorisedUser(err, history);

					setSnackErr("Not able to send mail at the moment");
					handleSnackClick();
					setBulkMailLoading(false);
				});
		} else if (switchShortlistTemp && !switchRejectTemp) {
			axios.post('/company/workflow/candidate/shortlist', { workflowId: shortlistCandidates[0].workflow, candlist: shortlistCandidates, emailIndex: bulkMailShortlistTemp })
				.then(() => {
					setSuccessMsg("Shortlist Mail Sent")
					handleGreenSnackClick();
					onCloseBulkMailModal();
					setBulkMailLoading(false);
				})
				.catch(err => {
					console.log(err)
					helperFunction.redirectUnauthorisedUser(err, history);

					setSnackErr("Not able to send mail at the moment");
					handleSnackClick();
					setBulkMailLoading(false);
				});
		} else {
			axios.post('/company/workflow/candidate/reject', { workflowId: rejectedCandidates[0].workflow, candlist: rejectedCandidates, emailIndex: bulkMailRejectTemp })
				.then(() => {
					setSuccessMsg("Rejection Mail Sent")
					handleGreenSnackClick();
					onCloseBulkMailModal();
					setBulkMailLoading(false);
				})
				.catch(err => {
					console.log(err)
					helperFunction.redirectUnauthorisedUser(err, history);

					setSnackErr("Not able to send mail at the moment");
					handleSnackClick();
					setBulkMailLoading(false);
				});
		}
	}

	const onOpenShortlistMailModal = () => setShortlistMailModalOpen(true);

	const onCloseShortlistMailModal = () => setShortlistMailModalOpen(false);

	const onOpenRejectMailModal = () => setRejectMailModalOpen(true);

	const onCloseRejectMailModal = () => setRejectMailModalOpen(false);

	useEffect(() => {

		if (rows.length == 0) return;

		let temp = [];
		let shortlistCandidates = [];
		let rejectedCandidates = [];
		rows.map((val) => {
			let x = { name: val.name, email: val.email[0], status: val.status };
			temp.push(x);

			if (val.status === "Shortlisted") shortlistCandidates.push(val);
			else if (val.status === "Rejected") rejectedCandidates.push(val);
		})
		setShortlistedRows(shortlistCandidates);
		setRejectedRows(rejectedCandidates);
		setCSVData(temp);
	}, [rows]);

	useEffect(async () => {
		setLoading(true);
		try {
			let shortlisted = await axios.post("/gettype", { status: "shortlisted", id: location.state.workflowId });
			let rejected = await axios.post("/gettype", { status: "rejected", id: location.state.workflowId });
			let keptInView = await axios.post("/gettype", { status: "kiv", id: location.state.workflowId });

			let totalData = [...shortlisted.data, ...rejected.data, ...keptInView.data];
			setData(totalData);
			setRows(totalData);
			setLoading(false);
		}
		catch (err) {
			console.log(err);
			setLoading(false);
			// helperFunction.redirectUnauthorisedUser(err, history);
		}
	}, [change]);

	useEffect(() => {
		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'shortlistEmail' }).then((res) => {
			setTempInv(res.data.mails);
			setTemplate(res.data.template);
		}).catch((err) => {
			console.log(err);
			// helperFunction.redirectUnauthorisedUser(err, history);
		});

		axios.post('/workflow/getemail', { workflowId: location.state.workflowId, key: 'rejectEmail' }).then((res) => {
			setTempRem(res.data.mails)
			setTemplateR(res.data.template);
		}).catch((err) => {
			console.log(err);
			// helperFunction.redirectUnauthorisedUser(err, history);
		});
	}, [change, tempChange]);

	const pdfurl = process.env.REACT_APP_BASE_SERVER_URL_FULL + '/pdfreport'//`https://test.aspiringconsultants.com/api/pdfreport`//`http://localhost:5000/api/pdfreport`

	const pdfDownload = (candidate) => {
		fetch(pdfurl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				candidate: candidate,
			}),
		},
			{ responseType: 'arraybuffer' }
		)
			.then(async res => {
				if (res.status === 200) {
					const blob = await res.blob();
					const file = new Blob([blob], { type: 'application/pdf' });
					const fileURL = URL.createObjectURL(file);

					window.open(fileURL);
				}
			})
	}

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);

		if (searchValue == '') {
			setRows(data);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = data.filter((row) => {
				return Object.keys(row).some((field) => {
					return searchRegex.test(row[field].toString());
				});
			});

			setRows(filteredRows);
		}
	};

	const getQuestionText = (row, cond) => {
		let txt = ''

		if (Array.isArray(row.ques)) {
			let ele = row.ques[row.ques.length - 1].split('***::')
			txt = ele[ele.length - 1]
		}
		else if (typeof (row.ques) == 'string') {
			txt = row.ques.split('***::')[row.ques.split('***::').length - 1]
		}

		if (!cond && txt.length > 200) {
			txt = txt.substring(0, 200) + '...'
		}
		return txt.replaceAll(';;', ' ').replaceAll('::', ' ')
	}
	const changeStatus = (status) => {
		if (selectedRow.length == 0) {
			handleSnackClick();
			setSnackErr('Please select atleast one candidate')
			return;
		}

		axios.post('/statusupdatebyid', { candidates: selectedRow, status: status })
			.then(() => {
				setChange(change + 1);
				setSuccessMsg("Status updated successfully");
				handleGreenSnackClick();
				setSelectionModel([]);
			})
			.catch(err => {
				console.log(err);
				helperFunction.redirectUnauthorisedUser(err, history);

				setSnackErr("Could not update status at the moment");
				handleSnackClick();
			});
	}

	const sendShortlistMail = function (rowSelected) {
		axios.post('/company/workflow/candidate/shortlist', { workflowId: rowSelected[0].workflow, candlist: rowSelected, emailIndex: selectedTemp })
			.then(() => {
				setSuccessMsg("Mail sent");
				handleGreenSnackClick();
			})
			.catch(err => {
				console.log(err)
				helperFunction.redirectUnauthorisedUser(err, history);

				setSnackErr("Not able to send mail at the moment");
				handleSnackClick();
			})
	}

	const sendRejectMail = function (rowSelected) {
		axios.post('/company/workflow/candidate/reject', { workflowId: rowSelected[0].workflow, candlist: rowSelected, emailIndex: selectedTemp })
			.then(() => {
				setSuccessMsg("Mail sent");
				handleGreenSnackClick();
			})
			.catch(err => {
				console.log(err)
				helperFunction.redirectUnauthorisedUser(err, history);

				setSnackErr("Not able to send mail at the moment");
				handleSnackClick();
			})
	}

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: windowDimensions.width / 14,
			headerAlign: 'center',
			align: 'center',
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "5ch", width: "100%" }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: "candidate",
			headerName: "Name",
			minWidth: windowDimensions.width / 8,
			flex: 0.3,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: "16px", fontWeight: "400" }}>
						{params.row.name}
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: windowDimensions.width / 8,
			flex: 0.3,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: "16px", fontWeight: "400" }}>
						{params.row.email}
					</div>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			minWidth: windowDimensions.width / 8,
			flex: 0.3,
			headerAlign: "center",
			align: 'center',
			type: 'singleSelect',
			valueOptions: ['Shortlisted', 'Rejected', 'Kept In View'],
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ marginRight: "4ch", width: "100%" }}>
						{params.row.status}
					</div>
				)
			}
		},
		{
			field: "score",
			headerName: "Score",
			width: windowDimensions.width / 15,
			minWidth: 125,
			renderCell: (params) => {
				var score = 0.0;
				var index = 0
				try {
					if (params.row.test && params.row.test.result && params.row.test.result.hasOwnProperty('total'))
						score = params.row.test.result.total
					else {
						if (params.row.productId.toLowerCase().includes('sale')) {
							if (params.row.test.result.dab)
								score += 0.2 * params.row.test.result.dab
							if (params.row.test.result.lpg)
								score += 0.2 * params.row.test.result.lpg
							if (params.row.test.result.stk)
								score += 0.1 * params.row.test.result.stk
							if (params.row.test.result.ema)
								score += 0.5 * params.row.test.result.ema
						}
						else {
							for (let keys in params.row.test.result) {
								score += params.row.test.result[keys]
								index += 1
							}
							if (index > 0) score = score / index
						}
					}
					if (score > 0) score *= 100
				}
				catch {
					console.log("Error in scores");
				}
				return (
					<div className="userListUser">
						{score.toFixed()}%
					</div>
				);
			},
		},
		{
			field: "report",
			headerAlign: "center",
			align: 'center',
			headerName: "Report",
			flex: 0.3,
			renderCell: (params) => {
				return (
					<>
						{
							params.row.test && params.row.test.result && params.row.test.result.hasOwnProperty('total') ? (
								<Button
									id="reviewedFlow-selector-12"
									className="productView"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
									}}
									size="small"
									variant="contained"
									onClick={(e) => onOpenReportModal(e, params)}
								>
									<span style={{ fontSize: '14px', color: '#fff' }}>Analyse</span>
								</Button>
							) : (
								<AssessmentOutlinedIcon onClick={(e) => { e.stopPropagation(); pdfDownload(params.row) }} style={{ cursor: "pointer", marginRight: '3ch' }} />
							)
						}
					</>
				)
			}
		}
	];

	if(userRole === 'Admin') {
		columns.push(
			{
				field: "action",
				headerName: "Action",
				minWidth: windowDimensions.width / 8,
				flex: 0.3,
				headerAlign: "center",
				align: 'center',
				renderCell: (params) => {
					return (
						<>
							{params.row.status == 'Shortlisted' ?
								<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
									<Button
										id="reviewedFlow-selector-8"
										className="productView"
										style={{
											background: '#479BD2',
											fontWeight: 'bold',
											fontFamily: "Poppins",
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: '5px 12px',
										}}
										size="small"
										variant="contained"
										onClick={(e) => {
											e.stopPropagation();
											onOpenShortlistMailModal();
											setSelectedRow([params.row]);
										}}
									>
										<span style={{ fontSize: '12px', color: '#fff' }}>Send Shortlist Mail</span>
									</Button>
								</div>
								:
								<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
									<Button
										className="productView"
										style={{
											background: '#479BD2',
											fontWeight: 'bold',
											fontFamily: "Poppins",
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: '5px 12px',
										}}
										size="small"
										variant="contained"
										onClick={(e) => {
											e.stopPropagation();
											onOpenRejectMailModal();
											setSelectedRow([params.row]);
										}}
									>
										<span style={{ fontSize: '12px', color: '#fff' }}>Send Rejection Mail</span>
									</Button>
								</div>
							}
						</>
					);
				}
			}
		)
	}

	if (settings) {
		if (settings.transcript)
			columns.splice(5, 0,
				{
					field: "chat",
					headerName: "Chat",
					minWidth: 120,
					headerAlign: "center",
					align: 'center',
					flex: 0.3,
					renderCell: (params) => {
						return (
							<>
								<Button
									className="productView"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
										marginRight: '5ch'
									}}
									size="small"
									variant="contained"
									onClick={(e) => { setChat(params.row); onOpenModal(); e.stopPropagation(); }}
								>
									<span style={{ fontSize: '14px', color: '#fff' }}>View</span>
								</Button>
							</>
						);
					}
				})
	}

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);

		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
		else {
			let temp = [];

			for (let i = 0; i < selectionModel.length; i++) {
				temp.push(...rows.filter((row) => selectionModel[i] == row.id))
			}

			setSelectedRow(temp);
		}
	}, [selectionModel]);

	const onOpenEditModal = () => {
		if (selectionModel.length == 1) setEditModal(true);
		else {
			setSnackErr('Please select atleast one candidate')
			handleSnackClick();
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setGreenSnackOpen(false);
		setSuccessMsg(null);
	};

	useEffect(() => {
		if (selectedRow.length >= 1) {
			let status = selectedRow[0].status;

			for (let i = 1; i < selectedRow.length; i++) {
				if (status !== selectedRow[i].status) {
					setBulkStatus(null);
					return;
				};
			}

			setBulkStatus(status);
		}
		else {
			setBulkStatus(null);
		}
	}, [selectedRow]);

	const handleExpandClick = index => {
		if (expanded === index) {
			setExpanded("");
		} else {
			setExpanded(index);
		}
	}

	const getCandVideo = (key) => {
		axios.post('/cand-video', { key }).then((res) => {
			window.open(res.data.signedUrl);
		}).catch((err) => {
			console.log(err);
			helperFunction.redirectUnauthorisedUser(err, history);
		})
	}

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					style={{
						height: "350px",
						width: "auto"
					}}
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
			</div>
		)
	}

	useEffect(async () => {
		if (!chat.test) return;

		let tempArr = [];
		for (let i = 0; i < chat.test.chat.length; i++) {
			let val = chat.test.chat[i];
			console.log(val.ans);
			if (val.ans && !val.ans.Workbook && val.ans.includes('.mp4')) {
				let res = await axios.post('/cand-video', { key: val.ans })
				let tempData = { ques: val.ques, score: val.score, src: res.data.signedUrl };
				tempArr.push(tempData);
			}
		}
		setVideoCardData(tempArr);
	}, [chat]);

	const handleReportScroll = () => {
		if (currentReportTab != 0) return;

		const scrollDown = document.getElementById('scroll-down-animation');

		if (scrollDown) {
			scrollDown.style.display = "none";
		}
	}

	return (
		<div className="productList" style={{ overflowX: 'scroll' }}>
			<div className="inner-div" style={{ marginBottom: '20px' }}>
				<div className='top-icon-box'>
					<IconButton aria-label="edit" onClick={onOpenEditModal} id="reviewedFlow-selector-3">
						<img src={Edit} alt="" width={30} height={30} />
					</IconButton>
					<IconButton onClick={() => onOpenDeleteModal()} aria-label="delete" id="reviewedFlow-selector-6"><img src={Trash} alt="" width={30} height={30} /></IconButton>
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div>
			</div>
			{
				chat.test && chat.test.result && chat.test.result.hasOwnProperty('total') ? (
					<>
						<Doughnut ref={doughnutRef} data={doughnutData(totalScore)} options={doughnutOptions} style={{ width: 100, height: 100, display: 'none' }} />
						{
							barData.labels.length > 0 ? (
								<Bar ref={barChartRef} data={barData} options={barData.options} style={{ display: 'none' }} />
							) : ''
						}
					</>
				) : ''
			}
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
				<p className="modal-text">
					Do you want to delete this candidate?
				</p>
				<div className="button-div">
					<Button
						id="reviewedFlow-selector-7"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
							handleDelete(selectedRow)
						}}>
						Yes
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
						}}>
						No
					</Button>
				</div>
			</Modal>
			<Modal open={bulkMailModal} onClose={onCloseBulkMailModal} center classNames={{ modal: 'invitationModal', closeIcon: 'closeIcon' }}>
				<div className="modal-heading-workflow">
					<h3>Send email to</h3>
				</div>
				<div style={{ marginTop: "20px", minWidth: "400px" }}>
					{
						shortlistedRows.length > 0 && tempInv.length > 0 ? (
							<>
								<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<p>All shortlisted candidates</p>
									<Switch defaultChecked={switchShortlistTemp} onChange={(e) => { setSwitchShortlistTemp(e.target.checked) }} />
								</div>
								<div style={{ minHeight: "90px" }}>
									{switchShortlistTemp ?
										(<FormControl fullWidth style={{ margin: "15px 0", borderRadius: 25, background: "#eee" }}>
											<InputLabel id="shorlisted-candidates">Shortlist template</InputLabel>
											<Select
												className="remove-bg"
												labelId="shorlisted-candidates"
												label="Shortlist template"
												value={bulkMailShortlistTemp}
												onChange={(e) => { setBulkMailShortlistTemp(e.target.value) }}
												style={{ borderRadius: 25 }}
											>
												{tempInv.length == 0 ? <MenuItem ></MenuItem> :
													tempInv.map((con, index) => {
														return (
															<MenuItem key={index} value={index}>{con.name}</MenuItem>
														)
													})
												}
											</Select>
										</FormControl>)
										: ""}
								</div>
							</>
						) : ''
					}
					{
						rejectedRows.length > 0 && tempRem.length > 0 ? (
							<>
								<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									<p>All rejected candidates</p>
									<Switch defaultChecked={switchRejectTemp} onChange={(e) => { setSwitchRejectTemp(e.target.checked) }} />
								</div>
								<div style={{ minHeight: "90px" }}>
									{switchRejectTemp ?
										(<FormControl fullWidth style={{ margin: "15px 0", borderRadius: 25, background: "#eee" }}>
											<InputLabel id="rejected-candidates">Reject template</InputLabel>
											<Select
												className="remove-bg"
												labelId="rejected-candidates"
												label="Reject template"
												value={bulkMailRejectTemp}
												onChange={(e) => { setBulkMailRejectTemp(e.target.value) }}
												style={{ borderRadius: 25 }}
											>
												{tempRem.length == 0 ? <MenuItem ></MenuItem> :
													tempRem.map((con, index) => {
														return (
															<MenuItem key={index} value={index}>{con.name}</MenuItem>
														)
													})
												}
											</Select>
										</FormControl>)
										: ""}
								</div>
							</>
						) : ''
					}
				</div>
				<div className="button-div">
					<Button
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px',
						}}
						size="small"
						variant="contained"
						disabled={(bulkMailShortlistTemp == null && bulkMailRejectTemp == null) || bulkMailLoading}
						onClick={sendBulkMail}>
						{bulkMailLoading ? 'Loading' : 'Send'}
					</Button>
				</div>
			</Modal>
			<div className="userList">
				<div className="userListTitleContainer">
					<div className="inner-div-details" >
						<h2>Shortlisted/Rejected</h2>
						<p>{location.state.workflowName}</p>
					</div>
					<div className="search-box" style={{ width: "35vw" }}>
						<input type="search" placeholder="Search" onChange={(event) => requestSearch(event.target.value)} />
					</div>
					{
						userRole === 'Admin' ? (
							<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
								{
									(shortlistedRows.length > 0 && tempInv.length > 0) || (rejectedRows.length > 0 && tempRem.length > 0) ? (
										<Tooltip title="Mailing Options">
											<IconButton><MailOutlineIcon onClick={onOpenBulkMailModal} className="check-btn" /></IconButton>
										</Tooltip>
									) : ''
								}
								<Tooltip title="Shortlist" disabled={bulkStatus === 'Shortlisted'}>
									<IconButton><CheckCircleOutlineOutlinedIcon onClick={() => { changeStatus('Shortlisted') }} className="check-btn" /></IconButton>
								</Tooltip>
								<Tooltip title="Reject" disabled={bulkStatus === 'Rejected'}>
									<IconButton><CancelOutlinedIcon onClick={() => { changeStatus('Rejected') }} className="cancel-btn" /></IconButton>
								</Tooltip>
								<Tooltip title="Keep in view" disabled={bulkStatus === 'Kept In View'}>
									<IconButton><RemoveRedEyeOutlinedIcon onClick={() => { changeStatus('Kept In View') }} className="visibility-btn" /></IconButton>
								</Tooltip>
							</div>
						) : ''
					}
					<div>
						<Button
							className="inner-div-btn bg-color-blue"
							id="btn1"
							style={{ ...buttonStyle }}
							size="small"
							variant="contained"
						>
							{CSVData ? <CSVLink filename="Data.csv" data={CSVData} target="_blank" style={{ color: "white", textDecoration: "none", fontSize: '16px', whiteSpace: 'nowrap' }} id="reviewedFlow-selector-2">Export <img src={Subtract} alt="" style={{ height: "18px", width: "18px", transform: `translate(3px, 4px)` }} /></CSVLink> : ''}
						</Button>
					</div>
				</div>
				<div className="cards-container">
					<div className="cards">
						<div className='add-card-container'>
							<div className='add-card-wrapper'>
								{isOpen ? <AddShortlistEmail type="shortlistEmail" index={1} field={""} /> : null}
								{isOpen ? <AddRejectEmail type="rejectEmail" index={1} field={""} /> : null}

								{tempp ? <AddShortlistEmail type="shortlistEmail" index={tempInv.length} field={tempp} tempChange={tempChange} setTempChange={setTempChange} /> : null}
								{tempr ? <AddRejectEmail type="rejectEmail" index={tempRem.length} field={tempr} tempChange={tempChange} setTempChange={setTempChange} /> : null}
							</div>
							<div className='upload-title'>
								<h3>Add templates</h3>
							</div>
						</div>
						{isOpen ?
							<UploadShortlist
								type="shortlistEmail"
								isDefault={false}
								index={1}
								field={{
									"name": "Invitation Template",
									"image": "default.png",
									"subject": "Invitation to HireQuotient Online Assessment",
									"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
									"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
									"button": "Let's Begin",
									"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
								}} /> : null}
						{isOpen ?
							<UploadReject
								type="rejectEmail"
								isDefault={false}
								index={1}
								field={{
									"name": "Invitation Template",
									"image": "default.png",
									"subject": "Invitation to HireQuotient Online Assessment",
									"body": "Hi {{name}},<br/><br/>I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.",
									"body2": "The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.<br/><br/>Wish you all the best!<br/><br/> Warm Regards,<br>HireQuotient",
									"button": "Let's Begin",
									"render": "<div style=\"width:100%;background:#bbb\">\n        <div style=\"width:500px;margin:auto;background:#fff;padding:20px;\">\n          <div style=\"width: 500px;text-align: center;\">\n            <img src=\"https://hqpics.s3.ap-southeast-1.amazonaws.com/default.png\" style=\" max-width: 40%; height: auto;\"/>\n          </div>\n          <p><span style=\"font-size:16px; text-align: left;\">Hi {{name}},\n            <br/><br/>\n            I am HireQuotient, your virtual interviewer for today! I am pleased to invite you to take the online assessment.\n            </span></p>\n           <br/><br/>\n          <div style=\"text-align: center;margin-top:15px;;margin-bottom:15px;\">\n             <a href=\"{{link}}\" target=\"_blank\" style=\"padding:6px 20px;border-radius:10em;background:#014954;margin:auto;color:white;border:none;text-decoration:none;font-size:16px;font-weight:bold;\">Start the test</a>\n          </div>\n          <br/><br/>\n           <p><span style=\"font-size:16px; text-align: left;\">The duration of the assessment is 30 minutes. Before starting the assessment, make sure to read all guidelines properly.\n          </span></p>\n         <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Wish you all the best!\n          </span></p>\n          <p><span style=\"font-size:16px; text-align: left;\"><br/><br/> Warm Regards,<br>HireQuotient\n          </span></p>\n          <br/><br/>\n         <p><span style=\"font-size:12px;overflow-wrap: break-word;\"><br/><br/>Note: If the button doesn't work, kindly use the following link: {{link}}. In case you encounter any technical difficulties, please close the assessment tab and open the above link again - you will not lose your place in the test. Should you continue to experience more difficulties, you can email at info@hirequotient.com.\n          </span></p>\n        </div>"
								}} /> : null}

						{tempInv?.length > 0 ?
							tempInv.map((temp, index) => {
								return (<UploadShortlist type="shortlistEmail" tempChange={tempChange} setTempChange={setTempChange} isDefault={false} index={index} field={temp} />)
							}) : null}
						{tempRem?.length > 0 ?
							tempRem.map((temp, index) => {
								return (<UploadReject type="rejectEmail" tempChange={tempChange} setTempChange={setTempChange} isDefault={false} index={index} field={temp} />)
							}) : null}

					</div>
				</div>
				{bulkStatus && bulkStatus !== 'Kept In View' && selectionModel.length > 0 ?
					<div style={{ background: "lightgrey", minHeight: "6vh", padding: "1vh 20px 1vh", display: "flex", justifyContent: "space-between", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
						<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
							{bulkStatus == 'Shortlisted' ? <Button size="small" variant="contained" onClick={onOpenShortlistMailModal} style={{ ...buttonStyle }}>Send Shortlist Mail</Button> : ''}
							{bulkStatus == "Rejected" ? <Button size="small" variant="contained" onClick={onOpenRejectMailModal} style={{ ...buttonStyle }}>Send Reject Mail</Button> : ''}
						</div>
					</div> : ""}
				<DataGrid
					rows={!isOpen ? rows : [{
						"_id": "618606360984b02fca8869ab",
						"name": "AJ",
						"email": [
							"s@d.com"
						],
						"id": "18",
						"code": "2a104GcAi8JsoK4HFFTGhCX5TSWQmKOEZThjJW8KiU0sLRb4JOQLOioO",
						"status": "Shortlisted",
						"lastActive": "2021-11-06T04:40:34.716Z",
						"createdDate": "2021-11-06T04:36:06.803Z",
						"company": "61730080f94921c9089ea99f",
						"workflow": "6178704387c9e1c238ae4f58",
						"__v": 0,
						"problem": "You are talking to the Head of Talent Acquisition (TA) at a well-funded Series C startup in the e-commerce space. They have had great success raising funds, and now have aggressive targets to grow the team and expand the business. Over the last 12 months, the team hired 28 people. Their target for the next 12 months is 80. ::The Head of TA is concerned that without significantly revamping the way they approach recruiting, they will not come anywhere close to meeting the hiring targets, which would greatly hinder the business’ ability to grow and scale.::She is seeking your help to ramp up their ability to hire without affecting hiring quality, while keeping the size of the HR team small. In order to meet these targets, she has received a much more generous budget than before to spend on recruiting.",
						"productId": "ChatId4",
						"title": "HR Evaluative"
					}]}
					components={{ NoRowsOverlay }}
					getRowClassName={() =>
						`rowOnHover`
					}
					columns={columns}
					checkboxSelection={userRole === 'Admin'}
					componentsProps={{
						toolbar: {
							value: searchText,
							onChange: (event) => requestSearch(event.target.value),
							clearSearch: () => requestSearch(''),
						},
					}}
					onSelectionModelChange={(newSelectionModel) => {
						setSelectionModel(newSelectionModel);
					}}
					selectionModel={selectionModel}
					autoHeight
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					style={{ marginBottom: "2vh", border: "none", minHeight: "500px", background: "#fff", borderRadius: "30px" }}
					loading={loading}
				/>
			</div>
			<Modal open={shortlistMailModalOpen} onClose={onCloseShortlistMailModal} classNames={{ modal: 'customModal-workflow', closeIcon: 'closeIcon' }}>
				<div className="modal-heading-workflow">
					Customise your shortlist mail
				</div>
				<label><br />Select template for shortlisting<br /></label>
				<Select
					id="reviewedFlow-selector-9"
					className="remove-bg"
					onChange={(e) => { setSelectedTemp(e.target.value) }}
					style={{ width: "380px", height: "45px", margin: "10px", "background": "#F2F2F2", "borderRadius": "30px" }}
				>
					<MenuItem selected>Select template for shortlisting</MenuItem>
					{tempInv?.length > 0 ?
						tempInv.map((con, index) => {
							return (
								<MenuItem value={index} id="reviewedFlow-selector-10">{con.name}</MenuItem>
							)
						}) : <MenuItem ></MenuItem>}
				</Select>
				<div className="button-div">
					<Button
						id="reviewedFlow-selector-11"
						style={{
							background: '#479BD2',
							margin: '0 10px',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
						}}
						onClick={() => {
							sendShortlistMail(selectedRow);
							onCloseShortlistMailModal();
						}}
					>
						Send Mail
					</Button>
					<Button
						style={{
							background: '#479BD2',
							margin: '0 10px',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
						}}
						onClick={onCloseShortlistMailModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>
			<Modal open={rejectMailModalOpen} onClose={onCloseRejectMailModal} classNames={{ modal: 'customModal-workflow', closeIcon: 'closeIcon' }}>
				<div className="modal-heading-workflow">
					Customise your rejection mail
				</div>
				<label><br />Select template for rejection<br /></label>
				<Select
					id="reviewedFlow-selector-13"
					className="remove-bg"
					onChange={(e) => { setSelectedTemp(e.target.value) }}
					style={{ width: "380px", height: "45px", margin: "10px", "background": "#F2F2F2", "borderRadius": "30px" }}
				>
					<MenuItem selected>Select template for rejection</MenuItem>
					{tempRem?.length > 0 ?
						tempRem.map((con, index) => {
							return (
								<MenuItem value={index} id="reviewedFlow-selector-14">{con.name}</MenuItem>
							)
						}) : <MenuItem ></MenuItem>}
				</Select>
				<div className="button-div">
					<Button
						id="reviewedFlow-selector-15"
						style={{
							background: '#479BD2',
							margin: '0 10px',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
						}}
						onClick={() => {
							sendRejectMail(selectedRow);
							onCloseRejectMailModal();
						}}
					>
						Send Reject Mail
					</Button>
					<Button
						style={{
							background: '#479BD2',
							margin: '0 10px',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
							color: '#fff',
						}}
						onClick={onCloseRejectMailModal}
					>
						Cancel
					</Button>
				</div>
			</Modal>
			<AnalyseModal
				open={reportModal}
				onClose={onCloseReportModal}
				chat={chat}
				changeStatus={changeStatus}
				doughnutRef={doughnutRef}
				barChartRef={barChartRef}
				isOpen={isOpen}
			/>
			<Modal open={modal} onClose={onCloseModal} center classNames={{ modal: 'customModalChats', closeIcon: 'closeIcon' }} styles={{ modal: { width: '100%' } }}>
				<div className="container-team" style={{ padding: "0 20px 20px", margin: "0" }}>
					<div className="heading-team" style={{ margin: "0 0 20px 0" }}>
						<div style={{ fontSize: "25px", fontWeight: "800", color: "#479BD2" }}>
							Chat Report : <span style={{ fontWeight: "400" }}>{chat.name}</span>
						</div>
					</div>
					<div style={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden", padding: "10px" }}>
						{
							chat?.test?.chat?.length > 0 ? chat.test.chat.map((row, index) => {
								return <div className="row-team">
									<div style={{ display: "flex", marginBottom: "15px" }}>
										<p style={{ flex: "1", fontWeight: "600" }}>
											Q.{index + 1} &nbsp; {(Array.isArray(row.ques) ? row.ques[row.ques.length - 1].split('***::')[1] : row.ques.split('***::')[row.ques.split('***::').length - 1])}
										</p>
										<div style={{ width: "50px" }}>
											<ExpandMore
												expand={index === expanded}
												onClick={() => { handleExpandClick(index) }}
												aria-expanded={index === expanded}
												aria-label="expand"
											>
												<ExpandMoreIcon />
											</ExpandMore>
										</div>
									</div>
									<Collapse in={index === expanded} timeout="auto">
										{(typeof row.ans === 'string' || row.ans instanceof String) ? <div>
											<b>Answer- </b> {row.ans.includes('.mp4') ? <a href="javascript:void(0)" target="_blank" onClick={(e) => { e.preventDefault(); getCandVideo(row.ans) }}>Click Here</a> : row.ans.replaceAll('::', ". ")}
										</div> : null}
									</Collapse>
								</div>
							}) : "No Chat"
						}
					</div>
				</div>
			</Modal>
			<Modal open={editModal} onClose={onCloseEditModal} center>
				<p className="modal-text">
					Edit candidate's name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								id="reviewedFlow-selector-4"
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						id="reviewedFlow-selector-5"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						<span style={{ fontSize: '16px' }}>Ok</span>
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						<span style={{ fontSize: '16px' }}>Cancel</span>
					</Button>
				</div>
			</Modal>
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose}>
				<Alert onClose={handleSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{snackErr ? snackErr : ''}
				</Alert>
			</Snackbar>

			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose}>
				<Alert onClose={handleGreenSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{successMsg ? successMsg : ''}
				</Alert>
			</Snackbar>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList(),
	rerender: getRerender(),
	userRole: getUserRole()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
	editCandidateName: (row, newName) => dispatch(editCandidateNameRequest(row, newName)),
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	resetRerender: () => dispatch(resetRerenderRequest()),
	deleteCandidate: (data) => dispatch(deleteCandidateRequest(data))
});

Finalized.propTypes = {
	editCandidateName: PropTypes.func,
	resetRerender: PropTypes.func,
	deleteCandidate: PropTypes.func,
	rerender: PropTypes.number,
	notification: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(Finalized);
