import React, { useState, useEffect } from "react";
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DataGrid, GridToolbarFilterButton, GridOverlay } from "@mui/x-data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { makeStyles, createStyles } from '@material-ui/styles';
import { useLocation, useHistory } from 'react-router';

import axios from "../../axios";
import helperFunction from '../../helper/helperFunction';
import { getCandidatesByType as getCandidatesByTypeRequest } from '../../actions/app';
import { getCandidatesList } from '../../selectors/app';
import { useWindowDimensions } from "../../utils/windowUtils";

import "./InProgress.css";

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const useStyles = makeStyles(
	() =>
		createStyles({
			root: {
				flexDirection: 'column',
				'& .ant-empty-img-1': {
					fill: '#aeb8c2',
				},
				'& .ant-empty-img-2': {
					fill: '#f5f5f7',
				},
				'& .ant-empty-img-3': {
					fill: '#dce0e6',
				},
				'& .ant-empty-img-4': {
					fill: '#fff',
				},
				'& .ant-empty-img-5': {
					fillOpacity: '0.8',
					fill: '#f5f5f5',
				},
				marginTop: "5px"
			},
			label: {
				margin: "5px 0 2px",
			},
		}),
);


const CustomNoRowsOverlay = () => {
	const classes = useStyles();

	return (
		<GridOverlay className={classes.root}>
			<svg
				width="120"
				height="100"
				viewBox="0 0 184 152"
				aria-hidden
				focusable="false"
			>
				<g fill="none" fillRule="evenodd">
					<g transform="translate(24 31.67)">
						<ellipse
							className="ant-empty-img-5"
							cx="67.797"
							cy="106.89"
							rx="67.797"
							ry="12.668"
						/>
						<path
							className="ant-empty-img-1"
							d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
						/>
						<path
							className="ant-empty-img-2"
							d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
						/>
						<path
							className="ant-empty-img-3"
							d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
						/>
					</g>
					<path
						className="ant-empty-img-3"
						d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
					/>
					<g className="ant-empty-img-4" transform="translate(149.65 15.383)">
						<ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
						<path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
					</g>
				</g>
			</svg>
			<div className={classes.label}>No Rows</div>
		</GridOverlay>
	);
}

const QuickSearchToolbar = (props) => {
	const { value, onChange, clearSearch } = props;

	return (
		<div className="root">
			<div>
				<GridToolbarFilterButton />
			</div>
			<TextField
				variant="standard"
				value={value}
				onChange={onChange}
				placeholder="Search…"
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: value ? 'visible' : 'hidden' }}
							onClick={clearSearch}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</div>
	);
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

const InProgress = (props) => {
	const { candidates, getCandidatesByType } = props;

	let location = useLocation();
	const history = useHistory();

	const [deleteModal, setDeleteModal] = useState(false);
	const [change, setChange] = useState(0);
	const [data, setData] = useState([]);
	const [rows, setRows] = useState(data);
	const [loading, setLoading] = useState(true);
	const [searchText, setSearchText] = useState('');
	const [selectionModel, setSelectionModel] = useState([]);
	const [deleteCandId, setDeleteCand] = useState();
	const [pageSize, setPageSize] = useState(15);

	const onOpenDeleteModal = () => { setDeleteModal(true) };

	const onCloseDeleteModal = () => setDeleteModal(false);

	useEffect(() => {
		const reqData = {
			status: "progress",
			id: location.state.workflowId
		};

		getCandidatesByType({ reqData });
	}, [change]);

	useEffect(() => {
		if (!candidates) return;

		setData(candidates);
		setRows(candidates);
	}, [candidates]);

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);

		const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
		const filteredRows = data.filter((row) => {
			return Object.keys(row).some((field) => {
				return searchRegex.test(row[field].toString());
			});
		});

		setRows(filteredRows);
	};

	const withdrawCand = (candidate) => {
		let data = {
			comp: "companyName",
			wf: location.state.workflowId,
			cand: candidate._id,
			status: "Withdraw"
		}

		axios.post("/statusupdate", data)
			.then(() => {
				setChange(change + 1);
			})
			.catch((err) => {
				console.log(err)
				helperFunction.redirectUnauthorisedUser(err, history);
			});
	}

	const handleDelete = (cand) => {
		axios.get('delete-candidate/' + cand._id).then(() => {
			setData(data.filter((item) => item.id !== cand.id));
			setRows(data.filter((item) => item.id !== cand.id));
			setChange(change + 1);
		}).catch((err) => {
			console.log(err)
			helperFunction.redirectUnauthorisedUser(err, history);
		});

	};

	const columns = [
		{ field: "id", headerName: "ID", width: useWindowDimensions().width / 15 },
		{
			field: "candidate",
			headerName: "Name",
			width: useWindowDimensions().width / 12,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontWeight: "bold", fontSize: "16px" }}>
						{params.row.name}
					</div>
				);
			},
		},
		{ field: "email", headerName: "Email", width: useWindowDimensions().width / 8 },
		{
			field: "stage",
			headerName: "Interview Stage",
			width: useWindowDimensions().width / 10,
			renderCell: (params) => {
				return (
					<div className="userListUser">
						{Number.isFinite(parseInt(params.row.status)) ? params.row.status + "%" : params.row.status}
					</div>
				);
			},
		},
		{
			field: "lastActive", headerName: "Last Action Taken", width: useWindowDimensions().width / 8,
			renderCell: (params) => {
				let date = new Date(params.row.lastActive).toLocaleString()
				return (
					<div className="userListUser">
						{
							date
						}
					</div>
				);
			}
		},
		{
			field: "remind",
			headerName: "Remind",
			width: useWindowDimensions().width / 10,
			renderCell: (params) => {
				return (
					<>
						<BlockOutlinedIcon
							className=""
							onClick={() => { withdrawCand(params.row) }}
						/>
					</>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			width: useWindowDimensions().width / 10,
			renderCell: (params) => {
				return (
					<>
						<DeleteOutline
							className="userListDelete"
							onClick={() => { setDeleteCand(params.row); onOpenDeleteModal(); }}
						/>
					</>
				);
			},
		},
	];

	return (
		<div className="userList">
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
				<p className="modal-text">
					Do you want to Delete the candidate from Workflow
				</p>
				<div className="button-div">
					<Button className="modal-button" onClick={() => {
						onCloseDeleteModal()
						handleDelete(deleteCandId)
					}}>
						Ok
					</Button>
					<Button className="cancel-modal-button" onClick={() => {
						onCloseDeleteModal()
					}}>
						Cancel
					</Button>
				</div>
			</Modal>
			<div className="userListTitleContainer">
				<div>
					<div style={{ fontSize: "3vh", fontWeight: "600" }}>
						In Progress
					</div>
					<div style={{ fontSize: "2vh" }}>
						{location.state.workflowName}
					</div>
				</div>
			</div>
			{selectionModel.length > 0 ?
				<div style={{ background: "lightgrey", position: "relative", top: "0vh", minHeight: "6vh", padding: "1vh 20px 1vh", display: "flex", justifyContent: "space-between", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
					<div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}>
						<Button style={{ color: "#fff", background: "#0C95BA ", fontSize: "12px", height: "40px", borderRadius: "25px", padding: "5px 20px", marginRight: "10px" }}>Withdraw</Button>
					</div>
				</div> : ""}
			<DataGrid
				rows={rows}
				components={{
					Toolbar: QuickSearchToolbar,
					NoRowsOverlay: CustomNoRowsOverlay
				}}
				getRowClassName={(params) =>
					`rowOnHover`
				}
				disableSelectionOnClick
				columns={columns}
				checkboxSelection
				componentsProps={{
					toolbar: {
						value: searchText,
						onChange: (event) => requestSearch(event.target.value),
						clearSearch: () => requestSearch(''),
					},
				}}
				onSelectionModelChange={(newSelectionModel) => {
					setSelectionModel(newSelectionModel);
				}}
				selectionModel={selectionModel}
				autoHeight
				pageSize={pageSize}
				loading={loading}
				onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
				rowsPerPageOptions={[5, 10, 15]}
				style={{ marginBottom: "2vh" }}
			/>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data))
});

InProgress.propTypes = {
	candidates: PropTypes.array,
	getCandidatesByType: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(InProgress);
