import React from 'react';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { LineStyle } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";

import { logoutUser as logoutUserRequest } from '../../actions/app';

import Logo from '../../assets/Logo.png';

import "./sidebar.scss";

const SidebarSuperAdmin = (props) => {
	const { logoutUser } = props;

	const history = useHistory();

	const onLogout = () => {
		logoutUser({ userType: null, history });
	};

	return (
		<div className="sidebar">
			<div className="sidebar__wrapper">
				<div className="sidebar__logo-div">
					<img className="sidebar__logo" src={Logo} alt="" />
				</div>

				<div className="sidebar__user-div">
				</div>

				<div className={window.location.pathname === "/companies" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/companies", state: { sidebar: "companies" } }}
						className="sidebar__link">
						<Button><LineStyle />&nbsp;Manage Companies</Button>
					</Link>
				</div>

				<div className={window.location.pathname === "/bots" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/bots", state: { sidebar: "bots" } }}
						className="sidebar__link">
						<Button><LineStyle />&nbsp;Manage Bots</Button>
					</Link>
				</div>

				<div className={window.location.pathname === "/superUser" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/superUser", state: { sidebar: "superUser" } }}
						className="sidebar__link">
						<Button><LineStyle />&nbsp;Manage Super User</Button>
					</Link>
				</div>

				<div className={window.location.pathname === "/feedback" ? "sidebar__menu-active" : "sidebar__menu"}>
					<Link
						to={{ pathname: "/feedback", state: { sidebar: "feedback" } }}
						className="sidebar__link">
						<Button><LineStyle />&nbsp;Feedbacks</Button>
					</Link>
				</div>

				<div className="sidebar__menu">
					<div
						to="/signin"
						className="sidebar__link"
						onClick={onLogout}>
						<Button><ExitToAppRoundedIcon />&nbsp;Log out</Button>
					</div>
				</div>

			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
	logoutUser: (data) => dispatch(logoutUserRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarSuperAdmin);
