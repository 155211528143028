import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { useHistory } from 'react-router'

import axios from "../../axios";
import helperFunction from '../../helper/helperFunction';

const VideoCard = (props) => {
	const { ques, src, score, cid, notes, candidateData } = props;

	const history = useHistory();

	const [vidNotes, setVidNotes] = useState();
	const [inputScore, setInputScore] = useState(0);
	const [loading, setLoading] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [errSnackOpen, setErrSnackOpen] = useState(false);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);

	const handleErrSnackClick = () => {
		setErrSnackOpen(true);
	};

	const handleErrSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setErrSnackOpen(false);
	};

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setGreenSnackOpen(false);
	};

	const handleSaveNotes = function () {
		if (inputScore > 100) {
			setErrMsg("Please enter a valid score");
			handleErrSnackClick();
			return;
		}

		setLoading(true);
		axios.post('/savenotes', { notes: vidNotes, score: inputScore, cid, testid: candidateData.test._id })
			.then(() => {
				handleGreenSnackClick();
				setLoading(false);
			})
			.catch(err => {
				setErrMsg("Couldn't save changes at the moment");
				handleErrSnackClick();
				setLoading(false);
				helperFunction.redirectUnauthorisedUser(err, history);
			});
	}

	useEffect(() => {
		setInputScore(score)
	}, [score]);

	useEffect(() => {
		if (notes) {
			setVidNotes(notes);
		}
	}, [notes]);

	return (
		<div className="videobox">
			<div className="questionbox">
				<p>
					{
						Array.isArray(ques) ? (
							<>
								{
									ques.map((q) => {
										<>
											{q.split('***').map(function (item2, key2) {
												return (<span key={key2}>{item2.split('**')[0]}<b>{item2.split('**')[1]}</b></span>)

											})}
											<br />
										</>
									})
								}
							</>
						) : <>{ques.replaceAll('::', ' ').replaceAll('*', '')}</>
					}</p>
			</div>
			<div className="video" dangerouslySetInnerHTML={{ __html: `<video controls='true' playsinline src="${src}" type="video/mp4" ></video>` }} />
			<div className="card-container">
				<div className="items">
					<p className="score">Total Score</p>
					<p className="input percent">
						<input
							value={inputScore}
							onChange={(e) => setInputScore(e.target.value)}
							type="number"
							className='score-input'
						/> %
					</p>
				</div>
				<hr />
				<div className="items">
					<textarea
						placeholder="Notes"
						value={notes}
						onChange={(e) => setVidNotes(e.target.value)}
					></textarea>
				</div>
				<div style={{ padding: '5px' }}>
					<Button
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "5px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={handleSaveNotes}
						disabled={loading}
					>
						Save
					</Button>
				</div>
			</div>
			<Snackbar open={errSnackOpen} autoHideDuration={2500} onClose={handleErrSnackClose}>
				<Alert onClose={handleErrSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{errMsg ? errMsg : ''}
				</Alert>
			</Snackbar>
			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose}>
				<Alert onClose={handleGreenSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					Notes saved successfully
				</Alert>
			</Snackbar>
		</div>
	)
}

export default VideoCard;
