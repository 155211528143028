import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Error from '../../components/common/Error';
import Loader from '../../components/common/Loader';
import { getAuthErrorMsg, getAuthLoading } from '../../selectors/app';
import {
	freeUserLogIn as freeUserLogInRequest,
	setAuthErrorMsg as setAuthErrorMsgRequest,
} from '../../actions/app';
import { validateFreeSigninInput } from '../../utils/validation/validateUtils';

import LogoWhite from '../../assets/logo-white.png';

import '../../styles/freeApp/signIn.scss'

const SignIn = (props) => {
	const { freeUserLogIn, setAuthErrorMsg, isLoading, isError, errorMsg } = props;

	let history = useHistory();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const onChangeEmail = (e) => {
		setEmail(e.target.value);
	}

	const onChangePassword = (e) => {
		setPassword(e.target.value);
	}

	const onSubmitLogIn = () => {
		setAuthErrorMsg('');

		const userData = {
			email,
			password
		};

		const errorResult = validateFreeSigninInput(userData);

		if (!errorResult.isValid) {
			Object.keys(errorResult.errors).forEach((item) => {
				if (item) {
					setAuthErrorMsg(errorResult.errors[item]);
					return;
				}
			});
		} else {
			freeUserLogIn({ userData, history });
		}
	}

	return (
		<div id="signInPage">
			<div style={{ width: 500, borderRadius: 40, overflow: 'hidden', boxShadow: "10px 15px 5px 0px #00000040" }}>
				<div style={{ backgroundColor: '#0D7072', textAlign: 'center' }}>
					<img src={LogoWhite} alt="" style={{ height: 80 }} />
				</div>
				<div style={{ padding: '50px 25px 60px', textAlign: 'center' }}>
					<div>
						<input type='email' placeholder='Email' value={email} onChange={onChangeEmail} />
					</div>
					<div>
						<input type='password' placeholder='Password' value={password} onChange={onChangePassword} />
					</div>
					<Link
						to={'/free/forgot-password'}
						style={{ marginTop: 16, color: '#565656', fontSize: 22, fontWeight: '600', display: 'inline-flex', justifyContent: 'center' }}>Forgot Password</Link>
					<div style={{ borderRadius: 12, backgroundColor: '#0D7072', color: '#fff', fontSize: 22, fontWeight: '700', padding: '20px 0', marginTop: 40, cursor: 'pointer' }} onClick={onSubmitLogIn}>Log In</div>
					<Link
						to={'/free/signup'}
						style={{ marginTop: 16, color: '#565656', fontSize: 22, fontWeight: '600', display: 'inline-flex', justifyContent: 'center' }}>SignUp</Link>
				</div>
			</div>
			<div style={{ minHeight: "55px", margin: "1rem" }}>
				{isLoading ? <Loader /> : ''}
				{errorMsg !== '' ? <Error errorMsg={errorMsg} /> : ''}
			</div>
		</div>
	)
}

const mapStateToProps = createStructuredSelector({
	isLoading: getAuthLoading(),
	errorMsg: getAuthErrorMsg()
});

const mapDispatchToProps = (dispatch) => ({
	freeUserLogIn: (data) => dispatch(freeUserLogInRequest(data)),
	setAuthErrorMsg: (data) => dispatch(setAuthErrorMsgRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
