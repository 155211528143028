export const reportGrade = ['Poor', 'Average', 'Good'];
export const percentageColor = ['#F36565', '#FCEE73', '#3AAD03'];

export const reportWorkFunctions = {
	"cso": {
		"label": "Convert sales opportunities to clients",
		"remarks": [
			"The candidate did not demonstrate ability to identify the key levers to improve outreach to potential customers.",
			"The candidate demonstrated some ability to communicate effectively  with potential customers.",
			"The candidate demonstrated a strong ability to communicate effectively with potential customers."
		],
		"typicalTasks": [
			"Provide administrative support to sales and business development teams.",
			"Maintain client/prospect database and documentation",
			"Assist with the preparation of client outreach and presentation materials",
			"Perform follow-up action to close sales."
		],
		"associatedSkills": [
			{
				"title": "Content management",
				"detail": "Assist in the maintenance and update of content management systems and participate in cross functional efforts to prepare relevant content to be posted and updated."
			}, {
				"title": "Communication",
				"detail": "Articulate and discuss ideas and persuade others to achieve common outcomes."
			}
		]
	},
	"moc": {
		"label": "Manage outreach campaign",
		"remarks": [
			"The candidate had difficulty analysing data and identifying areas for improvement.",
			"The candidate showed basic ability to monitor campaigns and identify weak spots.",
			"The candidate showed good ability to analyse data and prioritise areas for improvement."
		],
		"typicalTasks": [
			"Participate in the conceptualisation and design development of marketing campaigns for the business.",
			"Identify target client profile segments, market segments and potential marketing mix.",
			"Consolidate performance data of campaigns.",
			"Monitor implementation of campaigns against planned timeline."
		],
		"associatedSkills": [
			{
				"title": "Marketing campaign management",
				"detail": "Execute marketing campaigns based on creative briefs, ensure compliance with budgetary requirements and collaborate with partners."
			}, {
				"title": "Data analytics",
				"detail": "Identify underlying trends and patterns in business data using statistical and computational techniques and tools."
			}
		]
	},
	"nbo": {
		"label": "Identify new business opportunities",
		"remarks": [
			"The candidate struggled to identify the relevant industries, company size or key stakeholders that should be targeted.",
			"The candidate was able to identify some, but not all, critical aspects of the ideal customer profile.",
			"The candidate showed good ability to identify the industry, company size and key stakeholders forming the ideal customer profile."
		],
		"typicalTasks": [
			"Identify prospective clients and channel partners through online research.",
			"Conduct client and channel partner research to uncover insights on potential business needs.",
			"Conduct market research and profiling, competitive landscape analysis and client profiling.",
			"Assist with identifying new business opportunities with existing clients based on research."
		],
		"associatedSkills": [
			{
				"title": "Business development",
				"detail": "Conduct research on key markets and identify potential leads.",
			}, {
				"title": "Business need analysis",
				"detail": "Document business requirements and identify basic needs as well as potential solutions."
			}, {
				"title": "Market research",
				"detail": "Conduct research and gather data on customers and competitors, to support the analysis of product performance, market trends and marketing effectiveness."
			}
		]
	},
	"pso": {
		"label": "Problem solving",
		"skills": 'Giving actionable recommendations, focus on the key findings',
		"remarks": [
			"Was unable to prioritise key actions needed to overcome challenges.",
			"Was able to identify solutions in some situations, but not consistently across all domains.",
			"Was able to identify the highest impact solutions to a range of challenges"
		]
	},
	"wco": {
		"label": "Written communication",
		"skills": 'Data interpretation, analytical skills, translating number',
		"remarks": [
			"Did not display the writing ability expected of the role, and may struggle if required to write in fluent English.",
			"Displayed some writing ability, but also made some errors that may indicate lack of fluency in written Englis.",
			"Displayed sound command of language and the principles of effective written communication"
		]
	},
	"dgb": {
		"label": "Develop and grow business",
		"remarks": [
			"Had difficulty working with data to build a sound sales plan.",
			"Showed some comfort working with data to build a sound sales plan.",
			"Showed good ability to analyse data and apply judgement to build a sound sales plan."
		],
		"typicalTasks": [
			'Develop marketing tactics and pricing strategies',
			'Drive market research studies to explore new technology',
			'Drive sales volume from specific target markets in collaboration with the sales and marketing team', 'Oversee the development of business proposals for new opportunities',
			'Identify potential partner relationships for the product'
		],
		"associatedSkills": [
			{
				"title": "Business Environment Analysis",
				"detail": "Utilise research instruments, quantitative and qualitative data to gather information on the business environment, evaluate data to draw out meaningful inferences that impact the organisation's market positioning and provide feedback to management"
			}, {
				"title": "Business Needs Analysis",
				"detail": "Elicit and analyse business requirements from key stakeholders and assess relevant solutions and their potential impact"
			}, {
				"title": "Demand Analysis",
				"detail": "Manage activities to carry out demand analysis and analyse market characteristics of products or services to assess its demand outlook"
			}, {
				"title": "Market Research",
				"detail": "Plan market, competitor and customer research activities and analyse trends and dynamics through information gathered"
			}, {
				"title": "Partnership Management",
				"detail": "Support the development and coordination of partnerships with external stakeholders and organisations"
			}, {
				"title": "Pricing Strategy",
				"detail": "Analyse trends to assess impact of internal and external factors on pricing and the effectiveness of pricing policies against competitors"
			}, {
				"title": "Product Management",
				"detail": "Identify competitor, consumer and technology trends impacting the product, and manage the product lifecycle and performance"
			}, {
				"title": "Project Management",
				"detail": "Oversee small projects or programmes, managing timelines, resources, risks and stakeholders"
			}, {
				"title": "Stakeholder Management",
				"detail": "Identify key stakeholder relationships, needs and interests, and coordinate with stakeholders on a day- to-day basis, Serve as the organisation's main contact point for stakeholder communications, clarifying responsibilities among stakholders, and engaging them to align expectations"
			}, {
				"title": "Strategy Implementation",
				"detail": "Analyse strategies for critical business functions to ensure plans are within risk mitigation factors"
			}
		]
	},
	"dpd": {
		"label": "Drive product development",
		"remarks": [
			"Exhibited limited familiarity with the key elements of setting up, running, monitoring and optimising marketing campaigns.",
			"Exhibited some familiarity with the key elements of setting up, running, monitoring and optimising marketing campaigns, but with some gaps.",
			"Exhibited consistent familiarity with the key elements of setting up, running, monitoring and optimising marketing campaigns."
		],
		"typicalTasks": [
			'Oversee development for a suite of products',
			'Evaluate products to identify gaps, issues with product interface, performance, and product functionalities based on market feedback',
			'Integrate feedback and requests in the ideation and development of products',
			'Validate detailed specifications and development costing against market potential and future revenue',
			'Prioritises the development of product features against strategic goals and initiatives',
			'Define the requirements for each feature and desired user experience',
			'Determine the timeline for development, implementation and release process for the product'
		],
		"associatedSkills": [
			{
				"title": "Business Needs Analysis",
				"detail": "Elicit and analyse business requirements from key stakeholders and assess relevant solutions and their potential impact"
			}, {
				"title": "Customer Experience Management",
				"detail": "Recognise customer profiles and preferences, and execute the customer engagement strategy, creating a positive customer experience through day to day interactions, Analyse implications of customer profiles, requirements and buying patterns on organisation's marketing strategy, and propose customer engagement initiatives"
			}, {
				"title": "Data Analytics",
				"detail": "Identify underlying trends and patterns in business data using statistical and computational techniques and tools, Develop, apply and evaluate algorithms, predictive data modelling and data visualisation to identify underlying trends and patterns in data"
			}, {
				"title": "Design Concepts Generation",
				"detail": "Research and evaluate existing information that informs new concept development as well as analyse concepts in terms of their suitability for the target audience or purpose, their feasibility and their commercial potential"
			}, {
				"title": "Design Thinking Practice",
				"detail": "Apply design thinking methodologies and execute design thinking processes to challenge norms and conventions in the organisation"
			}, {
				"title": "Emerging Technology Synthesis",
				"detail": "Conduct research and identify opportunities for new and emerging technology to support the business"
			}, {
				"title": "Market Research",
				"detail": "Plan market, competitor and customer research activities and analyse trends and dynamics through information gathered"
			}, {
				"title": "Product Management",
				"detail": "Identify competitor, consumer and technology trends impacting the product, and manage the product lifecycle and performance"
			}, {
				"title": "Project Management",
				"detail": "Oversee small projects or programmes, managing timelines, resources, risks and stakeholders"
			}, {
				"title": "User Experience Design",
				"detail": "Translate key user experience concepts and guidelines into simple wireframes, proposing elements of aesthetics and accessibility that would impact the user experience, Analyse and understand the desired experience from target users of IT products and/or services, and develop solutions to address gaps in the overall user experience"
			}, {
				"title": "User Interface Design",
				"detail": "Identify functionalities and information flows to develop components of user interface prototypes, making tweaks to graphical user interfaces"
			}
		]
	},
	"fip": {
		"label": "Formulate and implement product development strategy and plans",
		"remarks": [
			"Struggled to translate data and information into actionable customer and market insights to drive marketing strategy.",
			"Showed basic ability to translate data and information into actionable customer and market insights, but was not systematic and rigorous.",
			"Could effectively translate data and information into actionable customer and market insights, and enrich the proposed marketing strategy."
		],
		"typicalTasks": [
			'Create feature descriptions for the organization’s products',
			'Develop product portfolio roadmap, pricing, and launch strategies, and financial projections',
			'Anticipate internal and/or external business challenges and/or regulatory issues',
			'Present product portfolio performance to senior stakeholders'
		],
		"associatedSkills": [
			{
				"title": "Budgeting",
				"detail": "Prepare business unit’s operational budgets"
			}, {
				"title": "Business Environment Analysis",
				"detail": "Utilise research instruments, quantitative and qualitative data to gather information on the business environment, evaluate data to draw out meaningful inferences that impact the organization's market positioning and provide feedback to management"
			}, {
				"title": "Business Needs Analysis",
				"detail": "Elicit and analyze business requirements from key stakeholders and assess relevant solutions and their potential impact"
			}, {
				"title": "Demand Analysis",
				"detail": "Manage activities to carry out demand analysis and analyze market characteristics of products or services to assess its demand outlook"
			}, {
				"title": "Market Research",
				"detail": "Plan market, competitor and customer research activities and analyze trends and dynamics through information gathered"
			}, {
				"title": "Pricing Strategy",
				"detail": "Analyse trends to assess the impact of internal and external factors on pricing and the effectiveness of pricing policies against competitors"
			}, {
				"title": "Product Management",
				"detail": "Identify competitor, consumer and technology trends impacting the product, and manage the product lifecycle and performance"
			}, {
				"title": "Project Management",
				"detail": "Oversee small projects or programmes, managing timelines, resources, risks and stakeholders"
			}
		]
	},
	"ass": {
		"label": "Analyse systems and propose solutions",
		"remarks": [
			"Candidate did not demonstrate the ability to manage complexities within systems and propose feasible solutions",
			"Candidate demonstrated some ability to manage complexities within systems and propose feasible solutions, but not in a thorough and systematic manner",
			"Candidate demonstrated strong ability to manage complexities within systems and create strong proposals for feasible solutions"
		],
		"typicalTasks": [
			"Analyse systems and processes to identify enhancement opportunities to resolve system gaps",
			"Evaluate the ability of an existing system to support proposed changes and identify systems deficiencies and performance gaps",
			"Ensure proposed solutions and/or enhancements are aligned with user needs and requirements",
			"Identify and conduct feasibility analysis of proposed solutions and/or enhancements to systems",
			"Prepare proposals for proposed solutions and/or enhancements to systems"
		],
		"associatedSkills": [
			{
				"title": "Process Improvement and Optimisation",
				"detail": "Identify and Implement the adoption of process improvement and optimisation methods"
			},
			{
				"title": "Project Management",
				"detail": "Oversee small projects or programmes, managing timelines, resources, risks and stakeholders"
			},
			{
				"title": "Software Testing",
				"detail": "Draft simple test scenarios, and perform software testing procedures, highlighting bugs or glitches affecting performance"
			},
			{
				"title": "System Integration",
				"detail": "Perform basic compatibility assessments and integrate selected system components according to a plan"
			},
			{
				"title": "Test Planning",
				"detail": "Identify and document the basic tools, testware, resources and processes to carry out required tests"
			}
		]
	},
	"dts": {
		"label": "Develop technical specifications",
		"remarks": [
			"Candidate had difficulty translating between business needs and technical specifications",
			"Candidate showed basic ability to act as liaison between business needs and technical specifications",
			"Candidate showed good ability to manage users and technical team to translate requirements into detailed functional specifications"
		],
		"typicalTasks": [
			"Assist with translating business requirements into functional specifications",
			"Document specifications and interfaces between legacy and new systems, and systems enhancements and detailed specifications",
			"Act as the liaison between users and technical staff throughout the solution implementation cycle"
		],
		"associatedSkills": [
			{
				"title": "Business Requirements Mapping",
				"detail": "Analyse relevant information from stakeholders and map business requirements to existing processes to identify gaps and/or opportunities"
			},
			{
				"title": "Data Visualisation",
				"detail": "Select appropriate visualisation techniques and develop dashboards to reflect data trends and findings"
			},
			{
				"title": "Partnership Management",
				"detail": "Support the development and coordination of partnerships with external stakeholders and organisations",
			},
			{
				"title": "Software Testing",
				"detail": "Draft simple test scenarios, and perform software testing procedures, highlighting bugs or glitches affecting performance"
			},
			{
				"title": "Stakeholder Management",
				"detail": "Serve as the organisation's main contact point for stakeholder communications, clarifying responsibilities among stakeholders, and engaging them to align expectations"
			},
			{
				"title": "System Integration",
				"detail": "Perform basic compatibility assessments and integrate selected system components according to a plan"
			}
		]
	},
	"ibr": {
		"label": "Identify business needs, systems and requirements",
		"remarks": [
			"Candidate struggled to identify key elements of business needs and requirements",
			"Candidate was able to identify some elements of business needs and requirements",
			"Candidate showed good ability to identify key elements of business needs and assess potential solutions"
		],
		"typicalTasks": [
			"Assist in identifying business needs and system requirements",
			"Assist in the in-depth analysis of the business requirements and systems specifications",
			"Conduct feasibility studies on proposed solutions",
			"Provide analysis to support the development of business cases",
			"Support the preparation of proposals for modified or replacement systems",
			"Conduct analysis on the risk and benefits for the proposed solutions"
		],
		"associatedSkills": [
			{
				"title": "Business Environment Analysis",
				"detail": "Utilise a range of data sources to analyse information to derive business environmental patterns and produce reports to present findings"
			},
			{
				"title": "Business Needs Analysis",
				"detail": "Document business requirements and identify basic needs as well as potential solutions"
			},
			{
				"title": "Business Requirements Mapping",
				"detail": "Analyse relevant information from stakeholders and map business requirements to existing processes to identify gaps and/or opportunities"
			},
			{
				"title": "Data Visualisation",
				"detail": "Select appropriate visualisation techniques and develop dashboards to reflect data trends and findings"
			}
		]
	},
	"mis": {
		"label": "Manage the implementation of new solutions and/or enhancements",
		"remarks": [
			"Candidate exhibited limited ability to support user testing and reaching the desired end-point",
			"Candidate exhibited reasonable support user testing, but with room for further improvement",
			"Candidate exhibited strong ability to manage testing, train users, and drive to desired outcomes"
		],
		"typicalTasks": [
			"Develop test plans and test cases",
			"Support users on change control and systems updates and escalate issues to relevant team members for resolution",
			"Document post-test evidence of expected results or defects",
			"Coordinate training for new users",
			"Prepare progress reports and training documents",
			"Develop technical documentation of the design documents, coding documents and user manuals",
			"Coordinate User Acceptance Testing (UAT) and integration testing in accordance with the implementation plan",
			"Ensure adherence to project plan to ensure deliverables are completed on time and in accordance with user and system requirements"
		],
		"associatedSkills": [
			{
				"title": "Change Management",
				"detail": "Apply change control procedures in work processes, assess impact of change and develop communications to prepare stakeholders for the change"
			},
			{
				"title": "Data Visualisation",
				"detail": "Select appropriate visualisation techniques and develop dashboards to reflect data trends and findings"
			},
			{
				"title": "Partnership Management",
				"detail": "Support the development and coordination of partnerships with external stakeholders and organisations"
			},
			{
				"title": "Project Management",
				"detail": "Oversee small projects or programmes, managing timelines, resources, risks and stakeholders"
			},
			{
				"title": "Software Testing",
				"detail": "Draft simple test scenarios, and perform software testing procedures, highlighting bugs or glitches affecting performance"
			},
			{
				"title": "Stakeholder Management",
				"detail": "Serve as the organisation's main contact point for stakeholder communications, clarifying responsibilities among stakholders, and engaging them to align expectations"
			},
			{
				"title": "System Integration",
				"detail": "Perform basic compatibility assessments and integrate selected system components according to a plan"
			},
			{
				"title": "Technical Sales Support",
				"detail": "Perform technical product demonstrations and shortlist potential solutions, resolving technical issues to meet customers' requirements"
			},
			{
				"title": "Test Planning",
				"detail": "Identify and document the basic tools, testware, resources and processes to carry out required tests"
			}
		]
	},
	"fra": {
		"label": "Framing",
		"skills": 'Identification of key issues, Structuring an approach',
		"remarks": [
			"Candidate showed a poor grasp of key issues and struggled to translate the problem at hand into next steps",
			"Candidate attempted to identify the main issues and design the required approach, but with some gaps.",
			"Candidate identified key objectives, and could structure an approach to a robust recommendation."
		],
		"description": "Problem framing is the quintessential consulting skill. It requires the ability to  get to the heart of the problem and identify the key issues, as well as to quickly structure an approach to get to the answer.",
		"relevantQuestions": [
			"What are the core questions that need to be answered in order to achieve your objectives?",
			"What analyses should you prioritise to rapidly identify potential problems?",
			"What are the first 2 steps you would take to identify the causes of this issue?"
		]
	},
	"ana": {
		"label": "Analysis",
		"skills": 'Critical thinking, logic and reasoning',
		"remarks": [
			"Candidate could not think critically to identify logical ways to solve problems.",
			"Candidate showed some ability to think critically, but does not always identify logical ways to solve problems.",
			"Candidate showed strong ability to think critically and could reliably identify logical ways to solve problems."
		],
		"description": "Analysis is the ability to break up a big problem into smaller constituent parts. It combines the ability to think critically about a problem with the application of logic and reasoning to work through the constituent parts.",
		"relevantQuestions": [
			"Which of the following initiatives should you focus on to achieve your business goals in the current context?",
			"Given what you know about the business, which options would be most attractive for your client?"
		]
	},
	"ins": {
		"label": "Insight",
		"skills": 'Practical business knowledge, right assumptions, prioritisation',
		"remarks": [
			"Candidate lacked awareness of real-world business considerations and made unrealistic assumptions/prioritization decisions.",
			"Candidate showed some awareness of real-world business considerations, but struggled to come to the right assumptions or prioritization decisions.",
			"Candidate displayed strong business acumen and made sensible assumptions and prioritization decisions."
		],
		"description": "Insight is the flash of understanding that comes after analysis. Insight comes from applying practical business knowledge to the results of your analysis or to make the right assumptions, and using that to guide your prioritisation as you move towards generating solutions.",
		"relevantQuestions": [
			"What are the main risks the company will face if they decide to implement this recommendation?",
			"Given the data, which actions should the company prioritise?",
			"What impact can the company expect from implementing this initiative?"
		]
	},
	"rec": {
		"label": "Synthesis",
		"skills": 'Giving actionable recommendations, focus on the key findings',
		"remarks": [
			"Candidate did not demonstrate ability to synthesize facts and evidence, and hence came to the wrong conclusions.",
			"Candidate made some errors while synthesizing facts and evidence, and could not always come to the right conclusion.",
			"Candidate was able to connect facts and evidence, and could come to the right recommendations"
		],
		"description": "Synthesis is the ability to distil actionable recommendations from the noise and chaos of information overload and uncertainty. This means being able to focus on the key findings while avoiding distractions and red herrings.",
		"relevantQuestions": [
			"What is the best way for the client to execute this strategy without hurting their overall economics?",
			"Which of the following strategic options make the most sense, given the CEO's objectives and your knowledge of the company / market dynamics?",
			"How would you structure your update to the board on the best way forward for the company?"
		]
	},
	"qua": {
		"label": "Quantitative",
		"skills": 'Data interpretation, analytical skills, translating number',
		"remarks": [
			"Candidate was unable to extract useful information from data and made significant calculation errors.",
			"Candidate extracted some useful information from data, but made calculation or logical errors.",
			"Candidate accurately extracted insights from complex data, and was accurate when doing calculations"
		],
		"description": "Quantitative skills are all about working with numbers to come to business insights. This typically involves interpreting data, performing calculations, and translating numbers into insights.",
		"relevantQuestions": [
			"By what percentage did the company’s revenue per customer change over the past year?",
			"Based on the graphs presented, which of the following statements are true?",
			"Which of the following strategic options would maximise revenue growth next year?"
		]
	},
	"ibd": {
		"label": "Implement business development and sales strategies",
		"skills": 'Budgeting, sales strategy, problem solving',
		"remarks": [
			"The candidate had difficulty working with data to build a sound sales plan.",
			"The candidate showed basic ability to work with data to build a sound sales plan",
			"The candidate showed good ability to analyse data and apply judgement to build a sound sales plan."
		],
		"typicalTasks": [
			"Analyse business development and sales approaches and strategies to determine their best use within the market",
			"Use sales tools for accurate forecasting of current and future business"
		],
		"associatedSkills": [
			{
				"title": "Budgeting",
				"detail": "Manage budgeting and forecasting for annual business planning within the team",
			}, {
				"title": "Sales strategy",
				"detail": "Develop and implement a sales action plan, evaluate its effectiveness and propose refinements to sales strategy and activities"
			}, {
				"title": "Problem solving",
				"detail": "Identify core issues for non-obvious problems and use problem solving tools and techniques to solve the problems"
			}
		]
	},
	"fdd": {
		"label": "Formulate data-driven market and client insights",
		"skills": 'Business Environment Analysis, Consumer Intelligence Analysis, Customer Behaviour Analysis, Contract Management, Data Analytics, Market Trend Analysis, Marketing Strategy, Research, Pricing Strategy',
		"remarks": [
			"Struggled to translate data and information into actionable customer and market insights to drive marketing strategy.",
			"Showed basic ability to translate data and information into actionable customer and market insights, but was not systematic and rigorous.",
			"Could effectively translate data and information into actionable customer and market insights, and enrich the proposed marketing strategy."
		],
		"typicalTasks": [
			'Conduct market research on trends, competitor’s product positioning, placement and pricing strategies',
			'Identify information needs requiring data-mining and analysis to derive market and client insights',
			'Analyse market research findings, sales data and digital marketing dashboards to derive market insights', "Analyse data obtained on clients' buying behaviours, motivation, attitudes, preferences and needs to derive client insights",
			'Analyse data from digital marketing dashboards',
			'Develop client insights, and market and competitor analysis reports',
			'Propose new marketing ideas and approaches based on client insights derived from market analysis'
		],
		"associatedSkills": [
			{
				"title": "Business Environment Analysis",
				"detail": "Utilise a range of data sources to analyse information to derive business environmental patterns and produce reports to present findings"
			}, {
				"title": "Consumer Intelligence Analysis",
				"detail": "Analyse data from CRM, point-of-sale and e-commerce systems and generate relevant customer insights"
			}, {
				"title": "Customer Behaviour Analysis",
				"detail": "Collect data on customer behaviours and characteristics based on established research frameworks and historical data"
			}, {
				"title": "Contract Management",
				"detail": "Prepare drafts of contracts and agreements, monitor vendor performance and resolve minor contractual issues on an operational level"
			}, {
				"title": "Data Analytics",
				"detail": "Identify underlying trends and patterns in business data using statistical and computational techniques and tools"
			}, {
				"title": "Market Trend Analysis",
				"detail": "Collect data by conducting research, support the analysis of market trends and developments and prepare research documentation"
			}, {
				"title": "Marketing Strategy",
				"detail": "Identify critical customer segments, market gaps and competitors to support the development of a marketing strategy, and lead implementation of marketing efforts"
			}, {
				"title": "Market Research",
				"detail": "Plan market, competitor and customer research activities and analyse trends and dynamics through information gathered"
			}, {
				"title": "Pricing Strategy",
				"detail": "Analyse trends to assess impact of internal and external factors on pricing and the effectiveness of pricing policies against competitors"
			}
		]
	},
	"imc": {
		"label": "Manage integrated marketing communications (IMC) programme",
		"skills": 'Content Management, Contract Management, Design Concepts Generation, Data Analytics, Integrated Marketing, Marketing Campaign Management, Marketing Communications, Plan Development, Marketing Mix Management, Marketing Strategy, Media Platforms Management Media Strategy Development Market',
		"remarks": [
			"Exhibited limited familiarity with the key elements of setting up, running, monitoring and optimising marketing campaigns.",
			"Exhibited some familiarity with the key elements of setting up, running, monitoring and optimising marketing campaigns, but with some gaps.",
			"Exhibited consistent familiarity with the key elements of setting up, running, monitoring and optimising marketing campaigns."
		],
		"typicalTasks": [
			'Participate in the conceptualisation and design development of IMC campaigns for the business',
			'and/or specific lines of product and service',
			'Identify target client profile segments, market segments and potential marketing mix for IMC campaign',
			'Identify possible traditional and digital media channels and platforms for IMC campaign',
			'Coordinate with design teams to develop IMC campaign concept design elements and materials for traditional and digital media channels',
			'Support the administrative and logistical needs for implementing IMC campaigns and trade events',
			'Consolidate performance data of IMC campaigns',
			'Monitor implementation of IMC campaigns against planned timeline',
			'Update marketing and communications content on various media and platforms',
			'Identify emerging technologies for potential adoption for IMC campaign and activities',
			'Prioritises the development of product features against strategic goals and initiatives',
			'Define the requirements for each feature and desired user experience',
			'Determine the timeline for development, implementation and release process for the product'
		],
		"associatedSkills": [
			{
				"title": "Content Management",
				"detail": "Assist in the maintenance and update of content management systems and participate in cross functional efforts to prepare relevant content to be posted and updated"
			}, {
				"title": "Contract Management",
				"detail": "Prepare drafts of contracts and agreements, monitor vendor performance and resolve minor contractual issues on an operational level",
			}, {
				"title": "Design Concepts Generation",
				"detail": "Research and evaluate existing information that informs new concept development as well as analyse concepts in terms of their suitability for the target audience or purpose, their feasibility and their commercial potential"
			}, {
				"title": "Data Analytics",
				"detail": "Identify underlying trends and patterns in business data using statistical and computational techniques and tools",
			}, {
				"title": "Integrated Marketing",
				"detail": "Assess and propose suitable marketing channels and platforms, developing a marketing plan for specific channels"
			}, {
				"title": "Marketing Campaign Management",
				"detail": "Execute marketing campaigns based on creative briefs, ensure compliance with budgetary requirements and collaborate with partners"
			}, {
				"title": "Marketing Communications Plan Development",
				"detail": "Collect feedback on marketing communications and media"
			}, {
				"title": "Marketing Mix Management",
				"detail": "Collect relevant information on marketing mix components and carry out consumer research"
			}, {
				"title": "Marketing Strategy",
				"detail": "Identify critical customer segments, market gaps and competitors to support the development of a marketing strategy, and lead implementation of marketing efforts"
			}, {
				"title": "Media Platforms Management",
				"detail": "Collate information on types of media and support implementation of media platform plans and activities Media Strategy Development : Collect past media performance and information to assist in refining media planning strategies"
			}
		]
	},
	"iss": {
		"label": "Implement sales strategy",
		"skills": 'Budgeting, Business Needs Analysis, Pricing Strategy, Sales Strategy',
		"remarks": [
			"Had difficulty working with data to build a sound sales plan.",
			"Showed some comfort working with data to build a sound sales plan.",
			"Showed good ability to analyse data and apply judgement to build a sound sales plan."
		],
		"typicalTasks": [
			'Analyse sales and client data to identify market trends and estimate market demand',
			'Determine strategic sales targets, markets, product and/or service offerings, expected volume and profits',
			'Create lead generation plans to ensure a substantive sales opportunity pipeline',
			'Develop pricing approaches to support sales and market growth strategies',
			'Coordinate sales activities in line with sales strategy',
			'Provide trends and market feedback to senior management'
		],
		"associatedSkills": [
			{
				"title": "Budgeting",
				"detail": "Manage budgeting and forecasting for annual financial and business planning within the business unit"
			}, {
				"title": "Business Needs Analysis",
				"detail": "Elicit and analyse business requirements from key stakeholders and assess relevant solutions and their potential impact"
			}, {
				"title": "Pricing Strategy",
				"detail": "Analyse trends to assess impact of internal and external factors on pricing and the effectiveness of pricing policies against competitors"
			}, {
				"title": "Sales Strategy",
				"detail": "Develop and implement a sales action plan for business units, evaluates its effectiveness and propose refinements to sales strategy and activities"
			}
		]
	},
	"dpp": {
		"label": "Develop project plans",
		"skills": 'Identification of key issues, Structuring an approach',
		"remarks": [
			"Struggled to translate data and information into actionable customer and market insights to drive marketing strategy.",
			"Showed basic ability to translate data and information into actionable customer and market insights, but was not systematic and rigorous.",
			"Could effectively translate data and information into actionable customer and market insights, and enrich the proposed marketing strategy."
		],
		"typicalTasks": [
			'Conduct cost-benefit analysis and develop project plan',
			'Review project plans to determine time frame, funding limitations, procedures for accomplishing projects',
			'Estimate budgetary needs based on the project scope and anticipate future budget challenges',
			"Assess consolidated project plans for dependencies, gaps and continued business value"
		],
		"associatedSkills": [
			{
				"title": "Budgeting",
				"detail": "Prepare business unit’s operational budgets"
			},
			{
				"title": "Business Environment Analysis",
				"detail": "Utilise research instruments, quantitative and qualitative data to gather information on the business environment, evaluate data to draw out meaningful inferences that impact the organisation's market positioning and provide feedback to management,Utilise a range of data sources to analyse information to derive business environmental patterns and produce reports to present findings"
			},
			{
				"title": "Business Needs Analysis",
				"detail": "Document business requirements and identify basic needs as well as potential solutions,Elicit and analyse business requirements from key stakeholders and assess relevant solutions and their potential impact"
			},
			{
				"title": "Business Performance Management",
				"detail": "Monitor performance of the department"
			},
			{
				"title": "Business Requirements Mapping",
				"detail": "Analyse relevant information from stakeholders and map business requirements to existing processes to identify gaps and/or opportunities"
			},
			{
				"title": "Business Risk Management",
				"detail": "Identify risks and their business impact and propose measures to manage risks"
			},
			{
				"title": "Manpower Planning",
				"detail": "Facilitate recruitment of manpower to meet forecast requirements"
			},
			{
				"title": "Process Improvement and Optimisation",
				"detail": "Identify and Implement the adoption of process improvement and optimisation methods"
			},
			{
				"title": "Project Management",
				"detail": "Oversee small projects or programmes, managing timelines, resources, risks and stakeholders, Plan and drive medium scale projects or programmes, including allocating resources to different parts, and engaging stakeholders on the project's progress and outcomes"
			}
		]
	},
	"mpo": {
		"label": "Manage people and organisation",
		"skills": 'Critical thinking, logic and reasoning',
		"remarks": [
			"Exhibited limited familiarity with the key elements of setting up, running, monitoring and optimising marketing campaigns.",
			"Exhibited some familiarity with the key elements of setting up, running, monitoring and optimising marketing campaigns, but with some gaps.",
			"Exhibited consistent familiarity with the key elements of setting up, running, monitoring and optimising marketing campaigns."
		],
		"typicalTasks": [
			'Manage project budget, work allocations, manpower and resourcing needs for the team',
			'Develop team members through ongoing coaching, mentoring and career discussions',
			'Drive performance management practices within the team in accordance with organisational policies and procedures',
			'Develop initiatives to support the continuing competence and professional development of the team',
			'Facilitate discussions, problem solving and conflict resolution'
		],
		"associatedSkills": [
			{
				"title": "Agile Coaching",
				"detail": "Coach teams in the conduct of Agile practices and the implementation of Agile methodologies and practices in the organisation"
			},
			{
				"title": "Budgeting",
				"detail": "Prepare business unit’s operational budgets"
			},
			{
				"title": "Business Agility",
				"detail": "Lead the implementation of operational initiatives to enhance business agility"
			},
			{
				"title": "Business Risk Management",
				"detail": "Identify risks and their business impact and propose measures to manage risks"
			},
			{
				"title": "Change Management",
				"detail": "Apply change control procedures in work processes, assess impact of change and develop communications to prepare stakeholders for the change"
			},
			{
				"title": "Learning and Development",
				"detail": "Support employees to develop their skills and facilitate learning opportunities and coaching junior management employees"
			},
			{
				"title": "Manpower Planning",
				"detail": "Facilitate recruitment of manpower to meet forecast requirements"
			},
			{
				"title": "People and Performance Management",
				"detail": "Implement performance management programmes"
			},
			{
				"title": "Process Improvement and Optimisation",
				"detail": "Identify and Implement the adoption of process improvement and optimisation methods"
			},
			{
				"title": "Project Management",
				"detail": "Oversee small projects or programmes, managing timelines, resources, risks and stakeholders, Plan and drive medium scale projects or programmes, including allocating resources to different parts, and engaging stakeholders on the project's progress and outcomes"
			},
			{
				"title": "Stakeholder Management",
				"detail": "Identify key stakeholder relationships, needs and interests, and coordinate with stakeholders on a day- to-day basis, Serve as the organisation's main contact point for stakeholder communications, clarifying responsibilities among stakeholders, and engaging them to align expectations"
			}
		]
	},
	"opi": {
		"label": "Oversee program implementation",
		"skills": 'Practical business knowledge, right assumptions, prioritisation',
		"remarks": [
			"Had difficulty working with data to build a sound sales plan.",
			"Showed some comfort working with data to build a sound sales plan.",
			"Showed good ability to analyse data and apply judgement to build a sound sales plan."
		],
		"typicalTasks": [
			'Plan and manage project compliance requirements and adherence to governance structures',
			'Evaluate and address external business environment changes for impact on project scope',
			'Plan and manage project closure and/or transitions',
			'Conduct project reviews to recommend changes to project schedules, cost or resource requirements',
			'Document and track project scope, changes, issues and risks that affect implementation',
			'Facilitate the daily stand-up to achieve team consensus',
			'Eliminate conflicts and assist in developing solutions to manage roadblocks',
			'Refine and manage the product backlog'
		],
		"associatedSkills": [
			{
				"title": "Business Risk Management",
				"detail": "Identify risks and their business impact and propose measures to manage risks"
			},
			{
				"title": "Change Management",
				"detail": "Apply change control procedures in work processes, assess impact of change and develop communications to prepare stakeholders for the change"
			},
			{
				"title": "Contract Management",
				"detail": "Prepare drafts of contracts and agreements, monitor vendor performance and resolve minor contractual issues on an operational level"
			},
			{
				"title": "Manpower Planning",
				"detail": "Facilitate recruitment of manpower to meet forecast requirements"
			},
			{
				"title": "People and Performance Management",
				"detail": "Implement performance management programmes"
			},
			{
				"title": "Process Improvement and Optimisation",
				"detail": "Identify and Implement the adoption of process improvement and optimisation methods"
			},
			{
				"title": "Project Management",
				"detail": "Oversee small projects or programmes, managing timelines, resources, risks and stakeholders, Plan and drive medium scale projects or programmes, including allocating resources to different parts, and engaging stakeholders on the project's progress and outcomes"
			},
			{
				"title": "Stakeholder Management",
				"detail": "Identify key stakeholder relationships, needs and interests, and coordinate with stakeholders on a day-to-day basis, Serve as the organisation's main contact point for stakeholder communications, clarifying responsibilities among stakeholders, and engaging them to align expectations"
			}
		]
	},
	"mop": {
		"label": "Manage people and organisation",
		"remarks": [
			"Candidate struggled with managing internal and external stakeholders, and planning to use resources effectively",
			"Candidate showed some signs of ability to manage internal and external stakeholders, and planning to use resources effectively",
			"Candidate did well with managing internal and external stakeholders, and planning to use resources effectively"
		],
		"typicalTasks": [
			"Manage the budget expenditure and allocation across teams and projects",
			"Monitor and track the achievement of the team’s achievements and key performance indicators",
			"Propose new operational plans, including targeted budgets, work allocations and staff forecasts",
			"Acquire, allocate and optimise the use of and allocation of resources",
			"Develop learning roadmaps to support the professional development of the team",
			"Manage the performance and development process, including providing coaching and development opportunities to maximise the potential of each individual"
		],
		"associatedSkills": [
			{
				"title": "Business Needs Analysis",
				"detail": "Elicit and analyse business requirements from key stakeholders and assess relevant solutions and their potential impact"
			},
			{
				"title": "Customer Experience Management",
				"detail": "Recognise customer profiles and preferences, and execute the customer engagement strategy, creating a positive customer experience through day to day interactions, Analyse implications of customer profiles, requirements and buying patterns on organisation's marketing strategy, and propose customer engagement initiatives"
			},
			{
				"title": "Design Concepts Generation",
				"detail": "Research and evaluate existing information that informs new concept development as well as analyse concepts in terms of their suitability for the target audience or purpose, their feasibility and their commercial potential"
			},
			{
				"title": "Design Thinking Practice",
				"detail": "Apply design thinking methodologies and execute design thinking processes to challenge norms and conventions in the organisation"
			},
			{
				"title": "Market Research",
				"detail": "Plan market, competitor and customer research activities and analyse trends and dynamics through information gathered"
			},
			{
				"title": "Product Management",
				"detail": "Identify competitor, consumer and technology trends impacting the product, and manage the product lifecycle and performance"
			},
			{
				"title": "Project Management",
				"detail": "Oversee small projects or programmes, managing timelines, resources, risks and stakeholders"
			},
			{
				"title": "Stakeholder Management",
				"detail": "Identify key stakeholder relationships, needs and interests, and coordinate with stakeholders on a day- to-day basis, Serve as the organisation's main contact point for stakeholder communications, clarifying responsibilities among stakholders, and engaging them to align expectations"
			},
			{
				"title": "Strategy Implemenntation",
				"detail": "Analyse strategies for critical business functions to ensure plans are within risk mitigation factors"
			},
			{
				"title": "Partnership Management",
				"detail": "Support the development and coordination of partnerships with external stakeholders and organisations"
			}
		]
	}
}

export const doughnutOptions = {
	responsive: true,
	cutout: "80%",
	plugins: {
		tooltip: false
	},
	hover: { mode: null },
	animation: false
}

export const doughnutOptionsAnimated = {
	responsive: true,
	cutout: "80%",
	plugins: {
		tooltip: false
	},
	hover: { mode: null },
}

export const barChartData = {
	labels: [],
	datasets: [
		{
			label: 'Ideal time for question',
			data: [],
			backgroundColor: '#334D6E',
		},
		{
			label: 'Time taken by candidate',
			data: [],
			backgroundColor: '#479BD2',
		}
	],
	options: {
		responsive: true,
		scales: {
			yAxes: {
				display: true,
				title: {
					display: true,
					text: "Time (minutes)"
				},
				grid: {
					borderDash: [12, 10],
					color: "#999"
				}
			},
			XAxes: {
				grid: {
					display: false
				}
			}
		},
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: false,
			}
		},
		animation: false
	},
};

export const reportRoles = {
	'sdr': 'SDR',
	'consulting': 'Consultant',
	'product': 'Product Manager',
	'project': 'Project Manager',
	'marketing': 'Marketing Manager',
	'business': 'Business Analyst'
}