import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import axios from '../../axios';

import '../../styles/testGuidelines.scss';
import styles from '../../styles/UserDetails.module.css';

import privacyPolicy from '../../assets/PrivacyPolicyHQ 26Nov2021.pdf'

const Dashboard = () => {
	let history = useHistory();
	const { comp, workflowId, user_id } = useParams();
	/*
	if (isMobile && process.env.REACT_APP_BASE_URL.includes("hirequotient.com"))
		history.push('/mobile/#/' + comp + '/' + workflowId + '/' + user_id);
*/
	const [checked, setChecked] = useState(false);

	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [resumeStat, setResumeStat] = useState(false);
	const [completed, setCompleted] = useState(false);
	const [isDisabled, setdisable] = useState(true);
	const [stateobj, setstateobj] = useState({});
	
	const postoptions = {
		user_id: user_id,
		comp: comp,
		workflowId: workflowId
	}
	
	const onGoAhead = () => {
		if (!completed)
			history.push({
				pathname: '/chatbox',
				state: stateobj
			});
	}

	useEffect(() => {
		if (stateobj)
			setdisable(false);
	}, [stateobj]);

	useEffect(() => {
		axios
			.post('/check-cases', postoptions)
			.then((res) => {
				if (res.data.auth) {
					if (res.data.completed)
						history.push('/success');
					else {
						setName(res.data.details.name);
						setEmail(res.data.details.email);
						setResumeStat(res.data.hasPendingCases);
						setCompleted(res.data.completed);
						setstateobj({ id: res.data.productId, wf: workflowId, comp: comp, startT: res.data.startT, code: user_id, heading: res.data.title, prob: res.data.problem, resume: res.data.hasPendingCases, videoProctoring: res.data.videoProctoring });
					}
				}
				else {
					history.push('/invalid');
				}
				setIsLoading(false);
			})
			.catch((err) => {
				history.push('/invalid');
				setIsLoading(false);
				console.log(err);
			});
	}, []);

	return (
		<div id="testGuidelinesPage">
			<div className='test-guidelines-header'>
				<div>
					<img style={{ height: "auto", width: "180px", padding: "1vh" }} src="/hq-logo.png" alt="logo" />
				</div>
				<div>
					<h3 style={{ color: '#fff', fontWeight: '400' }}>{email ? email : ''}</h3>
				</div>
			</div>
			<div className='test-guidelines-welcome'>
				<h2>Welcome to HireQuotient, {name ? name : ""}!</h2>
			</div>
			<div className='test-guidelines-wrapper'>
				<div className={styles.paper}>
					<div className='test-guidelines-content'>
						<h2 style={{ padding: '0 40px', marginBottom: '10px' }}>Guidelines</h2>
						<div className='test-guidelines-content-scroll'>
							<ul style={{ textAlign: 'left' }}>
								<br />
								<li>Please ensure that you are in an environment with steady internet connectivity as this is a timed assessment and you will not be able to recover time lost due to connectivity issues.</li>
								<br />
								<li>Please also have some paper and a pen or pencil handy. This will allow you to take notes and lay out your thoughts throughout the assessment. Critical information will be relayed in small doses, so it is important that you keep track of important numbers, hypotheses, or interim conclusions, and be able to quickly find them if needed later. This will minimize having to navigate back and forth through the assessment to find the required information.</li>
								<br />
								<li>We recommend that you use a laptop or desktop for this test. Mobile devices are not recommended. Our preferred browsers are Firefox, Chrome, or Safari.</li>
								<br />
								<li>This assessment requires us to record your video for proctorial purposes. Please allow access to your web camera when prompted by your browser.</li>
								<br />
								<li>This assessment may contain questions which require video submissions. Please make sure that the camera and microphone is working on your device, and that you allow the application access when prompted by browser.</li>
							</ul>
						</div>
					</div>
					<hr className={styles.hr} />
					<div className='test-guidelines-actions'>
						<div className='test-guidelines-pp'>
							<Checkbox
								checked={checked}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
							/>
							<a target="_blank" href={privacyPolicy}><span className='pp-accept'>Accept </span><span className='pp-privacy'>privacy</span> policy</a>
						</div>
						<div className='test-guidelines-start'>
							<Button
								className='text-transform-none'
								size="small"
								variant="contained"
								type='submit'
								disabled={!checked || isDisabled || isLoading}
								onClick={onGoAhead}
							>
								{isLoading ? 'Loading' : resumeStat ? 'Resume' : "Let's begin"}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Dashboard;
