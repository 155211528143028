import React from 'react';

import SignIn from '../components/SignIn';

const SignInPage = () => {
	return (
		<div style={{ background: "#fff", minHeight: "100vh", height: "100%", width: "100%" }}>
			<SignIn />
		</div>
	)
}

export default SignInPage
