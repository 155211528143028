export const planAssessmentCount = {
    'EarlyStage-onetime': {
        workflows: 1,
        assessments: 10
    },
    'EarlyStage-subscribe': {
        workflows: 1,
        assessments: 10
    },
    'Growth-onetime': {
        assessments: 50
    },
    'Growth-subscribe': {
        assessments: 50
    },
    'Rocketship-onetime': {
        assessments: 100
    },
    'Rocketship-subscribe': {
        assessments: 100
    }
};
