import React, { useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { ReactComponent as Next } from "../../../assets/chevronDown.svg";
import { ReactComponent as Prev } from "../../../assets/chevronUp.svg";
import "./VerticalExhibits.css";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-vertical-carousel-component-in-react
 */

const VerticalCarousel = ({ exhibits }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(exhibits.length / 2);

  // Usd to determine the height/spacing of each item
  const itemHeight = 52;

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight;

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2;

  const determinePlacement = (itemIndex) => {

    if (activeIndex === itemIndex) return 0;

    if (itemIndex >= halfwayIndex) {
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex);
      } else {
        return -(exhibits.length + activeIndex - itemIndex);
      }
    }

    if (itemIndex > activeIndex) {
      return (itemIndex - activeIndex);
    }

    if (itemIndex < activeIndex) {
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (exhibits.length - (activeIndex - itemIndex));
      }
      return -(activeIndex - itemIndex);
    }
  };

  const handleClick = (direction) => {
    setActiveIndex((prevIndex) => {
      if (direction === "next") {
        if (prevIndex + 1 > exhibits.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      }

      if (prevIndex - 1 < 0) {
        return exhibits.length - 1;
      }

      return prevIndex - 1;
    });
  };

  return (
    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
          <button
            type="button"
            className="carousel-button prev"
            onClick={() => handleClick("prev")}
          >
            <Prev />
          </button>

            <div style={{width:"15vw", height:"20vh", margin:"0"}}>
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:'center', overflowY:"hidden", height:"20vh"}}>
                {exhibits.map((exhibit, i) => (
                    <img
                      src={exhibit}
                      style={{
                        width: "90%",
                        height: "10vh",
                        maxWidth: "10vw",
                        margin: "1vh 0",
                        cursor: "pointer",
                        transform: `translateY(${determinePlacement(i)*12}vh)`
                      }}
                      
                    key={i}
                    />
                ))}
              </div>
            </div>

          <button
            type="button"
            className="carousel-button next"
            onClick={() => handleClick("next")}
          >
            <Next />
          </button>
    </div>
  );
};

VerticalCarousel.propTypes = {
  data: PropTypes.array.isRequired,
  leadingText: PropTypes.string.isRequired
};

export default VerticalCarousel;
