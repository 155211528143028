const redirectUnauthorisedUser = function(err,history){
  if(err.response && err.response.status == "403"){
    history.push("/signin");
  }
}

const companyCheck = (convo,setConvo2) =>{
  let companyName = localStorage.getItem('company');
  // console.log(companyName)
  if(companyName == "61dbe29f5955c3e41ba62d81"){
     // console.log("BCGDV")
    setConvo2(convo.filter((val) => val.name == "Venture Architect"));
  }
  else if(companyName == "61e487656711f18af5f23de4"){
     //  console.log("Neuraflash")
    setConvo2(convo.filter((val) => val.name == "CM Case Study Assessment"));
  }
  else if(companyName == "61ee5a7e708f79ae133d286d"){
    setConvo2(convo.filter((val) => val.name == "SDR Assessment"));
  }
  else if(companyName == "6203465ce56a113e20d9f53d"){
    setConvo2(convo.filter((val) => val.name == "It's a Breeze"));
  }
  else if(companyName == "620e79c541ecfc97c40cf33e"){
    setConvo2(convo.filter((val) => val.name == "Market Entry"));
  }
  else {
     // console.log("Some other company")
     setConvo2(convo);
  }
}

const helperFunction = {redirectUnauthorisedUser, companyCheck}

export default helperFunction;