import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { useLocation } from 'react-router'
import axios from '../../axios'
import { useHistory } from 'react-router'
// import { Button } from '@material-ui/core'
import Button from '@mui/material/Button';
import { Modal } from 'react-responsive-modal';
import { io } from "socket.io-client";
import ProgressBar from "./Progress"
import ChatBody from './chat_section/ChatBody'
import '../../styles/modal.css'
import useSpeechSynthesis from './useSpeechSynthesis';
import "../../styles/chatboxpage.css"
import { fixedAssessmentData } from "../../constants/fixedAssessmentData"
import Sidebar from './Sidebar'
import Telecommuting from '../../assets/Telecommuting.gif';
// import Finishline from '../../assets/Finishline.gif';
import completedTourImg from '../../assets/Completed-pana.gif';

import { useTour } from '@reactour/tour';

var connectionString = process.env.REACT_APP_BASE_URL
//var connectionString="http://www.localhost:5001"
const Chatbox = forwardRef((props, ref) => {
	const { setIsOpen } = useTour();
	let location = useLocation()
	// console.log(location.state)
	const [modal, setModal] = useState(false);
	const [toSpeak, setToSpeak] = useState(false);
	const videoRef = useRef(null);
	const photoRef = useRef(null);
	const [modal3, setModal3] = useState(false);
	const onOpenModal3 = () => { setModal3(true) };
	const onCloseModal3 = () => setModal3(false);
	const [modal2, setModal2] = useState(false);
	const onOpenModal2 = () => { setModal2(true) };
	const onCloseModal2 = () => setModal2(false);
	let history = useHistory()
	let speakqueue = []
	let [globalRef, setGlobalRef] = useState({ 'na': useRef(null) })
	// console.log(location.state)
	let quesStatement = {
		q: location.state.prob,
		ref: "na",
	}
	let tnc = {
		q: "I will be assessing your application for this role. This assessment will take about 30 minutes in total to complete.::Please use Firefox, Chrome, or Safari, but not Internet Explorer.::This assessment requires us to record your video for proctorial purposes. Please allow access to your web camera when prompted by your browser.::Should you encounter any technical difficulties, please close this tab and visit the link that was shared with you - you will not lose your place in the test. Should you continue to experience difficulties, please email support@hirequotient.com",
		ref: "na",
		qtype: "tac"
	}
	if (location.state.id == "Test") {
		tnc['q'] = "Welcome to the video structuring practice. This exercise consists of 3 individual cases and will take about 30 minutes in total to complete.::::For each case question, you will be given the introduction to the case. You should then spend 2-3 minutes planning out your approach. When ready, start the video recorder, and then spend another 2-3 minutes explaining  your proposed approach to your interviewer. You have the option to re-record your answer one additional time, if needed.::::If you are satisfied with your answer, simply move on to the next question."
	}
	// console.log(quesStatement)
	const [messageBox, setMessageBox] = useState({ new: { messages: [tnc] }, old: { messages: [] } })
	const [caseState, setCaseState] = useState(false)
	//const [text, setText] = useState("");
	const [nextQues, setNextQues] = useState([])
	const [startT, setStartT] = useState(location.state.resume ? (new Date().getTime() - new Date(location.state.startT).getTime()) / 1000 : 0)
	const [seeQues, setSeeQues] = useState(true)
	const [progress, setProgress] = useState(0)
	const [exhibits, setExhibits] = useState([])
	const [stopChat, setStopChat] = useState(false)
	const onClickCaseState = () => {
		setCaseState(!caseState);
	}

	let newMessageBox = [...messageBox.new.messages, ...messageBox.old.messages]

	function addToMessageBox(mssg) {
		// if(mssg[0].qid && mssg[0].qid === "-1"){
		//   setCaseState(false)
		// }

		// console.log(newMessageBox)
		setMessageBox({ new: { messages: mssg }, old: { messages: newMessageBox } })
		let tempExhibits = []
		mssg.forEach(element => {
			if (element?.exhibit) {
				tempExhibits.push(element.exhibit)
			}
		});
		setExhibits([...exhibits, ...tempExhibits])
		newMessageBox = [...mssg, ...newMessageBox];
		if (!mssg[0].isMyMessage) {
			addToSpeakQueue(mssg)
			// console.log(speakqueue)
			if (toSpeak && !speaking && speakqueue.length > 0)
				speaktext(speakqueue.shift())
		}
		else {
			speakqueue = []
			clearTimeout(timeoutResumeInfinity)
			cancel()
		}



	}

	useEffect(() => {
		if (!toSpeak) {
			speakqueue = []
			cancel()
			clearTimeout(timeoutResumeInfinity)

		}
	}, [toSpeak])
	function addToSpeakQueue(mssg) {
		let tmp = []
		for (let i = mssg.length - 1; i >= 0; i--) {
			let totalstring = mssg[i].q.split('.  ')
			// console.log(totalstring)
			speakqueue.push(...totalstring)


		}

	}
	const onEnd = () => {
		clearTimeout(timeoutResumeInfinity);
		// console.log('starting next',speakqueue)
		if (speakqueue.length > 0 && toSpeak)
			speaktext(speakqueue.shift())

	}

	const { speak, cancel, speaking, supported, voices, resume } = useSpeechSynthesis({ onEnd, });
	let timeoutResumeInfinity = null;
	function resumeInfinity() {

		resume();
		timeoutResumeInfinity = setTimeout(resumeInfinity, 1000);
	}
	//const voice = voices[0] || null;
	const pitch = 1;
	const rate = 0.95;


	function speaktext(text) {
		speak({ text, pitch, rate });
		// console.log('has to speak')
		resumeInfinity();
	}
	const addFirstMessage = () => {
		//shutting voice functions
		cancel();
		/*const url = '/userconvo';
		const getData = {
		  user_id : location.state.code,
		  comp: location.state.comp,
		  mode: -1,
		  message1 : location.state.id,
		  message2 : "z",
		};  
		axios.post('/userconvo', JSON.stringify(getData), {headers: {'Content-Type': 'application/json'}})
		.then((res) => {
		  // console.log(res.data);
		  let parsedMssg = res.data.message[0].split(";;").map((ele,index)=>{
			return({
			  q: ele,
			  ref: "na"
			})
		  })
		  parsedMssg[parsedMssg.length-1]["qtype"] = res.data.message[1]
		  parsedMssg[parsedMssg.length-1]["options"] = res.data.message[2]
		  parsedMssg[parsedMssg.length-1]["qid"] = res.data.message[3]
		  parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.message[4]
		  parsedMssg[parsedMssg.length-1]["progress"] = res.data.message[5]
			addToMessageBox(parsedMssg.reverse())
			setCaseState(true)
			setProgress(parseInt(res.data.message[5], 10))
		  })
		  .catch((err) => {
			console.log(err)
		  })*/
		const msgg = fixedAssessmentData[location.state.id][0];
		console.log(msgg);
		let parsedMssg = msgg[0].split(";;").map((ele, index) => {
			return ({
				q: ele,
				ref: "na"
			})
		})
		parsedMssg[parsedMssg.length - 1]["qtype"] = msgg[1]
		parsedMssg[parsedMssg.length - 1]["options"] = msgg[2]
		parsedMssg[parsedMssg.length - 1]["qid"] = msgg[3]
		parsedMssg[parsedMssg.length - 1]["exhibit"] = msgg[4]
		parsedMssg[parsedMssg.length - 1]["progress"] = msgg[5]
		addToMessageBox(parsedMssg.reverse())
		setCaseState(true)
		setProgress(parseInt(msgg[5], 10))


	}

	var socket;


	useEffect(() => {


	}, [videoRef]);

	const [videoOn, setVideoOn] = useState(false)

	const getVideo = () => {
		navigator.mediaDevices
			.getUserMedia({ video: { width: 640 } })
			.then(stream => {
				let video = videoRef.current;
				video.srcObject = stream;
				try {
					video.play();

					socket = io(connectionString, { path: "/socket", query: "email=" + location.state.code });
					socket.on('connect', function () {
						console.log('Websocket Connected with App');
						setVideoOn(true)
					});
					socket.on('disconnect', function (evt) {
						// console.log('errr', evt)
					});
					// console.log(socket)
					paintToCanvas();
				}
				catch (err) {
					console.log(err)
				}
			})
			.catch(err => {
				console.error("error:", err);
				setVideoOn(false)
			});
	};

	var setIntervalId;
	const paintToCanvas = () => {
		let video = videoRef.current;
		let photo = photoRef.current;
		let ctx = photo.getContext("2d");

		const width = 640;
		const height = 480;
		photo.width = width;
		photo.height = height;

		setIntervalId = setInterval(() => {
			ctx.drawImage(video, 0, 0, width, height);
			if (video.srcObject) {
				socket.emit('image', photo.toDataURL());
				//console.log(socket)
			}
			else {
				socket.emit('end', 'sendind end')
				console.log('sending end')
				socket.disconnect()
				clearInterval(setIntervalId);
			}
		}, 1000);
	};
	const stop = () => {


		setVideoOn(false)
		console.log('sending end')
		if (videoRef.current) {
			let video = videoRef.current;
			if (video.srcObject) {

				const stream = video.srcObject;
				const tracks = stream.getTracks();


				for (let i = 0; i < tracks.length; i++) {
					let track = tracks[i];
					track.stop();
				}





				video.srcObject = null;
			}
		}
	}
	useEffect(() => {
		console.log(stopChat)
		if (stopChat)
			stop()
	}, [stopChat])
	useEffect(() => {
		/*if (location.state.resume){
		  props.removeSampleVideoExhibits();
		  onOpenModal2()
		  setMessageBox({new: {messages: [quesStatement]},old:{messages: []}})
		  const getData = {
			user_id : location.state.code,
			comp: location.state.comp,
			mode: 2,
			message1 : '',
			message2 : "z",
		  };  
		axios.post('/userconvo', JSON.stringify(getData), {headers: {'Content-Type': 'application/json'}})
		.then((res) => {
		  // console.log(res.data)
		  if(res.data.msg2.length>0){
			let parsedMssg = res.data.msg2.map((objects)=>{
			  let obj= objects.ques.split(';;').filter(word => word.length >0);
			  let mmssgg = obj.map((ele)=>{
				if (ele!="")
				return({
				  q: ele,
				  ref: "na",
				})
			  })
			  obj= objects.ans.split('::').filter(word => word.length > 0);
			  let mmssgg1=obj.map((ele)=>{
				if (ele!="")
				return({
				  q:ele,
				  ref:'na',
				  isMyMessage:true
				})
			  })
			  obj= objects.resp.split(';;').filter(word => word.length > 0);
			  let mmssgg2=obj.map((ele)=>{
				if (ele!="")
				return({
				  q:ele,
				  ref:'na'
				})
			  })
	
			  return([...mmssgg,...mmssgg1,...mmssgg2])
		  })
			let realMssg = []
			for(let i=0;i<parsedMssg.length;i++){
			  for (let j=0;j<parsedMssg[i].length;j++)
				realMssg.push(parsedMssg[i][j])
			}
			// console.log(realMssg)
	    
		  addToMessageBox(realMssg.reverse())
		}
		  let parsedMssg1 = res.data.msg1[0].split(";;").map((ele,index)=>{
			return({
			  q: ele,
			  ref: "na"
			})
		  })
		  parsedMssg1[parsedMssg1.length-1]["qtype"] = res.data.msg1[1]
		  parsedMssg1[parsedMssg1.length-1]["options"] = res.data.msg1[2]
		  parsedMssg1[parsedMssg1.length-1]["qid"] = res.data.msg1[3]
		  parsedMssg1[parsedMssg1.length-1]["exhibit"] = res.data.msg1[4]
		  parsedMssg1[parsedMssg1.length-1]["progress"] = res.data.msg1[5]
			addToMessageBox(parsedMssg1.reverse())
			setCaseState(true)
			setProgress(parseInt(res.data.msg1[5], 10))
	  })
	  }
	  else{
		// addTnc
		onOpenModal3();
		setMessageBox({new: {messages: [tnc]},old:{messages: []}})
		// setMessageBox([tnc])
		// AddProb
		// addFirstMessage()
				// setCaseState(true)
	  }*/
		onOpenModal3();
		setMessageBox({ new: { messages: [tnc] }, old: { messages: [] } })
	}
		, []);

	const [tourFinishModal, setTourFinishModal] = useState(false);
	const openTourFinishModal = () => { setTourFinishModal(true) };
	const closeTourFinishModal = () => setTourFinishModal(false);

	useImperativeHandle(ref, () => ({
		openTourFinishModal: openTourFinishModal,
		getVideo: getVideo
	}))

	return (
		<div id="background" style={{ display: "flex", background: "#E5E5E5" }}>
			<Sidebar toSpeak={toSpeak} setToSpeak={setToSpeak} exhibits={exhibits} photoRef={photoRef} videoRef={videoRef} videoOn={videoOn} />
			<div style={{ flex: "80vw" }}>
				<ProgressBar messages={messageBox.old} messages1={messageBox.new} startT={startT} addToMessageBox={addToMessageBox} progress={progress} timerState={caseState} setCaseState={setCaseState} setStopChat={setStopChat} toSpeak={toSpeak} setToSpeak={setToSpeak} />
				<ChatBody messages={messageBox} exhibits={exhibits} addToMessageBox={addToMessageBox} nextQues={nextQues} setNextQues={setNextQues} seeQues={seeQues} setSeeQues={setSeeQues} setCaseState={setCaseState} setProgress={setProgress} setStopChat={setStopChat} addFirstMessage={addFirstMessage} setMessageBox={setMessageBox} />

				<Modal open={modal3} onClose={onOpenModal3} closeIcon={() => { }} center styles={{ modal: { borderRadius: "0", padding: "40px", maxWidth: "500px" } }}>

					<div style={{ textAlign: "center" }}>
						<div style={{ margin: '0 0 20px 0' }}>
							<img
								style={{
									width: '300px'
								}}
								src={Telecommuting}
								alt="user-logo"
							/>
						</div>
						<div style={{ color: '#3c3c3c' }}>
							<h1 style={{ color: "inherit" }}>Want to view our quick user guide?</h1>
							<h4 style={{ color: "inherit", margin: "10px 0 30px 0", fontWeight: "500" }}>Familiarise yourself with the interface of the virtual interviewer. Don't worry, this <b>won't include</b> the time for the assessment!</h4>
						</div>
						<div style={{ margin: "10px 0" }}>
							<Button
								size="small"
								variant="contained"
								className="cancel-modal-button"
								style={{
									backgroundImage: 'linear-gradient(to left, #23597C, #008095)',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: '5px 30px',
								}}
								onClick={() => {
									setIsOpen(true)
									onCloseModal3()
								}}
							>
								<span style={{ fontSize: '18px', fontWeight: 'bold', textTransform: 'none' }}>Take tour</span>
							</Button>
						</div>
						<div>
							<a
								href="javascript:void(0)"
								className="cancel-modal-button"
								style={{
									background: 'transparent',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
								}}
								onClick={() => {
									props.removeSampleVideoExhibits();
									onCloseModal3()
									getVideo()
								}}
							>
								<span style={{ color: '#3c3c3c', fontSize: '16px', textDecoration: 'underline' }}>No thanks, I'll take it from here</span>
							</a>
						</div>

					</div>

				</Modal>

				<Modal open={tourFinishModal} onClose={closeTourFinishModal} closeIcon={() => { }} center styles={{ modal: { borderRadius: "0", padding: "40px", maxWidth: "500px" } }}>
					<div style={{ textAlign: "center" }}>
						<div style={{ margin: '0 0 20px 0' }}>
							<img
								style={{
									width: '300px'
								}}
								src={completedTourImg}
								alt="user-logo"
							/>
						</div>
						<div style={{ color: '#3c3c3c' }}>
							<h1 style={{ color: "inherit" }}>You are all set</h1>
							<h4 style={{ color: "inherit", margin: "10px 0 30px 0", fontWeight: "400" }}>Press start to begin your assessment</h4>
						</div>
						<div style={{ margin: "10px 0" }}>
							<Button
								size="small"
								variant="contained"
								className="cancel-modal-button"
								style={{
									backgroundImage: 'linear-gradient(to left, #23597C, #008095)',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: '5px 30px',
								}}
								onClick={() => {
									closeTourFinishModal();
									getVideo();
								}}
							>
								<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Start</span>
							</Button>
						</div>
					</div>
				</Modal>

				<Modal open={modal2} onClose={onOpenModal2} closeIcon={() => { }} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
					<p className="modal-text">
						Shall we resume?
					</p>
					<div className="button-div">
						<Button
							size="small"
							variant="contained"
							className="modal-button"
							onClick={() => {
								onCloseModal2()
								getVideo()
							}}
							style={{
								background: '#479BD2',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								marginLeft: "10px"
							}}
						>
							<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Ok</span>
						</Button>
						<Button
							size="small"
							variant="contained"
							className="cancel-modal-button"
							onClick={() => {
								onCloseModal2()
								setStopChat(true)
								history.goBack();
							}}
							style={{
								background: '#479BD2',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
							}}
						>
							<span style={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>Cancel</span>
						</Button>
					</div>
				</Modal>
			</div>
		</div>
	)
})

export default Chatbox;