import React, { forwardRef, useEffect, useRef, useState } from "react";
import Alert from '@mui/material/Alert';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { Bar, Doughnut } from 'react-chartjs-2';
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CSVLink } from "react-csv";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';
import { Tooltip } from "@material-ui/core";
import { useLocation, useHistory } from "react-router";
import { useTour } from '@reactour/tour';

import AccountMenu from "../accountMenu/AccountMenu";
import axios from "../../axios";
import helperFunction from '../../helper/helperFunction';
import NotificationBell from "../notificationBell/notificationBell";
import { getCandidatesList, getRerender, getIsReportModelOpen, getUserRole } from '../../selectors/app';
import {
	getCandidatesByType as getCandidatesByTypeRequest,
	editCandidateName as editCandidateNameRequest,
	resetSuccessMsg as resetSuccessMsgRequest,
	resetRerender as resetRerenderRequest,
	deleteCandidate as deleteCandidateRequest,
	setReportModel as setReportModelRequest
} from '../../actions/app';
import { useWindowDimensions } from "../../utils/windowUtils";

import Edit from '../../assets/Icons/Edit.png';
import emptyRowImg from '../../assets/emptyrow2.png';
import Subtract from '../../assets/Icons/Subtract.png';
import Trash from '../../assets/Icons/Trash.png';

import "./ReviewApplicants.css";
import { barChartData, doughnutOptions } from "../../constants/reports";
import { doughnutData } from "../../utils/reports";
import { Link } from "react-router-dom";
import AnalyseModal from "../../components/common/AnalyseModal";

const escapeRegExp = (value) => {
	return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const QuickSearchToolbar = (props) => {
	const { value, onChange, clearSearch } = props;

	return (
		<div className="root">
			<div>
				<GridToolbarFilterButton />
			</div>
			<TextField
				variant="standard"
				value={value}
				onChange={onChange}
				placeholder="Search…"
				InputProps={{
					startAdornment: <SearchIcon fontSize="small" />,
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: value ? 'visible' : 'hidden' }}
							onClick={clearSearch}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					),
				}}
			/>
		</div>
	);
}

QuickSearchToolbar.propTypes = {
	clearSearch: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};


const ReviewApplicants = (props) => {
	const {
		candidates,
		getCandidatesByType,
		rerender,
		editCandidateName,
		resetRerender,
		deleteCandidate,
		isReportModelOpen,
		setReportModel,
		notification,
		userRole
	} = props;

	const settings = JSON.parse(localStorage.getItem('settings'))

	let history = useHistory();
	let location = useLocation();
	const { isOpen } = useTour();
	const windowDimensions = useWindowDimensions();

	const [deleteModal, setDeleteModal] = useState(false);
	const [chat, setChat] = useState({});
	const [change, setChange] = useState(0);
	const [data, setData] = useState([]);
	const [rows, setRows] = useState(data);
	const [loading] = useState(false);
	const [CSVData, setCSVData] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [selectionModel, setSelectionModel] = useState([]);
	const [pageSize, setPageSize] = useState(15);
	const [snackOpen, setSnackOpen] = useState(false);
	const [snackErr, setSnackErr] = useState(null);
	const [selectedRow, setSelectedRow] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [successMsg, setSuccessMsg] = useState(null);
	const [greenSnackOpen, setGreenSnackOpen] = useState(false);
	const [totalScore, setTotalScore] = useState(0);
	const [barData, setBarData] = useState({ ...barChartData });

	const editNameRef = useRef(null);
	const doughnutRef = useRef();
	const barChartRef = useRef();

	const onOpenDeleteModal = () => {
		if (selectionModel.length == 0) {
			setSnackErr('Please select atleast one candidate');
			handleSnackClick();
		} else setDeleteModal(true);
	};

	const onCloseDeleteModal = () => setDeleteModal(false);

	const onOpenModal = (e, params) => {
		e.stopPropagation();
		setChat(params.row);
		setTotalScore(parseInt((params.row.test.result.total || 0) * 100));

		const newBarData = { ...barData };

		newBarData.labels = [];
		newBarData.datasets[0].data = [];
		newBarData.datasets[1].data = [];

		if (params.row.test.chat[0].hasOwnProperty('idealqtime')) {
			params.row.test.chat.forEach((item, index) => {
				newBarData.labels.push('Q' + (1 + index));
				newBarData.datasets[0].data.push(item.idealqtime);
				newBarData.datasets[1].data.push(item.userqtime);
			});
		}

		setBarData({ ...newBarData });
	};

	useEffect(() => {
		if (chat.productId) {
			setReportModel(true);
		}
	}, [barData]);

	const onCloseModal = () => setReportModel(false);

	useEffect(() => {
		if (rows.length == 0) return;

		let temp = [];

		rows.map((val) => {
			var score = 0.0;
			var index = 0;

			try {
				if (val.test.result['total'])
					score = val.test.result['total']
				else {
					for (let keys in val.test.result) {
						score += val.test.result[keys]
						index += 1
					}
					if (index > 0) score = score / index;
				}
				if (score > 0) score *= 100;
			}
			catch (err) {
				console.log(err);
			}

			let x = { name: val.name, email: val.email[0], id: val.id, status: val.status, score: score }
			temp.push(x);
		});

		setCSVData(temp);
	}, [rows]);

	useEffect(() => {
		const reqData = {
			status: "review",
			id: location.state.workflowId
		};

		getCandidatesByType({ reqData });
	}, [change]);

	const nameEdit = function (row) {
		editCandidateName(row, editNameRef.current.value)
	};

	const handleDelete = (rowSelected) => {
		const IDs = [];
		rowSelected.map((val) => {
			IDs.push(val._id);
		})
		deleteCandidate(IDs);
	};

	useEffect(() => {
		if (!rerender) return;

		setChange(change + 1);
		resetRerender();

		if (editModal) onCloseEditModal();
		if (deleteModal) onCloseDeleteModal();

	}, [rerender]);

	useEffect(() => {
		if (!candidates) return;

		setData(candidates);
		setRows(candidates);
	}, [candidates]);

	const pdfurl = process.env.REACT_APP_BASE_SERVER_URL_FULL + '/pdfreport'//`https://test.aspiringconsultants.com/api/pdfreport`//`http://localhost:5000/api/pdfreport`

	const pdfDownload = (candidate) => {
		fetch(pdfurl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				candidate: candidate,
			}),
		}).then(async res => {
			if (res.status === 200) {
				const blob = await res.blob();
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `${candidate.name}_${location.state.workflowName}.pdf`;
				link.click();
			}
		})
	}

	const requestSearch = (searchValue) => {
		setSearchText(searchValue);

		if (searchValue == '') {
			setRows(data);
		} else {
			const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
			const filteredRows = data.filter((row) => {
				return Object.keys(row).some((field) => {
					return searchRegex.test(row[field].toString());
				});
			});

			setRows(filteredRows);
		}
	};

	const changeStatus = (status, rowSelected) => {
		if (rowSelected.length == 0) {
			handleSnackClick();
			setSnackErr('Please select atleast one candidate')
			return;
		}

		axios.post('/statusupdatebyid', { candidates: rowSelected, status: status })
			.then(() => {
				setChange(change + 1)
				setSuccessMsg("Status updated successfully");
				handleGreenSnackClick();
				setSelectionModel([]);

				if (isReportModelOpen) onCloseModal();
			})
			.catch(err => {
				helperFunction.redirectUnauthorisedUser(err, history);

				setSnackErr("Could not update status at the moment");
				handleSnackClick();
			})
	}

	const getCandVideo = (key) => {
		axios.post('/cand-video', { key }).then((res) => {
			window.open(res.data.signedUrl)
		}).catch((err) => {
			helperFunction.redirectUnauthorisedUser(err, history);
		})
	}

	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: windowDimensions.width / 14,
			headerAlign: 'center',
			align: 'center',
			renderCell: (params) => {
				return (
					<div style={{ marginRight: "5ch" }}>
						{params.row.id}
					</div>
				)
			}
		},
		{
			field: "candidate",
			headerName: "Name",
			flex: 0.5,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: "16px", fontWeight: "400" }}>
						{params.row.name}
					</div>
				);
			},
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: windowDimensions.width / 6,
			flex: 0.4,
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ fontSize: "16px", fontWeight: "400" }}>
						{params.row.email}
					</div>
				);
			},
		},
		{
			field: "score",
			headerName: "Score",
			width: windowDimensions.width / 15,
			minWidth: 130,
			type: 'number',
			headerAlign: "center",
			valueGetter: (params) => {
				var score = 0.0;
				var index = 0
				try {
					if (params.row.test && params.row.test.result && params.row.test.result.hasOwnProperty('total'))
						score = params.row.test.result.total
					else {
						if (params.row.productId.toLowerCase().includes('sale')) {
							if (params.row.test.result.dab)
								score += 0.2 * params.row.test.result.dab
							if (params.row.test.result.lpg)
								score += 0.2 * params.row.test.result.lpg
							if (params.row.test.result.stk)
								score += 0.1 * params.row.test.result.stk
							if (params.row.test.result.ema)
								score += 0.5 * params.row.test.result.ema
						}
						else {
							for (let keys in params.row.test.result) {
								score += params.row.test.result[keys]
								index += 1
							}
							if (index > 0) score = score / index
						}
					}
					if (score > 0) score *= 100
				} catch {
					console.log("Error in scores");
				}

				return score.toFixed();
			},
			renderCell: (params) => {
				return (
					<div className="userListUser" style={{ width: "100%", textAlign: "center", marginRight: "4ch" }}>
						{params.value}%
					</div>
				);
			},
		},
		{
			field: "report",
			headerName: "Report",
			sortable: false,
			filterable: false,
			headerAlign: 'center',
			align: 'center',
			flex: 0.4,
			renderCell: (params) => {
				return (
					<>
						{
							params.row.test && params.row.test.result && params.row.test.result.hasOwnProperty('total') ? (
								<Button
									className="productView"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
									}}
									size="small"
									variant="contained"
									onClick={(e) => onOpenModal(e, params)}
								>
									<span style={{ fontSize: '14px', color: '#fff' }}>Analyse</span>
								</Button>
							) : (
								<AssessmentOutlinedIcon onClick={(e) => { e.stopPropagation(); pdfDownload(params.row) }} style={{ cursor: "pointer", marginRight: '3ch' }} id="reviewApplicantsFlow-selector-6" />
							)
						}
					</>
				);
			}
		}
	]

	if (settings) {
		if (settings.vidanswer)
			columns.push(
				{
					field: "video",
					headerName: "Video",
					minWidth: 150,
					sortable: false,
					filterable: false,
					headerAlign: 'center',
					align: 'center',
					flex: 0.3,
					renderCell: (params) => {
						return (
							<>
								<Button
									className="productView"
									style={{
										background: '#479BD2',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '25px',
										padding: '5px 12px',
										textTransform: 'none'
									}}
									size="small"
									variant="contained"
								>
									<Link
										to={{
											pathname: "/video-submission",
											state: {
												workflowId: location.state.workflowId,
												workflowName: location.state.workflowName,
												sidebar: "reviewApplicants",
												candidateData: params.row
											}
										}}
										style={{
											fontSize: '14px',
											color: 'inherit',
											textDecoration: 'none'
										}}
									>
										Analyse
									</Link>
								</Button>
							</>
						);
					}
				}
			)
	}

	if (userRole === 'Admin') {
		columns.push(
			{
				field: "action",
				headerName: "Action",
				minWidth: windowDimensions.width / 7,
				headerAlign: 'center',
				align: 'center',
				renderCell: (params) => {
					return (
						<div style={{ marginRight: "4ch" }}>
							<Tooltip title="Shortlist"><IconButton><CheckCircleOutlineOutlinedIcon onClick={(e) => { e.stopPropagation(); changeStatus('Shortlisted', [params.row]) }} className="check-btn" id="reviewApplicantsFlow-selector-7" /></IconButton></Tooltip>
							<Tooltip title="Reject"><IconButton><CancelOutlinedIcon onClick={(e) => { e.stopPropagation(); changeStatus('Rejected', [params.row]) }} className="cancel-btn" id="reviewApplicantsFlow-selector-8" /></IconButton></Tooltip>
							<Tooltip title="Keep in view"><IconButton><RemoveRedEyeOutlinedIcon onClick={(e) => { e.stopPropagation(); changeStatus('Kept In View', [params.row]) }} className="visibility-btn" id="reviewApplicantsFlow-selector-9" /></IconButton></Tooltip>
						</div>
					);
				}
			});
	}

	const handleSnackClick = () => {
		setSnackOpen(true);
	};

	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	useEffect(() => {
		if (selectionModel.length == 0) setSelectedRow([]);
		if (selectionModel.length == 1) setSelectedRow(rows.filter((row) => selectionModel[0] == row.id));
		else {
			let temp = [];

			for (let i = 0; i < selectionModel.length; i++) {
				temp.push(...rows.filter((row) => selectionModel[i] == row.id))
			}

			setSelectedRow(temp);
		}
	}, [selectionModel]);

	const onOpenEditModal = () => {
		if (selectionModel.length == 1) setEditModal(true);
		else {
			setSnackErr('Please select atleast one candidate')
			handleSnackClick();
		}
	};

	const onCloseEditModal = () => setEditModal(false);

	const handleGreenSnackClick = () => {
		setGreenSnackOpen(true);
	};

	const handleGreenSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setGreenSnackOpen(false);
		setSuccessMsg(null);
	};

	const NoRowsOverlay = () => {
		return (
			<div
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%,-50%)",
					textAlign: "center"
				}}
			>
				<img
					src={emptyRowImg}
					alt="no rows"
					style={{
						height: "350px",
						width: "auto"
					}}
				/>
				<p style={{ fontSize: "20px", fontWeight: "700", color: "#334D6E" }}>No data to show</p>
			</div>
		)
	}

	return (
		<div className="productList">
			<div className="inner-div" style={{ marginBottom: '20px' }}>
				<div className='top-icon-box'>
					{
						userRole === 'Admin' ? (
							<IconButton aria-label="edit" onClick={onOpenEditModal} id="reviewApplicantsFlow-selector-3">
								<img src={Edit} alt="" width={30} height={30} />
							</IconButton>
						) : ''
					}
					{
						userRole === 'Admin' ? (
							<IconButton onClick={() => onOpenDeleteModal()} aria-label="delete"><img src={Trash} alt="" width={30} height={30} /></IconButton>
						) : ''
					}
					<NotificationBell notification={notification} />
					<AccountMenu />
				</div >
			</div >
			{
				chat.test && chat.test.result && chat.test.result.hasOwnProperty('total') ? (
					<>
						<Doughnut ref={doughnutRef} data={doughnutData(totalScore)} options={doughnutOptions} style={{ width: 100, height: 100, display: 'none' }} />
						{
							barData.labels.length > 0 ? (
								<Bar ref={barChartRef} data={barData} options={barData.options} style={{ display: 'none' }} />
							) : ''
						}
					</>
				) : ''
			}
			<AnalyseModal
				open={isReportModelOpen}
				onClose={onCloseModal}
				chat={chat}
				changeStatus={changeStatus}
				doughnutRef={doughnutRef}
				barChartRef={barChartRef}
				isOpen={isOpen}
			/>
			<Modal open={deleteModal} onClose={onCloseDeleteModal} center>
				<p className="modal-text">
					Do you want to delete this candidate?
				</p>
				<div className="button-div">
					<Button
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
							handleDelete(selectedRow)
						}}>
						Yes
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "6px 20px",
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseDeleteModal()
						}}>
						No
					</Button>
				</div>
			</Modal>
			<div className="userListTitleContainer" style={{ padding: "0 20px" }}>
				<div className="inner-div-details">
					<h2>Review Applicants</h2>
					<p>{location.state.workflowName}</p>
				</div>
				<div className="search-box" style={{ flex: "1", margin: "0 5px" }}>
					<input type="search" placeholder="Search" onChange={(event) => requestSearch(event.target.value)} />
				</div>
				{
					userRole === 'Admin' ? (
						<div>
							<Tooltip title="Shortlist"><IconButton><CheckCircleOutlineOutlinedIcon onClick={() => { changeStatus('Shortlisted', selectedRow) }} className="check-btn" id="reviewApplicantsFlow-selector-10" /></IconButton></Tooltip>
							<Tooltip title="Reject"><IconButton><CancelOutlinedIcon onClick={() => { changeStatus('Rejected', selectedRow) }} className="cancel-btn" id="reviewApplicantsFlow-selector-11" /></IconButton></Tooltip>
							<Tooltip title="Keep in view"><IconButton><RemoveRedEyeOutlinedIcon onClick={() => { changeStatus('Kept In View', selectedRow) }} className="visibility-btn" id="reviewApplicantsFlow-selector-12" /></IconButton></Tooltip>
						</div>
					) : ''
				}
				<div>
					<Button
						className="inner-div-btn"
						id="btn1"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: "8px 20px",
						}}
						size="small"
						variant="contained"
					>
						{CSVData ? <CSVLink filename="Data.csv" data={CSVData} target="_blank" style={{ color: "white", textDecoration: "none", fontSize: '16px', whiteSpace: 'nowrap' }} id="reviewApplicantsFlow-selector-2">Export  <img src={Subtract} alt="" style={{ height: "18px", width: "18px", transform: `translate(3px, 4px)` }} /></CSVLink> : ''}
					</Button>
				</div>
			</div >
			{
				selectionModel.length < 0 ?
					<div style={{ background: "lightgrey", minHeight: "6vh", padding: "1vh 20px 1vh", display: "flex", justifyContent: "space-between", borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
						<div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center" }}>
							<Button onClick={() => { changeStatus('Withdraw', selectedRow) }} style={{ color: "#fff", background: "#0C95BA ", fontSize: "12px", height: "40px", borderRadius: "25px", padding: "5px 20px", marginRight: "10px" }}>Withdraw</Button>
						</div>
					</div> : ""
			}
			< div style={{ minHeight: '500px', height: 'max-content', padding: '10px', background: '#fff', borderRadius: '25px', overflow: 'hidden' }}>
				<DataGrid
					rows={!isOpen ? rows : [{
						"_id": "61876d63673eccda481f16ef",
						"name": "SD",
						"email": [
							"s@as.com"
						],
						"id": "30",
						"code": "2a10RHuOLbRUdj9qZsEStxo4Xe3Tbem2GnbbGUTwrHkGy3FKJu3KbJ5G",
						"status": "Shortlisted",
						"lastActive": "2021-11-07T06:11:30.544Z",
						"createdDate": "2021-11-07T06:08:35.734Z",
						"company": "61730080f94921c9089ea99f",
						"workflow": "6178704387c9e1c238ae4f58",
						"__v": 0,
						"problem": "You are talking to the Head of Talent Acquisition (TA) at a well-funded Series C startup in the e-commerce space. They have had great success raising funds, and now have aggressive targets to grow the team and expand the business. Over the last 12 months, the team hired 28 people. Their target for the next 12 months is 80. ::The Head of TA is concerned that without significantly revamping the way they approach recruiting, they will not come anywhere close to meeting the hiring targets, which would greatly hinder the business’ ability to grow and scale.::She is seeking your help to ramp up their ability to hire without affecting hiring quality, while keeping the size of the HR team small. In order to meet these targets, she has received a much more generous budget than before to spend on recruiting.",
						"productId": "ChatId4",
						"title": "HR Evaluative"
					}]}
					components={{ NoRowsOverlay }}
					getRowClassName={() =>
						`rowOnHover`
					}
					columns={columns}
					checkboxSelection={userRole === 'Admin'}
					componentsProps={{
						toolbar: {
							value: searchText,
							onChange: (event) => requestSearch(event.target.value),
							clearSearch: () => requestSearch(''),
						},
					}}
					onSelectionModelChange={(newSelectionModel) => {
						setSelectionModel(newSelectionModel);
					}}
					selectionModel={selectionModel}
					autoHeight
					pageSize={pageSize}
					onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
					rowsPerPageOptions={[5, 10, 15]}
					style={{ marginBottom: "2vh", border: "none" }}
					loading={loading}
					className="dataGridScroll"
				/>
			</div >
			<Modal open={editModal} onClose={onCloseEditModal} center>
				<p className="modal-text">
					Edit candidate's name
				</p>
				{selectedRow.length == 1 ?
					(<>
						<div className="input-div-workflow">
							<label style={{ margin: '0 15px', fontWeight: 'bold', fontSize: '18px' }}>New name</label><br />
							<input
								id="reviewApplicantsFlow-selector-4"
								type="text"
								ref={editNameRef}
								defaultValue={selectedRow[0].name}
								placeholder="Name"
								style={{ "background": "#F2F2F2", "borderRadius": "30px" }}
								required
							/>
						</div>
					</>)
					: 'Loading'}
				<div className="button-div">
					<Button
						id="reviewApplicantsFlow-selector-5"
						className="modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							nameEdit(selectedRow[0])
						}}>
						<span style={{ fontSize: '16px' }}>Ok</span>
					</Button>
					<Button
						className="cancel-modal-button"
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px'
						}}
						size="small"
						variant="contained"
						onClick={() => {
							onCloseEditModal()
						}}>
						<span style={{ fontSize: '16px' }}>Cancel</span>
					</Button>
				</div>
			</Modal>
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose}>
				<Alert onClose={handleSnackClose} variant="filled" severity="error" sx={{ width: '100%' }}>
					{snackErr ? snackErr : ''}
				</Alert>
			</Snackbar>

			<Snackbar open={greenSnackOpen} autoHideDuration={2500} onClose={handleGreenSnackClose}>
				<Alert onClose={handleGreenSnackClose} variant="filled" severity="success" sx={{ width: '100%' }}>
					{successMsg ? successMsg : ''}
				</Alert>
			</Snackbar>
		</div >
	);
};

const mapStateToProps = createStructuredSelector({
	candidates: getCandidatesList(),
	rerender: getRerender(),
	isReportModelOpen: getIsReportModelOpen(),
	userRole: getUserRole()
});

const mapDispatchToProps = (dispatch) => ({
	getCandidatesByType: (data) => dispatch(getCandidatesByTypeRequest(data)),
	editCandidateName: (row, newName) => dispatch(editCandidateNameRequest(row, newName)),
	resetSuccessMsg: () => dispatch(resetSuccessMsgRequest()),
	resetRerender: () => dispatch(resetRerenderRequest()),
	deleteCandidate: (data) => dispatch(deleteCandidateRequest(data)),
	setReportModel: (data) => dispatch(setReportModelRequest(data))
});

ReviewApplicants.propTypes = {
	candidates: PropTypes.array,
	getCandidatesByType: PropTypes.func,
	rerender: PropTypes.number,
	editCandidateName: PropTypes.func,
	resetRerender: PropTypes.func,
	deleteCandidate: PropTypes.func,
	isReportModelOpen: PropTypes.bool,
	setReportModel: PropTypes.func,
	notification: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewApplicants);