import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Error from '../../components/common/Error';
import Loader from '../../components/common/Loader';
import { getAuthErrorMsg, getAuthLoading, getAuthSuccessMsg } from '../../selectors/app';
import {
    freeUserSignUp as freeUserSignUpRequest,
    setAuthErrorMsg as setAuthErrorMsgRequest,
    setAuthSuccessMsg as setAuthSuccessMsgRequest,
} from '../../actions/app';
import { validateEmail } from '../../utils/validation/validateUtils';

import LogoWhite from '../../assets/logo-white.png';

import '../../styles/freeApp/signUp.scss'
import { Box } from '@mui/system';

const SignIn = (props) => {
    const { freeUserSignUp, setAuthErrorMsg, isLoading, errorMsg, successMsg, setAuthSuccessMsg } = props;

    const [email, setEmail] = useState('');

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const onSubmitSignUp = () => {
        setAuthErrorMsg('');
        setAuthSuccessMsg('');

        const data = {
            email
        };
        const errorResult = validateEmail(email);

        if (!errorResult.isValid) {
            Object.keys(errorResult.errors).forEach((item) => {
                if (item) {
                    setAuthErrorMsg(errorResult.errors[item]);
                    return;
                }
            });
        } else if (!validEmail()) {
            setAuthErrorMsg('Enter valid business email');
        } else {
            freeUserSignUp(data);
        }
    }

    const validEmail = () => {
        let valid = true;
        const emailDomain = email.split('@')[1];
        const blacklist = [
            'gmail.com',
            'yahoo.com',
            'outlook.com',
            'hotmail.com',
            'aol.com',
            'barraiser.com',
            'skillsetech.com'
        ]

        blacklist.forEach((item) => {
            if (item === emailDomain) {
                valid = false;
                return;
            }
        });

        return valid;
    }

    return (
        <div id="signUpPage">
            <div style={{ width: 500, borderRadius: 40, overflow: 'hidden', boxShadow: "10px 15px 5px 0px #00000040" }}>
                <div style={{ backgroundColor: '#0D7072', textAlign: 'center' }}>
                    <img src={LogoWhite} alt="" style={{ height: 80 }} />
                </div>
                <div style={{ padding: '40px 25px 60px', textAlign: 'center' }}>
                    <div style={{ fontSize: 24, fontWeight: '600', color: '#656565', textAlign: 'center' }}>Create your assessment now</div>
                    <div style={{ marginTop: 40 }}>
                        <input type='email' placeholder='Business email' value={email} onChange={onChangeEmail} />
                    </div>
                    <div className="modal-checkbox">
                        <label>I have read and accepted the terms of use<input type="checkbox" name="termsofuse"
                            value="termsofuse" /><span className="checkmark"></span></label>
                    </div>
                    <div className="modal-checkbox">
                        <label>Subscribe to our newsletter<input type="checkbox" name="newsletter"
                            value="newsletter" /><span className="checkmark"></span></label>
                    </div>
                    <div style={{ borderRadius: 12, backgroundColor: '#0D7072', color: '#fff', fontSize: 22, fontWeight: '700', padding: '20px 0', marginTop: 40, cursor: 'pointer' }} onClick={onSubmitSignUp}>Sign Up</div>
                    <Link
                        to={'/free/signin'}
                        style={{ marginTop: 16, color: '#565656', fontSize: 22, fontWeight: '600', display: 'inline-flex', justifyContent: 'center' }}>Log In</Link>
                </div>
            </div>
            <div style={{ minHeight: "55px", margin: "1rem" }}>
                {isLoading ? <Loader /> : ''}
                {errorMsg !== '' ? <Error errorMsg={errorMsg} /> : ''}
                {
                    successMsg !== '' ? (
                        <div>
                            <Box align='center' mt={2} mb={2} style={{ display: 'block' }}>
                                <h3 style={{ color: 'green', textAlign: 'center' }}> {successMsg}</h3>
                            </Box>
                        </div>
                    ) : ''
                }
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    isLoading: getAuthLoading(),
    errorMsg: getAuthErrorMsg(),
    successMsg: getAuthSuccessMsg()
});

const mapDispatchToProps = (dispatch) => ({
    freeUserSignUp: (data) => dispatch(freeUserSignUpRequest(data)),
    setAuthErrorMsg: (data) => dispatch(setAuthErrorMsgRequest(data)),
    setAuthSuccessMsg: (data) => dispatch(setAuthSuccessMsgRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
