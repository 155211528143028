import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { MenuItem, Select } from '@mui/material';
import { useHistory } from 'react-router-dom';

import Header from './Header';
import Sidebar from './Sidebar';
import AssessmentData from '../../constants/assessmentData';
import { submitAssessment as submitAssessmentRequest } from '../../actions/app';

import '../../styles/freeApp/createAssessment.scss';

const CreateAssessment = (props) => {
    const { submitAssessment } = props;

    const history = useHistory();

    const [openModal, setOpenModal] = useState(false);
    const [chatRole, setChatRole] = useState('Project Manager');
    const [jobRole, setJobRole] = useState('');
    const [name, setName] = useState('');
    const [currentTab, setCurrentTab] = useState(0);
    const [workFunctionMenu, setWorkFunctionMenu] = useState([]);
    const [workFunctionSelected, setWorkFunctionSelected] = useState([]);
    const [keyTaskMenu, setKeyTaskMenu] = useState({});
    const [keyTaskSelected, setKeyTaskSelected] = useState({});

    const onOpenModal = () => {
        setOpenModal(true);
    }

    const onCloseModal = () => {
        setOpenModal(false);
    }

    const onChangeRole = (e) => {
        setChatRole(e.target.value)
    }

    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onConfirm = () => {
        history.push({
            pathname: '/demobox',
            state: {
                id: chatRole,
                wf: 'dummy',
                comp: 'dummy',
                startT: (new Date()).getTime(),
                code: 'dummy',
                heading: chatRole,
                prob: 'na',
                resume: 'false'
            }
        });
    }

    const goBackFirst = () => {
        setCurrentTab(0);
    }

    const goSecondStep = () => {
        if (name !== '' && jobRole !== '') {
            if (jobRole !== 'Consulting') {
                setCurrentTab(1);

                if (workFunctionSelected.length === 0) {
                    const workFunctionMenuArr = [];
                    const workFunctionSelectedArr = [];

                    Object.keys(AssessmentData[jobRole]).forEach((item) => {
                        workFunctionMenuArr.push(item);
                        workFunctionSelectedArr.push(item);
                    });

                    setWorkFunctionMenu([...workFunctionMenuArr]);
                    setWorkFunctionSelected([...workFunctionSelectedArr]);
                }
            } else {
                setCurrentTab(3);
            }
        }
    }

    const goBackSecond = () => {
        setCurrentTab(1);
    }

    const goThirdStep = () => {
        if (workFunctionSelected.length > 0) {
            setCurrentTab(2);

            if (Object.keys(keyTaskSelected).length === 0) {
                const keyTaskMenuArr = {};
                const keyTaskSelectedArr = {};

                workFunctionSelected.forEach((item) => {
                    keyTaskMenuArr[item] = [];
                    keyTaskSelectedArr[item] = [];
                    AssessmentData[jobRole][item].forEach((val) => {
                        keyTaskMenuArr[item].push(val);
                        keyTaskSelectedArr[item].push(val);
                    });
                });

                setKeyTaskMenu({ ...keyTaskMenuArr });
                setKeyTaskSelected({ ...keyTaskSelectedArr });
            } else {
                const newKeyTaskSelected = { ...keyTaskSelected };
                const newKeyTaskMenu = { ...keyTaskMenu };
                workFunctionSelected.forEach((item) => {
                    if(!newKeyTaskSelected[item]) {
                        newKeyTaskSelected[item] = [];
                        AssessmentData[jobRole][item].forEach((val) => {
                            newKeyTaskSelected[item].push(val);
                        });
                    }
                    if(!newKeyTaskMenu[item]) {
                        newKeyTaskMenu[item] = [];
                        AssessmentData[jobRole][item].forEach((val) => {
                            newKeyTaskMenu[item].push(val);
                        });
                    }
                });
                
                setKeyTaskSelected({ ...newKeyTaskSelected });
                setKeyTaskMenu({ ...newKeyTaskMenu });
            }
        }
    }

    const goBackThird = () => {
        setCurrentTab(2);
    }

    const goLastStep = () => {
        if (Object.keys(keyTaskSelected).length > 0) {
            setCurrentTab(3);
        }
    }

    const onRemoveWorkFunction = (val) => {
        const newWorkFunctionSelected = [...workFunctionSelected];
        let index = 0;
        workFunctionSelected.forEach((item, i) => {
            if (item === val) {
                index = i;
                return;
            }
        });
        newWorkFunctionSelected.splice(index, 1);

        setWorkFunctionSelected([...newWorkFunctionSelected]);
    }

    const onRemoveKeyTask = (val, currentWf) => {
        const newKeyTaskSelected = { ...keyTaskSelected };
        let index = 0;
        keyTaskSelected[workFunctionSelected[currentWf]].forEach((item, i) => {
            if (item === val) {
                index = i;
                return;
            }
        });
        newKeyTaskSelected[workFunctionSelected[currentWf]].splice(index, 1);

        if (newKeyTaskSelected[workFunctionSelected[currentWf]].length === 0) {
            delete newKeyTaskSelected[workFunctionSelected[currentWf]];
        }

        setKeyTaskSelected({ ...newKeyTaskSelected });
    }

    const onChangeJobRole = (e) => {
        setJobRole(e.target.value);
    }

    const onAddWorkFunction = (val) => {
        const newWorkFunctionSelected = [...workFunctionSelected];
        newWorkFunctionSelected.push(val);

        setWorkFunctionSelected([...newWorkFunctionSelected]);
    }

    const onAddKeyTask = (val, currentWf) => {
        const newKeyTaskSelected = { ...keyTaskSelected };
        if (!newKeyTaskSelected[workFunctionSelected[currentWf]]) {
            newKeyTaskSelected[workFunctionSelected[currentWf]] = [];
        }
        newKeyTaskSelected[workFunctionSelected[currentWf]].push(val);

        setKeyTaskSelected({ ...newKeyTaskSelected });
    }

    const onSubmit = () => {
        const data = {
            name,
            createdDate: new Date(),
            role: jobRole,
            workFunction: jobRole !== 'Consulting' ? workFunctionSelected : {},
            keyTask: jobRole !== 'Consulting' ? keyTaskSelected : {},
        };

        submitAssessment({ assessmentData: data, history });
    }

    return (
        <div id="createAssessmentPage">
            <Header showFreeTrial={true} showCreateBtn={false} onOpenFreeTrialModal={onOpenModal} />
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <Sidebar />
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', padding: '10px 25px', boxSizing: 'border-box', height: 'calc(100vh - 102px)', overflowY: 'auto' }}>
                    <div style={{ marginTop: 10, color: '#014954', fontSize: 28, fontWeight: '500', textAlign: 'center' }}>Create New Assessment</div>
                    <div className='create-assessment-steps'>
                        <div className={`create-assessment-step-item ${currentTab > -1 ? ' showBlue' : ''}`} style={{ pointerEvents: currentTab > 0 ? 'all' : 'none' }} onClick={goBackFirst}>
                            <div className='sequence-step'>1</div>
                            <div>Name your assessment</div>
                        </div>
                        <div className={`create-assessment-step-item ${currentTab > 0 ? ' showBlue' : ''}`} style={{ pointerEvents: currentTab > 1 ? 'all' : 'none' }} onClick={goBackSecond}>
                            <div className='sequence-step'>2</div>
                            <div>Select critical work functions</div>
                        </div>
                        <div className={`create-assessment-step-item ${currentTab > 1 ? ' showBlue' : ''}`} style={{ pointerEvents: currentTab > 2 ? 'all' : 'none' }} onClick={goBackThird}>
                            <div className='sequence-step'>3</div>
                            <div>Select key tasks</div>
                        </div>
                        <div className={`create-assessment-step-item ${currentTab > 2 ? ' showBlue' : ''}`}>
                            <div className='sequence-step'>4</div>
                            <div>Review &amp; submit</div>
                        </div>
                    </div>
                    {
                        currentTab === 0 ? (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                <div style={{ width: 400, padding: '25px 15px', boxShadow: '2px 3px 9px 0px #00000040', borderRadius: 24, boxSizing: 'border-box' }}>
                                    <div style={{ fontSize: 20, color: '#014954', fontWeight: 500 }}>Name your assessment</div>
                                    <div style={{ marginTop: 35 }}>
                                        <input type='text' onChange={onNameChange} value={name} style={{ outline: 'none', width: '100%', border: '1px solid #949494', padding: '10px 30px', fontSize: 16, color: '#949494', fontFamily: 'inherit', boxSizing: 'border-box', borderRadius: 12 }} placeholder='Assessment name' />
                                    </div>
                                    <div style={{ fontSize: 20, color: '#014954', fontWeight: 500, marginTop: 20 }}>Select Role</div>
                                    <Select
                                        className="remove-bg"
                                        onChange={onChangeJobRole}
                                        style={{ height: 45, marginTop: 20, width: '100%', background: "#F2F2F2", borderRadius: 12, padding: '0 18px' }}
                                        displayEmpty
                                        value={jobRole}
                                    >
                                        {/* <MenuItem selected>Select Role</MenuItem>
                                        {jobRole} */}
                                        <MenuItem value="Project Manager">Project Manager</MenuItem>
                                        <MenuItem value="Consulting">Consulting</MenuItem>
                                        <MenuItem value="Sales">Sales</MenuItem>
                                    </Select>
                                </div>
                                <div style={{ marginTop: 40, color: '#fff', backgroundColor: '#479BD2', fontSize: 16, fontWeight: 500, padding: '8px 24px', borderRadius: 8, cursor: 'pointer', boxSizing: 'border-box' }} onClick={goSecondStep}>Next Step</div>
                            </div>
                        ) : ''
                    }
                    {
                        currentTab === 1 ? (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                <div style={{ flexDirection: 'row', display: 'flex' }}>
                                    <div style={{ width: 350, padding: '25px 15px', boxShadow: '2px 3px 9px 0px #00000040', borderRadius: 24, boxSizing: 'border-box' }}>
                                        <div style={{ fontSize: 20, color: '#014954', fontWeight: 500 }}>Critical work functions</div>
                                        <div style={{ marginTop: 35 }}>
                                            <span style={{ fontWeight: 600 }}>Role: </span> {jobRole}
                                        </div>
                                    </div>
                                    {
                                        workFunctionMenu.length > 0 ? (
                                            <div style={{ marginLeft: 60, width: 350 }}>
                                                <div style={{ marginBottom: 20, fontSize: 16, textAlign: 'center' }}>Deselect the ones not relevant for your role</div>
                                                {
                                                    workFunctionMenu.map((wfm) => (
                                                        <div key={wfm} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, backgroundColor: ((workFunctionSelected.indexOf(wfm) > -1) ? '#E9F6FF' : '#f5f5f5'), padding: '6px 18px', color: '#010101', fontSize: 12, justifyContent: 'space-between', alignItems: 'center', borderRadius: 20 }}>
                                                            <div onClick={() => onAddWorkFunction(wfm)} style={{ cursor: workFunctionSelected.indexOf(wfm) > -1 ? 'default' : 'pointer', display: 'flex', flex: 1 }}>{wfm}</div>
                                                            {
                                                                workFunctionSelected.indexOf(wfm) > -1 ? (
                                                                    <div style={{ marginLeft: 10, fontSize: 16, cursor: 'pointer' }} onClick={() => onRemoveWorkFunction(wfm)}>x</div>
                                                                ) : ''
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        ) : ''
                                    }
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ marginTop: 40, color: '#fff', backgroundColor: '#479BD2', fontSize: 16, fontWeight: 500, padding: '8px 24px', borderRadius: 8, cursor: 'pointer', marginRight: 25 }} onClick={goBackFirst}>Back</div>
                                    <div style={{ marginTop: 40, color: '#fff', backgroundColor: '#479BD2', fontSize: 16, fontWeight: 500, padding: '8px 24px', borderRadius: 8, cursor: 'pointer', boxSizing: 'border-box' }} onClick={goThirdStep}>Next Step</div>
                                </div>
                            </div>
                        ) : ''
                    }
                    {
                        currentTab === 2 ? (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 50 }}>
                                {
                                    workFunctionSelected.length > 0 ? (
                                        workFunctionSelected.map((wfs, i) => (
                                            <div key={wfs} style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 30 }}>
                                                <div style={{ width: 350, padding: '25px 15px', boxShadow: '2px 3px 9px 0px #00000040', borderRadius: 24, boxSizing: 'border-box' }}>
                                                    <div style={{ fontSize: 20, color: '#014954', fontWeight: 500 }}>Select Key tasks</div>
                                                    <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <span style={{ fontWeight: 600, fontSize: 72, color: '#e7e7e7' }}>{i + 1}</span> {wfs}
                                                    </div>
                                                </div>
                                                {
                                                    keyTaskMenu[wfs].length > 0 ? (
                                                        <div style={{ marginLeft: 60, width: 350 }}>
                                                            <div style={{ marginBottom: 20, fontSize: 16, textAlign: 'center' }}>Deselect the ones not relevant for your role</div>
                                                            {
                                                                keyTaskMenu[wfs] ? keyTaskMenu[wfs].map((ktm) => (
                                                                    <div key={ktm} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, backgroundColor: ((keyTaskSelected[wfs].indexOf(ktm) > -1) ? '#E9F6FF' : '#f5f5f5'), padding: '6px 18px', color: '#010101', fontSize: 12, justifyContent: 'space-between', alignItems: 'center', borderRadius: 20 }}>
                                                                        <div onClick={() => onAddKeyTask(ktm, i)} style={{ cursor: keyTaskSelected[wfs].indexOf(ktm) > -1 ? 'default' : 'pointer', display: 'flex', flex: 1 }}>{ktm}</div>
                                                                        {
                                                                            keyTaskSelected[wfs].indexOf(ktm) > -1 ? (
                                                                                <div style={{ marginLeft: 10, fontSize: 16, cursor: 'pointer' }} onClick={() => onRemoveKeyTask(ktm, i)}>x</div>
                                                                            ) : ''
                                                                        }
                                                                    </div>
                                                                )) : ''
                                                            }
                                                        </div>
                                                    ) : ''
                                                }
                                            </div>
                                        ))
                                    ) : ''
                                }
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ marginTop: 40, color: '#fff', backgroundColor: '#479BD2', fontSize: 16, fontWeight: 500, padding: '8px 24px', borderRadius: 8, cursor: 'pointer', marginRight: 25 }} onClick={goBackSecond}>Back</div>
                                    <div style={{ marginTop: 40, color: '#fff', backgroundColor: '#479BD2', fontSize: 16, fontWeight: 500, padding: '8px 24px', borderRadius: 8, cursor: 'pointer' }} onClick={goLastStep}>Next Step</div>
                                </div>
                            </div>
                        ) : ''
                    }
                    {
                        currentTab === 3 ? (
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, marginTop: 50 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', width: 'calc(100vw - 400px)', overflowX: 'auto', boxSizing: 'border-box', padding: '0 10px' }}>
                                    <div style={{ minHeight: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <div style={{ fontSize: 18, fontWeight: '700', color: '#014954' }}>Role:</div>
                                        <div style={{ fontSize: 18, fontWeight: '500', color: '#014954' }}>{jobRole}</div>
                                    </div>
                                    {
                                        workFunctionSelected.map((wfs, i) => (
                                            <div key={wfs} style={{ width: 350, padding: '15px 15px 25px', boxShadow: '2px 3px 9px 0px #00000040', borderRadius: 24, marginLeft: 40, minWidth: 350 }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <span style={{ fontWeight: 600, fontSize: 72, color: '#e7e7e7', lineHeight: 1 }}>{i + 1}</span> {wfs}
                                                </div>
                                                <div>
                                                    {
                                                        keyTaskSelected[wfs] ? keyTaskSelected[wfs].map((kts) => (
                                                            <div key={kts} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, backgroundColor: '#E9F6FF', padding: '6px 18px', color: '#010101', fontSize: 12, justifyContent: 'space-between', alignItems: 'center', borderRadius: 20 }}>
                                                                <div>{kts}</div>
                                                            </div>
                                                        )) : ''
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ marginTop: 40, color: '#fff', backgroundColor: '#479BD2', fontSize: 16, fontWeight: 500, padding: '8px 24px', borderRadius: 8, cursor: 'pointer', marginRight: 25 }} onClick={goBackThird}>Back</div>
                                    <div style={{ marginTop: 40, color: '#fff', backgroundColor: '#479BD2', fontSize: 16, fontWeight: 500, padding: '8px 24px', borderRadius: 8, cursor: 'pointer' }} onClick={onSubmit}>Submit</div>
                                </div>
                            </div>
                        ) : ''
                    }
                </div>
            </div>
            <Modal open={openModal} onClose={onCloseModal} center>
                <div style={{ width: 400, padding: 25 }}>
                    <div style={{ fontSize: 20, fontWeight: '700' }}>Select Role</div>
                    <Select
                        className="remove-bg"
                        onChange={onChangeRole}
                        style={{ height: 45, marginTop: 20, width: '100%', background: "#F2F2F2", borderRadius: 30 }}
                        displayEmpty
                    >
                        {/* <MenuItem selected>Select Role</MenuItem> */}
                        <MenuItem value="Project Manager">Project Manager</MenuItem>
                        <MenuItem value="Consulting">Consulting</MenuItem>
                        <MenuItem value="Sales">Sales</MenuItem>
                    </Select>
                    <div style={{ display: 'inline-flex', justifySelf: 'flex-end', marginTop: 25, backgroundColor: '#F0F7FF', color: '#197BBD', fontSize: 14, fontWeight: 700, padding: '4px 12px', cursor: 'pointer', borderRadius: 20 }} onClick={onConfirm}>Confirm</div>
                </div>
            </Modal>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
    submitAssessment: (data) => dispatch(submitAssessmentRequest(data))
});

CreateAssessment.propTypes = {
    submitAssessment: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAssessment);
