import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Doughnut, Bar } from 'react-chartjs-2';

import { barChartData, doughnutOptionsAnimated, percentageColor, reportGrade, reportWorkFunctions } from '../../constants/reports';
import { doughnutData, getBucket } from '../../utils/reports';

import icon1 from '../../assets/icons1.png';
import icon2 from '../../assets/icons2.png';

const ReportTab = (props) => {
	const { setCurrentReportTab, candidateData } = props;
	const totalScore = parseInt((candidateData.test.result.total || 0) * 100);
	const barData = { ...barChartData };

	barData.labels = [];
	barData.datasets[0].data = [];
	barData.datasets[1].data = [];

	if (candidateData.test.chat[0].hasOwnProperty('idealqtime')) {
		candidateData.test.chat.forEach((item, index) => {
			barData.labels.push('Q' + (1 + index));
			barData.datasets[0].data.push(item.idealqtime);
			barData.datasets[1].data.push(item.userqtime);
		});
	}

	const getSkills = (wf) => {
		if(wf.associatedSkills) {
			const associatedSkills = wf.associatedSkills.map((as) => as.title);
			return associatedSkills.join(', ');
		} else {
			return wf.skills;
		}
	}

	return (
		<div style={{ paddingRight: "10px", position: "relative" }}>
			<div id='scroll-down-animation'>
				<p>scroll down</p>
			</div>
			<div style={{ borderBottom: "1px solid rgb(150,150,150)", padding: "15px 0" }}>
				<h2>Performance overview</h2>
			</div>
			<div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
				<div style={{ height: "240px", padding: "20px", border: "1px solid rgb(150,150,150)", borderRadius: "15px" }}>
					<p style={{ marginBottom: "20px", textAlign: "center", fontWeight: "600", fontSize: "16px", color: "#7d7d7d" }}>Total Score</p>
					<div style={{ position: "relative" }}>
						<div style={{ position: "absolute", top: "50%", left: "50%", textAlign: "center", transform: "translate(-50%,-50%)" }}>
							<p style={{ fontSize: "25px", fontWeight: "800", color: percentageColor[getBucket(totalScore)] }}>{totalScore}%</p>
							<p style={{ fontWeight: "500", fontSize: "16px", color: "#696969" }}>{reportGrade[getBucket(totalScore)]}</p>
						</div>
						<Doughnut data={doughnutData(totalScore)} options={doughnutOptionsAnimated} style={{ width: "200px", height: "200px" }} />
					</div>
				</div>
				<div style={{ flex: "1", margin: "0 15px" }}>
					{/* <div style={{ color: "#7d7d7d", fontSize: "14px", fontWeight: "700", display: "flex", justifyContent: "flex-end" }}>
						<div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", margin: "0 10px" }}>
							<div style={{ width: "15px", height: "15px", background: "#479BD2", marginRight: "5px" }}>&nbsp;</div>
							<span>Functional skill</span>
						</div>
						<div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", margin: "0 10px" }}>
							<div style={{ width: "15px", height: "15px", background: "#F88E2A", marginRight: "5px" }}>&nbsp;</div>
							<span>Core skill</span>
						</div>
					</div> */}
					{Object.keys(candidateData.test.result).map((value, index) => {
						if (value !== 'total' && reportWorkFunctions[value.toLowerCase()]) {
							const title = reportWorkFunctions[value.toLowerCase()].label;
							const skillScore = parseInt(candidateData.test.result[value] * 100);
							return (
								<div style={{ marginTop: "20px" }} key={value.title}>
									<div style={{ display: "flex", justifyContent: "flex-end" }}>
										<div style={{ width: "400px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
											<img src={index % 2 === 0 ? icon1 : icon2} alt="" style={{ width: "35px", height: "35px", marginRight: "20px" }} />
											<div style={{ flex: "1" }}>
												<p>{title}</p>
												<div style={{ display: "flex", alignItems: "center" }}>
													<div style={{ position: "relative", width: "80%", height: "10px" }}>
														<div style={{ position: "absolute", width: "100%", height: "10px", background: "#d4d4d4", borderRadius: "15px" }}></div>
														<div style={{ position: "absolute", width: `${skillScore}%`, height: "10px", background: percentageColor[getBucket(skillScore)], borderRadius: "15px" }}></div>
													</div>
													<span style={{ fontSize: "18px", fontWeight: "500", color: "#7d7d7d", paddingLeft: "15px", maxWidth: "20%", boxSizing: "border-box" }}>{skillScore}%</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							)
						} else {
							return '';
						}
					})}
				</div>
			</div>
			<div style={{ borderBottom: "1px solid rgb(150,150,150)", padding: "15px 0", marginTop: "20px" }}>
				<h2>Category breakdown</h2>
			</div>
			<div style={{ marginTop: "20px" }}>
				<h2 style={{ color: "#479BD2" }}>Functional skills</h2>
			</div>
			<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
				{Object.keys(candidateData.test.result)
					.map(val => {
						if (val !== 'total') {
							const title = reportWorkFunctions[val.toLowerCase()].label;
							const skillScore = parseInt(candidateData.test.result[val] * 100);
							const performance = reportGrade[getBucket(skillScore)];
							const remarks = reportWorkFunctions[val.toLowerCase()].remarks[getBucket(skillScore)];
							const skills = getSkills(reportWorkFunctions[val.toLowerCase()]);

							return (
								<div style={{ width: "45%", boxSizing: "border-box", padding: "20px", margin: "15px 0", borderRadius: "30px", boxShadow: "5px 5px 10px 5px rgba(0,0,0,0.2)" }} key={title}>
									<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
										<p style={{ color: "#479BD2", fontWeight: "600", fontSize: "14px" }}>{title}</p>
										<div style={{ position: "relative" }}>
											<p style={{ position: "absolute", top: "50%", left: "50%", textAlign: "center", transform: "translate(-40%,-30%)", fontSize: "16px", color: "#504848", fontWeight: "600" }}>{skillScore}%</p>
											<Doughnut data={doughnutData(skillScore)} options={doughnutOptionsAnimated} style={{ width: "80px", height: "80px" }} />
										</div>
									</div>
									<div style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
										<div style={{ marginRight: "15px", color: "#479BD2" }}>&#10148;</div>
										<p style={{ fontSize: "12px" }}>{skills}</p>
									</div>
									<div style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
										<div style={{ marginRight: "15px", color: "#479BD2" }}>&#10148;</div>
										<p style={{ fontSize: "12px" }}>Candidate's performance: {performance}</p>
									</div>
									<div style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
										<div style={{ marginRight: "15px", color: "#479BD2" }}>&#10148;</div>
										<p style={{ fontSize: "12px" }}>{remarks}</p>
									</div>
								</div>
							)
						} else {
							return '';
						}
					})
				}
			</div>
			{/* <div style={{ marginTop: "40px" }}>
				<h2 style={{ color: "#479BD2" }}>Core skills</h2>
			</div>
			<div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
				{[
					{ title: 'Written communication', value: written, remarks: ['Associated skills: Business development, business need analysis, market research', 'Candidate’s performance: Average', 'The candidate was able to identify some, but not all, critical aspects of the ideal customer profile.'] },
					{ title: 'Problem solving', value: problem, remarks: ['Associated skills: Business development, business need analysis, market research', 'Candidate’s performance: Average', 'The candidate was able to identify some, but not all, critical aspects of the ideal customer profile.'] },
				]
					.map(val => (
						<div style={{ width: "45%", boxSizing: "border-box", padding: "20px", margin: "15px 0", borderRadius: "30px", boxShadow: "5px 5px 10px rgba(0,0,0,0.2)" }}>
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
								<p style={{ color: "#479BD2", fontWeight: "600", fontSize: "14px" }}>{val.title}</p>
								<div style={{ position: "relative" }}>
									<p style={{ position: "absolute", top: "50%", left: "50%", textAlign: "center", transform: "translate(-40%,-30%)", fontSize: "16px", color: "#504848", fontWeight: "600" }}>{val.value}%</p>
									<Doughnut data={doughnutData(val.value)} options={doughnutDataOptions} style={{ width: "80px", height: "80px" }} />
								</div>
							</div>
							{val.remarks.map((remark) => (
								<div style={{ display: "flex", alignItems: "center", margin: "10px 0" }}>
									<div style={{ marginRight: "15px", color: "#479BD2" }}><img style={{ width: "12px", height: "12px" }} src={reportarrow} /></div>
									<p style={{ fontSize: "12px" }}>{remark}</p>
								</div>
							))}
						</div>
					))}
			</div> */}
			{
				barData.labels.length > 0 ? (
					<>
						<div style={{ borderBottom: "1px solid rgb(150,150,150)", padding: "15px 0", marginTop: "40px", marginBottom: "20px" }}>
							<h2>Time map</h2>
						</div>
						<div>
							<div>
								<Bar data={barData} options={barData.options} />
							</div>
							<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
								<div>
									<Button
										style={{
											background: '#479BD2',
											letterSpacing: "0.01em",
											borderRadius: '25px',
											padding: "6px 20px",
											fontSize: '16px'
										}}
										size="small"
										variant="contained"
										onClick={() => setCurrentReportTab(3)}
									>
										Interviewer's guide
									</Button>
								</div>
							</div>
						</div>
					</>
				) : ''
			}
		</div>
	);
}

ReportTab.propTypes = {
	setCurrentReportTab: PropTypes.func,
	candidateData: PropTypes.object
}

export default ReportTab;
