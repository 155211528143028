import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';

import { sendForgotPasswordLink as sendForgotPasswordLinkRequest } from '../../actions/app';

import ForgotPasswordImg from '../../assets/forgot-password.png';
import Logo from '../../assets/Logo.png';

import '../../styles/freeApp/forgotPassword.scss';

const ForgotPassword = (props) => {
    const { sendForgotPasswordLink } = props;

    const [email, setEmail] = useState('');

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const onRequestLink = () => {
        sendForgotPasswordLink({ email });
    }

    return (
        <div id='forgotPasswordPage'>
            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fcfcfc', height: 100, alignItems: 'center' }}>
                <img src={Logo} alt='' style={{ width: 300 }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                <div>
                    <img src={ForgotPasswordImg} alt='' style={{ width: 400, marginTop: -50 }} />
                </div>
                <div style={{ marginTop: -50 }}>
                    <div style={{ fontSize: 22, fontWeight: 600, color: '#646464' }}>Forgot password</div>
                    <div style={{ marginTop: 5, fontSize: 14, fontWeight: 400, color: '#616161' }}>Please enter the email address you’d like your<br />password information sent to</div>
                    <div>
                        <input style={{ padding: '16px 0', color: '#bababa', border: 'none', borderBottom: '1px solid #bababa', marginTop: 12, fontFamily: 'inherit', outline: 'none', fontSize: 18, fontWeight: '400', width: 300 }} type='email' placeholder='Enter email address' onChange={onChangeEmail} value={email} />
                    </div>
                    <div style={{ marginTop: 25, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ marginRight: 25, backgroundColor: '#008095', borderRadius: 50, padding: '12px 24px', fontSize: 16, fontWeight: '400', color: '#fff', cursor: 'pointer' }} onClick={onRequestLink}>Request link</div>
                        <Link to={'/free/signin'} style={{ color: '#0C95BA', textDecoration: 'none', fontSize: 14, fontWeight: '600' }}>Back to log in </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({});

const mapDispatchToProps = (dispatch) => ({
	sendForgotPasswordLink: (data) => dispatch(sendForgotPasswordLinkRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
