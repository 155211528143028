import React, { useState } from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory } from 'react-router-dom';

import Error from '../components/common/Error';
import Loader from '../components/common/Loader';
import { getAuthErrorMsg, getAuthLoading } from '../selectors/app';
import {
	userSignIn as userSignInRequest,
	setAuthErrorMsg as setAuthErrorMsgRequest
} from '../actions/app';
import { validateSignInInput } from '../utils/validation/validateUtils';

import logo from '../assets/Logo.png';

import '../styles/btn.css';
import '../styles/signIn.css';

function SignIn(props) {
	const { userSignIn, isLoading, errorMsg, setAuthErrorMsg } = props;

	let history = useHistory();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [captchaValue, setCaptchaValue] = useState(null);

	const handleSignIn = (e) => {
		e.preventDefault();
		setAuthErrorMsg('');

		const userData = {
			email,
			password,
			captchaValue
		};

		const errorResult = validateSignInInput(userData);

		if (!errorResult.isValid) {
			Object.keys(errorResult.errors).forEach((item) => {
				if (item) {
					setAuthErrorMsg(errorResult.errors[item]);
					return;
				}
			});
			return;
		}

		userSignIn({ userData, history });
	};

	const onChange = (value) => {
		setCaptchaValue(value);
	}

	return (
		<div style={{ display: "flex", height: "100vh" }}>
			<form style={{ width: "50%" }} onSubmit={handleSignIn}>
				<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
					<div style={{ width: "400px", margin: "auto", maxWidth: "80%" }}>
						<div style={{ marginBottom: "50px" }}>
							<img
								src={logo}
								alt="logo"
								style={{
									maxWidth: "150px",
									marginBottom: "10px"
								}}
							/>
							<h1
								style={{
									fontSize: '30px',
									color: '#008095',
									marginBottom: '15px',
									fontWeight: '800'
								}}
							>
								Client Dashboard
							</h1>
						</div>
						<div>
							<div style={{ marginBottom: "20px" }}>
								<p style={{ fontWeight: "600", color: "#000", marginBottom: "10px" }}>Email</p>
								<input
									required
									className="signinInput"
									placeholder="Please enter your email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									type="email"
									id="testLoginFlow-selector-1"
								></input>
							</div>
							<div style={{ marginBottom: "20px" }}>
								<p style={{ fontWeight: "600", color: "#000", marginBottom: "10px" }}>Password</p>
								<input
									required
									placeholder="Please enter your password"
									className="signinInput"
									type="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									id="testLoginFlow-selector-2"
								></input>
							</div>
							<ReCAPTCHA
								sitekey="6LdR4pgfAAAAAC5TKy_JSYyA9u-6BcRalLBW_eIQ"
								onChange={onChange}
							/>
							<div style={{ marginTop: "40px" }}>
								<Button
									size="small"
									variant="contained"
									style={{
										background: '#008095',
										fontWeight: 'bold',
										fontFamily: "Poppins",
										letterSpacing: "0.01em",
										borderRadius: '15px',
										padding: '5px 50px',
										fontSize: '16px',
										backgroundImage: "linear-gradient(to right, #23597C, #008095)"
									}}
									type="submit"
									disabled={isLoading}
									id="testLoginFlow-selector-3"
								>
									Login
								</Button>
							</div>
							<div style={{ minHeight: "55px", margin: "1rem" }}>
								{isLoading ? <Loader /> : ''}
								{errorMsg !== '' ? <Error errorMsg={errorMsg} /> : ''}
							</div>
						</div>
					</div>
				</div>
			</form>
			<div style={{ width: "50%", backgroundColor: "rgb(0, 128, 149)" }} className="singinBg">
				<div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
					<div className="signinRightText">
						<h1>Assess more</h1>
						<h1>Interview less</h1>
						<h1>Hire better</h1>
						<h2>Your efficient hiring toolkit</h2>
					</div>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	isLoading: getAuthLoading(),
	errorMsg: getAuthErrorMsg()
});

const mapDispatchToProps = (dispatch) => ({
	userSignIn: (data) => dispatch(userSignInRequest(data)),
	setAuthErrorMsg: (data) => dispatch(setAuthErrorMsgRequest(data))
});

SignIn.propTypes = {
	userSignIn: PropTypes.func,
	setAuthErrorMsg: PropTypes.func,
	isLoading: PropTypes.bool,
	errorMsg: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
