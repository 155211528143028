import React from 'react';

import AssessmentTypeImg from '../../../assets/assessment-type.png';

const SelectAssessmentType = (props) => {
	const { selectBotMenu, openDefaultForm, openCustomForm } = props;

	return (
		<>
			<div className='title'>Virtual Interviewer - Select type</div>
			<div className='subtitle'>Select assessment to create</div>
			<div className='default-list' id="testNewDriveFlow-selector-2">
				{
					selectBotMenu.map((item) => (
						<div className='list-item' key={item._id}>
							<div className='list-item-box' onClick={() => openDefaultForm(item)}>
								<img src={AssessmentTypeImg} alt='' />
								<div>{item.name}</div>
							</div>
							<div>Default</div>
						</div>
					))
				}
			</div>
			<div className='divider'>OR</div>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className='action' onClick={openCustomForm}>Create custom Virtual Interviewer</div>
			</div>
		</>
	)
}

export default SelectAssessmentType;
