import React, {useState} from 'react'
import axios from '../../../axios'
import { Button } from '@material-ui/core';
import "../../../styles/btn.css"

export default function ChatInput3({addToMessageBox, nextQues, setNextQues, setSeeQues}) {

    const continueButtonClick = () => {
      console.log(nextQues)
      addToMessageBox(nextQues);
        
        //sendToChatBox(nextQues,nextQues.length-1);
        setNextQues([])
        setSeeQues(true)
    }
    
  function sendToChatBox(msglist,x){
    console.log('ChatInput3')
    addToMessageBox([msglist[x]])
    if (x>0)
      setTimeout(() =>{sendToChatBox(msglist,x-1)},1500);
  }
  return (
    <div id="chat-form" style={{height:"8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display:"flex", alignItems:"center", justifyItems:
    "center"}}>
      <button className='btn btn__secondary' style = {{ padding: "0.4vh 1.4vw", textTransform: "capitalize", fontSize: "1.1vw", margin: "auto", }} onClick = {continueButtonClick()}>
        Continue
      </button>
    </div> 
  )
}

const styles = {
    background: "#fff"
}