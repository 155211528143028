import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, useHistory } from 'react-router-dom';

import Header from './Header';
import Sidebar from './Sidebar';
import { getAssessmentHistory } from '../../selectors/app';
import { fetchAssessmentHistory as fetchAssessmentHistoryRequest } from '../../actions/app';

import NoAssessmentHistory from '../../assets/no-assessment-history.png';

import '../../styles/freeApp/assessmentHistory.scss';
import { MenuItem, Select } from '@mui/material';
import moment from 'moment';

const AssessmentHistory = (props) => {
    const { fetchAssessmentHistory, assessmentHistory } = props;

    const history = useHistory();

    const [openModal, setOpenModal] = useState(false);
    const [openWorkFunctionModal, setOpenWorkFunctionModal] = useState(false);
    const [openKeyTaskModal, setOpenKeyTaskModal] = useState(false);
    const [chatRole, setChatRole] = useState('Project Manager');
    const [currentWorkFunction, setCurrentWorkFunction] = useState(null);
    const [selectedWorkFunction, setSelectedWorkFunction] = useState(0);

    useEffect(() => {
        fetchAssessmentHistory();
    }, []);

    const onOpenModal = () => {
        setOpenModal(true);
    }

    const onCloseModal = () => {
        setOpenModal(false);
    }

    const onCloseWorkFunctionModal = () => {
        setOpenWorkFunctionModal(false);
    }

    const onCloseKeyTaskModal = () => {
        setOpenKeyTaskModal(false);
    }

    const onChangeRole = (e) => {
        setChatRole(e.target.value)
    }

    const onConfirm = () => {
        history.push({
            pathname: '/demobox',
            state: {
                id: chatRole,
                wf: 'dummy',
                comp: 'dummy',
                startT: (new Date()).getTime(),
                code: 'dummy',
                heading: chatRole,
                prob: "na",
                resume: 'false'
            }
        });
    }

    const showWorkFunction = (val) => {
        setCurrentWorkFunction(val);
        setOpenWorkFunctionModal(true);
    }

    const showKeyTask = (val) => {
        setOpenKeyTaskModal(true);
        setCurrentWorkFunction(val);
    }

    const changeSelectedWorkFunction = (val) => {
        setSelectedWorkFunction(val);
    }

    return (
        <div id="historyPage">
            <Header showFreeTrial={false} showCreateBtn={true} />
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <Sidebar />
                {
                    assessmentHistory.length > 0 ? (
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', padding: '0 50px', boxSizing: 'border-box' }}>
                            <div className='assessment-history-table'>
                                <div className='assessment-history-column'>
                                    <div className='assessment-history-column-header'>Assessment</div>
                                    {
                                        assessmentHistory.map((item) => {
                                            const parsedItem = JSON.parse(item);
                                            return (
                                                <div className='assessment-history-row'>{parsedItem.name}</div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='assessment-history-column'>
                                    <div className='assessment-history-column-header'>Role</div>
                                    {
                                        assessmentHistory.map((item) => {
                                            const parsedItem = JSON.parse(item);
                                            return (
                                                <div className='assessment-history-row'>{parsedItem.role}</div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='assessment-history-column'>
                                    <div className='assessment-history-column-header'>Critical work functions</div>
                                    {
                                        assessmentHistory.map((item) => {
                                            const parsedItem = JSON.parse(item);
                                            return (
                                                <div className='assessment-history-row link' onClick={() => showWorkFunction(parsedItem)}>See all</div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='assessment-history-column'>
                                    <div className='assessment-history-column-header'>Key tasks</div>
                                    {
                                        assessmentHistory.map((item) => {
                                            const parsedItem = JSON.parse(item);
                                            return (
                                                <div className='assessment-history-row link' onClick={() => showKeyTask(parsedItem)}>See all</div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='assessment-history-column'>
                                    <div className='assessment-history-column-header'>Date created</div>
                                    {
                                        assessmentHistory.map((item) => {
                                            const parsedItem = JSON.parse(item);
                                            return (
                                                <div className='assessment-history-row'>{moment(parsedItem.createdDate).format('L')}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', padding: '0 50px', boxSizing: 'border-box' }}>
                            <div>
                                <img src={NoAssessmentHistory} alt='' style={{ width: 400 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20 }}>
                                <Link to={'/free/create-assessment'} style={{ color: '#fff', fontSize: 16, fontWeight: '500', backgroundColor: '#479BD2', borderRadius: 8, marginRight: 50, textDecoration: 'none', padding: '10px 40px' }}>+ Create</Link>
                                <div style={{ color: '#fff', fontSize: 16, fontWeight: '500', backgroundColor: '#479BD2', borderRadius: 8, textDecoration: 'none', padding: '10px 40px', cursor: 'pointer' }} onClick={onOpenModal}>Try our sample mini interviewer</div>
                            </div>
                        </div>
                    )
                }
            </div >
            <Modal open={openModal} onClose={onCloseModal} center>
                <div style={{ width: 400, padding: 25 }}>
                    <div style={{ fontSize: 20, fontWeight: '700' }}>Select Role</div>
                    <Select
                        className="remove-bg"
                        onChange={onChangeRole}
                        style={{ height: 45, marginTop: 20, width: '100%', background: "#F2F2F2", borderRadius: 30 }}
                        displayEmpty
                    >
                        {/* <MenuItem selected>Select Bot</MenuItem> */}
                        <MenuItem value="Project Manager">Project Manager</MenuItem>
                        <MenuItem value="Consulting">Consulting</MenuItem>
                        <MenuItem value="Sales">Sales</MenuItem>
                    </Select>
                    <div style={{ display: 'inline-flex', justifySelf: 'flex-end', marginTop: 25, backgroundColor: '#F0F7FF', color: '#197BBD', fontSize: 14, fontWeight: 700, padding: '4px 12px', cursor: 'pointer', borderRadius: 20 }} onClick={onConfirm}>Confirm</div>
                </div>
            </Modal>
            <Modal open={openWorkFunctionModal} onClose={onCloseWorkFunctionModal} center>
                {
                    currentWorkFunction ? (
                        <div style={{ width: 400, padding: 25, boxSizing: 'border-box' }}>
                            <div style={{ fontSize: 20, fontWeight: '600', borderBottom: '1px solid #c4c4c4', padding: '10px 0' }}>{currentWorkFunction.role}</div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', boxSizing: 'border-box' }}>
                                    <div style={{ color: '#014954', fontSize: 18, fontWeight: '600', borderBottom: '1px solid #c4c4c4', padding: '5px 0' }}>Critical work functions</div>
                                    <div style={{ marginTop: 20 }}>
                                        {
                                            currentWorkFunction.workFunction.map((item, i) => (
                                                <div style={{ fontSize: 16, fontWeight: 500, color: '#479BD2', marginTop: 15 }}>{i+1}. {item}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ''
                }
            </Modal>
            <Modal open={openKeyTaskModal} onClose={onCloseKeyTaskModal} center>
                {
                    currentWorkFunction ? (
                        <div style={{ width: 800, padding: 25, boxSizing: 'border-box' }}>
                            <div style={{ fontSize: 20, fontWeight: '600', borderBottom: '1px solid #c4c4c4', padding: '10px 0' }}>{currentWorkFunction.role}</div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30 }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', paddingRight:30, boxSizing: 'border-box' }}>
                                    <div style={{ color: '#014954', fontSize: 18, fontWeight: '600', borderBottom: '1px solid #c4c4c4', padding: '5px 0' }}>Critical work functions</div>
                                    <div style={{ marginTop: 20 }}>
                                        {
                                            currentWorkFunction.workFunction.map((item, i) => (
                                                <div style={{ fontSize: 16, fontWeight: 500, color: (i === selectedWorkFunction ? '#479BD2' : '#c4c4c4'), marginTop: 15, cursor: 'pointer' }} onClick={() => changeSelectedWorkFunction(i)}>{i+1}. {item}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: 30, boxSizing: 'border-box' }}>
                                    <div style={{ color: '#014954', fontSize: 18, fontWeight: '600', borderBottom: '1px solid #c4c4c4', padding: '5px 0' }}>Key tasks</div>
                                    <div style={{ marginTop: 12 }}>
                                        {
                                            currentWorkFunction.keyTask[currentWorkFunction.workFunction[selectedWorkFunction]] ? currentWorkFunction.keyTask[currentWorkFunction.workFunction[selectedWorkFunction]].map((item, i) => (
                                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px', backgroundColor: '#E9F6FF', borderRadius: 12, alignItems: 'center', marginTop: 8 }}>
                                                    <div style={{ fontSize: 12, color: '#010101' }}>{item}</div>
                                                </div>
                                            )) : <div style={{ marginTop: 8 }}>No Key Tasks</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ''
                }
            </Modal>
        </div >
    )
}

const mapStateToProps = createStructuredSelector({
    assessmentHistory: getAssessmentHistory()
});

const mapDispatchToProps = (dispatch) => ({
    fetchAssessmentHistory: () => dispatch(fetchAssessmentHistoryRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentHistory);
