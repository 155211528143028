import React, { useState, Fragment, useRef } from 'react'
import axios from '../../../axios'
import { Button } from '@material-ui/core';
import { useWindowDimensions } from '../../../utils/windowUtils';
import { useLocation } from 'react-router'
import { useReactMediaRecorder } from "react-media-recorder";
import VideoPreview from './videoInput/VideoPreview';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Modal } from 'react-responsive-modal';
import Message from './videoInput/Message';
import Progress from './videoInput/Progress';
import styles from '../../../styles/MultiSelect.module.css'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import play from '../../../assets/play.png'
// import green from '../../../assets/green.png'
import red from '../../../assets/red.png'
import rotate from '../../../assets/rotate.png'
// import reverse from '../../../assets/reverse.png'
import Tooltip from '@mui/material/Tooltip';
import { fixedAssessmentData } from '../../../constants/fixedAssessmentData';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
//
export default function VideoInput({ addToMessageBox, messages, setNextQues, setProgress }) {
	let location = useLocation()
	const containerRef = useRef(null);
	const refVideo = useRef(null)
	const [open, setOpen] = useState(false);
	const [key, setKey] = useState(0);
	const openToast = () => {
		setOpen(true);
	}
	const [uploadBtnDisabled, setUploadBtnDisabled] = useState(false);

	const qtype = messages.messages[0].qtype;
	let initialTime = 59;

	const setInitialTime = function () {
		if (qtype == "vid0") return;

		initialTime = qtype.slice(-1) * 60;
	}
	setInitialTime();

	const [openModal, setOpenModal] = useState(false);

	const [message, setMessage] = useState('Time to upload does not count in test timer');
	const [uploadPercentage, setUploadPercentage] = useState(0);

	const {
		status,
		startRecording,
		stopRecording,
		mediaBlobUrl,
		clearBlobUrl,
		previewStream,
		error,
		muteAudio,
		unMuteAudio
	} = useReactMediaRecorder({ video: true, audio: true });

	const onOpenModal = () => { setOpenModal(true) };
	const onCloseModal = () => { clearBlobUrl(); setOpenModal(false) };
	const startRecordingVideo = () => {
		//muteAudio()
		clearBlobUrl();
		if (refVideo.current) {

			//open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
			refVideo.current.src = '';
		}
		if (error) {
			openToast()
			return
		}
		setKey(key + 1)
		//unMuteAudio()
		startRecording();
	}
	function sendToChatBox(msglist, x) {
		addToMessageBox([msglist[x]])
		if (x > 0)
			setTimeout(() => { sendToChatBox(msglist, x - 1) }, 1500);
	}
	const onSubmit = async e => {
		setUploadBtnDisabled(true);
		setMessage('Upload in progress. Please do not close this window')
		const videoBlob = await fetch(mediaBlobUrl).then(r => r.blob());
		const videofile = new File([videoBlob], location.state.comp + "::" + location.state.code + "::" + messages.messages[0].qid + ".mp4", { type: "video/mp4" })
		console.log(videofile);
		const formData = new FormData();
		formData.append("file", videofile);
		formData.append("path", location.state.comp + "/" + location.state.code + "/" + messages.messages[0].qid + ".mp4")
		formData.append("mode", 0);
		formData.append("message2", location.state.comp + "/" + location.state.code + "/" + messages.messages[0].qid + ".mp4");
		formData.append("user_id", location.state.code);
		formData.append("message1", messages.messages[0].qid);

		try {
			const res = await axios.post('/video-userconvo-fixed', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: progressEvent => {
					setUploadPercentage(
						parseInt(
							Math.round((progressEvent.loaded * 100) / progressEvent.total)
						)
					);
				}
			});

			// Clear percentage
			setTimeout(() => setUploadPercentage(0), 10000);
			console.log(res.data)
			const { fileName, filePath } = res.data;
			setMessage('File uploaded');
			setUploadBtnDisabled(false);
			let convoMssg = {
				q: "File uploaded",
				isMyMessage: true
			}
			addToMessageBox([convoMssg]);
			let msg22 = 'Thanks. Let\'s go to the next question.'
			const msg1 = fixedAssessmentData[location.state.id][parseInt(messages.messages[0].qid)]
			let parsedMssg = msg1[0].split(";;").map((ele, index) => {
				return ({
					q: ele,
					ref: "na"
				})
			})
			parsedMssg[parsedMssg.length - 1]["qtype"] = msg1[1]
			parsedMssg[parsedMssg.length - 1]["options"] = msg1[2]
			parsedMssg[parsedMssg.length - 1]["qid"] = msg1[3]
			parsedMssg[parsedMssg.length - 1]["exhibit"] = msg1[4]
			parsedMssg[parsedMssg.length - 1]["progress"] = msg1[5];

			if (msg1[5] == "100") {
				msg22 = '';
			}

			let parsedRespMssg = msg22.split(";;").map((ele) => {
				return ({
					q: ele,
					ref: "na"
				})
			})
			let finalQues = [...parsedRespMssg]
			// let combinedMssg = [...mssg, ...parsedRespMssg, convoMssg]
			// addToMessageBox(finalQues)
			sendToChatBox(finalQues, finalQues.length - 1)
			//setTimeout(function(){addToMessageBox(finalQues)},1200)
			setTimeout(() => { setNextQues(parsedMssg.reverse()) }, (finalQues.length) * 1500)
			setProgress(parseInt(msg1[5], 10))
		} catch (err) {
			setUploadBtnDisabled(false);
			if (err?.response?.status === 500) {
				setMessage('There was a problem with the server');
			} else {
				setMessage(err?.response?.data?.msg);
			}
			setUploadPercentage(0)
		}
	};
	const renderTime = ({ remainingTime }) => {
		var timerhours = Math.floor(remainingTime / 3600);
		var timerminutes = -timerhours * 60 + Math.floor(remainingTime / 60);
		var timerseconds = -timerminutes * 60 - timerhours * 3600 + remainingTime;
		if (remainingTime === 0) {
			timerminutes = 0;
			timerseconds = 0;
		}
		return (
			<div className="timer" style={{ fontSize: "14px", display: "flex", color: "#eee", margin: "auto" }}>
				<div className="minutesvalue">{timerminutes < 10 ? `0${timerminutes}` : timerminutes}:</div>
				<div className="secondsvalue">{timerseconds < 10 ? `0${timerseconds}` : timerseconds}</div>
			</div>
		);
	}
	return (


		<div ref={containerRef} className='videoInputModal-container' style={{ alignItems: "center", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
			<Modal
				open={openModal}
				onClose={onCloseModal}
				// center 
				container={containerRef.current}
				classNames={{ modal: 'videoInputModal', closeIcon: 'videoInputModalClose', overlay: 'videoInputModalOverlay' }}
				styles={{ modal: { borderRadius: "50px", padding: "40px" } }}
			>
				<div style={{ display: "flex", flexDirection: "column", margin: "30px" }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '30px' }}>
						<div style={{ alignSelf: 'flex-start' }}>
							<img style={{ width: '50px', height: '50px' }} src="bot.png" alt="" />
						</div>
						<div className='videoInput-question'>
							{messages.messages[0].q.replaceAll('*', '').replaceAll(':', ' ').replaceAll(';', ' ')}
						</div>
					</div>

					<div style={{ display: "flex", flexDirection: "column", placeItems: "center", margin: "20px 20px 0 20px" }}>
						<hr className='videoInput-hr' />

						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<video ref={refVideo} src={mediaBlobUrl} controls controlsList="nodownload" disablePictureInPicture disableRemotePlayback style={{ display: status == "recording" ? "none" : "flex", background: "#111", borderRadius: '25px' }} autoPlay width="300" height="300" />
							<VideoPreview stream={previewStream} status={status} />
							<div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

								<p style={{ fontSize: "16px", color: '#eee', margin: '0 10px 0 0' }}>Time Left: </p>

								<div className='videoInputTimer'>
									<CountdownCircleTimer
										key={key}
										isPlaying={status === "recording"}
										duration={initialTime}
										colors={[
											['#42B6D6', 1]
										]}
										strokeWidth={useWindowDimensions().height / 200}
										size={useWindowDimensions().height / 15}
										trailColor="#C4C4C4"
										onComplete={stopRecording}
									>
										{renderTime}
									</CountdownCircleTimer>
								</div>
							</div>
						</div>

						<div
							style={{
								display: status === "recording" ? "flex" : "none",
								color: '#eee'
							}}
						>
							Recording started.
						</div>

						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative', width: '100%' }}>

							<Tooltip title="Record Again" placement="bottom">
								<Button onClick={startRecordingVideo} style={{ display: status === "stopped" || status === "recording" ? "flex" : "none", borderRadius: "30px", margin: "10px 0" }}>
									<img style={{ width: '50px', height: '50px' }} src={rotate} alt="" />
								</Button>
							</Tooltip>

							<Tooltip title="Start Recording" placement="bottom">
								<Button onClick={startRecordingVideo} style={{ display: status === "idle" || status === "acquiring_media" ? "flex" : "none", borderRadius: "30px", margin: "10px" }}>
									<img style={{ width: '50px', height: '50px' }} src={play} alt="" />
								</Button>
							</Tooltip>

							<Tooltip title="Stop Recording" placement="bottom">
								<Button onClick={stopRecording} style={{ display: status === "recording" || status === "stopping" ? "flex" : "none", borderRadius: "30px", margin: "10px 0" }}>
									<img style={{ width: '50px', height: '50px' }} src={red} alt="" />
								</Button>
							</Tooltip>

							<Button
								variant="contained"
								size="small"
								onClick={onSubmit}
								style={{
									display: status === "stopped" ? "flex" : "none",
									alignSelf: 'center',
									borderRadius: "30px",
									marginTop: "10px",
									background: '#fff',
									fontFamily: "Poppins",
									letterSpacing: "0.01em",
									borderRadius: '25px',
									padding: '5px 12px',
									position: 'absolute',
									right: '0'
								}}
								disabled={uploadBtnDisabled}
							>
								<span style={{ color: '#479BD2', fontSize: '16px', fontWeight: 'bold' }}>Upload</span>
							</Button>
						</div>

						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', margin: '10px 0' }}>
							<div
								style={{
									display: status === "stopped" ? "flex" : "none",
									flexDirection: 'column',
									justifySelf: 'center',
									flex: '1'
								}}
							>
								<div style={{ margin: '0 0 10px 0' }}>
									<Progress percentage={uploadPercentage} />
								</div>
								<div>{message ? <Message msg={message} /> : null}</div>
							</div>
						</div>

						{/* <Button onClick={startRecordingVideo} style={{display: status==="idle"||status==="acquiring_media"?"flex":"none", borderRadius:"30px", margin: "10px"}}>
               Start Recording
            </Button>

            <div style={{display: status==="recording"?"flex":"none"}}>
               Recording started. Please speak up.
            </div>
            <Button onClick={startRecordingVideo} style={{display: status==="stopped" || status==="recording" ?"flex":"none", borderRadius:"30px", margin: "10px 0"}}>
               Record Again
            </Button>
            <Button onClick={stopRecording} style={{display: status==="recording" ||status==="stopping" ?"flex":"none", borderRadius:"30px", margin: "10px 0"}}>
               Stop Recording
            </Button>
            <div style={{display: status==="stopped"?"flex":"none"}}>
               {message ? <Message msg={message} /> : null}
               <Progress percentage={uploadPercentage} />
            </div>
            <Button onClick={onSubmit} style={{display: status==="stopped"?"flex":"none", borderRadius:"30px", marginTop: "10px"}}>
               Upload Video
            </Button> */}
					</div>
				</div>
			</Modal>

			<Snackbar open={open} autoHideDuration={2500} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} onClose={() => setOpen(false)}>
				<Alert severity="error" sx={{ width: '100%', backgroundColor: "#d32f2f !important", color: "#fff !important" }}>
					{/* {error.replaceAll("_"," ").toUpperCase()} */}
					Check camera and microphone permissions and not in use anywhere else
				</Alert>
			</Snackbar>
			<div id="chat-form" style={{ height: "70px", borderTopRightRadius: "16px", borderTopLeftRadius: "16px", display: "flex", placeItems: "center" }}>
				<button className={styles.SubmitButton} onClick={onOpenModal} style={{ border: "none", cursor: "pointer" }}>
					Open recorder
				</button>
			</div>
		</div>
	)
}