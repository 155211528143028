import React, { useState, useEffect, useRef } from 'react';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { SpreadsheetComponent, SheetsDirective, SheetDirective, RangesDirective } from '@syncfusion/ej2-react-spreadsheet';
import { RangeDirective, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-react-spreadsheet';
import Modal from 'react-responsive-modal';
import axios from '../../../../axios'
import Button from '@mui/material/Button';
import { spreadSheetData } from './dataSource2';
import { useLocation } from 'react-router'
import "./spreadsheet.css";
import { fixedAssessmentData } from "../../../../constants/fixedAssessmentData"

export default function EsyncSpreadSheet({ messages, addToMessageBox, setNextQues, seeQues, setSeeQues, setProgress }) {
	let location = useLocation()
	const [ss, setSS] = useState(1)
	const newData = messages.messages[0].options[0]
	// const [data, setData] = useState(newData);
	const [data, setData] = useState(newData);
	const [spredSheetModalOpen, setSpredSheetModalOpen] = useState(false);
	const handleSpreadSheetModalOpen = () => {
		setSS(ss + 1)
		setSpredSheetModalOpen(true);

	}
	const handleSpreadSheetModalClose = () => setSpredSheetModalOpen(false);
	function sendToChatBox(msglist, x, ques) {
		addToMessageBox([msglist[x]])
		if (x > 0)
			setTimeout(() => { sendToChatBox(msglist, x - 1, ques) }, 1500);
		else
			setNextQues(ques.reverse())
	}
	const onSubmit = () => {
		let optionsSelected = [{
			q: 'Excel submitted.',
			isMyMessage: true,
			ref: ""
		}];
		const url = '/userconvo'
		spread.current.saveAsJson()
			.then(json => {
				console.log(json.jsonObject.Workbook.sheets[0].rows)
				let getData = {
					user_id: location.state.code,
					comp: location.state.comp,
					message1: messages.messages[0].qid,
					message2: json.jsonObject,
					mode: 0
				}
				console.log(getData)
				addToMessageBox(optionsSelected)
				//  addToMessageBox(optionsSelected)
				let msg22 = 'Thanks. Let\'s go to the next question.'
				const msg1 = fixedAssessmentData[location.state.id][parseInt(messages.messages[0].qid)]

				let parsedMssg = msg1[0].split(";;").map((ele, index) => {
					return ({
						q: ele,
						ref: "na"
					})
				})
				parsedMssg[parsedMssg.length - 1]["qtype"] = msg1[1]
				parsedMssg[parsedMssg.length - 1]["options"] = msg1[2]
				parsedMssg[parsedMssg.length - 1]["qid"] = msg1[3]
				parsedMssg[parsedMssg.length - 1]["exhibit"] = msg1[4]
				parsedMssg[parsedMssg.length - 1]["progress"] = msg1[5]

				if (msg1[5] == "100") {
					msg22 = '';
				}


				let parsedRespMssg = msg22.split(";;").map((ele) => {
					return ({
						q: ele,
						ref: "na"
					})
				})

				// addToMessageBox(optionsSelected)
				setTimeout(() => { sendToChatBox(parsedRespMssg, parsedRespMssg.length - 1, parsedMssg) }, optionsSelected.length * 1500);

				//addToMessageBox(optionsSelected)
				//setTimeout(function(){addToMessageBox(combinedMssg)},1500)
				//setTimeout(()=>{setNextQues(parsedMssg.reverse())},(1+parsedRespMssg.length)*1500)
				setProgress(parseInt(msg1[5], 10))
				/*axios
				 .post(url, getData, {headers: {'Content-Type': 'application/json'}})
				 .then((res) => {
				   console.log(res.data)
				   let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
					   return({
						 q: ele,
						 ref: "na"
					   })
					 })
					 parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
					 parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
					 parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
					 parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
					 parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]
			 
					 let respMssg = [{
							   q: res.data.msg2,
							   ref:"na"
							 }]
			 
					 
					 // addToMessageBox(optionsSelected)
					 setTimeout(()=>{sendToChatBox(respMssg,respMssg.length-1,parsedMssg)},  optionsSelected.length*1500);
					 ;
					 //addToMessageBox(optionsSelected)
					 //setTimeout(function(){addToMessageBox(combinedMssg)},1500)
					 //setTimeout(()=>{setNextQues(parsedMssg.reverse())},(1+parsedRespMssg.length)*1500)
					 setProgress(parseInt(res.data.msg1[5], 10))
					 // by context show next question
				   })
				   .catch((err) => {
					 console.log(err)
				   })*/
			})

	}
	const spread = useRef()

	React.useEffect(() => {
		if (spread.current) {
			spread.current.openFromJson({ file: messages.messages[0].options })
		}
		else {
			setSS(ss + 1)
		}
	}, [ss])
	return (
		<div>
			<Button
				className="spreadSheetBtn"
				size="small"
				variant="contained"
				onClick={handleSpreadSheetModalOpen}
				style={{
					background: '#479BD2',
					fontWeight: 'bold',
					fontFamily: "Poppins",
					letterSpacing: "0.01em",
					borderRadius: '25px',
					padding: '5px 12px',
					fontSize: '16px',
					textTransform: 'none'
				}}
			>
				Click here to open excel
			</Button>

			<Modal
				open={spredSheetModalOpen}
				onClose={handleSpreadSheetModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				center={true}
				classNames={{
					modal: 'syncSpreadSheetModal',
				}}
			>
				<div className='spreadSheet' style={{ margin: '25px 0 0 0', height: '70vh' }}>
					<SpreadsheetComponent ref={spread}>
					</SpreadsheetComponent>
				</div>
				<div style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					color: 'red',
					fontStyle: 'italic'
				}}>
					Note: After writing the final answer, first press 'Enter' and then click on 'Submit'.
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						margin: '25px 0 0 0'
					}}>
					<Button
						size="small"
						variant="contained"
						onClick={onSubmit}
						style={{
							background: '#479BD2',
							fontWeight: 'bold',
							fontFamily: "Poppins",
							letterSpacing: "0.01em",
							borderRadius: '25px',
							padding: '5px 12px',
							fontSize: '16px',
							textTransform: 'none'
						}}
					>
						Submit
					</Button>
				</div>
			</Modal>
		</div>
	)
}
