import React, {useState} from 'react'
import axios from '../../../axios'
// import { Button } from '@material-ui/core';
import Button from '@mui/material/Button';
import "../../../styles/btn.css"
import { useLocation } from 'react-router'
import styles from '../../../styles/MultiSelect.module.css'

export default function TncInput({addToMessageBox, addFirstMessage}) {
  let location=useLocation()
  let quesStatement = {
    q: location.state.prob,
    ref: "na"
  }
    const acceptButtonClick = () => {
      // console.log(nextQues)
        // addToMessageBox(nextQues);
        addToMessageBox([quesStatement])
        setTimeout(() =>{addFirstMessage()},1500)
        // setCaseState(true)
        // sendToChatBox(nextQues,nextQues.length-1);
        // setNextQues([])
        // setSeeQues(true)
    }
    
  // function sendToChatBox(msglist,x){
  //   addToMessageBox([msglist[x]])
  //   if (x>0)
  //     setTimeout(() =>{sendToChatBox(msglist,x-1)},1500);
  // }
  return (
    <div id="chat-form" style={{height:"8vh", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display:"flex", alignItems:"center", justifyItems:"center"}}>
      <Button variant="contained" size="small" className={`${styles.SubmitButton} bot-first-step`}style = {{ padding: "0.4vh 1.4vw", textTransform: "capitalize", margin: "auto", }} onClick = {acceptButtonClick}>
        <p style={{fontSize: "16px", fontWeight: 'bold'}}>Accept</p>
      </Button>
    </div> 
  )
}