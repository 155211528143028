const newDriveData = {
	"Project Manager": {
		"Develop project plans": [
			'Conduct cost-benefit analysis and develop project plan',
			'Review project plans to determine time frame, funding limitations, procedures for accomplishing projects',
			'Estimate budgetary needs based on the project scope and anticipate future budget challenges',
			'Assess consolidated project plans for dependencies, gaps and continued business value',
		],
		"Oversee program implementation": [
			'Plan and manage project compliance requirements and adherence to governance structures',
			'Evaluate and address external business environment changes for impact on project scope',
			'Plan and manage project closure and/or transitions',
			'Conduct project reviews to recommend changes to project schedules, cost or resource requirements',
			'Document and track project scope, changes, issues and risks that affect implementation',
			'Facilitate the daily stand-up to achieve team consensus',
			'Eliminate conflicts and assist in developing solutions to manage roadblocks',
			'Refine and manage the product backlog',
		],
		"Manage people and organisation": [
			'Manage project budget, work allocations, manpower and resourcing needs for the team',
			'Develop team members through ongoing coaching, mentoring and career discussions',
			'Drive performance management practices within the team in accordance with organisational policies and procedures',
			'Develop initiatives to support the continuing competence and professional development of the team',
			'Facilitate discussions, problem solving and conflict resolution',
		],
	},
	"Sales": {
		"Implement sales strategy": [
			"Analyse sales and client data to identify market trends and estimate market demand",
			"Determine strategic sales targets, markets and product and/or service offerings, expected volume and profits",
			"Create lead generation plans to ensure a substantive sales opportunity pipeline",
			"Develop pricing approaches to support sales and market growth strategies",
			"Coordinate sales activities in line with sales strategy",
			"Provide trends and market feedback to senior management"
		],
		"Identify new sales opportunities": [
			"Identify new sales opportunities with existing clients",
			"Evaluate prospect qualification analysis of leads generated by the business development team or insides sales team",
			"Present new products and/or services to new and existing clients",
			"Participate in price formulation for product and/or service",
			"Work with pre-sales teams and other internal stakeholders to meet client needs"
		],
		"Convert sales opportunities to client accounts": [
			"Plan approach for sales opportunities",
			"Develop sales proposals, quotes and bid documents",
			"Manage the preparation of documents and materials for meetings and negotiations",
			"Analyse motivations and concerns of influencers and decision makers in the client organisation",
			"Negotiate specific terms of product and/or service offerings",
			"Coordinate with relevant stakeholders to finalise terms and conditions related to contracts and agreements"
		],
		"Manage relationship with clients and channel partners": [
			"Develop engagement plans and activities to build and strengthen relationships with clients",
			"Engage clients regularly to uncover current and potential business concerns and needs",
			"Manage the resolution of client feedback and escalate to higher level when needed",
			"Evaluate client feedback to identify areas for improvement and recommend changes to enhance client experience",
			"Communicate client feedback and market sentiments to relevant internal stakeholders to enhance products and/or services"
		]
	}
}

export default newDriveData;