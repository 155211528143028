import {
	ADD_NOTIFICATION,
	ADD_USER, GET_BOTS,
	SET_WORKFLOW_FUNNEL_DATA,
	GET_CANDIDATES_BY_TYPE,
	EDIT_CANDIDATE_NAME,
	RESET_SUCCESS_MSG,
	RESET_RERENDER,
	DELETE_CANDIDATE,
	EDIT_DRIVE_NAME,
	USER_SIGN_IN,
	AUTH_ERROR_MSG,
	SET_REPORT_MODEL,
	USER_LOGOUT,
	FETCH_NOTIFICATIONS,
	UPDATE_NOTIFICATION,
	FREE_USER_SIGN_IN,
	VERIFY_USER,
	SEND_FORGOT_PASSWORD_LINK,
	RESET_PASSWORD,
	SET_PASSWORD,
	SUBMIT_ASSESSMENT,
	FETCH_ASSESSMENT_HISTORY,
	FREE_USER_SIGN_UP,
	AUTH_SUCCESS_MSG,
	SET_USER_SETTINGS,
	GET_DRIVE_DETAILS,
	ADD_COMPANY,
	GET_COMPANIES,
	DELETE_COMPANY,
	ADD_BOTS,
	DELETE_BOTS,
	GET_SUPERUSER,
	ADD_SUPERUSER,
	DELETE_SUPERUSER,
	GET_FEEDBACK,
	FETCH_MY_DETAILS,
	UPDATE_MY_DETAILS,
	CHANGE_MY_PASSWORD
} from "../constants/app";

export const editCandidateName = (row, newName) => ({
	type: EDIT_CANDIDATE_NAME,
	row: row,
	newName: newName
});

export const editDriveName = (row, newName) => ({
	type: EDIT_DRIVE_NAME,
	row: row,
	newName: newName
});

export const deleteCandidate = (data) => ({
	type: DELETE_CANDIDATE,
	data
});

export const resetSuccessMsg = () => ({
	type: RESET_SUCCESS_MSG
});

export const resetRerender = () => ({
	type: RESET_RERENDER
});

export const getBots = () => ({
	type: GET_BOTS
});

export const addUser = (data) => ({
	type: ADD_USER,
	data
});

export const addNotification = (data) => ({
	type: ADD_NOTIFICATION,
	data
});

export const setWorkFlowFunnelData = (data) => ({
	type: SET_WORKFLOW_FUNNEL_DATA,
	data
});

export const setReportModel = (data) => ({
	type: SET_REPORT_MODEL,
	data
});

export const getCandidatesByType = (data) => ({
	type: GET_CANDIDATES_BY_TYPE,
	data
});

export const userSignIn = (data) => ({
	type: USER_SIGN_IN,
	data
});

export const freeUserLogIn = (data) => ({
	type: FREE_USER_SIGN_IN,
	data
});

export const freeUserSignUp = (data) => ({
	type: FREE_USER_SIGN_UP,
	data
});

export const setAuthErrorMsg = (data) => ({
	type: AUTH_ERROR_MSG,
	data
});

export const setAuthSuccessMsg = (data) => ({
	type: AUTH_SUCCESS_MSG,
	data
});

export const logoutUser = (data) => ({
	type: USER_LOGOUT,
	data
});

export const fetchNotifications = () => ({
	type: FETCH_NOTIFICATIONS
});

export const updateNotification = (data) => ({
	type: UPDATE_NOTIFICATION,
	data
});

export const verifyUser = (data) => ({
	type: VERIFY_USER,
	data
});

export const sendForgotPasswordLink = (data) => ({
	type: SEND_FORGOT_PASSWORD_LINK,
	data
});

export const resetPassword = (data) => ({
	type: RESET_PASSWORD,
	data
});

export const setUserPassword = (data) => ({
	type: SET_PASSWORD,
	data
});

export const submitAssessment = (data) => ({
	type: SUBMIT_ASSESSMENT,
	data
});

export const fetchAssessmentHistory = () => ({
	type: FETCH_ASSESSMENT_HISTORY
});

export const updateUserSettings = (data) => ({
	type: SET_USER_SETTINGS,
	data
});

export const fetchDriveDetails = (data) => ({
	type: GET_DRIVE_DETAILS,
	data
});

export const getCompanies = () => ({
	type: GET_COMPANIES
});

export const addCompany = (data) => ({
	type: ADD_COMPANY,
	data
});

export const deleteCompany = (data) => ({
	type: DELETE_COMPANY,
	data
});

export const addBots = (data) => ({
	type: ADD_BOTS,
	data
});

export const deleteBots = (data) => ({
	type: DELETE_BOTS,
	data
});

export const getSuperUser = () => ({
	type: GET_SUPERUSER
});

export const addSuperUser = (data) => ({
	type: ADD_SUPERUSER,
	data
});

export const deleteSuperUser = (data) => ({
	type: DELETE_SUPERUSER,
	data
});

export const getFeedback = () => ({
	type: GET_FEEDBACK,
});

export const fetchMyDetails = () => ({
	type: FETCH_MY_DETAILS
});

export const updateMyDetails = (data) => ({
	type: UPDATE_MY_DETAILS,
	data
});

export const changeMyPassword = (data) => ({
	type: CHANGE_MY_PASSWORD,
	data
})
