import { Button } from '@material-ui/core';
import React, {useState} from 'react'
import Modal from 'react-responsive-modal'
import { useLocation, useHistory } from 'react-router';
import axios from '../../axios'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Feedback({showFeedback, setShowFeedBack}) {

  const location = useLocation();
  const history = useHistory();
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(null);
  const [feedbackText, setFeedbackText] = useState("");

  const selectedSrc = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e"
  const notSelectedSrc = "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e"
  
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const openToast = () => {
    setOpen(true);
  }

  let ratingSection = [];
  for(let i= 1; i <= 5; i++){
    if(i <= rating)
      ratingSection.push(
        <span style={{cursor:"pointer"}}><img src={selectedSrc} style={{margin:"1vh 0.5vw 2vh", height:"4.3vh"}} onClick={()=>{setRating(i);setHoveredRating(i)}} /></span>
      )
    else
      ratingSection.push(
        <span style={{cursor:"pointer"}}><img src={hoveredRating&&hoveredRating>=i?selectedSrc:notSelectedSrc} style={{margin:"1vh 0.5vw 2vh", height:"4.3vh"}} onClick={()=>{setRating(i);setHoveredRating(i)}} onMouseEnter={()=>setHoveredRating(i)} onMouseLeave={()=>{setHoveredRating(rating)}}/></span>
      )
  }

  const sendFeedback = () => {
    if(rating === 0 ){
      console.log("Make sure to fill the feedback and give a rating before clicking send button");
      openToast();
    }
    else{
      let deets={
        name:location.state.code,
        rating:rating,
        feedback:feedbackText
      }
      /*formData.append('name', location.state.code);
      formData.append('entry.21212129', rating);
      formData.append('entry.330084535', feedbackText);*/
      axios.post("/setFeedback", deets)
      .then((res) => {
        setShowFeedBack(false)
        history.push('/success')
      })
      .catch((err) => {
        console.log(err)
        history.push('/success')
      })
    }
  }

  const cancelFeedback = () => {
    setShowFeedBack(false)
    history.push("/success")
  }
  return (
    <div>
      
      <Snackbar open={open} autoHideDuration={5000} anchorOrigin={{vertical:"bottom", horizontal:"center"}} onClose={()=>setOpen(false)}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Make sure to give a rating and fill some feedback before clicking send button
        </Alert>
      </Snackbar>
      <Modal open={showFeedback} onClose={()=>{}} closeIcon={()=> {}} center styles={{modal:{borderRadius:"60px", padding:"3vh 3vw", maxWidth:"90vw"}}}>
        <div style={{display:"flex", flexDirection:"column", alignItems:'center'}}>
          <div style={{fontSize:"6vh", fontWeight:"bold", color:"rgb(8, 105, 184)"}}>Assessment completed</div>
          <p style={{fontSize:"1.85vh", color:"rgba(0,0,0,65%", width: "100%", textAlign: "center"}}>Thanks for taking the time to complete this assessment.</p>
          <p style={{fontSize:"1.85vh", marginBottom:"4vh", color:"rgba(0,0,0,65%", width: "100%", textAlign: "center"}}>You should hear about next steps shortly!</p>
          <div style={{fontSize:"2.7vh", fontWeight:"bold", color:"rgba(0,0,0,65%"}}>How was your test experience?</div>
          <div>
            {
              ratingSection
            }
          </div>
          <textarea placeholder="Anything else you want to tell us? Please write your feedback here..." rows="6" style={{width:"80%", maxWidth:"80%", borderRadius:"40px", padding:"2.5vh", fontSize:"16px", boxShadow:"inset 0 3px 3px 0px #aaa"}} onChange={(e)=>{setFeedbackText(e.target.value)}}></textarea>
          <div className="justify-content-end" style={{display:"flex", justifyContent:"flex-end", alignItems:"flex-end", width:"100%"}}>
            <Button 
            style={{
               textTransform:"none", 
               margin:"4vh 1vw 0", 
               background: '#479BD2',
               borderRadius:"30px", 
               fontFamily:"Poppins", 
               letterSpacing:"0.01em", 
               borderRadius: '25px',
               padding: '5px 12px',
               fontSize: '16px'
            }} 
            onClick={cancelFeedback}
            >
               <span style={{color: '#fff', fontSize: '16px', fontWeight: 'bold'}}>Cancel</span>
            </Button>
            <Button 
            style={{
               textTransform:"none", 
               marginTop:"4vh",
               background: '#479BD2',
               fontFamily:"Poppins", 
               letterSpacing:"0.01em", 
               borderRadius: '25px',
               padding: '5px 12px',
               fontSize: '16px'
            }} 
            onClick={sendFeedback}
            >
               <span style={{color: '#fff', fontSize: '16px', fontWeight: 'bold'}}>Send</span>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Feedback
