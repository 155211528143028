import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Grid } from '@material-ui/core';
import { Modal } from 'react-responsive-modal';
import { useLocation } from 'react-router';

import axios from '../../../axios';

import "../../../styles/btn.css";
import "../../../styles/modal.css";
import styles from '../../../styles/MultiSelect.module.css';
import '../../../styles/multiSelectQuestion.scss';

const MultiSelect = (props) => {
	const { messages, addToMessageBox, setNextQues, seeQues, setSeeQues, setProgress, addToExhibitObjs } = props;

	const location = useLocation();
	const [optionCountModal, setOptionCountModal] = useState(false);
	const [modal, setModal] = useState(false);
	const [confirmation] = useState(false);
	const [appState, changeAppState] = useState({
		activeObjects: new Set(),
		objects: messages.messages[0].options
	})

	const onOpenModal = () => setModal(true);

	const onCloseModal = () => setModal(false);

	const onOpenOptionCountModal = () => setOptionCountModal(true);

	const onCloseOptionCountModal = () => setOptionCountModal(false);

	let currqtype = messages.messages[0].qtype;
	let currMssg = messages.messages[0].q;
	let maxNo = Number(messages.messages[0].qtype.replace(/\D/g, ''));
	let options = messages.messages[0].options

	if (maxNo === 0)
		maxNo = Number.MAX_VALUE;

	const onConfirmation = () => {
		let optionsSelected = [];
		let selectedOptions = options.map((option, index) => {
			if (appState.activeObjects.has(index)) {
				let x = {
					q: option,
					isMyMessage: true,
					ref: ""
				};

				return x;
			} else
				return null;
		});

		for (let x = selectedOptions.length - 1; x >= 0; x--)
			if (selectedOptions[x] !== null)
				optionsSelected.push(selectedOptions[x]);

		if (currqtype.includes('max')) {
			if (optionsSelected.length > maxNo || optionsSelected.length == 0) {
				onOpenModal();

				return null;
			}
		} else if ((optionsSelected.length !== maxNo && maxNo !== Number.MAX_VALUE) || optionsSelected.length == 0) {
			onOpenModal();

			return null;
		}
		
		onClickConfirm();
	};

	const onClickConfirm = () => {
		setSeeQues(true);

		let optionsSelected = [];
		let selectedOptions = options.map((option, index) => {
			if (appState.activeObjects.has(index)) {
				let x = {
					q: option,
					isMyMessage: true,
					ref: ""
				};

				return x;
			} else
				return null;
		});

		for (let x = selectedOptions.length - 1; x >= 0; x--)
			if (selectedOptions[x] !== null)
				optionsSelected.push(selectedOptions[x]);

		const url = '/userconvo';
		let getData = {
			user_id: location.state.code,
			comp: location.state.comp,
			message1: messages.messages[0].qid,
			message2: [...appState.activeObjects].sort().join(','),
			mode: 0
		};
		
		addToMessageBox(optionsSelected);

		axios
			.post(url, getData, { headers: { 'Content-Type': 'application/json' } })
			.then((res) => {
				let parsedMssg = res.data.msg1[0].map((ele) => {
					return ({
						q: ele,
						ref: "na"
					})
				});
				parsedMssg[parsedMssg.length - 1]["qtype"] = res.data.msg1[1];
				parsedMssg[parsedMssg.length - 1]["options"] = res.data.msg1[2];
				parsedMssg[parsedMssg.length - 1]["qid"] = res.data.msg1[3];
				parsedMssg[parsedMssg.length - 1]["exhibit"] = res.data.msg1[4];
				parsedMssg[parsedMssg.length - 1]["progress"] = res.data.msg1[5];
				let parsedRespMssg = res.data.msg2.split(";;").map((ele) => {
					return ({
						q: ele,
						ref: "na"
					});
				});
				
				setTimeout(() => { sendToChatBox(parsedRespMssg.reverse(), parsedRespMssg.length - 1, parsedMssg) }, optionsSelected.length * 1500);
				setProgress(parseInt(res.data.msg1[5], 10));
				addToExhibitObjs(res.data.msg1);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	const sendToChatBox = (msglist, x, ques) => {
		addToMessageBox([msglist[x]]);

		if (x > 0)
			setTimeout(() => { sendToChatBox(msglist, x - 1, ques) }, 1500);
		else
			setNextQues(ques.reverse());
	}

	const toggleActive = (index) => {
		if (appState.activeObjects.has(index)) {
			let set = appState.activeObjects;

			set.delete(index);
			changeAppState({ ...appState, activeObjects: set });

			return "";
		} else {
			if (appState.activeObjects.size === maxNo && maxNo === 1) {
				let set = new Set();

				set.add(index);
				changeAppState({ objects: appState.objects, activeObjects: set });

				return "changed option";
			} else if (appState.activeObjects.size === maxNo) {
				onOpenOptionCountModal();

				return null;
			}

			let set = appState.activeObjects;

			set.add(index);
			changeAppState({ ...appState, activeObjects: set });

			return "selected";
		}
	}

	useEffect(() => {
		changeAppState({
			activeObjects: new Set(),
			objects: messages.messages[0].options
		});
	}, [messages]);

	return (
		<div id="multiSelectQuestion">
			<div style={{ display: seeQues ? "none" : "flex", flexDirection: "column" }}>
				<p style={{ color: "rgb(93, 172, 236)", padding: "2vh 4vw 0", fontSize: "16px", fontWeight: "600" }}>
					{
						currMssg.replaceAll('*', '').split('::').map(function (item, key) {
							return (
								<span>
									{item}
									{key === 0 && item[0] == "Q" ? <><br /></> : null}
									<br />
								</span>
							)
						})
					}
				</p>
				{
					confirmation ?
						<Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "2vh 10vw 5vh", display: "flex", justifyContent: 'center' }}>
							<div style={{ padding: "10px", cursor: "pointer", width: "100%", display: "flex", flexWrap: "wrap", justifyContent: 'center' }}>
								<p style={{ width: "100%", textAlign: "center", fontSize: "1.3vw", color: "#000", fontWeight: "bold" }}>Do you want to confirm these options? </p>
								{appState.objects.map((element, index = { index }) => {
									if (appState.activeObjects.has(index)) {
										return (
											<Grid
												key={index}
												xs={6}
												style={{ margin: "2vh 0 0" }}
											>
												<div style={typoStyles}>
													{element}
												</div>
											</Grid>
										)
									}

									else {
										return null;
									}
								})
								}</div>
						</Grid>
						:
						<Grid container spacing={6} className="options">
							{appState.objects.map((element, index = { index }) => {
								return (
									<Grid
										key={index}
										xs={6}
										style={{ margin: "0" }}
									>
										<div style={{ ...typoStyles, borderBottom: index < appState.objects.length - 2 ? "1.5px solid #dfdfdf" : null }} onClick={() => { toggleActive(index) }}>
											{<img alt="" src={appState.activeObjects.has(index) ? "Eo_circle_light-blue_checkmark.svg.png" : "unselected.png"} width="25px" style={{ marginRight: "12.5px" }} />}
											{element}
										</div>
									</Grid>
								)
							})
							}
						</Grid>
				}
			</div>
			<div id="chat-form" className='justify-content-end' style={{ height: "70px", display: seeQues ? "none" : "flex", alignItems: "center", justifyContent: "flex-end" }}>
				<Button variant="contained" size="small" className={styles.SubmitButton} onClick={onConfirmation}>
					<p style={{ fontSize: '16px', fontWeight: 'bold', textTransform: "none" }}>Confirm</p>
				</Button>
				<Modal open={modal} onClose={onCloseModal} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
					<p className="modal-text">
						Please select the appropriate number of options
					</p>
					<div className="button-div">
						<Button
							variant="contained"
							size="small"
							className="modal-button"
							onClick={onCloseModal}
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px',
								textTransform: 'none',
								marginLeft: "10px"
							}}
						>
							OK
						</Button>
					</div>
				</Modal>
				<Modal open={optionCountModal} onClose={onCloseOptionCountModal} center styles={{ modal: { borderRadius: "50px", padding: "40px" } }}>
					<p className="modal-text">
						Cannot select more than {maxNo}, please first deselect an option
					</p>
					<div className="button-div">
						<Button
							variant="contained"
							size="small"
							className="modal-button"
							onClick={onCloseOptionCountModal}
							style={{
								background: '#479BD2',
								fontWeight: 'bold',
								fontFamily: "Poppins",
								letterSpacing: "0.01em",
								borderRadius: '25px',
								padding: '5px 12px',
								fontSize: '16px',
								textTransform: 'none',
								marginLeft: "10px"
							}}
						>
							OK
						</Button>
					</div>
				</Modal>
			</div>
			<div id="chat-form" style={{
				display: seeQues ? "flex" : "none", height: "70px", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
					"center"
			}}>
				<button className='btn btn__secondary' onClick={() => { setSeeQues(false) }}>
					See Options
				</button>
			</div>
		</div>
	)
}

let typoStyles = {
	color: "#000",
	fontSize: "16px",
	height: "100%",
	borderBottom: "",
	padding: "0px",
	margin: "2vh",
	lineHeight: "20px",
	userSelect: "none",
	MSUserSelect: "none",
	MozUserSelect: "none",
	WebkitUserSelect: "none",
	display: "flex",
	alignItems: 'center'
};

export default MultiSelect;
