import React, {useState, useContext} from 'react'
import { Button } from '@material-ui/core'
import axios from '../../../axios'
import { GridProvider, GridContext } from "./dnd/GridContext";
import App1 from "./dnd/App"
import "../../../styles/btn.css"
import { Grid } from '@material-ui/core'
import { useLocation } from 'react-router'
import styles from '../../../styles/MultiSelect.module.css'
import {fixedAssessmentData} from "../../../constants/fixedAssessmentData"

const Dnd = ({messages, addToMessageBox, seeQues, setSeeQues, setNextQues, setProgress}) => {
  const { items, moveItem } = useContext(GridContext);
  // messages.messages[0].options
  let currMssg = messages.messages[0].q;
  let map = messages.messages[0].options.map((option, index) => {
    return {"option": option,"index": index}
  })
  let location = useLocation()
  const no = parseInt(messages.messages[0].qtype[3])
  const [options, updateOptions] = useState({values: map})

  const [confirmation, setConfirmation] = useState(false);
    const onConfirmation = () => setConfirmation(true);
    const onCloseConfirmation = () => setConfirmation(false);

  const onClickConfirm = () => {
    setSeeQues(true)
    console.log(options)
    let optionsSelected = [], selectedOptions = [];
    for(let x = no-1; x >= 0; x--){
      selectedOptions.push({q:options[x].option,isMyMessage:true})
      optionsSelected.push(options[no-1-x].index)
    }

    const url = '/userconvo'
    let getData = {
      user_id : location.state.code,
      comp: location.state.comp,
      message1 : messages.messages[0].qid,
      message2 : optionsSelected.toString(),
      mode: 0
    }
    addToMessageBox(selectedOptions)
    let msg22='Thanks. Let\'s go to the next question.'
    const msg1=fixedAssessmentData[location.state.id][parseInt(messages.messages[0].qid)]
    
    let parsedMssg = msg1[0].split(";;").map((ele,index)=>{
        return({
          q: ele,
          ref: "na"
        })
      })
      parsedMssg[parsedMssg.length-1]["qtype"] = msg1[1]
      parsedMssg[parsedMssg.length-1]["options"] = msg1[2]
      parsedMssg[parsedMssg.length-1]["qid"] = msg1[3]
      parsedMssg[parsedMssg.length-1]["exhibit"] = msg1[4]
      parsedMssg[parsedMssg.length-1]["progress"] = msg1[5]

      if(msg1[5] == "100") {
        msg22 = '';
      }
      
      
        let parsedRespMssg = msg22.split(";;").map((ele)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        
        // addToMessageBox(optionsSelected)
        setTimeout(()=>{sendToChatBox(parsedRespMssg.reverse(),parsedRespMssg.length-1,parsedMssg)},  optionsSelected.length*1500);
        //setTimeout(function(){addToMessageBox(finalQues)},1500)
        //setTimeout(()=>{setNextQues(parsedMssg.reverse())},(finalQues.length)*1500)
        setProgress(parseInt(msg1[5], 10))
   /* axios
    .post(url, getData, {headers: {'Content-Type': 'application/json'}})
    .then((res) => {
        console.log(res)
        
        let parsedMssg = res.data.msg1[0].split(";;").map((ele,index)=>{
          return({
            q: ele,
            ref: "na"
          })
        })
        parsedMssg[parsedMssg.length-1]["qtype"] = res.data.msg1[1]
        parsedMssg[parsedMssg.length-1]["options"] = res.data.msg1[2]
        parsedMssg[parsedMssg.length-1]["qid"] = res.data.msg1[3]
        parsedMssg[parsedMssg.length-1]["progress"] = res.data.msg1[5]
        parsedMssg[parsedMssg.length-1]["exhibit"] = res.data.msg1[4]
        
        let parsedRespMssg = res.data.msg2.split(";;").map((ele)=>{
          return({
            q: ele,
            ref: "na"
          })
        })

        
        // addToMessageBox(optionsSelected)
        setTimeout(()=>{sendToChatBox(parsedRespMssg.reverse(),parsedRespMssg.length-1,parsedMssg)},  optionsSelected.length*1500);
        //setTimeout(function(){addToMessageBox(finalQues)},1500)
        //setTimeout(()=>{setNextQues(parsedMssg.reverse())},(finalQues.length)*1500)
        setProgress(parseInt(res.data.msg1[5], 10))
      })
      .catch((err) => {
        console.log(err)
      })*/
  }
  function sendToChatBox(msglist, x, ques) {  
    addToMessageBox([msglist[x]])
    if (x > 0)
      setTimeout(() => { sendToChatBox(msglist, x - 1, ques) }, 1500);
    else
      if (ques!='na') setNextQues(ques.reverse())
  }

  return (
        <div style={{ background: "#fff", borderRadius:"2vh", boxShadow:"0.1rem 0.5rem 1rem grey", width:"50vw" }}>
            
          <p style={{color:"#0B76BC", padding:"2vh 4vw 0", fontSize:"16px", fontWeight:"600"}}> {currMssg.replaceAll('*','').split('::').map(function(item, key) {
            return (
            <span >
                {item}
                {key===0?<><br/></>:null}
                <br/>
            </span>
            )
        })}</p>
            <div style={{ display: seeQues ? "none" : "flex", flexDirection: "column" }}>
                <Grid lg={12} style={{ display: confirmation ? "flex" : "none",margin: "auto " }}>
                    <div style={{ padding: "10px", cursor: "pointer", width: "100%", display: "flex", justifyContent: "center" }}>
                <p style={{ width: "100%", textAlign: "center", fontSize: "1.5vw", color: "white", fontWeight: "bold" }}>Do you want to confirm the options in the following order? </p>
                </div>
                </Grid>
                {confirmation ?

                    <Grid container spacing={6} className="options" style={{ listStyle: "none", padding: "5vh 10vw", display: "flex", justifyContent: "center" }}>{
                        options.slice(0, no).map((element, index = { index }) => {
                            return (
                                <Grid
                                    key={index}
                                    xs={12}
                                    style={{
                                        margin: "auto 0", display: "flex",
                                        alignItems: "stretch",
                                        width: "25vw",

                                        boxSizing: "border-box"
                                    }}>

                                    <p style={{ display: "flex", fontSize: "2.4vh", color: "#ffffff", margin: "auto" }} >
                                        {index + 1}.
                                    </p>

                                    <li
                                        className={index < no ? "selected" : ""}
                                        style={typoStyles}>
                                        {options[index].option}
                                    </li>

                                </Grid>
                            )
                        }
                        )}
                    </Grid>
                    : <GridProvider options={map} updateOptions={updateOptions} noOfRight={parseInt(messages.messages[0].qtype[3], 10)}>
                        <App1 />
                    </GridProvider>}
            </div>
            <div id="chat-form" style={{ height: "70px", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", display: seeQues ? "none" : "flex", justifyContent: "center", justifyContent:"flex-end" }}>
                {confirmation ? <div style={{ display: "flex", width: "40%" }}>
                <Button className={styles.SubmitButton} onClick={onCloseConfirmation} style={{}}>
                    Back
                </Button>
                <Button className={styles.SubmitButton} onClick={onClickConfirm} style={{}} >
                    Confirm
                </Button> </div> :
                <div>
                    <Button className={styles.SubmitButton} onClick={onClickConfirm}>
                        <p style={{ fontSize:'16px',fontWeight:'bold',textTransform:"none"}}>Confirm</p>
                    </Button>
                </div>
                }
            </div>
            <div id="chat-form" style={{
                display: seeQues ? "flex" : "none", height: "8vh", background: "#0F3F79", borderTopRightRadius: "2vh", borderTopLeftRadius: "2vh", alignItems: "center", justifyItems:
                    "center"
            }}>
                <button className='btn btn__secondary' onClick={() => { setSeeQues(false) }}>
                    See Options
                </button>
            </div>
        </div>
    )
}

let typoStyles = {
  color: "#000",
  fontSize: "18px",
  background: "#dbdbdb",
  borderRadius:"12px",
  padding: "12px",
  margin: "10px",
  lineHeight: "20px",
  userSelect: "none",
  cursor: "grabbing",
  width:"65%",
  cursor: "-webkit-grabbing",
  MSUserSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  textAlign: "center"
}


export default Dnd
